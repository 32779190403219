import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { U2BPatternValidator } from '@shared/validators/core/pattern';

export class NumberFormatValidator extends BaseValidator {

    public validationError = new BaseValidationError(
        'numberFormat',
        this.message || 'Nur ganze Zahlen oder Kommazahlen mit maximal 4 Stachkommastellen erlaubt. Dezimaltrennzeichen: "," (Komma)'
    );

    constructor(private message?: string) {
        super();
    }

    public validate(control: AbstractControl): ValidationErrors {
        return new U2BPatternValidator(/^-?[0-9]+([.|,][0-9]{1,4})?$/, this.validationError.message).validate(control);
    }
}
