import { ListItemType, U2bNavigationItem } from './navigation.shared';

export enum U2bNavigationElementKey {
    Header = 'header',
    ServiceRequest = 'service_request',
    AppInvite = 'app_invite',
    Version = 'version',
}

const coreNavigationElements: { [key in U2bNavigationElementKey]: U2bNavigationItem } = {
    [U2bNavigationElementKey.Header]: {
        id: 'tenant_name',
        title: 'Yachthafenverwaltung',
        type: ListItemType.Item,
        url: '#',
        isText: true
    },
    [U2bNavigationElementKey.ServiceRequest]: {
        id: 'service-request',
        title: 'Service-Anfrage',
        type: ListItemType.Item,
        icon: 'live_help',
        url: 'https://www.up2boat.com/support',
        externalUrl: true,
        openInNewTab: true,
    },
    [U2bNavigationElementKey.AppInvite]: {
        id: 'app-invite',
        title: 'Zur App einladen!',
        type: ListItemType.Item,
        icon: 'send',
        function: () => null
    },
    [U2bNavigationElementKey.Version]: {
        id: 'bcm-version',
        title: ``,
        type: ListItemType.Item,
        classes: 'release-info',
        function: () => null
    },
};

export const getHeaderNavItem: (title: string) => U2bNavigationItem =
    (title) => ({ ...coreNavigationElements[U2bNavigationElementKey.Header], title });

export const getServiceRequestNavItem: () => U2bNavigationItem =
    () => ({ ...coreNavigationElements[U2bNavigationElementKey.ServiceRequest] });

export const getAppInviteNavItem: (onClick: () => void) => U2bNavigationItem =
    (onClick: () => void) => ({ ...coreNavigationElements[U2bNavigationElementKey.AppInvite], function: onClick });

export const getVersionNavItem: (title: string, onClick: () => void) => U2bNavigationItem =
    (title, onClick) => ({...coreNavigationElements[U2bNavigationElementKey.Version], function: onClick, title});
