import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BcmApiService } from './bcm-api.service';

export interface BcmPermission {
    id: number;
    permission: string;
    title: string;
    note: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class BcmPermissionsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'permissions';

    getAll(params: HttpParams = new HttpParams()): Observable<BcmPermission[]> {
        return super.get<BcmPermission[]>(this.RESOURCE_ENDPOINT, params);
    }

    getAllFromActiveUser(params: HttpParams = new HttpParams()): Observable<BcmPermission[]> {
        return super.get<BcmPermission[]>(this.RESOURCE_ENDPOINT + '/active-user', params);
    }

}
