import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BcmOrder, BcmOrderDto } from '@shared/models/bcm-order';
import { BcmOrderStatus, BcmOrderStatusDto, BcmOrderStatusType } from '@shared/models/bcm-order-status';
import { BcmApiService } from '@bcmServices/index';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BcmOrderInvoicePosition, BcmOrderInvoicePositionDto } from '@shared/models/bcm-order-invoice-position';


@Injectable({
    providedIn: 'root'
})
export class OrdersApi extends BcmApiService {
    getMany(params: HttpParams = new HttpParams()): Observable<BcmOrder[]> {
        return super.get<BcmOrderDto[]>('orders', params)
            .pipe(parseHttpResponseList<BcmOrderDto, BcmOrder>(BcmOrder));
    }

    getSingle(id: number, params: HttpParams = new HttpParams()): Observable<BcmOrder> {
        return super.get<BcmOrderDto>(`orders/${id}`, params)
            .pipe(parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder));
    }

    create(newOrder: Partial<BcmOrderDto>, sendMail: boolean): Observable<BcmOrder> {
        return super.post<{
            order: BcmOrderDto,
            mailSentError: Error
        }>('orders', newOrder, new HttpParams().set('sendMail', sendMail))
            .pipe(
                // todo: how to handle mailSentError ?
                map(({order}) => order),
                parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder)
            );
    }

    update(updatedOrder: BcmOrder, sendMail: boolean): Observable<BcmOrder> {
        return super.put<{
            order: BcmOrderDto,
            mailSentError: Error
        }>(`orders/${updatedOrder.id}`, updatedOrder, new HttpParams().set('sendMail', sendMail))
            .pipe(
                // todo: how to handle mailSentError ?
                map(({order}) => order),
                parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder)
            );
    }

    updateStatus(order: BcmOrder, orderStatus: BcmOrderStatusType, sendMail: boolean): Observable<{
        orderStatus: BcmOrderStatus,
        order: BcmOrder
    }> {
        return super.post<{
            orderStatus: BcmOrderStatusDto,
            order: BcmOrderDto
        }>(`orders/${order.id}/status`, {orderStatus, sendMail})
            .pipe(
                map((data) => {
                    return {
                        order: new BcmOrder(data.order),
                        orderStatus: new BcmOrderStatus(data.orderStatus)
                    };
                })
            );
    }

    remove(order: BcmOrder): Observable<number> {
        return super.delete(`orders/${order.id}`);
    }

    addOrderInvoicePosition(invoicePosition: Partial<BcmOrderInvoicePositionDto>): Observable<BcmOrderInvoicePosition> {
        return super.post<BcmOrderInvoicePositionDto>(`orders/${invoicePosition.bcm_orders_id}/invoice-positions`, invoicePosition)
            .pipe(parseHttpResponseItem<BcmOrderInvoicePositionDto, BcmOrderInvoicePosition>(BcmOrderInvoicePosition));
    }

    deleteOrderInvoicePosition(order: BcmOrder, orderInvoicePosition: BcmOrderInvoicePosition): Observable<void> {
        return super.delete<void>(`orders/${order.id}/invoice-positions/${orderInvoicePosition.id}`);
    }

}
