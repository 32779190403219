import { Injectable } from '@angular/core';
import { Product } from '@shared/models/product';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { FormControl } from '@angular/forms';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';

@Injectable({providedIn: 'root'})
export class InvoicePositionDialogService {

    constructor(
        private confirmDialogService: ConfirmDialogService
    ) {
    }

    public checkForCostCenterAvailability(product: Product, costCenterControl: FormControl) {
        if (!costCenterControl?.value
            && product instanceof Product
            && product?.costCenters?.length) {
            this.confirmDialogService
                .setTitle('Kostenstelle hinzufügen')
                .setBody('Möchtest Du eine mit diesem Produkt verknüpfte Kostenstelle ins Formular übernehmen?')
                .appendSelectToBody<BcmCostCenter>({
                    name: 'costCenter',
                    type: 'select',
                    label: 'Kostenstelle',
                    list: product?.costCenters,
                    listAttribute: 'name',
                })
                .openAndReturnResult<{ costCenter: BcmCostCenter }>()
                .subscribe(result => {
                    if (result?.costCenter) {
                        costCenterControl.setValue(result.costCenter);
                    }
                });
        }
    }
}
