<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="memberForm">

            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input type="text" matInput data-test="FI-Personen durchsuchen..." formControlName="member"
                           placeholder="Personen durchsuchen..."
                           [matAutocomplete]="addressSelector">
                    <mat-error>{{memberForm.get('member').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>
            </div>

            <mat-autocomplete #addressSelector="matAutocomplete" [displayWith]="displayMemberWith">
                <mat-option *ngFor="let member of (filteredMembers$ | async)" [value]="member">
                    <span>{{ member.firstName }} {{ member.lastName }}</span>
                </mat-option>
            </mat-autocomplete>

            <ng-container *ngIf="includeKinshipFields">
                <div>
                    <mat-form-field appearance="outline" fxFlex="20">
                        <mat-label>Prefix</mat-label>
                        <mat-select data-test="FS-Prefix" formControlName="kinshipDegreePrefix" #prefixField>
                            <mat-option [value]="'Groß-'">Groß-</mat-option>
                            <mat-option [value]="'Ur-'">Ur-</mat-option>
                            <mat-option [value]="'Enkel-'">Enkel-</mat-option>
                            <mat-option [value]="'Halb-'">Halb-</mat-option>
                            <mat-option [value]="'Adoptiv-'">Adoptiv-</mat-option>
                            <mat-option [value]="'Wahl-'">Wahl-</mat-option>
                            <mat-option [value]="'Schwieger-'">Schwieger-</mat-option>
                            <mat-option [value]="'Stief-'">Stief-</mat-option>
                        </mat-select>
                        <mat-error>{{memberForm.get('kinshipDegreePrefix').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="80">
                        <mat-label>Verwandschaftsgrad</mat-label>
                        <mat-select data-test="FS-Verwandschaftsgrad" formControlName="kinshipDegree">
                            <mat-option [value]="'Vater'">Vater</mat-option>
                            <mat-option [value]="'Mutter'">Mutter</mat-option>
                            <mat-option [value]="'Kind'">Kind</mat-option>
                            <mat-option [value]="'Sohn'">Sohn</mat-option>
                            <mat-option [value]="'Tochter'">Tochter</mat-option>
                            <mat-option [value]="'Enkel'">Enkel</mat-option>
                            <mat-option [value]="'Enkelin'">Enkelin</mat-option>
                            <mat-option [value]="'Neffe'">Neffe</mat-option>
                            <mat-option [value]="'Nichte'">Nichte</mat-option>
                            <mat-option [value]="'Onkel'">Onkel</mat-option>
                            <mat-option [value]="'Tante'">Tante</mat-option>
                            <mat-option [value]="'Cousin'">Cousin</mat-option>
                            <mat-option [value]="'Cousine'">Cousine</mat-option>
                            <mat-option [value]="'Ehe- / Lebenspartner'">Ehe- / Lebenspartner</mat-option>
                        </mat-select>
                        <mat-error>{{memberForm.get('kinshipDegree').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <u2b-message>
                    Um einen Verwandschaftsgrad wie z.B. <strong>Großvater</strong> zu wählen, nutze zusätzlich das Feld
                    <a class="mat-primary" href="javascript:void(0)" (click)="prefixField.focus()">Prefix</a>.
                </u2b-message>
            </ng-container>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Speichern" mat-flat-button
                (click)="save()"
                class="save-button mat-accent"
                [disabled]="isSaving || !memberForm.dirty"
                aria-label="Speichern">
            Speichern
        </button>

    </div>
</div>
