export enum UnitUniqueName {
    KWH = 'kwh',
    YEAR = 'year',
    QUARTER = 'quarter',
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day',
    HOUR = 'hour',
    PIECE = 'piece',
    TON = 't',
    KILOGRAM = 'kg',
    SQM = 'sqm',
    GRAM = 'g',
    LITER = 'l',
    MILLILITER = 'ml',
    METER = 'm',
    CENTIMETER = 'cm',
    SEASON = 'season',
    UNIT = 'unit',
    OVERNIGHT_STAY = 'overnight_stay',
}

export interface IUnit {
    id: number;
    uniqueName: UnitUniqueName;
    name: string;
    note?: string;
}
