import { Injectable } from '@angular/core';
import { BcmSettingsApiService } from '@modules/bcm/@shared/services';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmUserSettingsStateService } from '@bcmServices/settings/bcm-user-settings-state.service';
import {
    BcmUserSettingKey,
    BcmUserSettings,
    BcmUserSettingsSection,
    BcmUserTableSettingKey
} from '@shared/models/bcm-settings-user';

@Injectable({providedIn: 'root'})
export class BcmUserSettingsFacade {

    get isUpdating$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    get settings$(): Observable<BcmUserSettings> {
        return this.state.getSettings$();
    }

    constructor(private api: BcmSettingsApiService,
                private state: BcmUserSettingsStateService,
                private appNotificationService: AppNotificationService,
    ) {
    }

    loadSettings(): Observable<BcmUserSettings> {
        return this.api.getUserSettings()
            .pipe(tap(settings => this.state.setSettings(settings)));
    }

    updateSingleSetting<T extends BcmUserSettingsSection>(
        key: BcmUserSettingKey | BcmUserTableSettingKey | string,
        settings: T,
        preventMessage = false,
    ): Subscription {
        this.state.startUpdating();

        return this.api.updateSingleUserSetting<T>(key, settings)
            .subscribe({
                next: () => {
                    if (!preventMessage) {
                        this.appNotificationService.showSuccess('Einstellung(en) erfolgreich gespeichert');
                    }
                    this.state.updateSingleSetting<T>(key, settings);
                },
                error: (error) => this.handleError(error),
                complete: () => this.state.stopUpdating()
            });
    }

    updateMultipleSettings(
        items: {
            key: (BcmUserSettingKey | BcmUserTableSettingKey | string),
            settings: BcmUserSettingsSection
        }[],
        preventMessage = false,
    ): Subscription {
        this.state.startUpdating();
        return forkJoin(items.map(item => this.api.updateSingleUserSetting(item.key, item.settings)))
            .subscribe({
                next: () => {
                    if (!preventMessage) {
                        this.appNotificationService.showSuccess('Einstellung(en) erfolgreich gespeichert');
                    }
                    for (const item of items) {
                        this.state.updateSingleSetting(item.key, item.settings);
                    }
                },
                error: (error) => this.handleError(error),
                complete: () => this.state.stopUpdating()
            });
    }

    private handleError(error: Error): void {
        // todo: handle settings specific errors and propagate rest with throw
        throw error;
    }
}
