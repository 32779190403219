import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { U2bAdminBaseApi } from '@modules/admin/state/u2b-admin-base.api';

type LAST_INSERT_ID = number;
type EFFECTED_ROWS = number;

export class U2bAdminApi extends U2bAdminBaseApi {

    protected className = 'U2bAdminApi';

    protected endpoint = environment.endpoints.u2b.apiAdmin;

    constructor(_http: HttpClient) {
        super(_http);
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return super.get<T>(path, params);
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(path, params);
    }

    protected post<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.post<T>(path, body);
    }

    protected put<T = EFFECTED_ROWS>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.put<T>(path, body);
    }

    protected delete<T = EFFECTED_ROWS>(path, params: HttpParams = new HttpParams()): Observable<T> {
        return super.delete<T>(path);
    }
}
