import {
    AfterViewInit,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
    MatLegacyFormFieldAppearance as MatFormFieldAppearance,
    MatLegacyFormFieldControl as MatFormFieldControl
} from '@angular/material/legacy-form-field';
import { hours, minutes } from '@shared/constants/date';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { setHours, setMinutes } from 'date-fns';
import { toDateStringByFormat } from '@core/functions/to-date-string';
import { isValidDate } from '@core/functions/is-valid-date';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

@Component({
    selector: 'app-input-date-time',
    templateUrl: './date-time-input.component.html',
    styleUrls: ['./date-time-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: DateTimeInputComponent
        }
    ],
})
export class DateTimeInputComponent extends BaseInputComponent<Date> implements AfterViewInit, OnInit, OnChanges {

    errorStateMatcher: DefaultInputErrorStateMatcher;

    hours = hours;

    minutes = minutes;

    placeholder: string;

    @Input() fc: AbstractControl;

    @Input() appearance: MatFormFieldAppearance = 'outline';

    @Input() minDate?: Date;

    @Input() maxDate?: Date;

    @Input() selectionStartDate?: Date;

    @Input() stepMinutes = 1;

    @Input() endOfTheDay = false;

    @ViewChild('dateInput') dateInput: HTMLInputElement;

    @ViewChild('hoursInput') hoursInput: MatInput;

    @ViewChild('minutesInput') minutesInput: MatInput;

    ngAfterViewInit(): void {
        this._updateSelects(this.value);
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stepMinutes) {
            const step = changes.stepMinutes.currentValue;
            this.minutes = minutes
                .filter(m => m === '59' || Number(m) % step === 0);
        }
    }

    public writeValue(inputValue: Date): void {
        super.writeValue(inputValue);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'value', inputValue);
        this._updateSelects(inputValue);
    }

    public setDisabledState(isDisabled: boolean): void {
        super.setDisabledState(isDisabled);
        const {nativeElement} = this.el;
        this._renderer.setProperty(nativeElement, 'disabled', isDisabled);
    }

    inputChange(event: MatDatepickerInputEvent<unknown, unknown | null>): void {
        super.value = event.value as Date;

        if (super.value == null) {
            return;
        }

        if (this.endOfTheDay) {
            super.value = setHours(super.value, 23);
            super.value = setMinutes(super.value, 59);
        }

        if (this.value == null) {
            this.hoursInput.value = null;
            this.minutesInput.value = null;
        }
    }

    setHours(event: Event, matEvent?: MatAutocompleteSelectedEvent): void {
        let inputValue = parseInt(matEvent?.option?.value || (event?.target as HTMLInputElement)?.value, 10);

        if (inputValue > 23) {
            inputValue = 23;
            this.hoursInput.value = inputValue;
        }

        if (isNaN(inputValue)) {
            inputValue = 0;
            this.minutesInput.value = inputValue;
        }

        super.value = setHours(this.value, inputValue);
    }

    setMinutes(event: Event, matEvent?: MatAutocompleteSelectedEvent): void {
        let inputValue = parseInt(matEvent?.option?.value || (event?.target as HTMLInputElement)?.value, 10);

        if (inputValue % this.stepMinutes !== 0) {
            inputValue = Math.round(inputValue / this.stepMinutes) * this.stepMinutes;
            inputValue = Math.min(59, inputValue);
            this.minutesInput.value = inputValue;
        }

        if (isNaN(inputValue)) {
            inputValue = 0;
            this.minutesInput.value = inputValue;
        }

        super.value = setMinutes(this.value, inputValue);
    }

    resetControl(): void {
        super.value = null;
        this.hoursInput.value = null;
        this.minutesInput.value = null;
    }

    private _updateSelects(date: Date): void {
        if (this.hoursInput && this.minutesInput && isValidDate(date)) {
            const hoursValue = toDateStringByFormat(date, 'HH');
            const minutesValue = toDateStringByFormat(date, 'mm');
            this.hoursInput.value = parseInt(hoursValue, 10) === 0
                ? '00'
                : hoursValue;
            this.minutesInput.value = parseInt(minutesValue, 10) === 0
                ? '00'
                : minutesValue;
        }
    }
}
