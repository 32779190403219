import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPayByCashComponent } from './check-pay-by-cash.component';
import { SharedModule } from '@shared/shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';


@NgModule({
    declarations: [
        CheckPayByCashComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FuseSharedModule,
        DragDropModule,
        MatButtonToggleModule,
    ],
    exports: [
        CheckPayByCashComponent
    ]
})
export class CheckPayByCashModule {
}
