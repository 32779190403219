import { U2bTableData } from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';

export const costCentersTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.CostCenters,
    hasServerSideFiltering: true,
    columnDefinitions:  [
        {
            property: 'name',
            name: 'Name',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'abbreviation',
            name: 'Kürzel',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'number',
            name: 'Nummer',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'description',
            name: 'Beschreibung',
            isActive: getIsActive(),
            filter: {},
        },
    ]
};
