export class D {
    static readonly DATE_DE = 'dd.MM.yyyy';
    static readonly DATE_ISO = 'yyyy-MM-dd';
    static readonly DATE_TIME_DE = `${D.DATE_DE} HH:mm`;
    static readonly DATE_TIME_DE_INCLUDING_SECONDS = `${D.DATE_DE} HH:mm:ss`;
    static readonly DATE_TIME_ISO = 'yyyy-MM-dd HH:mm:ss';
    static readonly DATE_TIME_FULL = `fullDate`;
    static readonly DATE_TIME_MEDIUM = `medium`;
}

export const DATE_DE = 'dd.MM.yyyy';
export const DATE_ISO = 'yyyy-MM-dd';
export const DATE_TIME_DE = `${DATE_DE} HH:mm`;
export const DATE_TIME_DE_INCLUDING_SECONDS = `${DATE_DE} HH:mm:ss`;
export const DATE_TIME_ISO = 'yyyy-MM-dd HH:mm';
export const DATE_TIME_ISO_INCLUDING_SECONDS = 'yyyy-MM-dd HH:mm:ss';
export const DATE_TIME_FULL = `fullDate`;
export const DATE_TIME_MEDIUM = `medium`;

// tslint:disable-next-line:no-bitwise
export const hours = Array(24).fill(0).map((_, i) => ('0' + ~~(i)).replace(/\d(\d\d)/g, '$1'));
// tslint:disable-next-line:no-bitwise
export const minutes = Array(60).fill(0).map((_, i) => ('0' + ~~(i)).replace(/\d(\d\d)/g, '$1'));
