<div [formGroup]="contractBuildingCostSubsidyForm">

    <h2>Baukostenzuschuss</h2>

    <div>
        <p>
            <mat-checkbox [(ngModel)]="captureBuildingCostSubsidy" [ngModelOptions]="{standalone: true}">
                Baukostenzuschuss erfassen
            </mat-checkbox>
        </p>
    </div>

    <ng-container *ngIf="captureBuildingCostSubsidy">


        <div fxLayout="column wrap">

            <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                    <mat-label>Aus Produkten wählen</mat-label>
                    <input type="text" matInput data-test="FI-Aus Produkten wählen"
                           autocomplete="new-password"
                           [formControl]="productControl"
                           [matAutocomplete]="productSelector">
                </mat-form-field>

                <mat-autocomplete #productSelector="matAutocomplete" [displayWith]="displayProductWith">
                    <mat-option *ngFor="let product of (filteredProducts$ | async)" [value]="product">
                        <span>{{ product.name }}</span>
                        <small> | {{product.price | bcmDynamicCurrency: '1.2-2'}}</small>
                    </mat-option>
                </mat-autocomplete>
            </div>

            <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                <mat-label>Betrag Netto</mat-label>
                <input matInput
                       type="number"
                       step=".01"
                       data-test="FI-Betrag Netto"
                       formControlName="BKZ_BETRAG_NETTO"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                <mat-label>MwSt</mat-label>
                <input matInput
                       type="number"
                       step=".01"
                       data-test="FI-MwSt"
                       formControlName="BKZ_MWST"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>MwSt Betrag</mat-label>
                <input matInput
                       type="number"
                       step=".01"
                       data-test="FI-MwSt Betrag"
                       formControlName="BKZ_MWST_BETRAG"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Netto Gesamt</mat-label>
                <input matInput
                       type="number"
                       step=".01"
                       data-test="FI-Netto Gesamt"
                       formControlName="BKZ_BETRAG_GESAMT_NETTO"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Brutto Gesamt</mat-label>
                <input matInput
                       type="number"
                       step=".01"
                       data-test="FI-Brutto Gesamt"
                       formControlName="BKZ_BETRAG_GESAMT_BRUTTO"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Zahlung fällig zum</mat-label>
                <input matInput
                       type="text"
                       data-test="FI-Zahlung fällig zum"
                       formControlName="BKZ_BETRAG_FAELLIG_ZUM"
                       autocomplete="new-password">
            </mat-form-field>


            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Teilzahlungsbetrag 1</mat-label>
                <input matInput
                       type="text"
                       data-test="FI-Teilzahlungsbetrag 1"
                       formControlName="BKZ_TZ_1_BETRAG"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Teilzahlungsbetrag 2</mat-label>
                <input matInput
                       type="text"
                       data-test="FI-Teilzahlungsbetrag 2"
                       formControlName="BKZ_TZ_2_BETRAG"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Fälligkeit Teilzahlungsbetrag 1</mat-label>
                <input matInput
                       type="text"
                       data-test="FI-Fälligkeit Teilzahlungsbetrag 1"
                       formControlName="BKZ_TZ_1_FAELLIG_ZUM"
                       autocomplete="new-password">
            </mat-form-field>

            <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
                <mat-label>Fälligkeit Teilzahlungsbetrag 2</mat-label>
                <input matInput
                       type="text"
                       data-test="FI-Fälligkeit Teilzahlungsbetrag 2"
                       formControlName="BKZ_TZ_2_FAELLIG_ZUM"
                       autocomplete="new-password">
            </mat-form-field>

        </div>

    </ng-container>

</div>
