import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { BcmDefaultAsset, BcmDefaultAssetDto } from '@shared/models/bcm-default-asset';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({
    providedIn: 'root'
})
export class DefaultAssetsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'default-assets';

    getAll(params: HttpParams = new HttpParams()): Observable<BcmDefaultAsset[]> {
        return super.get<BcmDefaultAssetDto[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<BcmDefaultAssetDto, BcmDefaultAsset>(BcmDefaultAsset));
    }

    add(defaultAsset: BcmDefaultAssetDto): Observable<BcmDefaultAsset> {
        return super.post<BcmDefaultAsset>(this.RESOURCE_ENDPOINT, defaultAsset)
            .pipe(parseHttpResponseItem<BcmDefaultAssetDto, BcmDefaultAsset>(BcmDefaultAsset));
    }

    update(defaultAsset: BcmDefaultAsset): Observable<BcmDefaultAsset> {
        return super.put<BcmDefaultAsset>(`${this.RESOURCE_ENDPOINT}/${defaultAsset.id}`, defaultAsset)
            .pipe(parseHttpResponseItem<BcmDefaultAssetDto, BcmDefaultAsset>(BcmDefaultAsset));
    }

    remove(defaultAsset: BcmDefaultAsset): Observable<void> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${defaultAsset.id}`);
    }
}
