import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayByCashDialogComponent } from './pay-by-cash-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';


@NgModule({
    declarations: [
        PayByCashDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        FuseSharedModule,
        DragDropModule,
        MatButtonToggleModule,
        BcmPipesModule
    ],
    exports: [
        PayByCashDialogComponent
    ]
})
export class PayByCashDialogModule {
}
