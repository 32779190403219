<ng-container *ngIf="isLoading">
    <div class="u2b-loading-shade">
        <mat-spinner></mat-spinner>
    </div>
</ng-container>
<div [class.disabled]="isLoading" class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane"
     cdkDragBoundary="body">
    <form [formGroup]="formGroup" appInvalidControlScroll
          [scrollContainer]="scrollContainer">

        <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
            <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">
                    {{ berthAssignmentTitle }}
                    <ng-container *ngIf="givenBerth">
                        - Liegeplatz {{ givenBerth.handle }}
                    </ng-container>
                </span>
                <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                        aria-label="Dialog schließen" type="button">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>

        <div mat-dialog-content cdkScrollable #scrollContainer>

            <ng-container *ngIf="showAssignmentTypeSelect">
                <div>
                    <mat-form-field fxFlex="100" appearance="outline" floatLabel="always">
                        <mat-label>Reservierung / Buchung</mat-label>
                        <mat-select data-test="FS-Typ"
                                    formControlName="assignmentType">
                            <mat-option [value]="BerthAssignmentType.Reservation">
                                Reservierung
                            </mat-option>
                            <mat-option [value]="BerthAssignmentType.Booking">
                                Buchung
                            </mat-option>
                        </mat-select>
                        <mat-error>{{ formGroup.get('assignmentType').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <form-widget-date-range [parentFormGroup]="formGroup"
                                    [headline]="'An- und Abreise'"
                                    [from]="givenFromDate"
                                    [fromLabel]="'Anreise'"
                                    [to]="givenToDate"
                                    [toLabel]="'Abreise'"></form-widget-date-range>

            <ng-container *ngIf="defaultBerthReservationTimeUnit?.type && durationOfStay > 0">
                <u2b-message type="info" class="mt-16" [collapsable]="true"
                             [header]="'Dauer der ' + berthAssignmentTitle + ' beträgt ' + durationOfStay + ' ' + (defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tag(e).' : 'Übernachtung(en).')">
                    In den Hafeneinstellungen wurde festgelegt, dass die {{ berthAssignmentTitle }} anhand der Anzahl
                    der
                    <b>{{ defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tage' : 'Übernachtungen' }}</b>
                    berechnet wird.<br><br>
                    <ng-container
                            *ngIf="defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY">
                        Der Zeitraum einer Übernachtung beginnt
                        um {{ defaultBerthReservationTimeUnit.fromHours + ':' + defaultBerthReservationTimeUnit.fromMinutes }}
                        Uhr und endet am darauf folgenden Tag um
                        {{ defaultBerthReservationTimeUnit.toHours + ':' + defaultBerthReservationTimeUnit.toMinutes }}
                        Uhr.<br><br>
                    </ng-container>
                    Die Dauer dieser {{ berthAssignmentTitle }} beträgt
                    <b>{{ durationOfStay }} {{ defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.DAY ? 'Tag(e).' : 'Übernachtung(en).' }}</b>
                    <br><br>
                    Anreise: {{ formGroup.get('dateRangeForm')?.get('from')?.value | dateTimeFormat: 'dd.MM. hh:mm' }}
                    <br>
                    Abreise: {{ formGroup.get('dateRangeForm')?.get('to')?.value | dateTimeFormat: 'dd.MM. hh:mm' }}<br>
                    <ng-container
                            *ngIf="defaultBerthReservationTimeUnit.type === DEFAULT_BERTH_RESERVATION_UNIT.OVERNIGHT_STAY && (!isAfterPeriodStarts || isAfterPeriodEnds)">
                        <br>
                        - Entspricht {{ totalDays }} Übernachtung(en).
                        <ng-container *ngIf="!isAfterPeriodStarts">
                            <br>
                            - Zusätzlich 1 Übernachtung, da Anreise
                            vor {{ defaultBerthReservationTimeUnit.fromHours + ':' + defaultBerthReservationTimeUnit.fromMinutes }}
                            Uhr.
                        </ng-container>
                        <ng-container *ngIf="!sameDay && isAfterPeriodEnds">
                            <br>
                            - Zusätzlich 1 Übernachtung, da Abreise
                            nach {{ defaultBerthReservationTimeUnit.toHours + ':' + defaultBerthReservationTimeUnit.toMinutes }}
                            Uhr.
                        </ng-container>
                    </ng-container>
                </u2b-message>
            </ng-container>

            <mat-divider class="special-divider"></mat-divider>

            <ng-container *ngIf="!givenBerth">
                <form-widget-berth-only [parentFormGroup]="formGroup"></form-widget-berth-only>
                <mat-divider class="special-divider"></mat-divider>
            </ng-container>

            <section>
                <mat-checkbox (change)="captureBoatChange($event)"
                              [checked]="captureBoat">
                    {{ 'captureBoat' | translate }}
                </mat-checkbox>

                <ng-container *ngIf="captureBoat">
                    <form-widget-boat [givenBerth]="givenBerth"
                                      [canSave]="true"
                                      [showMetaData]="true"></form-widget-boat>

                    <ng-container *ngIf="boatToHeavy">
                        <u2b-message type="warning" [customClass]="'mt-16'">
                            <header>{{ 'boatToHeavyForBuoy' | translate }}</header>
                            <p [innerHtml]="'boatToHeavyForBuoyText' | translate : {
                                    'berthMaxWeight': (berthMaxWeight | number: '1.0-2'),
                                    'boatWeight': (boatWeight | number: '1.0-2'),
                                    'boatToHeavy': (boatToHeavy | number: '1.0-2')
                                }">
                            </p>
                        </u2b-message>
                    </ng-container>

                    <ng-container *ngIf="boatToLong || boatToWide">
                        <u2b-message type="warning" [customClass]="'mt-16'">
                            <header>{{ 'boatDoesNotFitBerth' | translate }}</header>
                            <ng-container *ngIf="boatToLong">
                                <p [innerHtml]="'boatLengthDoesNotFitBerth' | translate : {
                                        'boatToLong': (boatToLong | number: '1.0-2'),
                                        'berthLength': (givenBerth.length | number: '1.0-2')
                                    }">
                                </p>
                            </ng-container>
                            <ng-container *ngIf="boatToWide">
                                <p [innerHtml]="'boatWidthDoesNotFitBerth' | translate : {
                                        'boatToWide': (boatToWide | number: '1.0-2'),
                                        'berthLength': (givenBerth.width | number: '1.0-2')
                                    }">
                                </p>
                            </ng-container>
                        </u2b-message>
                    </ng-container>

                    <mat-divider class="special-divider"></mat-divider>
                </ng-container>
            </section>

            <h2>
                Wer {{ givenBerthAssignmentType === BerthAssignmentType.Reservation ? 'reserviert' : 'bucht' }}?
            </h2>

            <ng-container *ngIf="formGroupPrepare.get('type').touched && formGroupPrepare.get('type').errors">
                <u2b-message type="warning">
                    {{ formGroupPrepare.get('type').errors | firstErrorMessage }}
                </u2b-message>
            </ng-container>

            <div [formGroup]="formGroupPrepare">
                <mat-radio-group class="radio-group-inline" formControlName="type">
                    <mat-radio-button [value]="'person'">Person</mat-radio-button>
                    <mat-radio-button [value]="'organisation'">Organisation</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="formGroupPrepare.get('type').value === 'person'">
                <form-widget-person [optional]="true"></form-widget-person>
            </ng-container>

            <ng-container *ngIf="formGroupPrepare.get('type').value === 'organisation'">
                <form-widget-company [optional]="true"></form-widget-company>
            </ng-container>

            <ng-container *ngIf="showIsOwnerCheckBox && boatName && boatOwnerName">
                <div class="mt-24">
                    <mat-checkbox formControlName="isOwner">
                        {{('nameIsOwnerOfThisBoat' | translate : {boatOwnerName, boatName})}}
                    </mat-checkbox>
                </div>
            </ng-container>

            <mat-divider class="special-divider"></mat-divider>

            <section>
                <mat-checkbox (change)="captureTenantRelationChange($event)"
                              [checked]="captureTenantRelation">
                    Beziehung erfassen
                </mat-checkbox>

                <ng-container *ngIf="captureTenantRelation">
                    <form-widget-tenant-relation [parentFormGroup]="formGroup"
                                                 [headline]="null"
                                                 [givenBerth]="givenBerth"
                                                 [prefilledTenantRelationId]="prefilledTenantRelationId"></form-widget-tenant-relation>
                    <mat-divider class="special-divider"></mat-divider>
                </ng-container>
            </section>

            <section>
                <mat-checkbox (change)="captureContract = $event.checked"
                              [checked]="captureContract">
                    Vertrag erzeugen
                </mat-checkbox>

                <ng-container *ngIf="captureContract">
                    <form-widget-contract [parentFormGroup]="formGroup"></form-widget-contract>
                </ng-container>

                <mat-divider class="special-divider"></mat-divider>
            </section>

            <section>
                <form-widget-invoice [parentFormGroup]="formGroup"
                                     [givenStartDate]="givenFromDate"
                                     [givenEndDate]="givenToDate"
                                     [givenInvoicePositions]="givenInvoicePositions"
                                     [showAddBerthPositionButton]="true"
                                     [showInvoiceCreationCheckBox]="true"
                                     [givenTenantRelation]="formGroup.get('tenantRelationForm')?.value?.tenantRelation"
                                     [givenBerth]="givenBerth"></form-widget-invoice>
                <mat-divider class="special-divider"></mat-divider>
            </section>

            <ng-container *ngIf="givenBerthAssignmentType === BerthAssignmentType.Reservation">
                <section>
                    <mat-checkbox (change)="captureReservedUntil = $event.checked"
                                  [checked]="captureReservedUntil">
                        Reservierungsfrist setzen
                    </mat-checkbox>

                    <ng-container *ngIf="captureReservedUntil">
                        <div class="mt-24">
                            <app-input-date-time formControlName="reservedUntil" appearance="fill">
                                <label>Reserviert bis (optional)</label>
                            </app-input-date-time>
                        </div>
                    </ng-container>
                </section>
            </ng-container>

            <!-- todo: need to add mail templates first.. -->
            <!--            <section>-->
            <!--                <mat-checkbox (change)="sendMail = $event.checked"-->
            <!--                              [checked]="sendMail">-->
            <!--                    Reservierung per E-Mail senden-->
            <!--                </mat-checkbox>-->

            <!--                <ng-container *ngIf="sendMail">-->
            <!--                    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">-->
            <!--                        <mat-label>E-Mail</mat-label>-->
            <!--                        <input matInput data-test="FI-E-Mail" type="text" formControlName="mail"-->
            <!--                               autocomplete="new-password">-->
            <!--                    </mat-form-field>-->
            <!--                </ng-container>-->
            <!--            </section>-->

            <div class="mt-24">
                <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                    <mat-label>Bemerkungen zur {{ berthAssignmentTitle }}</mat-label>
                    <textarea #noteText
                              matInput
                              [attr.data-test]="'FT-Bemerkungen zur ' + berthAssignmentTitle"
                              formControlName="note"
                              autocomplete="new-password"
                              [cdkTextareaAutosize]="true"
                              [cdkAutosizeMinRows]="3"
                              [cdkAutosizeMaxRows]="5"
                              [maxLength]="1024"></textarea>
                    <mat-error>{{ formGroup.get('note').errors | firstErrorMessage }}</mat-error>
                    <mat-hint align="end">
                        {{ noteText.value?.length || 0 }} von max. 1024 Zeichen benutzt.
                    </mat-hint>
                </mat-form-field>
            </div>

            <mat-divider class="special-divider"></mat-divider>

            <ng-container
                    *ngIf="(formGroup.dirty && formGroup.invalid) || (formGroupPrepare.dirty && formGroupPrepare.invalid) || !canSave">
                <u2b-message type="warning" customClass="mt-24">
                    Deine Angaben sind noch nicht vollständig, oder enthalten Fehler, die
                    korrigiert werden müssen.
                </u2b-message>

                <ng-container *ngIf="!canSave">
                    <u2b-message type="error" customClass="mt-24">
                        Bitte prüfe, ob ein {{'boat' | translate}} erfasst wurde bzw. ein neu
                        erfasstes {{'boat' | translate}} gespeichert wurde.
                    </u2b-message>
                </ng-container>
            </ng-container>

            <ng-container
                    *ngIf="(formGroup.dirty && formGroup.valid) && (formGroupPrepare.dirty && formGroupPrepare.valid) && canSave">
                <u2b-message type="success" customClass="mt-24">
                    <header>Geschafft!</header>
                    Alle Daten wurden erfasst. Klicke auf "Speichern" um die Daten im System zu speichern.
                </u2b-message>
            </ng-container>
        </div>

        <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

            <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
                Abbrechen
            </button>

            <div>
                <ng-template #saving>
                    <mat-icon>save</mat-icon>
                    <span>Wird gespeichert...</span>
                    <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
                </ng-template>

                <ng-template #notSaving>
                    <mat-icon>save</mat-icon>
                    <span>{{ berthAssignmentTitle }} speichern</span>
                </ng-template>

                <button (click)="onClickSaveAndClose()" data-test="B-Speichern" mat-flat-button color="accent"
                        [disabled]="isSaving || !canSave">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>
            </div>

        </div>
    </form>
</div>
