<div [formGroup]="dateRageFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline">
        <div fxFlex=100>
            <h2>{{headline}}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="hideHourMinFields; else showComplete">
        <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
            <mat-label>{{fromLabel}}</mat-label>
            <input matInput data-test="FI-von" formControlName="from" [matDatepicker]="fromDatePicker" appMaskDate
                   autocomplete="new-password"
                   placeholder="TT.MM.JJJJ">
            <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
                    [disabled]="!dateRageFormGroup.get('from').value || dateRageFormGroup.get('from').disabled"
                    (click)="resetControl(dateRageFormGroup.get('from'))">
                <mat-icon>clear</mat-icon>
            </button>
            <mat-datepicker-toggle data-test="FIT-von" matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #fromDatePicker></mat-datepicker>
            <mat-error>{{dateRageFormGroup.get('from').errors | firstErrorMessage}}</mat-error>
            <mat-hint>Das heutige Datum ist vorausgewählt.</mat-hint>
        </mat-form-field>

        <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
            <mat-label>{{toLabel}}</mat-label>
            <input matInput data-test="FI-bis" formControlName="to" [matDatepicker]="untilDatePicker" appMaskDate
                   autocomplete="new-password"
                   placeholder="TT.MM.JJJJ">
            <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
                    [disabled]="!dateRageFormGroup.get('to').value || dateRageFormGroup.get('to').disabled"
                    (click)="resetControl(dateRageFormGroup.get('to'))">
                <mat-icon>clear</mat-icon>
            </button>
            <mat-datepicker-toggle data-test="FIT-bis" matSuffix [for]="untilDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #untilDatePicker></mat-datepicker>
            <mat-hint>Nur, wenn zeitlich befristet</mat-hint>
            <mat-error>{{dateRageFormGroup.get('to').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>
    </ng-container>

    <ng-template #showComplete>
        <app-input-date-time formControlName="from" [appearance]="appearance">
            <label>{{fromLabel}}</label>
        </app-input-date-time>

        <app-input-date-time formControlName="to" [appearance]="appearance">
            <label>{{toLabel}}</label>
        </app-input-date-time>
    </ng-template>
</div>
