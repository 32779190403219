import { addYears, getYear, setYear } from 'date-fns';

export interface SeasonRAW {
    id?: number;
    uniqueId?: string;
    name: string;
    note: string;
    startDate: string;
    endDate: string;
    repetitive: 1 | 0;
    editable: boolean;
    inEditMode?: boolean;
}

export class Season {
    id: number;
    uniqueId?: string;
    name: string;
    note: string;
    startDate: Date;
    endDate: Date;
    repetitive: boolean;
    editable: boolean;
    inEditMode?: boolean;

    constructor(season = {} as SeasonRAW) {
        this.id = season.id;
        this.name = season.name;
        this.startDate = season.startDate ? setYear(new Date(season.startDate), getYear(new Date())) : null;
        this.endDate = season.endDate ? setYear(new Date(season.endDate), getYear(new Date())) : null;
        this.repetitive = season.repetitive === 1;
        this.note = season.note;

        if (this.endDate < this.startDate) {
            this.endDate = addYears(this.endDate, 1);
        }
    }
}
