import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmBaseFacade } from '@modules/bcm/@core/state-management/bcm-base.facade';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';
import { costCentersTableData } from '@modules/bcm/@core/state-management/cost-centers/cost-centers-table-data';

@Injectable({providedIn: 'root'})
export class CostCentersFacade extends BcmBaseFacade<BcmCostCenter, BcmCostCenterDto> {

    readonly resourcePath = 'cost-centers';

    readonly resourceNameSingular = 'Kostenstelle';

    readonly resourceNamePlural = 'Kostenstellen';

    readonly resourceIconPlural = 'account_balance';

    readonly resourceIconSingular = 'account_balance';

    readonly deletePermission = BcmUserPermission.COST_CENTERS_DELETE;

    readonly readPermission = BcmUserPermission.COST_CENTERS_READ;

    readonly writePermission = BcmUserPermission.COST_CENTERS_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportCostCenters(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'Excel Export - Positionen',
            onClick: () => this._bcmExportExcelApiService.exportPositionsByCostCenters(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (costCenter: BcmCostCenter) => this._bcmNavigationService.navigate('cost-centers/' + costCenter.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (costCenter: BcmCostCenter) => super.remove(costCenter, costCenter.name).subscribe(),
        }
    ];

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
    ) {
        super(
            BcmCostCenter,
            costCentersTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );
    }
}
