<!--<ng-template infoBannerHost></ng-template>-->
<ng-container *ngIf="showDemoEnvironmentBanner; else testBanner">
    <div class="environment-banner-demo">
        {{environmentBannerText || 'DEMO Zugang'}}
    </div>
</ng-container>

<ng-template #testBanner>
    <ng-container *ngIf="showTestEnvironmentBanner">
        <div class="environment-banner-test">
            {{environmentBannerText || 'Testumgebung'}}
        </div>
    </ng-container>
</ng-template>

<u2b-system-notifications></u2b-system-notifications>

<div class="app-container">
    <fuse-progress-bar></fuse-progress-bar>

    <layout></layout>
</div>

<div class="drag-indicator">
    <div class="drag-info">
        <div fxLayout="column" class="m-auto">
            <mat-icon>publish</mat-icon>
            <span>Datei hier ablegen</span>
        </div>
    </div>
</div>
