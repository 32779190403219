import { Injectable } from '@angular/core';
import { BcmApiService } from './bcm-api.service';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { downloadFile } from '@shared/functions/download-file';
import { catchError, map } from 'rxjs/operators';
import {
    BerthBoatAssignment,
    NewBerthBoatAssignment,
    NewBerthBoatAssignmentRaw
} from '@shared/models/berth-boat-assignment';
import { AppNotificationService } from '@core/services/app-notification.service';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmBooking } from '@shared/models/bcm-booking';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';

@Injectable({providedIn: 'root'})
export class BcmWorkflowApiService extends BcmApiService {

    berthReservation(body: object = {}): Observable<NewBerthBoatAssignment> {
        return super.post<NewBerthBoatAssignmentRaw>('workflows/berth-reservation', body)
            .pipe(map((res) => {
                return {
                    success: res.success,
                    person: res.person ? new Person(res.person) : undefined,
                    company: res.company ? new Company(res.company) : undefined,
                    positions: (res.positions || []).map(p => new InvoicePosition(p)),
                    assignments: (res.assignments || []).map(a => new BerthBoatAssignment(a)),
                    booking: new BcmBooking(res.booking),
                    errors: res.errors
                };
            }));
    }

    berthReservationCancellation(body: object = {}): Observable<any> {
        return super.post('workflows/berth-reservation-cancellation', body);
    }

    contractCreation(body: any, appNotificationService: AppNotificationService): Subscription {
        return super.postWithBlobResponse('workflows/contract-creation', body)
            .pipe(catchError(() => EMPTY))
            .subscribe((file) => {
                appNotificationService.showSuccess(
                    `Der Vertrag wurde erstellt${body?.downloadPdf ? ' und heruntergeladen' : ''}.`
                );
                if (body?.downloadPdf) {
                    downloadFile(file, body.contract?.title, 'pdf');
                }
            });
    }

}
