import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FormWidgetBoatModule } from '@bcm-work-flows/@form-widgets/boat/form-widget-boat.module';
import { FormWidgetTenantRelationModule } from '@bcm-work-flows/@form-widgets/tenant-relation/form-widget-tenant-relation.module';
import { FormWidgetBerthOnlyModule } from '@bcm-work-flows/@form-widgets/berth-only/form-widget-berth-only.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormWidgetSeasonModule } from '@bcm-work-flows/@form-widgets/season/form-widget-season.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { ProductService } from '@modules/bcm/products/product/product.service';
import { FormWidgetWinterStorageModule } from '@bcm-work-flows/@form-widgets/winter-storage/form-widget-winter-storage.module';
import { ProductVoucherComponent } from '@bcm-work-flows/product-voucher/product-voucher.component';
import { FormWidgetPersonModule } from '@bcm-work-flows/@form-widgets/person/form-widget-person.module';
import { FormWidgetCompanyModule } from '@bcm-work-flows/@form-widgets/company/form-widget-company.module';
import { FormWidgetVoucherModule } from '@bcm-work-flows/@form-widgets/voucher/form-widget-voucher.module';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [ProductVoucherComponent],
    imports: [
        FuseSharedModule,
        SharedModule,
        MatSlideToggleModule,
        FormWidgetBoatModule,
        FormWidgetTenantRelationModule,
        FormWidgetBerthOnlyModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        FormWidgetSeasonModule,
        BcmPipesModule,
        FormWidgetWinterStorageModule,
        FormWidgetPersonModule,
        FormWidgetCompanyModule,
        FormWidgetVoucherModule,
        TranslationModule,
    ],
    providers: [ProductService],
    exports: [ProductVoucherComponent]
})
export class ProductVoucherModule {

}
