import { IFile } from '@shared/interfaces/file';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { ElectricMeter } from '@shared/models/electric-meter';
import { InvoicePosition } from '@shared/models/invoice-position';
import { Boat } from '@shared/models/boat';
import { ElectricMeterPaymentType } from '@modules/bcm/@shared/enums/electric-meter-payment-type';
import { ElectricMeterAssignment, IElectricMeterAssignment } from '@shared/models/electric-meter-assignment';
import { v4 as uuidv4 } from 'uuid';

export interface IReadingRaw {
    id: number;
    startDate: string;
    startMeterReading: number;
    endDate: string;
    endMeterReading: number;
    image: IFile;
    reason: string;
    note: string;
    electricMeter?: any;
    invoicePosition?: any;
    boat?: any;
    paymentType: ElectricMeterPaymentType;
    bcm_electric_meters_id: number;
    electricMeterAssignments?: IElectricMeterAssignment[];
    uuid?: string;
}

export interface IReading {
    id: number;
    startDate: Date;
    startMeterReading: number;
    endDate: Date;
    endMeterReading: number;
    image: IFile;
    reason: string;
    note: string;
    paymentType: ElectricMeterPaymentType;
    boat?: Boat;
    bcm_electric_meters_id: number;
    electricMeterAssignments?: ElectricMeterAssignment[];
    uuid?: string;
    // insertedOn: string;
    // insertedBy: string;
    // lastUpdateOn: string;
    // lastUpdateBy: string;
}

export class Reading {
    id: number;
    startDate: Date;
    startMeterReading: number;
    endDate: Date;
    endMeterReading: number;
    image: IFile;
    reason: string;
    note: string;
    paymentType: ElectricMeterPaymentType;

    electricMeterAssignments?: ElectricMeterAssignment[];

    // tslint:disable-next-line:variable-name
    bcm_electric_meters_id: number;
    electricMeter?: ElectricMeter;
    invoicePosition?: InvoicePosition | null;
    boat?: Boat | null;
    active?: boolean;
    uuid?: string;

    constructor(reading: IReadingRaw) {
        this.id = reading.id || undefined;
        this.startDate = tryParseDate(reading.startDate, undefined);
        this.endDate = tryParseDate(reading.endDate, undefined);
        this.startMeterReading = reading.startMeterReading;
        this.endMeterReading = reading.endMeterReading;
        this.image = reading.image;
        this.reason = reading.reason;
        this.note = reading.note;
        this.electricMeter = reading.electricMeter;
        this.invoicePosition = reading.invoicePosition ? new InvoicePosition(reading.invoicePosition) : null;
        this.boat = reading.boat ? new Boat(reading.boat) : null;
        this.paymentType = reading.paymentType;
        this.bcm_electric_meters_id = reading.bcm_electric_meters_id;

        this.electricMeterAssignments = (reading.electricMeterAssignments || []).map(assignment => {
            return new ElectricMeterAssignment(assignment);
        });

        const today = new Date();
        this.active = (this.startDate.getTime() <= today.getTime()) && (this.endDate == null || this.endDate.getTime() >= today.getTime());
        this.uuid = reading.uuid || uuidv4();
    }

}
