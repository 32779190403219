import { Component, Input } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

const CLASS_NAME_DEFAULT = 'default-dialog';
const CLASS_NAME_WIDER = 'wider-dialog';
const CLASS_NAME_MIN_HEIGHT_250 = 'min-height-250';

@Component({
    selector: 'u2b-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {

    @Input() set wider(value: boolean) {
        if (value) {
            this._addClass(CLASS_NAME_WIDER);
        } else {
            this._removeClass(CLASS_NAME_WIDER);
        }
    }

    @Input() set minHeight250(value: boolean) {
        if (value) {
            this._addClass(CLASS_NAME_MIN_HEIGHT_250);
        } else {
            this._removeClass(CLASS_NAME_MIN_HEIGHT_250);
        }
    }

    @Input() disableScrollableContent: boolean;

    @Input() closeFunction?: () => void;

    constructor(
        public dialogRef: MatDialogRef<ModalComponent>,
    ) {
        this._addClass(CLASS_NAME_DEFAULT);
    }

    protected closeDialog() {
        if (this.closeFunction && typeof this.closeFunction === 'function') {
            this.closeFunction();
        } else {
            this.dialogRef.close();
        }
    }

    private _addClass(className: string): void {
        this.dialogRef.addPanelClass(className);
    }

    private _removeClass(className: string): void {
        this.dialogRef.removePanelClass(className);
    }
}
