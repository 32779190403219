import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { Boat } from '@shared/models/boat';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'choose-boat-dialog',
    templateUrl: './choose-boat-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ChooseBoatDialogComponent implements OnInit {
    dataSource: Boat[];

    displayedColumns = ['name', 'licensePlate', 'type', 'length', 'width'];

    constructor(
        public dialogRef: MatDialogRef<ChooseBoatDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            boats?: Boat[],
        },
    ) {
    }

    ngOnInit(): void {
        this.dataSource = this.data.boats;
    }

    selectBoat(boat: Boat): void {
        this.dialogRef.close(boat);
    }

}
