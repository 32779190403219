import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BcmSettings, BcmSettingsSection, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { BaseStateService } from '@bcmServices/base-state.service';

@Injectable({providedIn: 'root'})
export class BcmSettingsState extends BaseStateService<unknown> {

    private _settings$ = new BehaviorSubject<BcmSettings | undefined>(undefined);

    constructor() {
        super(undefined);
    }

    get settings(): BcmSettings {
        return this._settings$.getValue();
    }

    get settings$(): Observable<BcmSettings> {
        return this._settings$.asObservable();
    }

    setSettings(settings: BcmSettings): void {
        this._settings$.next(this._prepareSettingsObject(settings));
    }

    updateBcmSection(section: BcmSettingsSectionName, settingsSection: BcmSettingsSection): void {
        const settings = this._settings$.getValue();
        this._settings$.next({
            ...settings,
            [section]: {
                ...settings[section],
                ...settingsSection
            }
        });
    }

    updateBcmSectionItem<T extends BcmSettingsSection, DataType extends any>(
        sectionKey: BcmSettingsSectionName,
        settingKey: keyof T,
        data: DataType
    ): void {
        const settings = this._settings$.getValue();
        this._settings$.next({
            ...settings,
            [sectionKey]: {
                ...settings[sectionKey],
                [settingKey]: data
            }
        });
    }

    private _prepareSettingsObject(settings: BcmSettings): BcmSettings {
        const response: BcmSettings = {} as BcmSettings;
        Object.keys(BcmSettingsSectionName)
            .forEach(key => response[BcmSettingsSectionName[key]] = settings[BcmSettingsSectionName[key]] || {});
        return {...response};
    }
}
