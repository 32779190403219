import { Berth, IBerth } from '@shared/models/berth';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export interface PierRaw {
    id: number;
    handle: string;
    width: number;
    note: string;
    berths: IBerth[];
    costCenter?: BcmCostCenterDto;
}

export class Pier {
    id: number;
    handle: string;
    width: number;
    note: string;
    berths: Berth[];
    costCenter?: BcmCostCenter;

    constructor(pier = {} as PierRaw) {
        this.id = pier.id || null;
        this.handle = pier.handle || null;
        this.width = pier.width || null;
        this.note = pier.note || null;
        this.berths = (pier.berths || []).map(berth => new Berth(berth));
        this.costCenter = pier.costCenter ? new BcmCostCenter(pier.costCenter) : undefined;
    }

    toString(): string {
        return this.handle.trim();
    }

    valueOf(): number {
        return this.id;
    }
}
