export enum TseDocumentType {
    RECEIPT = '[0] = Receipt',
    INVOICE = '[1] = Invoice',
    DELIVERY_NOTE = '[2] = DeliveryNote',
    PAY_OUT = '[10] = PayOut',
    PAY_IN = '[11] = PayIn',
    PROFORMA_INVOICE = '[12] = ProformaInvoice',
    CUSTOMER_ORDER = '[13] = CustomerOrder',
    PRELIMINARY_RECEIPT = '[14] = PreliminaryReceipt',
    LONG_TERM_ORDER = '[80] = LongTermOrder',
    OPENING_BALANCE = '[90] = OpeningBalance',
    CASH_CHECK = '[98] = CashCheck',
    END_OF_DAY = '[99] = EndOfDay',
    INVENTORY = '[100] = Inventory',
    PURCHASE = '[101] = Purchase',
    NULL_RECEIPT = '[1000] = NullReceipt',
    MISCELLANEOUS_NON_FISCAL = '[1100] = MiscellaneousNonFiscal',
}



