import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BcmReleaseNotesFacade } from '@bcmServices/release-notes/release-notes.facade';
import { BcmReleaseNote } from '@bcmServices/release-notes/bcm-release-note';

@Component({
    selector: 'dialog-release-notes',
    templateUrl: './release-notes-dialog.component.html',
})
export class ReleaseNotesDialogComponent {

    loading: boolean;

    releaseNotes: BcmReleaseNote[];

    constructor(
        public dialogRef: MatDialogRef<ReleaseNotesDialogComponent>,
        private bcmReleaseNotesFacade: BcmReleaseNotesFacade
    ) {
        this.dialogRef.addPanelClass(['default-dialog', 'wider-dialog', 'has-min-height-250']);

        this.bcmReleaseNotesFacade
            .loadReleaseNotes()
            .toPromise()
            .then(releaseNotes => this.releaseNotes = releaseNotes);
    }
}
