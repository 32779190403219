import { Component, Inject, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { PayableOptionId } from '@bcmApiServices/product-payable-option.api-service';
import { hours, minutes } from '@shared/constants/date';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { ProductSubscription } from '@shared/models/product-subscription';
import { removeErrorFromControl } from '@core/functions/remove-error-from-controls';
import { toDateTimeStringDE } from '@core/functions/to-date-string';
import { tryParseDate } from '@shared/functions/try-parse-date';

@Component({
    selector: 'get-product-subscription-end-dialog',
    templateUrl: './get-product-subscription-end-dialog.component.html',
})
export class GetProductSubscriptionEndDialogComponent {
    formGroup: UntypedFormGroup;
    PayableOptionId = PayableOptionId;
    hours = hours;
    minutes = minutes;
    isSaving: boolean;
    dateStartAt = new Date();
    dateFilter = null;
    dateStartView: 'month' | 'year' | 'multi-year' = 'month';

    subscription: TenantRelationAssignment | ProductSubscription;
    currentPayableOptionId: PayableOptionId;
    modalTitle: string;

    constructor(
        public dialogRef: MatDialogRef<GetProductSubscriptionEndDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: {
            subscription: TenantRelationAssignment | ProductSubscription,
            modalTitle?: string
        },
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogRef.addPanelClass('default-dialog');
        this.subscription = data.subscription;
        this.currentPayableOptionId = data.subscription?.payableOption?.id;
        this.modalTitle = data.modalTitle || 'Abo beenden';
        this.formGroup = this._createForm();
    }

    saveAndContinue(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        this.dialogRef.close(this.formGroup.value?.date);
    }

    private _createForm(): UntypedFormGroup {
        const form = this._formBuilder.group({
            date: [null, [U2bValidators.required('Bitte angeben, zu welchem Datum das Abo enden soll.')]],
        });

        const dateControl = form.get('date');

        form.valueChanges.subscribe(value => {
            if (!value) {
                return;
            }

            const {date} = value;

            if (!date) {
                return;
            }

            const start = tryParseDate(this.subscription.fromDate);
            const endIsBeforeMinEndDate = date < start;

            if (endIsBeforeMinEndDate) {
                dateControl.setErrors({
                    wrongDateRange: {
                        message: `Laufzeitende muss gleich oder älter sein als "${toDateTimeStringDE(start)}"`
                    }
                });
            } else {
                removeErrorFromControl('wrongDateRange', dateControl);
            }
        });

        return form;
    }
}
