import { BcmOrder, BcmOrderDto } from './bcm-order';
import { InvoicePosition } from './invoice-position';

export interface BcmOrderInvoicePositionDto {
    id: number;
    bcm_orders_id: number;
    bcm_invoice_positions_id: number;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    order: BcmOrderDto;
    invoicePosition: InvoicePosition;
}

export class BcmOrderInvoicePosition {
    id: number;
    bcm_orders_id: number;
    bcm_invoice_positions_id: number;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;
    order!: BcmOrder;
    invoicePosition!: InvoicePosition;

    constructor(bcmOrderAttachment: BcmOrderInvoicePositionDto) {
        this.id = bcmOrderAttachment.id;
        this.bcm_orders_id = bcmOrderAttachment.bcm_orders_id;
        this.bcm_invoice_positions_id = bcmOrderAttachment.bcm_invoice_positions_id;
        this.insertedOn = bcmOrderAttachment.insertedOn;
        this.insertedBy = bcmOrderAttachment.insertedBy;
        this.lastUpdateOn = bcmOrderAttachment.lastUpdateOn;
        this.lastUpdateBy = bcmOrderAttachment.lastUpdateBy;

        this.order = new BcmOrder(bcmOrderAttachment.order);
        this.invoicePosition = bcmOrderAttachment.invoicePosition;
    }
}

