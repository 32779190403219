import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { ICategory } from '@shared/models/category';

@Injectable({
    providedIn: 'root'
})
export class ProductCategoryApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'product-categories';

    getAll(params: HttpParams = new HttpParams()): Observable<ICategory[]> {
        return super.get<ICategory[]>(this.RESOURCE_ENDPOINT, params);
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<ICategory> {
        return super.get<ICategory>(`${this.RESOURCE_ENDPOINT}/${id}`, params);
    }

    add(category: ICategory): Observable<ICategory> {
        return super.post(this.RESOURCE_ENDPOINT, category);
    }

    update(category: ICategory): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${category.id}`, category);
    }

    remove(category: ICategory): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${category.id}`);
    }
}
