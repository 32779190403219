import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';

export const SNACK_BAR_CONFIG__DEFAULT = {
    horizontalPosition: 'center',
    verticalPosition: 'top',
    duration: 6000,
} as MatSnackBarConfig;

export const SNACK_BAR_CONFIG__SUCCESS = {
    ...SNACK_BAR_CONFIG__DEFAULT,
    panelClass: ['success']
} as MatSnackBarConfig;

export const SNACK_BAR_CONFIG__FAILURE = {
    ...SNACK_BAR_CONFIG__DEFAULT,
    panelClass: ['failure']
} as MatSnackBarConfig;

export const SNACK_BAR_CONFIG__WARNING = {
    ...SNACK_BAR_CONFIG__DEFAULT,
    panelClass: ['warning']
} as MatSnackBarConfig;

export const SNACK_BAR_CONFIG__INFO = {
    ...SNACK_BAR_CONFIG__DEFAULT,
    panelClass: ['info']
} as MatSnackBarConfig;
