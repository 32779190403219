import { interval, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

const CLOCK_INTERVAL = 1000;

export class ClockService {

    static readonly _time$: Observable<Date> = interval(CLOCK_INTERVAL)
        .pipe(
            map(_ => new Date()),
            share()
        );

    static get time(): Observable<Date> {
        return ClockService._time$;
    }

}
