import { NgModule } from '@angular/core';
import { BoatOwnerLinkComponent } from '@modules/bcm/@shared/components/html-links/boat-owner-link.component';
import { CommonModule } from '@angular/common';
import { BoatLinkComponent } from '@modules/bcm/@shared/components/html-links/boat-link.component';
import { BcmDirectivesModule } from '@modules/bcm/@core/directives/bcm-directives.module';
import { PersonLinkComponent } from '@modules/bcm/@shared/components/html-links/person-link.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { CompanyLinkComponent } from '@modules/bcm/@shared/components/html-links/company-link.component';
import { BerthLinkComponent } from '@modules/bcm/@shared/components/html-links/berth-link.component';
import { KeyLinkComponent } from '@modules/bcm/@shared/components/html-links/key-link.component';
import { InvoiceLinkComponent } from '@modules/bcm/@shared/components/html-links/invoice-link.component';
import { WinterStorageLinkComponent } from '@modules/bcm/@shared/components/html-links/winter-storage-link.component';
import { ElectricMeterLinkComponent } from '@modules/bcm/@shared/components/html-links/electric-meter-link.component';
import { TranslationModule } from '@core/translation/translation.module';
import {
    PersonOrCompanyLinkComponent
} from '@modules/bcm/@shared/components/html-links/person-or-company-link.component';
import { PierLinkComponent } from '@modules/bcm/@shared/components/html-links/pier-link.component';
import {
    TenantRelationColoredLabelModule
} from '@modules/bcm/@shared/components/tenant-relation-colored-label/tenant-relation-colored-label.module';

@NgModule({
    declarations: [
        BoatLinkComponent,
        BoatOwnerLinkComponent,
        PersonLinkComponent,
        CompanyLinkComponent,
        BerthLinkComponent,
        KeyLinkComponent,
        InvoiceLinkComponent,
        WinterStorageLinkComponent,
        ElectricMeterLinkComponent,
        PersonOrCompanyLinkComponent,
        PierLinkComponent,
    ],
    imports: [
        CommonModule,
        BcmDirectivesModule,
        MatTooltipModule,
        TranslationModule,
        TenantRelationColoredLabelModule
    ],
    exports: [
        BoatLinkComponent,
        BoatOwnerLinkComponent,
        PersonLinkComponent,
        CompanyLinkComponent,
        BerthLinkComponent,
        KeyLinkComponent,
        InvoiceLinkComponent,
        WinterStorageLinkComponent,
        ElectricMeterLinkComponent,
        PersonOrCompanyLinkComponent,
        PierLinkComponent,
    ]
})
export class HtmlLinksModule {

}
