export enum BcmOrderStatusType {
    None = '',
    Open = 'open',
    Processing = 'processing',
    Paused = 'paused',
    Cancelled = 'cancelled',
    Done = 'done',
    Payed = 'payed',
}

export const bcmOrderStatusTypeDE = {
    [BcmOrderStatusType.None]: '-kein Status-',
    [BcmOrderStatusType.Open]: 'offen',
    [BcmOrderStatusType.Processing]: 'in Arbeit',
    [BcmOrderStatusType.Paused]: 'pausiert',
    [BcmOrderStatusType.Cancelled]: 'storniert',
    [BcmOrderStatusType.Done]: 'abgeschlossen',
    [BcmOrderStatusType.Payed]: 'bezahlt',
};

export interface BcmOrderStatusDto {
    id: number;
    bcm_orders_id: number;
    orderStatus: BcmOrderStatusType;
    note: string;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
}

export class BcmOrderStatus {
    id;
    // tslint:disable-next-line:variable-name
    bcm_orders_id;
    orderStatus: BcmOrderStatusType = BcmOrderStatusType.Open;
    note;
    readonly insertedOn;
    readonly insertedBy;
    readonly lastUpdateOn;
    readonly lastUpdateBy;

    constructor(bcmOrderStatusDto: BcmOrderStatusDto) {

        this.id = bcmOrderStatusDto.id;
        this.bcm_orders_id = bcmOrderStatusDto.bcm_orders_id;
        this.orderStatus = bcmOrderStatusDto.orderStatus || BcmOrderStatusType.Open;
        this.note = bcmOrderStatusDto.note;
        this.insertedOn = bcmOrderStatusDto.insertedOn;
        this.insertedBy = bcmOrderStatusDto.insertedBy;
        this.lastUpdateOn = bcmOrderStatusDto.lastUpdateOn;
        this.lastUpdateBy = bcmOrderStatusDto.lastUpdateBy;
    }
}

