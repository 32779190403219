import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { Logger } from '@core/services/logger.service';
import { ElectricMeterCabinetApiService } from '@modules/bcm/@shared/services';
import { RealBaseStateService } from '@bcmServices/real-base-state.service';
import { ElectricMeterCabinet } from '@shared/models/electric-meter-cabinet';

const log = new Logger('ElectricMeterCabinetsFacade');

@Injectable({providedIn: 'root'})
export class ElectricMeterCabinetsFacade extends ElectricMeterCabinetApiService {

    private readonly state = new RealBaseStateService<ElectricMeterCabinet>();

    get electricMeterCabinets$(): Observable<ElectricMeterCabinet[]> {
        return this.state.list$;
    }

    get selectedElectricMeterCabinets$(): Observable<ElectricMeterCabinet> {
        return this.state.selected$;
    }

    get loading$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    constructor(private appNotificationService: AppNotificationService,
                httpClient: HttpClient,
                bcmService: BcmService) {
        super(httpClient, bcmService);
    }

    loadAll(httpParams?: HttpParams): Observable<ElectricMeterCabinet[]> {
        this.state.startUpdating();
        return this.getAll(httpParams)
            .pipe(
                tap(electricMeterCabinets => {
                    this.state.setList(electricMeterCabinets);
                    this.state.stopUpdating();
                }),
                switchMap(() => this.electricMeterCabinets$) // remove asap
            );
    }

    loadOne(id: number): Observable<ElectricMeterCabinet> {
        this.state.startUpdating();
        return this.getOne(id)
            .pipe(
                tap(electricMeterCabinet => {
                    this.state.setSelected(electricMeterCabinet);
                    this.state.stopUpdating();
                }),
                switchMap(() => this.selectedElectricMeterCabinets$)
            );
    }

    addCabinet(tmpElectricMeterCabinet: ElectricMeterCabinet): Observable<ElectricMeterCabinet> {

        if (tmpElectricMeterCabinet?.handle?.length) {

            tmpElectricMeterCabinet.id = -1;

            this.state.startUpdating();
            this.state.addListItem(tmpElectricMeterCabinet);

            return this.add(tmpElectricMeterCabinet as any)
                .pipe(
                    tap((electricMeter) => {
                        this.state.replaceListItem(tmpElectricMeterCabinet, electricMeter);
                        this.state.stopUpdating();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.state.removeListItem(tmpElectricMeterCabinet);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    updateCabinet(electricMeter: ElectricMeterCabinet, electricMeterData: Partial<ElectricMeterCabinet>): Observable<any> {
        if (electricMeter?.id) {

            this.state.startUpdating();

            const updatedElectricMEter = {
                ...electricMeter,
                ...electricMeterData
            } as ElectricMeterCabinet;

            this.state.replaceListItem(electricMeter, updatedElectricMEter); // optimistic change

            return this.remove(electricMeter.id)
                .pipe(
                    tap(() => this.state.stopUpdating()),
                    catchError((error: HttpErrorResponse) => {
                        this.state.replaceListItem(updatedElectricMEter, electricMeter); // revert
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

    removeCabinet(electricMeter: ElectricMeterCabinet): Observable<ElectricMeterCabinet> {

        if (electricMeter?.id) {

            this.state.removeListItem(electricMeter);

            return this.remove(electricMeter.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => {
                        this.state.addListItem(electricMeter);
                        return throwError(error);
                    })
                );
        }

        return throwError('Bitte überprüfe Deine Angaben.');
    }

}
