<ng-container *ngIf="key">
    <ng-container *ngIf="disableLink; else withLink">
        <span [attr.data-test]="dataTest">
            <ng-container *ngIf="viewData; else noIdent">
                {{viewData}}
            </ng-container>
            <ng-template #noIdent>
                <abbr matTooltip="Schlüssel kann nicht identifiziert werden!">
                    <em>Kein Name</em>
                </abbr>
            </ng-template>
        </span>
    </ng-container>
    <ng-template #withLink>
        <a (click)="click($event)"
           [attr.data-test]="dataTest"
           [bcmRouterLink]="['keys', key.id]">
            <ng-container *ngIf="viewData; else noIdent">
                {{viewData}}
            </ng-container>
            <ng-template #noIdent>
                <abbr matTooltip="Schlüssel kann nicht identifiziert werden!">
                    <em>Kein Name</em>
                </abbr>
            </ng-template>
        </a>
    </ng-template>
</ng-container>
