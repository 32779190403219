import { ValidationErrors } from '@angular/forms';

export interface IValidationError {
    id: string;
    message: string;
}

export class BaseValidationError extends Error implements IValidationError {

    public get id(): string {
        return this._id;
    }

    public get INVALID(): ValidationErrors {
        return {
            [this._id]: {
                message: this.message
            }
        };
    }

    constructor(private _id: string,
                public message = 'Bitte gültigen Wert eingeben/auswählen') {
        super(message);
    }

}
