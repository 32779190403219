import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { endOfDay, isAfter, } from 'date-fns';
import { endOfMonth, endOfToday, endOfTomorrow, endOfWeek, startOfTomorrow } from '@core/date.facade';
import { SharedLayerService } from '@modules/bcm/berths/berths-map/_shared/shared-layer.service';
import { DateRangeType } from '@modules/bcm/berths/berths-map/berth-map-action-bar/berth-map-action-bar.component';
import { HttpParams } from '@angular/common/http';
import { BerthsFacade } from '@bcmServices/berths/berths-facade';
import { U2bValidators } from '@shared/validators/validators';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { toSqlDateTime } from '@core/functions/to-date-string';
import { parseInt } from 'lodash';
import { Boat } from '@shared/models/boat';

type LastFilter = {
    timestamp: number,
    start: Date,
    end: Date,
    length: number,
    width: number,
    maxDraft: number,
    boat?: Boat,
};

@Injectable({providedIn: 'root'})
export class BerthsMapFilterService {

    currentDateSelectType = DateRangeType.Today;

    timeRange = new FormControl([(new Date().getHours() * 60) + new Date().getMinutes(), 1440]); // 24 * 60 = 1440

    private _advancedBerthFiltersOpen: boolean;

    public get advancedBerthFiltersOpen(): boolean {
        return this._advancedBerthFiltersOpen;
    }

    private _dateRangeForm = this._formBuilder.group({
        start: new FormControl(new Date(), [U2bValidators.required('Bitte Anreisedatum angeben')]),
        end: new FormControl(endOfToday()),
    });

    public get dateRangeForm(): FormGroup {
        return this._dateRangeForm;
    }

    private _berthFormGroup = new FormGroup<{
        length: AbstractControl<number>,
        width: AbstractControl<number>,
        maxDraft: AbstractControl<number>,
        boatType: AbstractControl<string>,
        boat: AbstractControl<Boat>,
    }>({
        length: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        width: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        maxDraft: new FormControl(null,
            [
                U2bNumericValidators.numberFormat(),
                U2bNumericValidators.numberMin(0, 'Keine negativen Werte erlaubt'),
            ]
        ),
        boatType: new FormControl(null),
        boat: new FormControl(null),
    });

    public get berthFormGroup(): FormGroup {
        return this._berthFormGroup;
    }

    private _lastBerthFilters: LastFilter[] = [];

    get lastBerthFilters(): LastFilter[] {
        return this._lastBerthFilters;
    }

    get lastBerthFilter(): LastFilter {
        return this._lastBerthFilters.length ? this._lastBerthFilters[0] : {} as LastFilter;
    }

    constructor(private _berthFacade: BerthsFacade,
                private _formBuilder: FormBuilder) {
        this.initSubscriptions();
    }

    toggleAdvancedFilters(): void {
        this._advancedBerthFiltersOpen = !this._advancedBerthFiltersOpen;
    }

    public getTimeStringFromSliderValue(value): string {

        if (value === 1440) {
            return '23:59';
        }

        const hourStr = this.getHourStringFromSliderValue(value).toString().padStart(2, '0');
        const minuteStr = this.getMinuteStringFromSliderValue(value).toString().padStart(2, '0');

        return `${hourStr}:${minuteStr}`;
    }

    public getHourStringFromSliderValue(value): number {
        const hourStr = (value / 60).toString().split('.')[0];
        return Math.min(23, parseInt(hourStr, 10));
    }

    public getMinuteStringFromSliderValue(value): number {
        return value === 1440 ? 60 : (value % 60);
    }

    public selectDate(value: DateRangeType): void {
        this.currentDateSelectType = value;
        const now = new Date();
        let start: Date;
        let end: Date;

        switch (value) {
            case DateRangeType.RestOfMonth:
                start = now;
                end = endOfMonth(now);
                break;
            case DateRangeType.RestOfWeek:
                start = now;
                end = endOfWeek(now);
                break;
            case DateRangeType.Today:
                start = now;
                end = endOfToday();
                break;
            case DateRangeType.Tomorrow:
                start = startOfTomorrow();
                end = endOfTomorrow();
                break;
        }

        this._dateRangeForm.patchValue({start, end});
        this.updateBerthView();
    }

    updateBerthView(keepBerthFiltersOpen = false): void {
        this._dateRangeForm.updateValueAndValidity();

        const {length, width, maxDraft, boatType, boat} = this._berthFormGroup.value;
        const {start, end} = this._dateRangeForm.value;

        // newest on start!
        this.lastBerthFilters.unshift({
            boat,
            length,
            width,
            maxDraft,
            start,
            end,
            timestamp: Date.now()
        });

        this._lastBerthFilters = this.lastBerthFilters.slice(0, 25);

        const timeRange = this.timeRange.value;

        // if (changedBySlider) {

        const startHour = this.getHourStringFromSliderValue(timeRange[0]);
        let startMinute = this.getMinuteStringFromSliderValue(timeRange[0]);

        if (startMinute === 60) { // hacky
            startMinute = 57;
        }

        let endHour = this.getHourStringFromSliderValue(timeRange[1]);
        let endMinute = this.getMinuteStringFromSliderValue(timeRange[1]);

        if (endMinute === 0) {
            endHour -= 1;
            endMinute = 59;
        } else {
            endMinute -= 1;
        }

        const startDate = start ? new Date(start.getTime()) : new Date();
        startDate.setHours(startHour, startMinute + 1, 0);


        const endDate = end ? new Date(end.getTime()) : null;

        if (endDate) {
            endDate.setHours(endHour, endMinute - 1, 59);
        }

        const httpParams = new HttpParams()
            .set('from', toSqlDateTime(startDate) || '')
            .set('to', toSqlDateTime(endDate) || '')
            .set('berthLength', !isNaN(length) ? String(length) : '')
            .set('berthWidth', !isNaN(width) ? String(width) : '')
            .set('berthMaxDraft', !isNaN(maxDraft) ? String(maxDraft) : '')
            .set('berthBoatType', boatType || '');

        this._berthFacade
            .loadBerthMapList(httpParams)
            .add(() => {
                this._advancedBerthFiltersOpen = this._advancedBerthFiltersOpen && keepBerthFiltersOpen;
                this._berthFormGroup.reset();
            });

        // todo: do I need this? electricMC assignment is on berthAssignment.boat.elec... ?
        // this._electricMeterCabinetsFacade
        //     .loadAll()
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe();
    }

    public reset() {
        this._dateRangeForm.reset({start: new Date(), end: endOfToday()});
    }

    private initSubscriptions(): void {
        SharedLayerService
            .mapObjectRawList$
            .subscribe(items => {
                if (items?.length) {
                    this.updateBerthView();
                }
            });

        const startDateControl = this._dateRangeForm.get('start');
        const endDateControl = this._dateRangeForm.get('end');

        startDateControl.valueChanges.subscribe((startDate: Date | null) => {
            const endDate = endDateControl.value;
            if (startDate && endDate && isAfter(startDate, endDate)) {
                endDateControl.setValue(endOfDay(startDate));
            }
        });
    }
}
