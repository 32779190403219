<div [formGroup]="pierFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline && !slimmedView">
        <div fxFlex=100>
            <h2>{{headline}}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="topList?.length || bottomList?.length">
        <div fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                    <mat-label>Steg</mat-label>
                    <input type="text" matInput data-test="FI-Nach Steg suchen..."
                           formControlName="pier"
                           [matAutocomplete]="pierSelector">
                    <button matSuffix
                            mat-icon-button
                            type="button"
                            color="warn"
                            matTooltip="Ausgewählte Steg entfernen"
                            [disabled]="!pier?.id"
                            (click)="onClickRemovePier()">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <mat-error
                            [hidden]="slimmedView">{{pierFormGroup.get('pier').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <mat-autocomplete #pierSelector="matAutocomplete" [displayWith]="displayPierWith">
                    <ng-container *ngIf="personOrCompanyName">
                        <ng-container *ngIf="filteredTopList$ | async as topList">
                            <mat-optgroup [label]="'Stege von ' + personOrCompanyName">
                                <ng-container *ngFor="let pier of topList">
                                    <mat-option [value]="pier">
                                        {{pier.handle}}
                                    </mat-option>
                                    <ng-container *ngIf="!topList?.length">
                                        {{personOrCompanyName}} besitzt keine Steg
                                    </ng-container>
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                        <mat-optgroup [label]="personOrCompanyName ? 'Weitere Stege' : 'Alle Stege'">
                            <ng-container *ngFor="let pier of bottomList">
                                <mat-option [value]="pier" [disabled]="disableOtherRelations">
                                    {{pier.handle}}
                                </mat-option>
                                <ng-container *ngIf="!bottomList?.length">
                                    Keine Stege gefunden
                                </ng-container>
                            </ng-container>
                        </mat-optgroup>
                    </ng-container>
                </mat-autocomplete>
            </div>
        </div>
    </ng-container>

</div>
