import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormWidgetPersonModule } from '@modules/bcm/@work-flows/@form-widgets/person/form-widget-person.module';
import { FormWidgetBerthModule } from '@modules/bcm/@work-flows/@form-widgets/berth/form-widget-berth.module';
import { FormWidgetBoatModule } from '@modules/bcm/@work-flows/@form-widgets/boat/form-widget-boat.module';
import { FormWidgetCompanyModule } from '@modules/bcm/@work-flows/@form-widgets/company/form-widget-company.module';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PremiumFormWidgetElectricMeterModule } from '@modules/bcm/@work-flows/@form-widgets/@premium/electric-meter/premium-form-widget-electric-meter.module';
import { FormWidgetBerthFeeModule } from '@modules/bcm/@work-flows/@form-widgets/berth-fee/form-widget-berth-fee.module';
import { FormWidgetTenantRelationModule } from '@modules/bcm/@work-flows/@form-widgets/tenant-relation/form-widget-tenant-relation.module';
import { FormWidgetContractModule } from '@bcm-work-flows/@form-widgets/contract/form-widget-contract.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormWidgetContractCoOwnerModule } from '@bcm-work-flows/@form-widgets/contract-co-owner/form-widget-contract-co-owner.module';
import { DocksiteOrderComponent } from '@bcm-work-flows/docksite-order/docksite-order.component';
import { FormWidgetDateRangeModule } from '@bcm-work-flows/@form-widgets/date-range/form-widget-date-range.module';
import { FormWidgetBerthOnlyModule } from '@bcm-work-flows/@form-widgets/berth-only/form-widget-berth-only.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {MatExpansionModule} from '@angular/material/expansion'; 
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { CoreDirectivesModule } from '@core/directives/core.directives.module';
import { ProductPriceLabelModule } from '@sharedComponents/product-price-label/product-price-label.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { NumberInputModule } from '@sharedComponents/form/number-input/number-input.module';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { TranslationModule } from '@core/translation/translation.module';


@NgModule({
    declarations: [DocksiteOrderComponent],
    imports: [
        MatAutocompleteModule,
        MatAutocompleteModule,
        RouterModule,
        MatListModule,
        BcmPipesModule,
        ProductPriceLabelModule,
        CoreDirectivesModule,
        FuseSharedModule,
        SharedModule,
        FlexModule,
        DragDropModule,
        ScrollingModule,
        CommonModule,
        HtmlLinksModule,
        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDividerModule,
        MatExpansionModule,
        FormWidgetPersonModule,
        FormWidgetBerthModule,
        FormWidgetBoatModule,
        FormWidgetCompanyModule,
        FormWidgetContractModule,
        FormWidgetContractCoOwnerModule,
        DirectivesModule,
        PremiumFormWidgetElectricMeterModule,
        FormWidgetBerthFeeModule,
        FormWidgetTenantRelationModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FormWidgetDateRangeModule,
        FormWidgetBerthOnlyModule,
        MatCheckboxModule,
        MatInputModule,
        MatSelectModule,
        NumberInputModule,
        TranslationModule
    ],
    exports: [DocksiteOrderComponent]
})
export class DocksiteOrderModule {

}
