import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';

@Injectable({providedIn: 'root'})
export class LogoService {

    private _logoPath$ = new BehaviorSubject<string>('assets/images/logos/up2boat.svg');

    private _logoStyle$ = new BehaviorSubject<'white' | 'black'>('black');

    private renderer: Renderer2;

    get logoPath$(): Observable<string> {
        return this._logoPath$.asObservable();
    }

    get logoStyle$(): Observable<'white' | 'black'> {
        return this._logoStyle$.asObservable();
    }

    constructor(rendererFactory: RendererFactory2,
                @Inject(DOCUMENT) private document: HTMLDocument) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    showUp2BoatLogo(): void {
        this._logoPath$.next('assets/images/logos/up2boat.svg');
        this._logoStyle$.next('black');

        if (environment.showTestEnvironmentBanner) {
            this._setFavIcon('favicon-test.ico');
        } else {
            this._setFavIcon('favicon.ico');
        }
    }

    showDmyvLogo(): void {
        this._logoPath$.next('assets/images/logos/dmyv.svg');
        this._logoStyle$.next('white');
        this._setFavIcon('favicon-dmyv-32x32.png');
    }

    private _setFavIcon(iconPath: string): void {
        this.renderer.setAttribute(this.document.getElementById('app-favicon'), 'href', iconPath);
    }
}
