import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { CustomDialogOnClickButton, DialogButton } from '@shared/components/dialogs/confirm-dialog/dialog-button';
import { SafeHtml } from '@angular/platform-browser';
import { isFunction } from '@shared/functions/is-function';

@Component({
    selector: 'u2b-information-dialog',
    templateUrl: './information-dialog.component.html',
    styleUrls: ['./information-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class U2bInformationDialogComponent {

    public dialogTitle: string;

    public dialogTitleTheme: ThemePalette;

    public dialogBody: SafeHtml;

    public dialogButton: DialogButton = {
        color: 'accent',
        text: 'Ok'
    };

    public leftButton: CustomDialogOnClickButton;

    public disableClose;

    constructor(
        public dialogRef: MatDialogRef<U2bInformationDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            leftButton?: CustomDialogOnClickButton,
            disableClose?: boolean,
        },
    ) {
        dialogRef.addPanelClass('information-dialog');

        this._reset();

        if (data?.leftButton?.text && isFunction(data?.leftButton?.onClick)) {
            this.leftButton = data?.leftButton;
        }

        this.disableClose = data?.disableClose || false;
    }

    private _reset(): void {
        this.dialogTitle = null;
        this.dialogTitleTheme = null;
        this.dialogBody = null;
        this.dialogButton = {
            color: 'accent',
            text: 'Ok'
        };
        this.leftButton = null;
    }
}
