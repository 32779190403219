import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseAsyncValidator } from '@shared/validators/base-async-validator';

// ToDo: check how to validate OPTIONAL dates... wrong input always fallback to NULL....
export class DateValidValidator extends BaseAsyncValidator {

    private _timeout;

    constructor(public message?: string) {
        super();
    }

    public validationError = new BaseValidationError('dateValid', this.message || 'Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ');

    public validate(control: AbstractControl): Promise<ValidationErrors | null> {

        clearTimeout(this._timeout);

        return new Promise((resolve) => {
            this._timeout = setTimeout(() => {
                if (control.valid) {
                    return resolve(this.IS_VALID);
                }

                if (control.hasError('matDatepickerParse')) {
                    return resolve(this.IS_INVALID);
                }

                return resolve(this.IS_VALID);
            });
        });
    }
}
