import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BcmApiService } from './bcm-api.service';
import { BcmOrder, BcmOrderDto } from '@shared/models/bcm-order';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { BcmOrderStatusType, BcmOrderStatusDto, BcmOrderStatus } from '@shared/models/bcm-order-status';

@Injectable({
    providedIn: 'root'
})
export class BcmOrdersApi extends BcmApiService {

    private RESOURCE_ENDPOINT = 'orders';

    getAllOrders(params: HttpParams = new HttpParams()): Observable<BcmOrder[]> {
        return super.get<BcmOrderDto[]>('orders', params)
            .pipe(parseHttpResponseList<BcmOrderDto, BcmOrder>(BcmOrder));
    }

    getSingle(id: number, params: HttpParams = new HttpParams()): Observable<BcmOrder> {
        return super.get<BcmOrderDto>(`orders/${id}`, params)
            .pipe(parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder));
    }

    create(order: Partial<BcmOrder>, sendMail: boolean): Observable<BcmOrder> {
        return super.post<{ order: BcmOrderDto, mailSentError: Error }>('orders', order, new HttpParams().set('sendMail', sendMail))
            .pipe(
                // todo: how to handle mailSentError ?
                map(({ order }) => order),
                parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder)
            );
    }

    update(order: BcmOrder, sendMail: boolean): Observable<BcmOrder> {
        return super.put<{ order: BcmOrderDto, mailSentError: Error }>(`orders/${order.id}`, order)
            .pipe(
                // todo: how to handle mailSentError ?
                map(({ order }) => order),
                parseHttpResponseItem<BcmOrderDto, BcmOrder>(BcmOrder)
            );
    }

    updateStatus(order: BcmOrder, orderStatus: BcmOrderStatusType, sendMail: boolean): Observable<{ orderStatus: BcmOrderStatus, order: BcmOrder }> {
        return super.post<{ orderStatus: BcmOrderStatusDto, order: BcmOrderDto }>(`orders/${order.id}/status`, { orderStatus, sendMail })
            .pipe(
                map((data) => {
                    return {
                        order: new BcmOrder(data.order),
                        orderStatus: new BcmOrderStatus(data.orderStatus)
                    };
                })
            );
    }

    remove(order: BcmOrder): Observable<number> {
        return super.delete(`orders/${order.id}`);
    }

    getAllUsers(params: HttpParams = new HttpParams()): Observable<BcmOrder[]> {
        return super.get('users', params);
    }


}
