import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { Contract, ContractFileType } from '@shared/models/contract';
import { isString } from '@shared/functions/is-string';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { BcmContractsStoreService } from '@bcmServices/contracts/bcm-contracts-store.service';
import { TenantRelation } from '@shared/models/tenant-relation';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-contract',
    templateUrl: './form-widget-contract.component.html',
    styleUrls: ['./form-widget-contract.component.scss']
})
export class FormWidgetContractComponent implements OnInit, OnDestroy {

    faFileWord = faFileWord;

    private _unsubscribeAll = new Subject<any>();

    public ContractFileType: typeof ContractFileType = ContractFileType;

    @Input()
    disabled = false;

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = 'Vertragsdaten';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    dataChangedObservable: Observable<any>;

    @Input()
    givenContracts: Contract[] = [];

    contracts: Contract[] = [];

    selectedContract: Contract;

    contractTemplateMissing: boolean;

    filteredContracts$: Observable<Contract[]>;

    contractFormGroup: UntypedFormGroup;

    selectedTenantRelation?: TenantRelation;

    loadingContracts = false;

    name: string;

    mail: string;

    tenantRelation: TenantRelation;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _contractsStore: BcmContractsStoreService) {
        this.contractFormGroup = this._formBuilder.group({
            generateContract: [true],
            contract: [null],
            sendViaMail: [true],
            mail: [null, [U2bValidators.email()]],
            generatePdf: [false],
            contractFileType: [ContractFileType.MAIL],
            downloadPdf: [false] // TODO
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('contractForm', this.contractFormGroup);

            this.parentFormGroup
                .valueChanges
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(value => {
                    this.updatePrefillData(value);

                    // todo: make configurable
                    // if (value.tenantRelationForm?.tenantRelation?.id != null) {
                    //     this.selectedTenantRelation = value.tenantRelationForm?.tenantRelation;
                    //     this.contracts = value.tenantRelationForm?.tenantRelation.contracts || [];
                    // }
                });


        });

        this.updatePrefillData(this.parentFormGroup.value);

        this.filteredContracts$ = this.contractFormGroup.get('contract').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(
                startWith(''),
                map(value => isString(value) ? this._filterContracts(value) : this.contracts.slice()),
            );

        if (!this.givenContracts?.length) {
            this._contractsStore
                .contracts$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    distinctUntilChanged()
                )
                // .pipe(map(contracts => contracts.filter((contract: Contract) => contract.category?.uniqueId === 'Liegeplatzvertrag')))
                .subscribe(contracts => this.contracts = contracts);

            this._loadContracts();
        } else {
            this.contracts = this.givenContracts;
        }

        this.contractFormGroup.get('contract').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(contract => {
                this.selectedContract = contract;
                this.contractTemplateMissing = contract?.isWordTemplate && !contract?.templateMailFile && !contract?.templatePrintFile;
            });

        this.contractFormGroup.get('sendViaMail').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(sendViaMail => {
                if (sendViaMail) {
                    this.contractFormGroup.get('mail').enable();
                } else {
                    this.contractFormGroup.get('mail').disable();
                }
            });

    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('contractForm');
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    updatePrefillData(value): void {
        this.name = value?.name;
        this.mail = value?.mail;

        const oldTenantRelation = this.tenantRelation;

        this.tenantRelation = value?.tenantRelationForm?.tenantRelation;

        // filter contracts depending on tenant relations
        if (oldTenantRelation?.id !== this.tenantRelation?.id) {
            this._filterContracts();
        }

        if (this.mail && this.contractFormGroup.get('mail').value == null) {
            this.contractFormGroup.patchValue({mail: this.mail}, {emitEvent: false, onlySelf: true});
        }
    }

    getOptionText(contract: Contract): string {
        return contract?.title;
    }

    private _loadContracts(): void {
        this.loadingContracts = true;
        this._contractsStore.fetchAll().finally(() => this.loadingContracts = false);
    }

    private _filterContracts(filter = ''): Contract[] {
        return this.contracts.filter(contract => {

            return contract.category?.uniqueId === 'Liegeplatzvertrag'
                && contract.tenantRelations.some(item => item.id === this.tenantRelation?.id)
                && contract.title.toLowerCase().includes(filter.toLowerCase());
        });
    }
}
