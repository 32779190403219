<div [formGroup]="tenantRelationFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline && !slimmedView">
        <div fxFlex=100>
            <h2>{{ headline }}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="topList?.length || bottomList?.length">
        <div fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start">
                <ng-container *ngIf="multipleSelectable">
                    <mat-form-field appearance="outline" class="w-100-p">
                        <mat-label>Beziehungen</mat-label>
                        <u2b-chip-list-input formControlName="tenantRelations"
                                             [hasRemove]="true"
                                             [dataObservable]="tenantRelationsApiService.getAll()"
                                             [disableConditionCallback]="disableConditionCallback"></u2b-chip-list-input>
                        <mat-error>{{ tenantRelationFormGroup.get('tenantRelations').errors | firstErrorMessage }}</mat-error>
                    </mat-form-field>
                </ng-container>

                <ng-container *ngIf="!multipleSelectable">
                    <mat-form-field fxFlex [appearance]="appearance" floatLabel="always">
                        <mat-label>Beziehung</mat-label>
                        <input type="text" matInput data-test="FI-Nach Beziehung suchen..."
                               formControlName="tenantRelation"
                               [matAutocomplete]="tenantRelationSelector">
                        <button matSuffix
                                mat-icon-button
                                type="button"
                                color="warn"
                                matTooltip="Ausgewählte Beziehung entfernen"
                                [disabled]="!selectTenantRelation?.id || parentFormGroup.disabled"
                                (click)="removeTenantRelation()">
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <mat-error
                                [hidden]="slimmedView">{{ tenantRelationFormGroup.get('tenantRelation').errors | firstErrorMessage }}
                        </mat-error>
                    </mat-form-field>

                    <mat-autocomplete #tenantRelationSelector="matAutocomplete"
                                      [displayWith]="displayTenantRelationWith">
                        <ng-container *ngIf="personOrCompanyName">
                            <ng-container *ngIf="filteredTopList$ | async as topList">
                                <mat-optgroup [label]="'Beziehungen von ' + personOrCompanyName">
                                    <ng-container *ngFor="let tenantRelation of topList">
                                        <mat-option [value]="tenantRelation">
                                            {{ tenantRelation.name }}
                                        </mat-option>
                                        <ng-container *ngIf="!topList?.length">
                                            {{ personOrCompanyName }} besitzt keine Beziehung
                                        </ng-container>
                                    </ng-container>
                                </mat-optgroup>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                            <mat-optgroup [label]="personOrCompanyName ? 'Weitere Beziehungen' : 'Alle Beziehungen'">
                                <ng-container *ngFor="let tenantRelation of bottomList">
                                    <mat-option [value]="tenantRelation" [disabled]="disableOtherRelations">
                                        {{ tenantRelation.name }}
                                    </mat-option>
                                    <ng-container *ngIf="!bottomList?.length">
                                        Keine Beziehungen gefunden
                                    </ng-container>
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </mat-autocomplete>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!slimmedView && (selectedTenantRelations$ | async) as selectedTenantRelations">
        <ng-container *ngIf="selectedTenantRelations?.length">
            <div fxFlex="100" class="mb-16">
                <table class="simple sm not-striped">
                    <thead>
                    <tr>
                        <th>Produkt</th>
                        <th style="width: 80px;" class="text-center">Anzahl</th>
                        <th class="text-right">
                            Einzelpreis
                            <ng-container *ngIf="(selectedTenantRelationPayableOption$ | async)?.name as payableOptionName">
                                <br>
                                <small>({{ payableOptionName }})</small>
                            </ng-container>
                        </th>
                        <th class="text-right">Summe</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let tenantRelation of selectedTenantRelations; let i = index">
                        <tr class="with-background">
                            <td colspan="4">{{ tenantRelation.name }}</td>
                        </tr>
                        <ng-container *ngFor="let product of tenantRelation.products">
                            <tr>
                                <td>{{ product.name }}</td>
                                <td style="width: 80px;" class="text-center">
                                    {{ product.quantity }}
                                </td>
                                <td class="text-right">
                                    {{ product.price | bcmDynamicCurrency: '1.2-2' }}
                                </td>
                                <td class="text-right">
                                    {{ (product.quantity || 0) * (product.price || 0) | bcmDynamicCurrency: '1.2-2' }}
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="3" class="no-border"></td>
                        <td class="text-right padding sum">
                            {{ selectedTenantRelationProductPriceTotal$ | async | bcmDynamicCurrency: '1.2-2' }}
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </ng-container>
    </ng-container>

</div>
