import { NgModule } from '@angular/core';
import { ButtonBrowserBackComponent } from './button-browser-back.component';
import { CoreDirectivesModule } from '../../directives/core.directives.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [ButtonBrowserBackComponent],
    exports: [ButtonBrowserBackComponent],
    imports: [
        CoreDirectivesModule,
        MatButtonModule,
        MatIconModule,
        FlexLayoutModule
    ]
})
export class ButtonBrowserBackModule {
}
