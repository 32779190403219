import { NgModule } from '@angular/core';
import { SystemNotificationsComponent } from './system-notifications.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [SystemNotificationsComponent],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [SystemNotificationsComponent]
})
export class SystemNotificationsModule {

}
