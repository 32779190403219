import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { startOfYear } from '@core/date.facade';
import { U2bValidators } from '@shared/validators/validators';
import {
    addDays,
    addMonths,
    addWeeks,
    addYears,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfDay,
    startOfMonth,
    startOfWeek,
    subDays,
    subMonths,
    subWeeks,
    subYears
} from 'date-fns';
import { isString } from '@shared/functions/is-string';
import { MatLegacySelectChange } from '@angular/material/legacy-select';

export enum FromToRangeButton {
    All,
    LastYear,
    CurrentYear,
    RestOfYear,
    NextYear,
    LastMonth,
    CurrentMonth,
    RestOfMonth,
    NextMonth,
    LastWeek,
    CurrentWeek,
    RestOfWeek,
    NextWeek,
    Yesterday,
    Today,
    RestOfToday,
    Tomorrow,
}

export const fromToRangeButtonTranslation: { [key in FromToRangeButton]: string } = {
    [FromToRangeButton.All]: 'Alle',
    [FromToRangeButton.LastYear]: 'Letztes Jahr',
    [FromToRangeButton.CurrentYear]: 'Aktuelles Jahr',
    [FromToRangeButton.RestOfYear]: 'Rest des Jahres',
    [FromToRangeButton.NextYear]: 'Nächstes Jahr',
    [FromToRangeButton.LastMonth]: 'Letzter Monat',
    [FromToRangeButton.CurrentMonth]: 'Aktueller Monat',
    [FromToRangeButton.RestOfMonth]: 'Rest des Monats',
    [FromToRangeButton.NextMonth]: 'Nächster Monat',
    [FromToRangeButton.LastWeek]: 'Letzte Woche',
    [FromToRangeButton.CurrentWeek]: 'Aktuelle Woche',
    [FromToRangeButton.RestOfWeek]: 'Rest der Woche',
    [FromToRangeButton.NextWeek]: 'Nächste Woche',
    [FromToRangeButton.Yesterday]: 'Gestern',
    [FromToRangeButton.Today]: 'Heute',
    [FromToRangeButton.RestOfToday]: 'Rest des heutigen Tages',
    [FromToRangeButton.Tomorrow]: 'Morgen',
};

@Component({
    selector: 'add-assignment-dialog',
    templateUrl: './get-from-to-range-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class GetFromToRangeDialogComponent implements OnInit {
    dialogTitle: string;

    formGroup: UntypedFormGroup;

    dateToday = new Date();

    @Input() types: FromToRangeButton[];

    @Input() defaultType: FromToRangeButton = FromToRangeButton.All;

    @Input() allowTypeAll = true;

    @Input() dateFieldsOptional = true;

    @Input() fromDateDefault: Date;

    @Input() toDateDefault: Date;

    @Input() fromDateMax: Date;

    @Input() toDateMax: Date;

    fromToRangeButtonTranslation = fromToRangeButtonTranslation;

    constructor(
        public dialogRef: MatDialogRef<GetFromToRangeDialogComponent>,
        private _appNotificationService: AppNotificationService,
        private _formBuilder: UntypedFormBuilder,
    ) {
    }

    ngOnInit(): void {
        this._createForm();

        this.types = Object.values(FromToRangeButton)
            .filter(_ => !isString(_))
            .filter(_ => this.allowTypeAll || _ !== FromToRangeButton.All) as FromToRangeButton[];
    }

    public save(): void {

        if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.value);
            return;
        }

        this.formGroup.markAllAsTouched();
        this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
    }

    public setDates(event: MatLegacySelectChange): void {
        let from: Date;
        let to: Date;

        switch (event.value as FromToRangeButton) {
            case FromToRangeButton.All:
                from = null;
                to = null;
                break;
            case FromToRangeButton.LastYear:
                from = subYears(startOfYear(this.dateToday), 1);
                to = subYears(endOfYear(this.dateToday), 1);
                break;
            case FromToRangeButton.CurrentYear:
                from = startOfYear(this.dateToday);
                to = endOfYear(this.dateToday);
                break;
            case FromToRangeButton.RestOfYear:
                from = this.dateToday;
                to = endOfYear(this.dateToday);
                break;
            case FromToRangeButton.NextYear:
                from = addYears(startOfYear(this.dateToday), 1);
                to = addYears(endOfYear(this.dateToday), 1);
                break;

            case FromToRangeButton.LastMonth:
                from = subMonths(startOfMonth(this.dateToday), 1);
                to = subMonths(endOfMonth(this.dateToday), 1);
                break;
            case FromToRangeButton.CurrentMonth:
                from = startOfMonth(this.dateToday);
                to = endOfMonth(this.dateToday);
                break;
            case FromToRangeButton.RestOfMonth:
                from = this.dateToday;
                to = endOfMonth(this.dateToday);
                break;
            case FromToRangeButton.NextMonth:
                from = addMonths(startOfMonth(this.dateToday), 1);
                to = addMonths(endOfMonth(this.dateToday), 1);
                break;

            case FromToRangeButton.LastWeek:
                from = subWeeks(startOfWeek(this.dateToday), 1);
                to = subWeeks(endOfWeek(this.dateToday), 1);
                break;
            case FromToRangeButton.CurrentWeek:
                from = startOfWeek(this.dateToday);
                to = endOfWeek(this.dateToday);
                break;
            case FromToRangeButton.RestOfWeek:
                from = this.dateToday;
                to = endOfWeek(this.dateToday);
                break;
            case FromToRangeButton.NextWeek:
                from = addWeeks(startOfWeek(this.dateToday), 1);
                to = addWeeks(endOfWeek(this.dateToday), 1);
                break;

            case FromToRangeButton.Yesterday:
                from = subDays(startOfDay(this.dateToday), 1);
                to = subDays(endOfDay(this.dateToday), 1);
                break;
            case FromToRangeButton.Today:
                from = startOfDay(this.dateToday);
                to = endOfDay(this.dateToday);
                break;
            case FromToRangeButton.RestOfToday:
                from = this.dateToday;
                to = endOfDay(this.dateToday);
                break;
            case FromToRangeButton.Tomorrow:
                from = addDays(startOfDay(this.dateToday), 1);
                to = addDays(endOfDay(this.dateToday), 1);
                break;
        }

        this.formGroup.patchValue({from, to});
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
                from: [this.fromDateDefault, this.dateFieldsOptional ? undefined : [U2bValidators.required('Bitte Datum "von" angeben')]],
                to: [this.toDateDefault, this.dateFieldsOptional ? undefined : [U2bValidators.required('Bitte Datum "bis" angeben')]],
            },
            {
                validators: [
                    (formGroup: UntypedFormGroup) => {

                        const fromDate = formGroup.get('from').value;
                        const toDate = formGroup.get('to').value;

                        if (!fromDate || !toDate) {
                            return null;
                        }

                        if (fromDate > toDate) {
                            formGroup.get('from').setErrors({
                                wrongDateRange: {
                                    message: 'Das "von" Datum darf nicht hinter dem "bis" Datum liegen'
                                }
                            });
                            formGroup.get('to').setErrors({
                                wrongDateRange: {
                                    message: 'Das "bis" Datum darf nicht vor dem "von" Datum liegen'
                                }
                            });
                        } else {
                            formGroup.get('from').setErrors(null);
                            formGroup.get('to').setErrors(null);
                        }
                    }
                ]
            }
        );
    }
}
