import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BcmApiService } from './bcm-api.service';
import { ElectricMeterCabinet, IElectricMeterCabinet } from '@shared/models/electric-meter-cabinet';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({
    providedIn: 'root'
})
export class ElectricMeterCabinetApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'electric-meter-cabinets';

    getAll(params: HttpParams = new HttpParams()): Observable<ElectricMeterCabinet[]> {
        return super.get<IElectricMeterCabinet[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IElectricMeterCabinet, ElectricMeterCabinet>(ElectricMeterCabinet));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<ElectricMeterCabinet> {
        return super.get<IElectricMeterCabinet>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IElectricMeterCabinet, ElectricMeterCabinet>(ElectricMeterCabinet));
    }

    add(body: object = {}): Observable<ElectricMeterCabinet> {
        return super.post<IElectricMeterCabinet>(this.RESOURCE_ENDPOINT, body)
            .pipe(parseHttpResponseItem<IElectricMeterCabinet, ElectricMeterCabinet>(ElectricMeterCabinet));
    }

    update(id: number, body: object = {}): Observable<ElectricMeterCabinet> {
        return super.put<IElectricMeterCabinet>(`${this.RESOURCE_ENDPOINT}/${id}`, body)
            .pipe(parseHttpResponseItem<IElectricMeterCabinet, ElectricMeterCabinet>(ElectricMeterCabinet));
    }

    remove(id: number): Observable<ElectricMeterCabinet> {
        return super.delete<IElectricMeterCabinet>(`${this.RESOURCE_ENDPOINT}/${id}`)
            .pipe(parseHttpResponseItem<IElectricMeterCabinet, ElectricMeterCabinet>(ElectricMeterCabinet));
    }
}
