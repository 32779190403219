import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Boat } from '@shared/models/boat';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';

@Component({
    selector: 'bcm-boat-owner-link',
    templateUrl: './boat-owner-link.component.html'
})
export class BoatOwnerLinkComponent implements OnChanges {

    @Input()
    public boat: Boat;

    @Input()
    public prefix: string;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public showTenantRelation: boolean;

    @Input()
    public disableLink: boolean;

    owner: Person;

    ownerCompany: Company;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(): void {
        if (this.boat?.activeAssignments && this.boat?.activeAssignments?.length > 0) {
            this.boat.activeAssignments.forEach(activeAssignment => {
                this.owner = activeAssignment?.boat?.owner;
            });
        }

        if (this.boat?.owner && this.boat?.ownerCompany) {
            this.ownerCompany = this.boat.ownerCompany;
            this.owner = this.boat.owner;
            return;
        }

        if (this.boat?.owner?.id) {
            this.ownerCompany = null;
            this.owner = this.boat.owner;
        }

        if (this.boat?.ownerCompany?.id) {
            this.owner = null;
            this.ownerCompany = this.boat.ownerCompany;
        }
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
