type NestedObject = {
    [key: string]: any;
};

export function getNestedAttribute(obj: NestedObject, attributePath: string, fallbackValue?: unknown): any {
    if (!obj || typeof obj !== 'object' || !attributePath || typeof attributePath !== 'string') {
        return fallbackValue;
    }

    const keys = attributePath.split('.');

    let result: any = obj;

    for (const key of keys) {
        if (result && typeof result === 'object' && key in result) {
            result = result[key];
        } else {
            return fallbackValue;
        }
    }

    return result;
}
