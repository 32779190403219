import { Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, map, switchMap } from 'rxjs/operators';
import { U2bFile, U2bFilesApiService } from '@core/services/api/u2b-files.api-service';
import { isString } from '@shared/functions/is-string';
import { Up2BoatFilesApiService } from '@modules/up2boat/@shared/services/up2boat-files.api-service';

@Component({
    selector: 'u2b-secured-image',
    styleUrls: ['./secured-image.component.scss'],
    templateUrl: './secured-image.component.html'
})
export class SecuredImageComponent implements OnChanges {

    @Input() filesApiService: U2bFilesApiService | Up2BoatFilesApiService;

    @Input() public class: string;

    @Input() public file: U2bFile;

    @Input() public tempUrl: SafeUrl;

    @Input() public placeholder = 'assets/images/placeholder/default.jpg';

    private src$: BehaviorSubject<U2bFile>;

    public dataUrl$;

    constructor(_filesApiService: U2bFilesApiService, private domSanitizer: DomSanitizer) {
        this.src$ = new BehaviorSubject(this.file);
        this.dataUrl$ = this.src$.pipe(switchMap((file: U2bFile) => this.loadImage(file)));
        if (!this.filesApiService) {
            this.filesApiService = _filesApiService;
        }
    }

    ngOnChanges(): void {
        if (this.tempUrl) {
            this.src$.next({} as U2bFile);
            return;
        }
        this.src$.next(this.file);
    }

    private loadImage(file: U2bFile): Observable<any> {

        if (!file || !file.id) {
            return of(this.tempUrl || this.placeholder);
        }

        return this.filesApiService.getOne(file)
            .pipe(
                map(e => {
                    if (isString(e)) {
                        return this.domSanitizer.bypassSecurityTrustUrl(e);
                    }

                    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e));
                }),
                catchError((err) => {
                    return of(this.tempUrl || this.placeholder);
                }),
            );
    }
}
