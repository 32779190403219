import { Pipe, PipeTransform } from '@angular/core';
import { BcmService } from '@modules/bcm/bcm.service';
import { Tenant } from '@shared/models/tenant';
import { BcmInvoiceTemplateSettings, BcmSettings, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { BcmSupportedCurrencyCodes } from '@modules/bcm/@shared/enums/supported-currencies';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmDynamicCurrency } from '@modules/bcm/@shared/pipes/dynamic-currency.pipe';

export enum RoundNumberFactor {
    Integer = 1,
    TwoDecimals = 0.001,
    Swiss5CentimesRounding = 0.05,
}

export function roundCurrency(num: number) {
    return roundNumber(num, RoundNumberFactor.TwoDecimals);
}

export function roundNumber(num: number, factor: RoundNumberFactor | number): number {
    if (!isFinite(num)) {
        return 0;
    }
    if (!factor) {
        console.warn(`roundNumber() was called with num=${num} and factor=undefined. Returning ${num}`);
        return num;
    }
    if (!factor || factor <= 0) {
        console.warn(`roundNumber() was called with num=${num} and factor=${factor}. Negative factor is not allowed. Returning ${num}`);
        return num;
    }
    const multiply = num < 0 ? -1 : 1;
    const absNum = Math.abs(num);
    const roundedNum = Math.round(absNum / factor) * factor;
    return Number((roundedNum * multiply).toFixed(2)) || 0;
}

@Pipe({
    name: 'bcmDynamicPriceRounded'
})
export class BcmDynamicPriceRoundedPipe implements PipeTransform {

    tenant: Tenant;

    settings: BcmSettings;

    invoiceTemplateSettings: BcmInvoiceTemplateSettings;

    constructor(private bcmService: BcmService,
                private bcmSettingsFacade: BcmSettingsFacade,
                private bcmDynamicCurrency: BcmDynamicCurrency) {
        this.bcmService.selectedTenant$.subscribe(tenant => this.tenant = tenant);
        this.settings = this.bcmSettingsFacade.settings();
        this.invoiceTemplateSettings = this.settings ? this.settings[BcmSettingsSectionName.InvoiceTemplate] : {};
    }

    transform(value: number, digitsInfo = '1.2-2', locale?: string): any {
        let returnValue = value;

        switch (this.tenant?.currencyCode) {
            case BcmSupportedCurrencyCodes.CHF:
                if (this.invoiceTemplateSettings.roundPricesTo5SwissCentimes) {
                    returnValue = roundNumber(value, RoundNumberFactor.Swiss5CentimesRounding);
                }
                break;
        }

        return this.bcmDynamicCurrency.transform(returnValue, digitsInfo, locale);
    }
}
