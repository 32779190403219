import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Pipe({
    name: 'firstErrorMessage'
})
export class FirstErrorMessagePipe implements PipeTransform {
    public transform(errors: ValidationErrors, defaultMessage: string = 'Falsche Auswahl/Eingabe.'): string {

        if (!errors || typeof errors !== 'object') {
            return '';
        }

        const arr = Object.keys(errors)
            .filter(key => !key.startsWith('_'));

        if (arr.includes('message')) {
            return errors['message'];
        }

        const firstErrorKey = arr[0];
        const firstError = errors[firstErrorKey];

        switch (firstErrorKey) {
            // MAT DATEPICKER ERRORS
            case 'matDatepickerFilter':
                return 'Das ausgewählte Datum ist nicht erlaubt.';
            case 'matDatepickerParse':
                return 'Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ.';
            case 'matDatepickerMin':
            case 'matDatepickerMax':
                return 'Das ausgewählte Datum liegt außerhalb des gültigen Bereichs.'; // todo: add range
            // MAT DATE RANGE PICKER ERRORS
            case 'matStartDateInvalid':
                return 'Das Startdatum ist ungültig.';
            case 'matEndDateInvalid':
                return 'Das Enddatum ist ungültig.';
            // CUSTOM ERRORS WITH MESSAGE ATTRIBUTE or DEFAULT MESSAGE
            default:
                return firstError?.message || defaultMessage;
        }
    }
}
