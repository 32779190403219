import { SafeHtml } from '@angular/platform-browser';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { AppUser } from '@core/services/user.service';

export enum SystemNotificationType {
    NONE = '',
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
    INFO = 'info'
}

export const systemNotificationTypeTranslations: {[key in SystemNotificationType]: string} = {
    [SystemNotificationType.NONE]: 'Farblos',
    [SystemNotificationType.ERROR]: 'Rot (Fehler)',
    [SystemNotificationType.WARNING]: 'Gelb (Warnung)',
    [SystemNotificationType.SUCCESS]: 'Grün (Erfolg)',
    [SystemNotificationType.INFO]: 'Blau (Info)',
};

export interface SystemNotificationDto {
    id: number;
    title: string;
    reason: string;
    content: string;
    type: SystemNotificationType | null;
    showFrom?: string;
    showUntil?: string;
    customClass?: string;
    insertedOn: string;
    createdBy: AppUser;
    lastUpdatedBy: AppUser;
}

export class SystemNotification {
    id: number;
    title: string;
    reason: string;
    content: string;
    type: SystemNotificationType | null;
    typeTranslation: string;
    trustedContent?: SafeHtml; // set later when having access to Sanitizer
    showFrom?: Date;
    showUntil?: Date;
    customClass?: string;
    insertedOn: Date;
    createdBy: AppUser;
    lastUpdatedBy: AppUser;

    constructor(dto: SystemNotificationDto) {
        this.id = dto.id;
        this.title = dto.title;
        this.reason = dto.reason;
        this.content = dto.content;
        this.type = dto.type;
        this.typeTranslation = systemNotificationTypeTranslations[dto.type] || null;
        this.showFrom = tryParseDate(dto.showFrom);
        this.showUntil = tryParseDate(dto.showUntil);
        this.customClass = dto.customClass;
        this.insertedOn = tryParseDate(dto.insertedOn);
        this.createdBy = dto.createdBy;
        this.lastUpdatedBy = dto.lastUpdatedBy;
    }
}
