import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { parseISO, subMinutes } from '@core/date.facade';

@Injectable({providedIn: 'root'})
export class WordPressService {

    public static postsEndpoint = environment.endpoints.wp + 'posts';

    public static pagesEndpoint = environment.endpoints.wp + 'pages';

    private _postsCache: any[];

    private _postsLastRequestDate: Date;

    private _pageCache: {[key: number]: any} = {};

    private _pageLastRequestDates: {[key: number]: Date} = {};

    constructor(private http: HttpClient,
                private _router: Router) {
    }

    getPosts(...categories): Observable<any[]> {

        if (this._postsCache
            && this._postsCache.length
            && subMinutes(new Date(), 5) < this._postsLastRequestDate) {
            return of(this._postsCache);
        }

        return this.http.get<any[]>(`${WordPressService.postsEndpoint}?_embed`, {
            params: {
                per_page: '6',
                post_status: 'publish',
                categories
            }
        }).pipe(
            map((posts: any[]) => {
                this._postsLastRequestDate = new Date();
                this._postsCache = posts.map((post) => {
                    post.date = parseISO(post.date);
                    return post;
                });

                return this._postsCache;
            }),
            catchError(() => {
                return of(this._postsCache || []);
            })
        );
    }

    getPost(slug: string): Observable<any> {
        return this.http.get<any>(`${WordPressService.postsEndpoint}?slug=${slug}`).pipe(
            map((post: any) => {
                return post;
            }),
            catchError((error: any) => {
                // this._router.navigate(['Login']);
                return of([]);
            })
        );
    }

    getPage(pageId: number): Observable<any> {

        if (this._pageCache[pageId]
            && subMinutes(new Date(), 5) < this._pageLastRequestDates[pageId]) {
            return of(this._pageCache[pageId]);
        }

        return this.http.get<any>(
            `${WordPressService.pagesEndpoint}/${pageId}`,
            {
                params: {
                    context: 'view',
                }
            }
        ).pipe(
            map((page: any) => {
                page.dateToday = parseISO(page.date);
                page.modified = parseISO(page.modified);

                this._pageLastRequestDates[pageId] = new Date();
                this._pageCache[pageId] = page;

                return this._pageCache[pageId];
            }),
            catchError((error: any) => {
                // this._router.navigate(['Login']);
                return of([]);
            })
        );
    }
}
