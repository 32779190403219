import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { IProduct, Product } from '@shared/models/product';
import { TenantRelation, TenantRelationRaw } from '@shared/models/tenant-relation';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';

@Injectable({
    providedIn: 'root'
})
export class TenantRelationsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'tenant-relations';

    getAll(params: HttpParams = new HttpParams()): Observable<TenantRelation[]> {
        return super.get<TenantRelationRaw[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<TenantRelationRaw, TenantRelation>(TenantRelation));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<TenantRelation> {
        return super.get<TenantRelationRaw>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<TenantRelationRaw, TenantRelation>(TenantRelation));
    }

    add(tenantRelation: TenantRelation): Observable<TenantRelation> {
        return super.post<TenantRelationRaw>(this.RESOURCE_ENDPOINT, tenantRelation)
            .pipe(parseHttpResponseItem<TenantRelationRaw, TenantRelation>(TenantRelation));
    }

    update(tenantRelation: TenantRelation, tenantRelationData: Partial<TenantRelation>): Observable<TenantRelation> {
        return super.put<TenantRelationRaw>(`${this.RESOURCE_ENDPOINT}/${tenantRelation.id}`, tenantRelationData)
            .pipe(parseHttpResponseItem<TenantRelationRaw, TenantRelation>(TenantRelation));
    }

    remove(tenantRelation: TenantRelation): Observable<TenantRelation> {
        return super.delete<TenantRelationRaw>(`${this.RESOURCE_ENDPOINT}/${tenantRelation.id}`)
            .pipe(parseHttpResponseItem<TenantRelationRaw, TenantRelation>(TenantRelation));
    }

    addProduct(tenantRelation: TenantRelation, product: IProduct, quantity = 1): Observable<any> {
        return super.post(this.RESOURCE_ENDPOINT + `/${tenantRelation.id}/products/`, {product, quantity});
    }

    removeProduct(tenantRelation: TenantRelation, product: IProduct | Product): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${tenantRelation.id}/products/${product.id}`);
    }

    updateProduct(tenantRelation: TenantRelation, product: IProduct | Product, body: { quantity?: number, sortIndex?: number }): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${tenantRelation.id}/products/${product.id}`, body);
    }

    updateOpenInvoicePositions(tenantRelation: TenantRelation): Observable<{persons: Person[], companies: Company[], products: Product[]}> {
        return super.post(`${this.RESOURCE_ENDPOINT}/${tenantRelation.id}/update-invoice-positions`);
    }
}
