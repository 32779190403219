import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormWidgetPersonModule } from '@modules/bcm/@work-flows/@form-widgets/person/form-widget-person.module';
import { FormWidgetBoatModule } from '@modules/bcm/@work-flows/@form-widgets/boat/form-widget-boat.module';
import { FormWidgetCompanyModule } from '@modules/bcm/@work-flows/@form-widgets/company/form-widget-company.module';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectivesModule } from '@shared/directives/directives.module';
import { SelectBoatByPersonOrCompanyComponent } from '@bcm-work-flows/select-boat-by-person-or-company/select-boat-by-person-or-company.component';
import { TranslationModule } from '@core/translation/translation.module';
import {
    FormWidgetBoatByPersonOrCompanyModule
} from '@bcm-work-flows/@form-widgets/boat-by-person-or-company/form-widget-boat-by-person-or-company.module';

@NgModule({
    declarations: [SelectBoatByPersonOrCompanyComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        FlexModule,
        DragDropModule,
        ScrollingModule,

        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDividerModule,

        FormWidgetPersonModule,
        FormWidgetCompanyModule,
        FormWidgetBoatModule,
        DirectivesModule,
        TranslationModule,
        FormWidgetBoatByPersonOrCompanyModule
    ],
    exports: [SelectBoatByPersonOrCompanyComponent]
})
export class SelectBoatByPersonOrCompanyModule {

}
