export enum TsePaymentType {
    CASH = 'cash',
    EC_CARD = 'ecCard',
    CREDIT_CARD = 'creditCard',
    SINGLE_PURPOSE_VOUCHER = 'singlePurposeVoucher',
    MULTI_PURPOSE_VOUCHER = 'multiPurposeVoucher',
    PAYMENT_PROVIDER = 'paymentProvider',
    DEPOSIT = 'deposit',
    NO_CASH = 'noCash',
    NONE = 'none',
    CRYPTO = 'crypto',
    MOBILE_PHONE_APPS = 'mobilePhoneApps',
    BANK_ACCOUNT = 'bankAccount',
    CUSTOMER_CARD = 'customerCard',
    LOYALTY = 'loyalty',
    CHECK = 'check',
    CREDIT_NOTE = 'creditNote',
}


