import { AfterViewInit, ContentChildren, Directive, ElementRef, Input, QueryList, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { BcmService } from '../../bcm.service';

@Directive({
    selector: '[bcmRouterLink]',
})
export class BcmRouterLinkDirective extends RouterLink implements AfterViewInit {

    private readonly _router: Router;

    private readonly _renderer: Renderer2;

    private readonly _element: ElementRef;

    @Input()
    set bcmRouterLink(commands: any[] | string) {
        super.routerLink = [
            this.bcmService.baseUrl,
            ...(typeof commands === 'string'
                    ? commands.split('/').filter(c => !!c)
                    : commands
            )
        ];
    }

    @ContentChildren(BcmRouterLinkDirective, {descendants: true})
    linksWithHrefs: QueryList<BcmRouterLinkDirective>;

    constructor(private bcmService: BcmService,
                router: Router,
                route: ActivatedRoute,
                renderer: Renderer2,
                element: ElementRef) {
        super(router, route, undefined, renderer, element);

        this._router = router;
        this._renderer = renderer;
        this._element = element;
    }

    ngAfterViewInit(): void {
        if (this.urlTree) {
            this._renderer.setAttribute(
                this._element.nativeElement,
                'href',
                this._router.serializeUrl(this.urlTree)
            );
        }
    }
}
