import { NgModule } from '@angular/core';
import { TenantRelationColoredLabelComponent } from '@modules/bcm/@shared/components/tenant-relation-colored-label/tenant-relation-colored-label.component';
import { NgForOf, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [TenantRelationColoredLabelComponent],
    imports: [
        NgIf,
        NgForOf,
        MatTooltipModule
    ],
    exports: [TenantRelationColoredLabelComponent]
})
export class TenantRelationColoredLabelModule  {}
