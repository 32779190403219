import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { StateResetFilter, StateResetService } from './state-reset.service';
import { deepClone } from '@core/functions/deep-clone';

export abstract class BaseStateService<StateType> {

    private state$: BehaviorSubject<StateType>;

    protected get state(): StateType {
        return this.state$.getValue();
    }

    private _updating$ = new BehaviorSubject<boolean>(false);

    public get isUpdating$(): Observable<boolean> {
        return this._updating$.asObservable();
    }

    protected constructor(private readonly initialState: StateType,
                          resetFilter: StateResetFilter = null) {
        this.state$ = new BehaviorSubject<StateType>(initialState);
        StateResetService
            .onReset
            .pipe(filter((_) => _ === resetFilter))
            .subscribe(() => this.resetState());
    }

    public setUpdating(isUpdating = true): void {
        this._updating$.next(isUpdating);
    }

    public unsetUpdating(): void {
        this._updating$.next(false);
    }

    protected select<K>(mapFn: (state: StateType) => K): Observable<K> {
        return this.state$.asObservable().pipe(
            map((state: StateType) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    protected setState(newState: Partial<StateType>): void {
        this.state$.next({
            ...deepClone(this.state),
            ...deepClone(newState),
        });
    }

    private resetState(): void {
        this.setState(deepClone(this.initialState));
    }

}
