import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApiService } from '@modules/bcm/@shared/services/base-api.service';
import { environment } from '@env/environment';

type LAST_INSERT_ID = number;
type EFFECTED_ROWS = number;

/**
 * ToDo: Should this API Services be placed in /modules/bcm/ ??
 */
export class U2bApiService extends BaseApiService {

    protected className = 'U2bApiService';

    protected endpoint = environment.endpoints.u2b.api;

    protected constructor(_http: HttpClient) {
        super(_http);
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return super.get<T>(path, params);
    }

    protected post<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.post<T>(path, body, params);
    }

    protected put<T = EFFECTED_ROWS>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return super.put<T>(path, body, params);
    }

    protected delete<T = EFFECTED_ROWS>(path, params: HttpParams = new HttpParams()): Observable<T> {
        return super.delete<T>(path, params);
    }
}
