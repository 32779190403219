import { catchError } from 'rxjs/operators';
import { of, OperatorFunction } from 'rxjs';

export function suppressErrorForStatus<T>(errorCode, returnValue = null): OperatorFunction<T, any> {
    return catchError((error) => {
        if (error.status === errorCode) {
            return of(returnValue);
        }

        throw error;
    });
}
