import { FuseNavigation } from '@fuse/types';

export const navigationLoggedIn: FuseNavigation[] = [
    // {
    //     id: 'u2b',
    //     title: 'Dein Up2Boat',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'profile',
    //             title: 'Dein Profil',
    //             type: 'item',
    //             icon: 'account_circle',
    //             url: 'profile'
    //         }
    //     ]
    // },
    {
        id: 'modules',
        title: 'Deine Module',
        type: 'group',
        children: [
            {
                id: 'tenants',
                title: 'Yachthafenmanagement',
                type: 'item',
                icon: 'directions_boat',
                url: 'bcm/tenants'
            }
        ]
    },
    {
        id: 'links',
        title: '',
        type: 'group',
        children: [
            {
                id: 'Impressum',
                title: 'Impressum',
                type: 'item',
                url: 'https://www.up2boat.com/impressum',
                externalUrl: true,
                openInNewTab: true,
            },
            {
                id: 'Datenschutzerklärung',
                title: 'Datenschutzerklärung',
                type: 'item',
                url: 'https://www.up2boat.com/datenschutzerklaerung',
                externalUrl: true,
                openInNewTab: true,
            },
            {
                id: 'AGB',
                title: 'AGB',
                type: 'item',
                url: 'https://www.up2boat.com/agb',
                externalUrl: true,
                openInNewTab: true,
            }
        ]
    }
];
