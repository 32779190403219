import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { D } from '@shared/constants/date';

@Pipe({
    name: 'dateTimeFormat'
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        if (!value) {
            return '';
        }
        return super.transform(value, args && args.format ? args.format : D.DATE_TIME_DE);
    }
}
