import { NgModule } from '@angular/core';
import { ChipListInputComponent } from './chip-list-input.component';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { FlexModule } from '@angular/flex-layout';
import { AsyncPipe, JsonPipe, NgForOf, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule } from '@angular/material/legacy-core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [ChipListInputComponent],
    exports: [ChipListInputComponent],
    imports: [
        MatChipsModule,
        FlexModule,
        NgForOf,
        MatIconModule,
        MatInputModule,
        NgIf,
        AsyncPipe,
        MatLegacyAutocompleteModule,
        MatLegacyOptionModule,
        ReactiveFormsModule,
        MatLegacyButtonModule,
        JsonPipe,
        MatLegacyTooltipModule,
    ]
})
export class ChipListInputModule {
}
