import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-contract-co-owner',
    templateUrl: './form-widget-contract-co-owner.component.html'
})
export class FormWidgetContractCoOwnerComponent implements OnInit, OnDestroy {

    @Input()
    disabled = false;

    @Input()
    appearance: MatFormFieldAppearance = 'fill';

    @Input()
    headline = 'Vertragsmitinhaber';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    knownShortCodeList: string[] = [];

    contractCoOwnerForm: UntypedFormGroup;

    captureContractCoOwner: boolean;

    constructor(private _formBuilder: UntypedFormBuilder) {
        this.contractCoOwnerForm = this._formBuilder.group({
            VMI_1_NAME_KOMPLETT: [],
            VMI_1_STR: [],
            VMI_1_PLZ: [],
            VMI_1_ORT: [],

            VMI_2_NAME_KOMPLETT: [],
            VMI_2_STR: [],
            VMI_2_PLZ: [],
            VMI_2_ORT: [],

            VMI_3_NAME_KOMPLETT: [],
            VMI_3_STR: [],
            VMI_3_PLZ: [],
            VMI_3_ORT: [],

            VMI_4_NAME_KOMPLETT: [],
            VMI_4_STR: [],
            VMI_4_PLZ: [],
            VMI_4_ORT: [],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('contractCoOwnerForm', this.contractCoOwnerForm);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('contractCoOwnerForm');
    }

}
