import { Component, HostBinding, Input } from '@angular/core';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { BcmService } from '@modules/bcm/bcm.service';

@Component({
    selector: 'bcm-product-price-label',
    template: `
        {{text}}
        <ng-container *ngIf="lineBrake"><br></ng-container>
        <ng-container *ngIf="(settingsState.settings$ | async) as settings">
            <ng-container *ngIf="switchToNet; else defaultLabel">
                ({{settings.invoice_template?.showSinglePriceInNet || bcmService?.managePricesInNet ? 'Netto' : 'Brutto'}})
            </ng-container>
            <ng-template #defaultLabel>
                ({{bcmService?.managePricesInNet ? 'Netto' : 'Brutto'}})
            </ng-template>
        </ng-container>
    `,
    styles: [':host { white-space: normal }']
})
export class ProductPriceLabelComponent {

    @Input()
    text = 'Einzelpreis';

    @Input()
    switchToNet = false;

    @Input()
    @HostBinding('style.text-align')
    textAlign: 'left' | 'right' | 'center' = 'right';

    @Input()
    lineBrake = false;

    constructor(public settingsState: BcmSettingsFacade,
                public bcmService: BcmService) {
    }

}
