<ng-container *ngIf="loading">
    <div class="py-48">
        <div style="display: flex; justify-content: center; align-items: center;">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="!loading">
    <div class="p-16 pb-0">
        <h2>Release Metainformationen</h2>

        <ng-container *ngIf="releaseNote.releaseDate">
            <p>Release-Datum: {{releaseNote.releaseDate | dfnsFormat: 'dd.MM.yyyy'}}</p>
        </ng-container>

        <ng-container *ngIf="releaseNote.releaseTypeView">
            <p>Release-Typ: {{releaseNote.releaseTypeView}}</p>
        </ng-container>

        <ng-container *ngIf="releaseNote.releaseReason">
            <p>Release-Grund: {{releaseNote.releaseReason}}</p>
        </ng-container>
    </div>
    <div [innerHTML]="finalReleaseNote.safeHtml" class="p-16"></div>
</ng-container>