import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormWidgetPersonModule } from '@modules/bcm/@work-flows/@form-widgets/person/form-widget-person.module';
import { FormWidgetBerthModule } from '@modules/bcm/@work-flows/@form-widgets/berth/form-widget-berth.module';
import { FormWidgetBoatModule } from '@modules/bcm/@work-flows/@form-widgets/boat/form-widget-boat.module';
import { FormWidgetCompanyModule } from '@modules/bcm/@work-flows/@form-widgets/company/form-widget-company.module';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PremiumFormWidgetElectricMeterModule } from '@modules/bcm/@work-flows/@form-widgets/@premium/electric-meter/premium-form-widget-electric-meter.module';
import { FormWidgetBerthFeeModule } from '@modules/bcm/@work-flows/@form-widgets/berth-fee/form-widget-berth-fee.module';
import { FormWidgetTenantRelationModule } from '@modules/bcm/@work-flows/@form-widgets/tenant-relation/form-widget-tenant-relation.module';
import { FormWidgetContractModule } from '@bcm-work-flows/@form-widgets/contract/form-widget-contract.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ContractCreatorComponent } from '@bcm-work-flows/contract-creator/contract-creator.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OrderModule } from 'ngx-order-pipe';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { FormWidgetSignatureModule } from '@bcm-work-flows/@form-widgets/core/signature/form-widget-signature.module';
import { BcmDirectivesModule } from '@modules/bcm/@core/directives/bcm-directives.module';
import { FormWidgetDateRangeModule } from '@bcm-work-flows/@form-widgets/date-range/form-widget-date-range.module';
import { FormWidgetContractCoOwnerModule } from '@bcm-work-flows/@form-widgets/contract-co-owner/form-widget-contract-co-owner.module';
import { FormWidgetBuildingCostSubsidyModule } from '@bcm-work-flows/@form-widgets/building-cost-subsidy/form-widget-building-cost-subsidy.module';
import { FormWidgetProductListModule } from '@bcm-work-flows/@form-widgets/product-list/form-widget-product-list.module';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [ContractCreatorComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        FlexModule,
        DragDropModule,
        ScrollingModule,

        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDividerModule,

        FormWidgetPersonModule,
        FormWidgetBerthModule,
        FormWidgetBoatModule,
        FormWidgetCompanyModule,
        FormWidgetContractModule,
        DirectivesModule,
        PremiumFormWidgetElectricMeterModule,
        FormWidgetBerthFeeModule,
        FormWidgetTenantRelationModule,
        MatTooltipModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        OrderModule,
        MatInputModule,
        MatCheckboxModule,
        FormWidgetSignatureModule,
        BcmDirectivesModule,
        FormWidgetDateRangeModule,
        FormWidgetContractCoOwnerModule,
        FormWidgetBuildingCostSubsidyModule,
        FormWidgetProductListModule,
        HtmlLinksModule,
        FontAwesomeModule,
        TranslationModule,
    ],
    exports: [ContractCreatorComponent]
})
export class ContractCreatorModule {

}
