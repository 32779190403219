import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';

export class NumberMaxValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(private _max: number,
                private _message?: string) {
        super();

        this.validationError = new BaseValidationError('numberMax', _message || `Nur Zahlen bis ${_max} erlaubt`);
    }

    public validate({value}: AbstractControl): ValidationErrors {

        if (value == null || this._max == null) {
            return this.IS_VALID;
        }

        return value <= this._max ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
