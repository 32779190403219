import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { deepCopyArray, deepCopyObject } from '@core/functions/deep-copy';

export interface BaseEntity {
    id: number;

    [key: string]: any;
}

export interface BaseState<T> {
    list: T[];
    selected: T;
}

export class U2bAdminBaseState<Type extends BaseEntity> {

    private readonly _state$ = new BehaviorSubject<BaseState<Type>>({list: [], selected: undefined});

    private get state(): BaseState<Type> {
        return this._state$.getValue();
    }

    private _updatingAll$ = new BehaviorSubject<boolean>(false);

    public get isUpdatingAll$(): Observable<boolean> {
        return this._updatingAll$.asObservable();
    }

    private _updating$ = new BehaviorSubject<boolean>(false);

    public get isUpdating$(): Observable<boolean> {
        return this._updating$.asObservable();
    }

    get list$(): Observable<Type[]> {
        return this.select(state => state.list);
    }

    get list(): Type[] {
        return this.state.list;
    }

    get selected$(): Observable<Type> {
        return this.select((state: BaseState<Type>) => state.selected);
    }

    get selected(): Type {
        return this.state.selected;
    }

    public startUpdatingAll(): void {
        this._updatingAll$.next(true);
    }

    public stopUpdatingAll(): void {
        this._updatingAll$.next(false);
    }

    public startUpdating(): void {
        this._updating$.next(true);
    }

    public stopUpdating(): void {
        this._updating$.next(false);
    }

    public setList(list: Type[]): void {
        this.setState({list});
    }

    public setSelected(selected: Type): void {
        this.setState({selected});
    }

    public addListItem(item: Type): void {
        this.setState({list: [...deepCopyArray(this.state.list), item]});
    }

    public replaceListItem(oldItem: Type, newItem: Type): void {

        const index = this.state.list.findIndex(mm => mm.id === oldItem.id);
        const list = deepCopyArray(this.state.list);

        list.splice(index, 1, newItem);

        this.setState({list});
    }

    public removeListItem(berth: Type): void {
        const index = this.state.list.findIndex(mm => mm.id === berth.id);
        const list = deepCopyArray(this.state.list);

        list.splice(index, 1);

        this.setState({list});
    }

    private select<T>(mapFn: (state: Partial<BaseState<Type>>) => T): Observable<T> {
        return this._state$.asObservable().pipe(
            map((state: Partial<BaseState<Type>>) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    private setState(newState: Partial<BaseState<Type>>): void {
        this._state$.next(deepCopyObject({
            ...this.state,
            ...newState,
        }));
    }
}
