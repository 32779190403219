import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '@shared/models/country';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';

@Injectable({
    providedIn: 'root'
})
export class CountryApiService extends BcmApi2Service {

    constructor(http: HttpClient,
                bcmService: BcmService) {
        super(http, bcmService);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<Country[]> {
        return super.get<Country[]>('countries', params);
    }
}
