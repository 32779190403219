import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { GetAddressDialogModule } from '@shared/components';
import { FormWidgetCompanyContactComponent } from '@bcm-work-flows/@form-widgets/company-contact/form-widget-company-contact.component';

@NgModule({
    declarations: [FormWidgetCompanyContactComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,

        GetAddressDialogModule
    ],
    exports: [FormWidgetCompanyContactComponent]
})
export class FormWidgetCompanyContactModule {
    
}
