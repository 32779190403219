import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductsApiService } from '@modules/bcm/@shared/services';

@Injectable({providedIn: 'root'})
export class ProductsResolver implements Resolve<any[]> {

    constructor(private _productsApiService: ProductsApiService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {
        return this._productsApiService.getAll();
    }
}
