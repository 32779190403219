import { Injectable } from '@angular/core';
import { InvoicePositionApiService } from './index';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';
import { Product } from '@shared/models/product';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { concatAll, EMPTY, forkJoin, Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';
import { WinterStorageAssignmentInvoicePositionsComponent } from '@bcm-work-flows/winter-storage-assignment-invoice-positions/winter-storage-assignment-invoice-positions.component';
import { ProduktQuantityButton } from '@shared/models/product-quantity-button';
import { BcmDynamicPriceContext } from '@shared/models/bcm-dynamic-price';
import { WinterStorageBoatAssignment } from '@shared/models/winter-storage-boat-assignment';
import { InvoicePositionDialogComponent } from '@sharedComponents/dialogs/invoice-position-dialog/invoice-position-dialog.component';
import { Boat } from '@shared/models/boat';
import { BerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmCostCenter } from '@shared/models/bcm-cost-center';

@Injectable({providedIn: 'root'})
export class BcmInvoicePositionsService {

    constructor(private _invoicePositionApiService: InvoicePositionApiService,
                private _matDialog: MatDialog) {
    }

    addPositionsForWinterStorageAssignment(winterStorageAssignment: WinterStorageBoatAssignment): Observable<any> {

        return this._matDialog.open(WinterStorageAssignmentInvoicePositionsComponent, {
            data: {
                winterStorageAssignment
            }
        }).afterClosed()
            .pipe(
                mergeMap((data: {
                    winterStorageAssignment: WinterStorageBoatAssignment,
                    positions: InvoicePosition[],
                    subscriptionPeriod: Date | null,
                    person: Person | undefined,
                    company: Company | undefined
                }) => {

                    if (data?.person) {
                        return forkJoin([...data.positions.map(position => {
                            return this._invoicePositionApiService.addPositionForPerson(position, data.person);
                        })]);
                    } else if (data?.company) {
                        return forkJoin([...data.positions.map(position => {
                            return this._invoicePositionApiService.addPositionForCompany(position, data.company);
                        })]);
                    }

                    return EMPTY;

                })
            );

    }

    addPositionsForPerson(person: Person, boat?: Boat): Observable<InvoicePosition[]> {
        return this
            .getInvoicePosition({person, boat, preselection: {boat}})
            .pipe(switchMap((positions) => {
                if (positions.length > 0) {
                    return forkJoin(positions.map(position => {
                        position.boat = boat;
                        return this._invoicePositionApiService.addPositionForPerson(position, person);
                    }));
                }
                return EMPTY;
            }));
    }

    addPositionsForMultiplePersons(persons: Person[]): Observable<InvoicePosition[]> {
        return this
            .getInvoicePosition(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)
            .pipe(
                switchMap((positions) => {
                    if (positions.length > 0) {
                        const requests = persons.map(person => {
                            return forkJoin(
                                positions.map(position =>
                                    this._invoicePositionApiService.addPositionForPerson(position, person)
                                )
                            );
                        });
                        return forkJoin(requests).pipe(concatAll());
                    }
                    return EMPTY;
                })
            );
    }

    addPositionsForCompany(company: Company, boat?: Boat): Observable<InvoicePosition[]> {
        return this
            .getInvoicePosition({company, boat, preselection: {boat}})
            .pipe(switchMap((positions) => {
                if (positions.length > 0) {
                    return forkJoin(positions.map(position => {
                        position.boat = boat;
                        return this._invoicePositionApiService.addPositionForCompany(position, company);
                    }));
                }
                return EMPTY;
            }));
    }

    addPositionsForMultipleCompanies(companies: Company[]): Observable<InvoicePosition[]> {
        return this
            .getInvoicePosition(undefined, undefined, undefined, undefined, undefined, undefined, undefined, true)
            .pipe(
                switchMap((positions) => {
                    if (positions.length > 0) {
                        const requests = companies.map(company => {
                            return forkJoin(
                                positions.map(position =>
                                    this._invoicePositionApiService.addPositionForCompany(position, company)
                                )
                            );
                        });
                        return forkJoin(requests).pipe(concatAll());
                    }
                    return EMPTY;
                })
            );
    }

    updatePositionForPerson(person: Person, position: InvoicePosition): Observable<InvoicePosition[]> {
        return this
            .editInvoicePosition(position)
            .pipe(switchMap(editedPositions => {
                if (editedPositions.length > 0) {
                    return forkJoin(editedPositions.map(p => {
                        if (p.id === null) {
                            return this._invoicePositionApiService.addPositionForPerson(p, person);
                        } else {
                            return this._invoicePositionApiService.updatePositionForPerson(p.id, p, person);
                        }
                    }));
                }
                return EMPTY;
            }));
    }

    updatePositionForCompany(company: Company, position: InvoicePosition): Observable<InvoicePosition[]> {
        return this
            .editInvoicePosition(position)
            .pipe(switchMap(editedPositions => {
                if (editedPositions.length > 0) {
                    return forkJoin(editedPositions.map(p => {
                        if (p.id === null) {
                            return this._invoicePositionApiService.addPositionForCompany(p, company);
                        } else {
                            return this._invoicePositionApiService.updatePositionForCompany(p.id, p, company);
                        }
                    }));
                }
                return EMPTY;
            }));
    }

    deletePosition(position: InvoicePosition): Observable<any> {
        return this._invoicePositionApiService.deletePosition(position);
    }

    deletePositions(idList: number[]): Observable<any> {
        return this._invoicePositionApiService.deletePositions(idList);
    }

    public getInvoicePosition(dynamicPriceContext?: BcmDynamicPriceContext,
                              productsFilterFn?: (product: Product) => boolean,
                              quantityButtons?: ProduktQuantityButton[],
                              startDate?: Date,
                              endDate?: Date,
                              berthAssignments?: BerthBoatAssignment[],
                              costCenter?: BcmCostCenter,
                              disableVoucherAndDynPrice?: boolean): Observable<InvoicePosition[]> {
        return this._matDialog.open(InvoicePositionDialogComponent, {
            data: {
                dynamicPriceContext,
                productsFilterFn,
                quantityButtons,
                startDate,
                endDate,
                berthAssignments,
                costCenter,
                disableVoucherAndDynPrice
            }
        }).afterClosed();
    }

    public editInvoicePosition(position: InvoicePosition, berthAssignments?: BerthBoatAssignment[]): Observable<InvoicePosition[]> {
        return this._matDialog.open(InvoicePositionDialogComponent, {data: {position, berthAssignments}}).afterClosed();
    }

}
