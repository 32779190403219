import { Component, OnInit } from '@angular/core';
import { SystemNotification } from '@core/components/system-notifications/system-notification';
import { U2bSystemNotificationsApiService } from '@core/services/api/u2b-system-notifications.api-service';
import { SystemNotificationsSocket } from '@core/sockets/system-notifications.socket';
import { HttpParams } from '@angular/common/http';

@Component({
    selector: 'u2b-system-notifications',
    templateUrl: './system-notifications.component.html',
    styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {

    messages: SystemNotification[];

    constructor(private api: U2bSystemNotificationsApiService,
                private systemNotificationsSocket: SystemNotificationsSocket) {
    }

    ngOnInit(): void {
        this.loadMessages();
        this.systemNotificationsSocket.reload$.subscribe(() => this.loadMessages());
    }

    private loadMessages() {
        const params: HttpParams = new HttpParams().set('onlyActive', '1');
        this.api.getAll(params).subscribe(messages => this.messages = messages);
    }
}
