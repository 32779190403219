import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { U2bApiService } from './u2b-api.service';
import { SystemNotification, SystemNotificationDto } from '../../components/system-notifications/system-notification';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({
    providedIn: 'root'
})
export class U2bSystemNotificationsApiService extends U2bApiService {

    private RESOURCE_ENDPOINT = 'system-notifications';

    constructor(http: HttpClient,
                private domSanitizer: DomSanitizer) {
        super(http);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<SystemNotification[]> {
        return super.get<SystemNotificationDto[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(
                parseHttpResponseList<SystemNotificationDto, SystemNotification>(SystemNotification),
                map(data => {
                    data.forEach(item => item.trustedContent = this.domSanitizer.bypassSecurityTrustHtml(item.content));
                    return data;
                })
            );
    }

    // do we need this?
    // markAsRead(notificationModel: SystemNotificationModel = {} as SystemNotificationModel): Observable<number> {
    //     return super.post(this.RESOURCE_ENDPOINT + `/${notificationModel.id}/mark-read`, notificationModel);
    // }

}
