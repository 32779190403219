import { Component, forwardRef, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';
import BigNumber from 'bignumber.js';

@Component({
    selector: 'app-input-number',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: NumberInputComponent
        }
    ],
})
export class NumberInputComponent extends BaseInputComponent<number> implements OnInit {

    errorStateMatcher: DefaultInputErrorStateMatcher;

    @Input() showArrowButtons = true;

    @Input() step = 1;

    @Input() min: number;

    @Input() max: number;

    @Input() align: 'l' | 'r' = 'l';

    @Input() fc: AbstractControl;

    @HostBinding('tabIndex') tabIndex = -1;

    get parsedValue(): string {
        const safeValue = this.safeValue(this.value);
        return this.stringify(safeValue).replace('.', ',');
    }

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }
    }

    public customOnInput(event: Event): void {
        this.value = this.safeValue((event.target as HTMLInputElement).value);
    }

    public customOnChange(event: Event): void {
        this.value = this.safeValue((event.target as HTMLInputElement).value);
        this.valueChange.emit(this.value);
    }

    public incrementValue(event: Event): void {
        this.disableEvent(event);

        if (this.value == null || this.max == null || this.value < this.max) {
            this.value = new BigNumber(this.value || 0).plus(this.step).toNumber();
        }
    }

    public decrementValue(event: MouseEvent): void {
        this.disableEvent(event);

        if (this.value == null || this.min == null || this.value > this.min) {
            this.value = new BigNumber(this.value || 0).minus(this.step).toNumber();
        }
    }

    public disableEvent(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private safeValue(val: string | number): number {
        const safeValue = parseFloat(this.stringify(val).replace(',', '.'));
        return isNaN(safeValue) ? null : safeValue;
    }

    private stringify(val: string | number): string {
        return val == null ? '' : `${val}`;
    }

}
