import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class U2BMaxlengthValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public maxLength: number, public message = `Anzahl erlaubter Zeichen von ${maxLength} überschritten.`) {
        super();
        this.validationError = new BaseValidationError('maxLength', message);
    }

    public validate({value}: AbstractControl): ValidationErrors | null {
        return !value || `${value}`.length <= this.maxLength ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
