export function getSalutationString(salutation: number | string): string {

    let salutationString = '';

    if (typeof salutation === 'number') {
        switch (salutation) {
            case 0:
                salutationString = 'Herr';
                break;
            case 1:
                salutationString = 'Frau';
                break;
            case 2:
                salutationString = 'Divers';
                break;
        }
    } else {
        salutationString = salutation;
    }

    return salutationString || '';
}
