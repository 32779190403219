import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { InvoiceApiService } from '../../@shared/services';
import { map } from 'rxjs/operators';
import { Invoice } from '@shared/models/invoice';
import { HttpParams } from '@angular/common/http';
import { InvoicePayment, InvoicePaymentDto } from '@shared/models/invoice-payment';

@Injectable({providedIn: 'root'})
export class InvoicesService implements Resolve<any> {
    invoices: Invoice[];
    onInvoicesChanged: BehaviorSubject<any>;

    constructor(
        private _invoiceApiService: InvoiceApiService,
    ) {
        this.onInvoicesChanged = new BehaviorSubject({});
    }

    resolve(): Observable<any> {
        return this.getInvoices();
    }

    getInvoices(params?: HttpParams): Observable<any[]> {
        return this._invoiceApiService.getAll(params)
            .pipe(
                map((invoices: Invoice[]) => {
                    this.invoices = invoices;
                    this.onInvoicesChanged.next(this.invoices);
                    return this.invoices;
                })
            );
    }

    getDatevExport(params: HttpParams): Observable<any> {
        return this._invoiceApiService.getDatevExport(params);
    }

    getZipExport(params?: HttpParams): Observable<any> {
        return this._invoiceApiService.getZipExport(params);
    }

    getPdfFile(invoice: Invoice): Observable<any> {
        return this._invoiceApiService.getPdfFile(invoice.id);
    }

    getPdfFileExample(): Observable<any> {
        return this._invoiceApiService.getPdfFileExample();
    }

    cancelInvoice(invoice: Invoice, addPositionsBackToCoaster: boolean, cancellationReason: string): Observable<Invoice> {
        return this._invoiceApiService.cancel(invoice, addPositionsBackToCoaster, cancellationReason);
    }

    addPayment(invoice: Invoice, data: InvoicePaymentDto): Observable<Invoice> {
        return this._invoiceApiService.addPayment(invoice, data);
    }

    updatePayment(payment: InvoicePayment, body: any): Observable<Invoice> {
        return this._invoiceApiService.updatePayment(payment, body);
    }

    removePayment(payment: InvoicePayment): Observable<Invoice> {
        return this._invoiceApiService.removePayment(payment);
    }
}
