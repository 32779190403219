<mat-form-field [formGroup]="parentFormGroup" [appearance]="appearance" fxFlex="100">
    <mat-label>Land</mat-label>
    <input type="text"
           matInput
           formControlName="country"
           [tabIndex]="tabIndex"
           [matAutocomplete]="countrySelector">
    <mat-error>{{parentFormGroup?.get('country')?.errors | firstErrorMessage}}</mat-error>

    <mat-autocomplete #countrySelector="matAutocomplete"
                      [displayWith]="displayCountryWith">
        <ng-container
                *ngFor="let country of filteredCountries | async | orderBy : 'de'">
            <mat-option [value]="country">
                <span>{{ country.de }}</span>
                <small class="secondary-text">
                    ({{country.code}})
                </small>
            </mat-option>
        </ng-container>
    </mat-autocomplete>
</mat-form-field>
