<div [formGroup]="avvFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid"
     class="with-loading-overlay">

    <ng-container *ngIf="isLoadingAvvPdf">
        <div class="loading-overlay">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>
    </ng-container>

    <mat-checkbox formControlName="accepted">
        Ich habe die <a href="javascript:void(0)" (click)="onClickGetAvv()">
        Auftragsverarbeitungsvereinbarung (AVV)</a> gelesen, akzeptiere diese und bestätige dies mit einer digitalen
        Unterschrift.
    </mat-checkbox>
    <ng-container *ngIf="avvFormGroup.get('accepted').invalid && avvFormGroup.get('accepted').touched">
        <div>
            <mat-error>{{avvFormGroup.get('accepted').errors | firstErrorMessage}}</mat-error>
        </div>
    </ng-container>

    <div>
        <p>
            Gib bitte Deinen vollen Namen als digitale Unterschrift und Bestätigung ein. Diese Unterschrift wird
            in dem Auftragsverarbeitungsvertrag verwendet.
        </p>
    </div>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Deine Unterschrift:</mat-label>
        <input matInput data-test="FI-Name" type="text" formControlName="signatureText"
               autocomplete="new-password">
        <mat-error>{{avvFormGroup.get('signatureText').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <!--    <div>-->
    <!--        Deine digitale Unterschrift:-->
    <!--        <signature-pad [options]="signaturePadOptions"-->
    <!--                       (onBeginEvent)="drawStart()"-->
    <!--                       (onEndEvent)="drawComplete()"></signature-pad>-->
    <!--        <ng-container *ngIf="avvFormGroup.get('signature').invalid && avvFormGroup.get('signature').touched">-->
    <!--            <div>-->
    <!--                <mat-error>{{avvFormGroup.get('signature').errors | firstErrorMessage}}</mat-error>-->
    <!--            </div>-->
    <!--        </ng-container>-->
    <!--        </div>-->

    <!--        <div class="pt-8">-->
    <!--            <button type="button"-->
    <!--                    mat-stroked-button-->
    <!--                    (click)="clearSignature()"-->
    <!--                    [disabled]="!isSigned">-->
    <!--                <mat-icon>undo</mat-icon>-->
    <!--                Unterschrift zurücksetzen-->
    <!--            </button>-->
    <!--        </div>-->
</div>