import { NgModule } from '@angular/core';
import { ModalComponent } from '@core/components/modal/modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [
        ModalComponent,
    ],
    imports: [
        DragDropModule,
        MatToolbarModule,
        MatDialogModule,
        FlexModule,
        MatButtonModule,
        MatIconModule,
        NgIf,
        NgScrollbarModule
    ],
    exports: [
        ModalComponent
    ]
})
export class ModalModule {
}
