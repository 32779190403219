import { IDataStorageItem, DataStorageItem } from './data-storage-item';
import { v4 as uuidv4 } from 'uuid';

export class DataSessionStorage {

    static init(): void {
        if (!DataSessionStorage.getItem('session-id').value) {
            DataSessionStorage.setItem('session-id', uuidv4());
        }
    }

    /**
     * Tries to return StorageItem, which should have been saved as array and stored as JSON string.
     * Returns StorageItem with undefined value, if JSON.parse() fails.
     * @param key
     */
    public static getItem<T>(key: string): IDataStorageItem {
        try {
            const storedItem = JSON.parse(sessionStorage.getItem(key));
            return new DataStorageItem<T>(key, storedItem[0]);
        } catch (e) {
            return new DataStorageItem<T>(key, undefined);
        }
    }

    /**
     * Makes an new array whose only item is given item and stores it as JSON string
     * @param key
     * @param item
     */
    public static setItem(key: string, item: any): void {
        sessionStorage.setItem(key, JSON.stringify([item]));
    }

    /**
     * Makes an new array whose only item is given item and stores it as JSON string
     * @param key
     * @param item
     */
    public static setItemIfNotExists(key: string, item: any): void {
        if (this.getItem(key).value === undefined) {
            sessionStorage.setItem(key, JSON.stringify([item]));
        }
    }

    /**
     * Removed an item with given key company storage
     * @param key
     */
    public static removeItem(key: string): void {
        sessionStorage.removeItem(key);
    }

}
