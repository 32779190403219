import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Boat } from '@shared/models/boat';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { BcmService } from '@modules/bcm/bcm.service';

@Component({
    selector: 'bcm-boat-link',
    templateUrl: './boat-link.component.html'
})
export class BoatLinkComponent implements OnChanges {

    // Todo: show boat avatar from this component? configurable?

    @Input()
    public boat!: Boat;

    @Input()
    public showName: boolean;

    @Input()
    public showLicensePlate: boolean;

    @Input()
    public showHIN: boolean;

    @Input()
    public showManufacturer: boolean;

    @Input()
    public showType: boolean;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(changes: SimpleChanges): void {
        const boat = this.boat || new Boat();

        this.viewData = [
            this.showLicensePlate ? (boat.licensePlate || null) : null,
            this.showName ? (boat.name || null) : null,
            this.showType ? (boat.type || null) : null,
            this.showManufacturer ? (boat.manufacturer || null) : null,
            this.additionalInfo ? this.additionalInfo : null,
        ].filter(item => item != null)
            .join(', ');

        // fallback?
        if (!this.showLicensePlate && !this.showName && !this.showType && !this.showManufacturer && !this.additionalInfo) {
            this.viewData = [
                boat.licensePlate || null,
                boat.name || null
            ]
                .filter(item => item != null)
                .join(', ') || 'Ohne Identifikator';
        }

    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
