import {NgModule} from '@angular/core';
import {MatPaginatorPersistedDirective} from '@shared/directives/mat-paginator-persister';
import {MaskDateDirective} from '@shared/directives/mask-date';
import {InvalidControlScrollContainerDirective} from '@shared/directives/invalid-control-scroll-container.directive';
import {InvalidControlScrollDirective} from '@shared/directives/invalid-control-scroll.directive';
import {MaskTimeDirective} from '@shared/directives/mask-time';
import {NumbersOnlyDirective} from './numbers-only.directive';
import { TypeSafeMatCellDefDirective } from '@shared/directives/mat-cell-def-monkey-patch.directive';
import { MaskTimeHoursDirective } from '@shared/directives/mask-time-hours.directive';
import { MaskTimeMinutesDirective } from '@shared/directives/mask-time-minutes.directive';

@NgModule({
    declarations: [
        MatPaginatorPersistedDirective,
        MaskDateDirective,
        MaskTimeDirective,
        MaskTimeHoursDirective,
        MaskTimeMinutesDirective,
        InvalidControlScrollContainerDirective,
        InvalidControlScrollDirective,
        NumbersOnlyDirective,
        TypeSafeMatCellDefDirective,
    ],
    exports: [
        MatPaginatorPersistedDirective,
        MaskDateDirective,
        MaskTimeDirective,
        MaskTimeHoursDirective,
        MaskTimeMinutesDirective,
        InvalidControlScrollContainerDirective,
        InvalidControlScrollDirective,
        NumbersOnlyDirective,
        TypeSafeMatCellDefDirective,
    ]
})
export class DirectivesModule {

}
