import {
    ContractShortCodes,
    contractShortCodeValues
} from '@modules/bcm/@shared/enums/contract-short-codes';

export function findKnownShortcodes(content: string): string[] {
    const pattern = /(\[[a-zA-Z0-9_]+])/ig;
    const matches = content.match(pattern);
    if (matches == null) {
        return [];
    }
    return matches
        .filter(item => contractShortCodeValues.includes(item as ContractShortCodes) || item.includes('PRODUKT_'));
}

export function findUnknownShortcodes(content: string): string[] {
    const pattern = /(\[[a-zA-Z0-9_]+])/ig;
    const matches = content.match(pattern);
    if (matches == null) {
        return [];
    }
    return matches
        .filter(item => !contractShortCodeValues.includes(item as ContractShortCodes) && !item.includes('PRODUKT_'));
}
