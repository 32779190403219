import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSidebarModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FooterModule } from './components/footer/footer.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { QuickPanelModule } from './components/quick-panel/quick-panel.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { LayoutComponent } from './layout.component';
import { NgScrollbarModule } from 'ngx-scrollbar';

// todo:
//  - put all layout stuff to /app/@core/...
//  - Try to get rid of @fuse stuff step by step (ViewEncapsulation.None needs to go!)
//  -
@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        FuseSidebarModule,
        FooterModule,
        NavbarModule,
        QuickPanelModule,
        ToolbarModule,
        NgScrollbarModule
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule {
}
