import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseList } from '@shared/functions/parse-http-response';
import { BcmOrderWorkPackage, BcmOrderWorkPackageDto } from '@shared/models/bcm-order-work-package';

@Injectable({
    providedIn: 'root'
})
export class OrderWorkPackagesApiService extends BcmApiService {
    
    private RESOURCE_ENDPOINT = 'order-work-packages';

    getAll(params: HttpParams = new HttpParams()): Observable<BcmOrderWorkPackage[]> {
        return super.get<BcmOrderWorkPackageDto[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<BcmOrderWorkPackageDto, BcmOrderWorkPackage>(BcmOrderWorkPackage));
    }

    add(body: object = {}): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    remove(id: number): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }

}
