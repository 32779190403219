import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BcmApiService } from './bcm-api.service';
import { BcmUser } from '../../../bcm-user';
import { AppUser } from '@core/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class BcmUserApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'users';

    getAll(params: HttpParams = new HttpParams()): Observable<BcmUser[]> {
        return super.get<BcmUser[]>(this.RESOURCE_ENDPOINT, params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BcmUser> {
        return super.get<BcmUser>(`${this.RESOURCE_ENDPOINT}/${id}`, params);
    }

    getOneByUUID(uuid: string, params: HttpParams = new HttpParams()): Observable<AppUser> {
        return super.get<AppUser>(`${this.RESOURCE_ENDPOINT}/k/${uuid}`, params);
    }

    update(id: string, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    remove(id: string): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }

}
