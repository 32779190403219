import { BcmOrder, BcmOrderDto } from './bcm-order';
import { Time } from '@angular/common';

export interface BcmOrderTodoDto {
    id: number;
    bcm_orders_id: number;
    todo: string;
    duration: Time; // todo: parse to sql time in backend
    done: boolean;
    position: number;
    note: string;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    order: BcmOrderDto;
}

export class BcmOrderTodo {
    id: number;
    bcm_orders_id: number;
    todo: string;
    duration: Time; // todo: parse to sql time in backend
    done: boolean;
    position: number;
    note: string;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;
    order: BcmOrder | null;

    constructor(bcmOrderWorkPackageDto: BcmOrderTodoDto = {} as BcmOrderTodoDto) {
        this.id = bcmOrderWorkPackageDto.id;
        this.bcm_orders_id = bcmOrderWorkPackageDto.bcm_orders_id;
        this.todo = bcmOrderWorkPackageDto.todo;
        this.duration = bcmOrderWorkPackageDto.duration;
        this.done = bcmOrderWorkPackageDto.done;
        this.position = bcmOrderWorkPackageDto.position;
        this.note = bcmOrderWorkPackageDto.note;
        this.insertedOn = bcmOrderWorkPackageDto.insertedOn;
        this.insertedBy = bcmOrderWorkPackageDto.insertedBy;
        this.lastUpdateOn = bcmOrderWorkPackageDto.lastUpdateOn;
        this.lastUpdateBy = bcmOrderWorkPackageDto.lastUpdateBy;

        this.order = bcmOrderWorkPackageDto.order ? new BcmOrder(bcmOrderWorkPackageDto.order) : null;
    }
}

