<div id="main">
    <div class="container">

        <fuse-sidebar name="navbar"
                      class="navbar-fuse-sidebar"
                      lockedOpen="gt-md"
                      [folded]="false">
            <navbar class="left-navbar"></navbar>
        </fuse-sidebar>

        <div class="container">

            <toolbar></toolbar>

            <div class="container">
                <div class="content">
                    <router-outlet *ngIf="true"></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>

<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</fuse-sidebar>
