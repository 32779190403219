import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmBaseFacade } from '../bcm-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { Company, ICompany } from '@shared/models/company';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { BcmExportExcelApiService } from '@bcmApiServices/bcm-export-excel.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { companiesTableData } from '@modules/bcm/@core/state-management/companies/companies-table-data';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { ThemePalette } from '@angular/material/core';
import { BcmUserSettingsFacade } from '@bcmServices/settings/bcm-user-settings-facade';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import {
    GetTenantRelationDataDialogComponent
} from '@sharedComponents/dialogs/get-tenant-relation-data/get-tenant-relation-data-dialog.component';
import { EMPTY, forkJoin, of, switchMap } from 'rxjs';
import { TenantRelationAssignment } from '@shared/models/tenant-relation-assignment';
import { tap } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmInvoicePositionsService } from '@bcmServices/invoice-positions.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CompanyApiService } from '@modules/bcm/@shared/services';
import { BcmBaseBulkChanges } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes';
import {
    GetCountryDialogComponent
} from '@modules/bcm/@shared/components/dialogs/get-country-dialog/get-country-dialog.component';
import { Country } from '@shared/models/country';

@Injectable({providedIn: 'root'})
export class CompaniesFacade extends BcmBaseFacade<Company, ICompany> {

    readonly resourcePath = 'companies';

    readonly resourceNameSingular = 'Organisation';

    readonly resourceNamePlural = 'Organisationen';

    readonly resourceIconPlural = 'location_city';

    readonly resourceIconSingular = 'location_city';

    readonly deletePermission = BcmUserPermission.COMPANIES_DELETE;

    readonly readPermission = BcmUserPermission.COMPANIES_READ;

    readonly writePermission = BcmUserPermission.COMPANIES_WRITE;

    readonly headerActions = [
        {
            title: 'Excel Export',
            onClick: () => this._bcmExportExcelApiService.exportCompanies(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        },
        {
            title: 'Excel Export - Beziehungen',
            onClick: () => this._bcmExportExcelApiService.exportCompanyTenantRelations(),
            tenantPermissions: [BcmTenantPermission.REPORTS],
        }
    ];

    readonly rowActions = [
        {
            title: 'Datensatz anzeigen',
            icon: 'assignment',
            iconColor: 'accent' as ThemePalette,
            onClick: (company: Company) => this._bcmNavigationService.navigate('companies/' + company.id),
        },
        {
            title: 'Datensatz löschen',
            icon: 'delete',
            iconColor: 'warn' as ThemePalette,
            onClick: (company: Company) => super.remove(company, company.name).subscribe(),
        }
    ];

    readonly bulkChanges = new BcmBaseBulkChanges<Company>()
        .setButtons(
            {
                title: 'Beziehung',
                icon: 'add',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (companies: Company[]) => this.addTenantRelation(...companies),
            },
            {
                title: 'Produkt',
                icon: 'add',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (companies: Company[]) => this.addInvoicePosition(...companies),
            },
            {
                title: 'Land',
                icon: 'edit',
                iconColor: 'accent' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (companies: Company[]) => this.updateCountry(...companies),
            },
            {
                title: 'Löschen',
                icon: 'delete',
                iconColor: 'warn' as ThemePalette,
                resetSelectionAfterSuccess: true,
                onClick: (companies: Company[]) => super.removeMultiple(companies),
            },
        );

    constructor(appNotificationService: AppNotificationService,
                confirmDialogService: ConfirmDialogService,
                bcmUserSettingsFacade: BcmUserSettingsFacade,
                httpClient: HttpClient,
                bcmService: BcmService,
                bcmNavigationService: BcmNavigationService,
                private _bcmExportExcelApiService: BcmExportExcelApiService,
                private _bcmNavigationService: BcmNavigationService,
                private _bcmInvoicePositionsService: BcmInvoicePositionsService,
                private _companiesApiService: CompanyApiService,
                private _matDialog: MatLegacyDialog,
    ) {
        super(
            Company,
            companiesTableData,
            bcmNavigationService,
            appNotificationService,
            confirmDialogService,
            bcmUserSettingsFacade,
            httpClient,
            bcmService
        );
    }

    addTenantRelation(...companies: Company[]) {
        const dialogRef = this._matDialog.open(GetTenantRelationDataDialogComponent);

        return dialogRef
            .afterClosed()
            .pipe(
                switchMap((tenantRelationAssignment: TenantRelationAssignment) => {

                    if (!tenantRelationAssignment) {
                        return of(undefined);
                    }

                    return this._companiesApiService
                        .multipleNewTenantRelationAssignment(tenantRelationAssignment, ...companies)
                        .pipe(tap((tenantRelationAssignments) => {
                            companies.forEach(company => company.addTenantRelationAssignment(
                                tenantRelationAssignments.find(assignment => assignment.company?.id === company.id)
                            ));
                            this.appNotificationService.showSuccess(`Beziehungen erfolgreich angelegt`);
                        }));
                })
            );
    }

    addInvoicePosition(...companies: Company[]) {
        return this._bcmInvoicePositionsService
            .addPositionsForMultipleCompanies(companies)
            .pipe(tap((positions: InvoicePosition[]) => {
                if (positions.length > 0) {
                    this.appNotificationService.showSuccess('Neue Position(en) hinzugefügt.');
                }
            }));
    }

    updateCountry(...companies: Company[]) {
        const dialogRef = this._matDialog.open(GetCountryDialogComponent);

        return dialogRef
            .afterClosed()
            .pipe(
                switchMap((country: Country) => {
                    if (country) {
                        companies.forEach(company => company.country = country);
                        return forkJoin(
                            companies.map(company => super.update(company, {country} as Partial<Company>))
                        );
                    }
                    return EMPTY;
                })
            );
    }
}
