import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApiService } from '../base-api.service';
import { environment } from '@env/environment';
import { BcmService } from '../../../bcm.service';
import { Injectable } from '@angular/core';

type LAST_INSERT_ID = number;
type EFFECTED_ROWS = number;

@Injectable({
    providedIn: 'root'
})
// export class BcmApi2Service<T> extends BaseApiService { // todo: add generic type here instead of each method
export class BcmApi2Service extends BaseApiService {

    private _disableSyncForNextRequest = false;

    protected className = 'BcmApiService';

    protected endpoint = environment.endpoints.bcm.api;

    constructor(
        _http: HttpClient,
        protected bcmService: BcmService,
    ) {
        super(_http);
    }

    protected disableSyncForNextRequest(): void {
        this._disableSyncForNextRequest = true;
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        this._syncEndpoint();
        return super.get<T>(path, params);
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
        this._syncEndpoint();
        return super.getBlob(path, params);
    }

    protected getBlobWithProgress(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {
        this._syncEndpoint();
        return super.getBlobWithProgress(path, params);
    }

    protected post<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        this._syncEndpoint();
        return super.post<T>(path, body, params);
    }

    protected postWithBlobResponse<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        this._syncEndpoint();
        return super.postWithBlobResponse<T>(path, body, params);
    }

    protected put<T = EFFECTED_ROWS>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {
        this._syncEndpoint();
        return super.put<T>(path, body, params);
    }

    protected delete<T = EFFECTED_ROWS>(path, params: HttpParams = new HttpParams(), body?: unknown): Observable<T> {
        this._syncEndpoint();
        return super.delete<T>(path, params, body);
    }

    private _syncEndpoint(): void {
        if (!this._disableSyncForNextRequest && this.bcmService.tenant) {
            this.endpoint = `${environment.endpoints.bcm.api}${this.bcmService.tenant.id}/`;
        } else {
            this.endpoint = environment.endpoints.bcm.api;
        }

        this._disableSyncForNextRequest = false;
    }
}
