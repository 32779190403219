export enum Salutation {
    Male = 0,
    Female = 1,
    Divers = 2,
    None = 3,
}

export enum SalutationNew {
    None = '',
    Male = 'Herr',
    Female = 'Frau',
    Divers = 'Divers',
}
