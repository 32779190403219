import { EventEmitter, Injectable } from '@angular/core';
import { User } from '@core/services/api/u2b-user.api-service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';

export enum PermissionAreas {
    U2B,
    BCM,
}

export interface AppUser extends User {
    permissions: string[];
    privacyAndConditions?: {
        id: number;
        readPrivacyOn?: string | Date;
        readConditionsOn?: string | Date;
    };
}

@Injectable({providedIn: 'root'})
// ToDo: check and updateSection user state every 30 seconds... ?
//  or use web sockets.. socket.io ??
export class UserService {

    private _user: AppUser;

    private _userPermissionsChanged = new EventEmitter<string[]>();

    private _permissionPackages: { [key: number]: string[] } = {};

    public get user(): AppUser {
        return this._user;
    }

    public set user(user: AppUser) {
        // ToDo: logout automatically if user is null;
        this._user = user || {} as AppUser;
        this._user.permissions = [];
    }

    public get userId(): string {
        return this._user.id;
    }

    constructor() {
    }

    public get userFullName(): string {
        return this._user ? `${this._user.firstName} ${this._user.lastName}`.trim() : '';
    }

    public get userFirstName(): string {
        return this._user ? this._user.firstName.trim() : '';
    }

    public get userLastName(): string {
        return this._user ? this._user.lastName.trim() : '';
    }

    public get userPermissionsChanged(): EventEmitter<string[]> {
        return this._userPermissionsChanged;
    }

    public addPermissions(permissionArea: PermissionAreas, permissions: string[]): number {
        if (this._permissionPackages[permissionArea] != null || permissions?.length === 0) {
            return;
        }

        permissions = permissions.filter((permission) => !this._user.permissions.includes(permission));

        this._permissionPackages[permissionArea] = permissions;

        if (permissions?.length) {
            this._user.permissions.push(...permissions);
            this._userPermissionsChanged.emit(this._user.permissions);
        }

        return permissionArea;
    }

    public removePermissions(permissionArea: PermissionAreas): void {
        if (this._permissionPackages[permissionArea] == null) {
            return;
        }

        const permissions = this._permissionPackages[permissionArea];

        if (permissions?.length) {
            this._user.permissions = this._user.permissions.filter((permission) => !permissions.includes(permission));
            this._userPermissionsChanged.emit(this._user.permissions);
            delete this._permissionPackages[permissionArea];
        }
    }

    public hasAllPermissionsOf(...permissions: BcmUserPermission[]): boolean {

        let result = true;

        for (const permission of permissions) {
            result = this.checkPermission(permission);

            if (!result) {
                break;
            }
        }

        return result;
    }

    public hasOnePermissionOf(...permissions: BcmUserPermission[]): boolean {

        let result = false;

        for (const permission of permissions) {
            result = this.checkPermission(permission);

            if (result) {
                break;
            }
        }

        return result === true;
    }

    public checkPermission(permission: string): boolean {
        if ((this._user?.permissions || []).includes(BcmUserPermission.FULL_GRANT)) {
            return true;
        }
        return permission == null || (this._user?.permissions || []).includes(permission);
    }

    public reset(): void {
        this._user = null;
        this._permissionPackages = [];
        this._userPermissionsChanged.emit([]);
    }
}


