import { BcmOrder, BcmOrderDto } from './bcm-order';
import { BcmFile, BcmFileDto } from './bcm-file';

export interface BcmOrderAttachmentDto {
    id: number;
    bcm_orders_id: number;
    bcm_files_id: number;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    order: BcmOrderDto;
    file: BcmFileDto;
}

export class BcmOrderAttachment {
    id: number;
    bcm_orders_id: number;
    bcm_files_id: number;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;
    order: BcmOrder | null;
    file: BcmFile | null;

    constructor(bcmOrderAttachment: BcmOrderAttachmentDto) {
        this.id = bcmOrderAttachment.id;
        this.bcm_orders_id = bcmOrderAttachment.bcm_orders_id;
        this.bcm_files_id = bcmOrderAttachment.bcm_orders_id;
        this.insertedOn = bcmOrderAttachment.insertedOn;
        this.insertedBy = bcmOrderAttachment.insertedBy;
        this.lastUpdateOn = bcmOrderAttachment.lastUpdateOn;
        this.lastUpdateBy = bcmOrderAttachment.lastUpdateBy;

        this.order = bcmOrderAttachment.order ? new BcmOrder(bcmOrderAttachment.order) : null;
        this.file = bcmOrderAttachment.file ? new BcmFile(bcmOrderAttachment.file) : null;
    }
}

