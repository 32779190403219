import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { map } from 'rxjs/operators';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';
import { parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { InvoicePayment, InvoicePaymentDto } from '@shared/models/invoice-payment';

@Injectable({
    providedIn: 'root'
})
export class InvoiceApiService extends BcmApi2Service {

    private RESOURCE_ENDPOINT = 'invoices';

    constructor(
        _http: HttpClient,
        protected bcmService: BcmService,
    ) {
        super(_http, bcmService);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<Invoice[]> {
        return super.get<IInvoice[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(map((invoiceData: any[]) => {
                return invoiceData.map(invoice => new Invoice(invoice));
            }));
    }

    getDatevExport(params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {
        return super.getBlobWithProgress(`${this.RESOURCE_ENDPOINT}/datev-export`, params);
    }

    getZipExport(params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {
        return super.getBlobWithProgress(`${this.RESOURCE_ENDPOINT}/zip`, params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Invoice> {
        return super.get<IInvoice>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(map((invoice: any) => new Invoice(invoice)));
    }

    getPdfFileExample(params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/pdf-example`, params);
    }

    getPdfFile(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${id}/pdf`, params);
    }

    add(invoice: any/*Invoice*/): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, invoice);
    }

    getInvoiceTestPDF(invoice: any): Observable<number> {
        return super.postWithBlobResponse(this.RESOURCE_ENDPOINT + '/pdf-example', invoice);
    }

    update(invoice: Invoice, body: any = {}): Observable<any> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${invoice.id}`, body);
    }

    addPayment(invoice: Invoice, body: InvoicePaymentDto): Observable<Invoice> {
        return super.post<IInvoice>(`${this.RESOURCE_ENDPOINT}/${invoice.id}/payments`, body)
            .pipe(parseHttpResponseItem<IInvoice, Invoice>(Invoice));
    }

    updatePayment(payment: InvoicePayment, body: InvoicePayment): Observable<Invoice> {
        return super.put<IInvoice>(`${this.RESOURCE_ENDPOINT}/${payment.bcm_invoices_id}/payments/${payment.id}`, body)
            .pipe(parseHttpResponseItem<IInvoice, Invoice>(Invoice));
    }

    removePayment(payment: InvoicePayment): Observable<Invoice> {
        return super.delete<IInvoice>(`${this.RESOURCE_ENDPOINT}/${payment.bcm_invoices_id}/payments/${payment.id}`)
            .pipe(parseHttpResponseItem<IInvoice, Invoice>(Invoice));
    }

    cancel(invoice: Invoice, addPositionsBackToCoaster: boolean, cancellationReason: string): Observable<Invoice> {
        return super.post<IInvoice>(`${this.RESOURCE_ENDPOINT}/${invoice.id}/cancel`, {addPositionsBackToCoaster, cancellationReason})
            .pipe(parseHttpResponseItem<IInvoice, Invoice>(Invoice));
    }

    remove(invoice: Invoice): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${invoice.id}`);
    }

    sendMail(invoiceId: number, body: { toMail: string, subject: string, content: string }): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/${invoiceId}/send-mail`, body);
    }
}
