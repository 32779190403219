import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import {
    LegacyDateAdapter as DateAdapter,
    MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
    MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
} from '@angular/material/legacy-core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ModulesModule } from '@modules/modules.module';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';
import {
    MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatLegacySnackBarModule as MatSnackBarModule
} from '@angular/material/legacy-snack-bar';
import { CoreModule } from '@core/core.module';
import { HttpErrorInterceptor } from '@shared/interceptors/http-error.interceptor';
import { SNACK_BAR_CONFIG__DEFAULT } from '@shared/constants/snack-bar';
import { WorkFlowModule } from '@modules/bcm/@work-flows/work-flow.module';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';
import { MAT_LEGACY_AUTOCOMPLETE_SCROLL_STRATEGY as MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/legacy-autocomplete';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { getGermanPaginatorIntl } from '@core/intl/get-german-paginator-intl';
import { SystemNotificationsModule } from '@core/components/system-notifications/system-notifications.module';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { ToastrModule } from 'ngx-toastr';
import { DragToSelectModule } from 'ngx-drag-to-select';
import { DateFnsAdapter, MAT_DATE_FNS_DATE_FORMATS } from './date-fns-date-adapter.class';
import { ReleaseNotesDialogModule } from '@modules/bcm/@shared/components/dialogs/release-notes/release-notes-dialog.module';
import { getDefaultLocale } from '@core/date.facade';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconModule } from '@core/modules/svg-icons/svg-icon.module';
import { AuthenticationModule } from './auth';
import { TokenInterceptor } from '@shared/interceptors/token-interceptor';
import { AuthService } from './auth/auth.service';
import { AuthEventsService } from './auth/auth-events.service';
import { TranslationService } from '@core/translation/translation.service';
import { appInitializer } from './app-initializer';
import { environment } from '@env/environment';

registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localeDeCH, 'de-CH');

const deConfig = new DateFnsConfigurationService();
deConfig.setLocale(getDefaultLocale());

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: '',
    }
];

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
    return () => overlay.scrollStrategies.close();
}

const socketIoConfig: SocketIoConfig = {url: 'http://localhost:1337', options: {}};

@NgModule({
    declarations: [
        AppComponent,
    ],
    providers: [
        {provide: APP_BASE_HREF, useValue: environment.baseHref || '/'},
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializer,
            multi: true,
            deps: [AuthService, AuthEventsService, TranslationService]
        },
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        // {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        {provide: DateFnsConfigurationService, useValue: deConfig},
        {provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_DATE_FORMATS},
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: SNACK_BAR_CONFIG__DEFAULT},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {panelClass: 'default-dialog', hasBackdrop: true}},
        {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}},
        {provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]},
        {provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl()},
    ],
    imports: [
        BrowserModule,
        SocketIoModule.forRoot(socketIoConfig),
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(
            appRoutes,
            {
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled',
                // enableTracing: true // <-- debugging purposes only
            }
        ),

        DateFnsModule.forRoot(),

        DragToSelectModule.forRoot(),
        NgIdleKeepaliveModule.forRoot(),

        HammerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        ModulesModule,
        CoreModule,
        MatSnackBarModule,
        WorkFlowModule,
        ReleaseNotesDialogModule,

        SystemNotificationsModule,
        ToastrModule.forRoot({
            timeOut: 8000,
            positionClass: 'toast-top-right',
            extendedTimeOut: 2000,
            preventDuplicates: true,
        }),
        MatIconModule,
        SvgIconModule,
        AuthenticationModule,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas, fab, far);
    }
}
