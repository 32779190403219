import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { HttpParams } from '@angular/common/http';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({
    providedIn: 'root'
})
export class BerthAssignmentApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<BerthBoatAssignment[]> {
        return super.get<IBerthBoatAssignment[]>('berth-assignments', params)
            .pipe(parseHttpResponseList<IBerthBoatAssignment, BerthBoatAssignment>(BerthBoatAssignment));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<BerthBoatAssignment> {
        return super.get<IBerthBoatAssignment>(`berth-assignments/${id}`, params)
            .pipe(parseHttpResponseItem<IBerthBoatAssignment, BerthBoatAssignment>(BerthBoatAssignment));
    }

    add(assignmentData: Partial<BerthBoatAssignment>): Observable<BerthBoatAssignment> {
        return super.post<BerthBoatAssignment>(this.getPath(), assignmentData);
    }

    update(assignment: BerthBoatAssignment, assignmentData: Partial<BerthBoatAssignment>): Observable<BerthBoatAssignment> {
        return super.put<BerthBoatAssignment>(this.getPath(assignment), assignmentData);
    }

    remove(assignment: BerthBoatAssignment, {cancelInvoice = false, removeInvoicePositions = false}): Observable<any> {
        return super.delete(
            this.getPath(assignment),
            new HttpParams()
                .set('cancelInvoice', cancelInvoice)
                .set('removeInvoicePositions', removeInvoicePositions)
        );
    }

    private getPath(assignment?: Partial<BerthBoatAssignment>): string {
        let path = `berth-assignments`;
        if (assignment?.id) {
            path += `/${assignment.id}`;
        }
        return path;
    }
}

