import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { GetBoatDialogComponent } from '@shared/components/dialogs/get-boat-dialog/get-boat-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { DirectivesModule } from '@shared/directives/directives.module';
import { BcmDirectivesModule } from '@modules/bcm/@core/directives/bcm-directives.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { DateInputModule } from '@sharedComponents/form/date-input/date-input.module';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [
        GetBoatDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,

        FuseSharedModule,
        FuseWidgetModule,

        SharedModule,
        DragDropModule,
        DirectivesModule,
        BcmDirectivesModule,
        MatCheckboxModule,
        HtmlLinksModule,
        DateInputModule,
        TranslationModule,
    ],
})
export class GetBoatDialogModule {
}
