import { NgModule } from '@angular/core';
import { BcmDynamicCurrency } from '@modules/bcm/@shared/pipes/dynamic-currency.pipe';
import { CurrencyPipe } from '@angular/common';
import { BcmDynamicPriceRoundedPipe } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { DynamicPricePipe } from '@modules/bcm/@shared/pipes/dynamic-price.pipe';
import { DynamicGrossToNetPricePipe } from '@modules/bcm/@shared/pipes/dynamic-gross-to-net-price.pipe';
import { DynamicNetToGrossPricePipe } from '@modules/bcm/@shared/pipes/dynamic-net-to-gross-price.pipe';
import { GrossToNetPricePipe } from '@modules/bcm/@shared/pipes/gross-to-net-price.pipe';
import { NetToGrossPricePipe } from '@modules/bcm/@shared/pipes/net-to-gross-price.pipe';
import { IdentNumberPipe } from '@modules/bcm/@shared/pipes/ident-number.pipe';

@NgModule({
    declarations: [
        BcmDynamicCurrency,
        BcmDynamicPriceRoundedPipe,
        DynamicPricePipe,
        DynamicGrossToNetPricePipe,
        DynamicNetToGrossPricePipe,
        GrossToNetPricePipe,
        NetToGrossPricePipe,
        IdentNumberPipe,
    ],
    exports: [
        BcmDynamicCurrency,
        BcmDynamicPriceRoundedPipe,
        DynamicPricePipe,
        DynamicGrossToNetPricePipe,
        DynamicNetToGrossPricePipe,
        GrossToNetPricePipe,
        NetToGrossPricePipe,
        IdentNumberPipe,
    ],
    providers: [CurrencyPipe, BcmDynamicCurrency]
})
export class BcmPipesModule {

}
