import { Observable } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { deepCopyArray } from '@core/functions/deep-copy';

interface BaseEntity {
    id?: number;
}

interface IBase<T> {
    list: T[];
    selected: T;
}

function getInitialState<T>(): IBase<T> {
    return {
        list: [] as T[],
        selected: {} as T,
    };
}

export class BaseState<T extends BaseEntity> extends BaseStateService<IBase<T>> {

    public list$: Observable<T[]> = this.select(state => state.list);

    public selected$: Observable<T> = this.select((state) => {
        return state.selected || state.list.find((item) => item.id === state.selected?.id);
    });

    public get getSelected(): T {
        return this.state.selected;
    }

    public get getList(): T[] {
        return this.state.list;
    }

    constructor() {
        super(getInitialState<T>());
    }

    public setSelected(selected: T): void {
        this.setState({selected});
    }

    public setList(list: T[]): void {
        this.setState({list});
    }

    public addToList(item: T): void {
        this.setState({list: [...deepCopyArray(this.state.list), item]});
    }

    public replaceInList(oldItem: T, newItem: T): void {
        const index = this.state.list.findIndex(item => item.id === oldItem.id);
        this.state.list[index] = newItem;
    }

    public removeFromList(item: T): void {
        const index = this.state.list.findIndex(mm => mm.id === item.id);
        const list = deepCopyArray(this.state.list);

        list.splice(index, 1);

        this.setState({
            list: [...list]
        });
    }

}
