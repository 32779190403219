import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITaxRate } from '@shared/models/tax-rate';
import { ProductTaxRateApiService } from '@modules/bcm/@shared/services';

@Injectable({providedIn: 'root'})
export class ProductTaxRatesResolver implements Resolve<ITaxRate[]> {

    constructor(private _productTaxRateApiService: ProductTaxRateApiService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ITaxRate[]> {
        return this._productTaxRateApiService.getAll();
    }
}
