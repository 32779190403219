import { Injectable } from '@angular/core';
import { U2bImageCropperDialogComponent } from '@shared/components';
import { IFile } from '@shared/interfaces/file';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({providedIn: 'root'})
export class ImageCropperDialogService {

    constructor(private _matDialog: MatDialog) {
    }

    getCroppedImage(image: IFile): Observable<IFile> {
        const dialogRef = this._matDialog.open(U2bImageCropperDialogComponent, {
            data: {image}
        });

        return dialogRef
            .afterClosed()
            .pipe(switchMap(response => {

                if (!response) {
                    return EMPTY;
                }

                return of({
                    ...image,
                    file: response.file,
                    mimeType: response.file.type,
                    markForDelete: false,
                    hasChanges: true
                } as IFile);
            }));
    }
}
