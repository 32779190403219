<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Änderungen von AGB / Datenschutzerklärung</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content fusePerfectScrollbar>

        <h2>Hallo {{user.firstName}},</h2>

        <p>
            im Zuge unserer unermüdlichen Anstrengungen Dein Up2Boat-Erlebnis zu verbessern, aktualisieren wir unsere
            Nutzungsbedingungen (AGB) und Datenschutzerklärung, um den neuen Funktionen und der neuen Steuerung unserer
            Dienste Rechnung zu tragen.
        </p>

        <p>
            Bitte lies die aktualisierten <a [href]="privacyPage.link" target="_blank" title="Unsere AGB lesen">Nutzungsbedingungen
            (AGB)</a> und die aktualisierte <a [href]="conditionsPage.link" target="_blank" title="Unsere Datenschutzerklärung lesen">Datenschutzerklärung</a>
            und stimme ihnen zu, indem Du unten auf "OK" klickst.
        </p>

        <p>
            Falls du ihnen nicht zustimmst, kannst du die Nutzung von www.up2boat.com einstellen und die Kontoschließung
            beantragen, indem du uns eine E-Mail sendest.
        </p>

        <p>
            Vielen Dank fürs Lesen und viel Spaß in den Up2Boat-"Gewässern"!
        </p>

    </div>

    <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

        <ng-template #saving>
            <span>Speichert...</span>
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-template>

        <ng-template #notSaving>
            <span>OK</span>
        </ng-template>

        <button data-test="B-OK" type="button" mat-flat-button color="warn" [disabled]="isSaving" (click)="onClickLogout()">
            Abmelden
        </button>

        <button data-test="B-OK" type="button" mat-flat-button color="accent" [disabled]="isSaving" (click)="onClickOK()">
            <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
        </button>

    </div>
</div>
