<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <ng-container *ngIf="!data?.addresses?.length">
            <u2b-message type="warning">
                Keine A-Mail Adressen gefunden.
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="data?.addresses?.length">
            <form [formGroup]="addressForm">
                <mat-selection-list formControlName="selectedAddresses">
                    <mat-list-option *ngFor="let address of data.addresses;  let i = index" [value]="address">
                        <mat-icon matListIcon>mail</mat-icon>
                        <h3 matLine> {{this.data.addresses[i]}} </h3>
                    </mat-list-option>
                </mat-selection-list>
            </form>
        </ng-container>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Speichern" mat-flat-button (click)="save()" class="save-button mat-accent"
            aria-label="Speichern" [disabled]=" isSaving || !addressForm.dirty">
            Speichern
        </button>

    </div>
</div>
