import { isValid, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

export function concatDateAndTime(date: Date,
                                  hours: number | string = '00',
                                  minutes: number | string = '00',
                                  seconds: number | string = '00',
                                  milliSeconds: number | string = '00'): Date {
    if (!date || !(isValid(date) && isValid(date))) {
        return null;
    }
    date = setHours(date, parseInt(`${hours}`, 10));
    date = setMinutes(date, parseInt(`${minutes}`, 10));
    date = setSeconds(date, parseInt(`${seconds}`, 10));
    date = setMilliseconds(date, parseInt(`${milliSeconds}`, 10));
    return date;
}

export function concatDateAndTimeArr(date: Date,
                                     time: string): Date {
    const [hours, minutes] = (time || '00:00').split(':');
    return concatDateAndTime(date, hours, minutes);
}
