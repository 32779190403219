import { EventEmitter, Inject, Injectable, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as L from 'leaflet';
import 'leaflet-geometryutil';
import {
    LatLng,
    LatLngExpression,
    LeafletMouseEvent,
    Point,
    PointExpression,
    Polyline,
} from 'leaflet';
import { MapObjectBase, MapObjectType, MapObjectPolylineRaw } from '@shared/models/map-interfaces';
import { MapObjectBerth } from '@shared/models/map-object-berth';
import { MapObjectElectricMeterCabinetMarker } from '@shared/models/map-object-electric-meter-cabinet';
import { DOCUMENT } from '@angular/common';
import { MapCursor } from '@modules/bcm/berths/berths-map/_shared/abstract-layer.service';
import { filter } from 'rxjs/operators';
import { MapObjectBerthBuoy } from '@shared/models/map-object-berth-buoy';

export const DEFAULT_MAP_LAT_DE_MID = 51.1642292;
export const DEFAULT_MAP_LONG_DE_MID = 10.4541194;

@Injectable({providedIn: 'root'})
export class SharedLayerService {

    static editorMode: boolean;

    static pierSelectMode: boolean;

    static mapZoomChange = new EventEmitter<number>();

    static mapObjectRawList$ = new EventEmitter<MapObjectPolylineRaw[]>();

    static hoverMapObjectChange = new EventEmitter<MapObjectBase>();

    static blurMapObjectChange = new EventEmitter<MapObjectBase>();

    static showHelpChange = new EventEmitter<MapObjectType | null>();

    private _renderer: Renderer2;

    private _mapContainerHtmlElement: HTMLElement;

    private _map: L.Map;

    get map(): L.Map {
        return this._map;
    }

    get mapZoom(): number {
        return this._map.getZoom();
    }

    get mapContainerHtmlElement(): HTMLElement {
        return this._mapContainerHtmlElement;
    }

    private _openSidebarOptions = new BehaviorSubject<MapObjectBase | null>(null);

    public get openSidebarOptions$(): Observable<MapObjectBase | null> {
        return this._openSidebarOptions.asObservable()
            .pipe(filter(item => item != null));
    }

    private _lastFocusedMapObject$ = new BehaviorSubject<MapObjectBase | null>(null);

    public get lastFocusedMapObject$(): Observable<MapObjectBase | null> {
        return this._lastFocusedMapObject$.asObservable();
    }

    private _berthsInMap$ = new BehaviorSubject<(MapObjectBerth | MapObjectBerthBuoy)[]>([]);

    get berthsInMap$(): Observable<(MapObjectBerth | MapObjectBerthBuoy)[]> {
        return this._berthsInMap$.asObservable();
    }

    private _electricMetersInMap$ = new BehaviorSubject<MapObjectElectricMeterCabinetMarker[]>([]);

    get electricMetersInMap$(): Observable<MapObjectElectricMeterCabinetMarker[]> {
        return this._electricMetersInMap$.asObservable();
    }

    private _allHarbourLayers = new L.FeatureGroup();

    public get allHarbourLayers(): L.FeatureGroup {
        return this._allHarbourLayers;
    }

    private _fingerBridgeLayers = new L.FeatureGroup();

    public get fingerBridgeLayers(): L.FeatureGroup {
        return this._fingerBridgeLayers;
    }

    constructor(private rendererFactory: RendererFactory2,
                @Inject(DOCUMENT) private document) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    static stopPropagation(event: LeafletMouseEvent): void {
        L.DomEvent.stopPropagation(event.originalEvent);
        event.originalEvent.stopPropagation();
        L.DomEvent.stopPropagation(event);
    }

    static stopEvent(event: LeafletMouseEvent): void {
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();
        L.DomEvent.stopPropagation(event.originalEvent);
        L.DomEvent.preventDefault(event.originalEvent);
        L.DomEvent.stop(event);
    }

    public init(map: L.DrawMap, editorMode = false, lat: number, lng: number, mapSelector = '#map'): void {
        this._map = map;
        this._map.zoomControl.remove();
        SharedLayerService.editorMode = editorMode;
        this._mapContainerHtmlElement = this.document.querySelector(mapSelector);

        if (lat === DEFAULT_MAP_LAT_DE_MID && lng === DEFAULT_MAP_LONG_DE_MID) {
            this.map.setZoom(7);
        }

        this.map.on('zoomend', () => SharedLayerService.mapZoomChange.emit(this.mapZoom));
    }

    public openSidebarOptions(value: MapObjectBase | null): void {
        this._openSidebarOptions.next(value);
    }

    public setLastFocusedMapObject(mapObject: MapObjectBase | null): void {
        this._lastFocusedMapObject$.getValue()?.blur();
        mapObject?.focus();
        this._lastFocusedMapObject$.next(mapObject);
    }

    public getBerthList(): (MapObjectBerth | MapObjectBerthBuoy)[] {
        return this._berthsInMap$.getValue();
    }

    public addBerthToList(berthMapObject: MapObjectBerth | MapObjectBerthBuoy): void {
        const previousList = this._berthsInMap$.getValue();
        const newList: (MapObjectBerth | MapObjectBerthBuoy)[] = [
            ...previousList,
            berthMapObject
        ];
        this._berthsInMap$.next(newList);
    }

    public removeBerthFromList(berthId: number): void {
        const previousList = this._berthsInMap$.getValue();
        const newList: (MapObjectBerth | MapObjectBerthBuoy)[] = previousList.filter(item => item.berth?.id !== berthId);
        this._berthsInMap$.next(newList);
    }

    public getElectricMeterCabinetList(): MapObjectElectricMeterCabinetMarker[] {
        return this._electricMetersInMap$.getValue();
    }

    public addElectricMeterCabinetToList(berthMapObject: MapObjectElectricMeterCabinetMarker): void {
        const previousList = this._electricMetersInMap$.getValue();
        const newList: MapObjectElectricMeterCabinetMarker[] = [
            ...previousList,
            berthMapObject
        ];
        this._electricMetersInMap$.next(newList);
    }

    public removeElectricMeterFromList(electricMeterId: number): void {
        const previousList = this._electricMetersInMap$.getValue();
        const newList: MapObjectElectricMeterCabinetMarker[] = previousList.filter(item => item.cabinetId !== electricMeterId);
        this._electricMetersInMap$.next(newList);
    }

    public getWeight(meters): number {
        return meters / this.getMetresPerPixel();
    }

    public getMetresPerPixel(): number {
        const centerLatLng = this.map.getCenter();
        const pointC = this.latLngToContainerPoint(centerLatLng);
        const pointX = L.point(pointC.x + 10, pointC.y); // add 10 pixels to x

        const latLngX = this.containerPointToLatLng(pointX);
        return centerLatLng.distanceTo(latLngX) / 10; // calculate distance between c and x (latitude)
    }

    public latLngToContainerPoint(latlng: LatLngExpression): Point {
        return this.map.latLngToContainerPoint(latlng);
    }

    public containerPointToLatLng(point: PointExpression): LatLng {
        return this.map.containerPointToLatLng(point);
    }

    public getClosestSegment(givenLatLng: LatLng, givenLatLngs: LatLng[]): [LatLng, LatLng] {

        if (!givenLatLngs || givenLatLngs?.length === 1) {
            return [givenLatLngs[0][0], givenLatLngs[0][1]];
        }

        let index = 0;
        let pointA: LatLng;
        let pointB: LatLng;

        do {
            pointA = givenLatLngs[index];
            pointB = givenLatLngs[++index];

            if (L.GeometryUtil.belongsSegment(givenLatLng, pointA, pointB)) {
                return [pointA, pointB];
            }
        } while (pointB);

        return;
    }

    public getClosestSegmentOnPolyline(givenLatLng: LatLng, polyline: Polyline): [LatLng, LatLng] {
        const {lat, lng} = L.GeometryUtil.closest(this._map, polyline, givenLatLng);
        const closestLatLng = new LatLng(lat, lng);
        return this.getClosestSegment(closestLatLng, polyline.getLatLngs() as LatLng[]);
    }

    public getClosestLatLangOnSegment(givenLatLng: LatLng, givenLatLngs: LatLng[]): LatLng {
        const latLngs = this.getClosestSegment(givenLatLng, givenLatLngs);
        return L.GeometryUtil.closestOnSegment(this.map, givenLatLng, latLngs[0], latLngs[1]);
    }

    // 0° - 360°
    public getAngleBetweenLines([latLng1a, latlng1b]: [LatLng, LatLng], [latLng2a, latLng2b]: [LatLng, LatLng], accuracy = 2): number {
        const angleLine1 = L.GeometryUtil.angle(this.map, latLng1a, latlng1b);
        const angleLine2 = L.GeometryUtil.angle(this.map, latLng2a, latLng2b);

        if (angleLine2 < angleLine1) {
            return Math.abs((angleLine2 + 360) - angleLine1) % 360;
        }

        return Math.abs(angleLine2 - angleLine1) % 360;
    }

    setCursor(cursor: MapCursor): void {
        this._renderer.setStyle(this._mapContainerHtmlElement, 'cursor', cursor, RendererStyleFlags2.Important);
    }

    reset(): void {
        SharedLayerService.editorMode = undefined;
        this._mapContainerHtmlElement = undefined;
        this._lastFocusedMapObject$.next(undefined);
        this._lastFocusedMapObject$.complete();
        this._lastFocusedMapObject$ = new BehaviorSubject<MapObjectBase | null>(null);
        this._berthsInMap$.next(undefined);
        this._berthsInMap$.complete();
        this._berthsInMap$ = new BehaviorSubject<MapObjectBerth[]>([]);
        this._electricMetersInMap$.next(undefined);
        this._electricMetersInMap$.complete();
        this._electricMetersInMap$ = new BehaviorSubject<MapObjectElectricMeterCabinetMarker[]>([]);
        this._allHarbourLayers.clearLayers();
        this._fingerBridgeLayers.clearLayers();
    }
}
