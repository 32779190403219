import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { BcmAuthGuard } from './bcm/bcm-auth.guard';
import { DmyvAuthGuard } from '@modules/dmyv/dmyv-auth.guard';
import { U2bAdminAuthGuard } from '@modules/admin/u2b-admin-auth.guard';
// import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';

const routes: Route[] = [
    {
        path: 'bcm',
        loadChildren: () => import('./bcm/bcm.module').then(m => m.BcmModule),
        // canLoad: [AutoLoginPartialRoutesGuard],
        // canActivate: [AutoLoginPartialRoutesGuard],
        canLoad: [BcmAuthGuard],
        canActivate: [BcmAuthGuard],
    },
    {
        path: 'dmyv',
        loadChildren: () => import('./dmyv/dmyv.module').then(m => m.DmyvModule),
        canLoad: [DmyvAuthGuard],
        canActivate: [DmyvAuthGuard],
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/u2b-admin.module').then(m => m.U2bAdminModule),
        canLoad: [U2bAdminAuthGuard],
        canActivate: [U2bAdminAuthGuard],
    },
    // {
    //     path: 'profile',
    //     loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    //     canActivate: [AppAuthGuard],
    //     data: { roles: ['manage-account'] }
    // },
    {
        path: 'errors',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule),
    },
    {
        path: '**',
        redirectTo: 'bcm'
    }
];

@NgModule({
    providers: [
        BcmAuthGuard,
        DmyvAuthGuard,
        U2bAdminAuthGuard,
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule
    ]
})
export class ModulesModule {
}
