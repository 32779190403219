import { Injectable } from '@angular/core';
import { BcmService } from './bcm.service';
import { BcmTenantPermission } from './bcm-tenant-permission';

@Injectable({providedIn: 'root'})
export class BcmTenantService {

    constructor(private bcmService: BcmService) {
    }

    public hasAllPermissionsOf(...permissions: BcmTenantPermission[]): boolean {

        let result = true;

        for (const permission of permissions) {
            result = this.checkPermission(permission);

            if (!result) {
                break;
            }
        }

        return result;
    }

    public hasOnePermissionOf(...permissions: BcmTenantPermission[]): boolean {

        let result = false;

        for (const permission of permissions) {
            result = this.checkPermission(permission);

            if (result) {
                break;
            }
        }

        return result === true;
    }

    public checkPermission(permission: BcmTenantPermission): boolean {
        return permission == null || (this.bcmService.tenant?.permissions || []).includes(permission);
    }
}
