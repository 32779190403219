import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'get-mail-dialog',
    templateUrl: './get-mail-dialog.component.html',
    styleUrls: ['./get-mail-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class GetMailDialogComponent {
    dialogTitle: string;
    addressForm: FormGroup;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<GetMailDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: { addresses?: any, listName?: any },
        private _formBuilder: UntypedFormBuilder,
    ) {
        this.dialogTitle = `Adressen in ${data.listName}`;
        this.addressForm = this._formBuilder.group({
            selectedAddresses: new FormControl([])
        });
        this.addressForm.get('selectedAddresses').patchValue(this.data.addresses);
    }

    save(): void {
        this.isSaving = true;
        const formData = this.addressForm.get('selectedAddresses').value;
        this.dialogRef.close(formData.toString() === this.data.addresses.toString() ? null : formData);
    }
}
