import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { U2bColumnDefinitionBrakePoint } from '@core/components/layout/table/table.types';

@Injectable({
    providedIn: 'root'
})
export class FuseMatchMediaService {
    _activeMediaQuery: string;

    get activeMediaQuery(): string {
        return this._activeMediaQuery;
    }

    private _mediaChange$ = new BehaviorSubject<string>('');

    get mediaChange$(): Observable<string> {
        return this._mediaChange$.asObservable();
    }

    private _tableBrakePointChange$ = new BehaviorSubject<U2bColumnDefinitionBrakePoint>(U2bColumnDefinitionBrakePoint.Desktop);

    get tableBrakePointChange$(): Observable<U2bColumnDefinitionBrakePoint> {
        return this._tableBrakePointChange$.asObservable();
    }

    private _widgetCompactHeaderView$ = new Subject<boolean>();

    get widgetCompactHeaderView$(): Observable<boolean> {
        return this._widgetCompactHeaderView$.asObservable();
    }

    /**
     * Constructor
     *
     * @param {MediaObserver} _mediaObserver
     */
    constructor(
        private _mediaObserver: MediaObserver
    ) {
        // Set the defaults
        this._activeMediaQuery = '';

        // Initialize
        this._init();

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void {
        this._mediaObserver.asObservable()
            .pipe(
                debounceTime(500),
                // distinctUntilChanged()
            )
            .subscribe((change: MediaChange[]) => {
                let prio = Number.MIN_SAFE_INTEGER;
                let highPrioMqAlias: string;

                change.forEach(item => {
                    if (this.activeMediaQuery !== item.mqAlias) {
                        this._activeMediaQuery = item.mqAlias;
                        this._mediaChange$.next(item.mqAlias);
                    }
                    if (item.priority > prio) {
                        prio = item.priority;
                        highPrioMqAlias = item.mqAlias.replace(/gt-/ig, '');
                    }
                });

                switch (highPrioMqAlias) {
                    case 'xs':
                        this._tableBrakePointChange$.next(U2bColumnDefinitionBrakePoint.Mobile);
                        break;
                    case 'sm':
                    case 'md':
                        this._tableBrakePointChange$.next(U2bColumnDefinitionBrakePoint.Tablet);
                        break;
                    case 'lg':
                    case 'xl':
                        this._tableBrakePointChange$.next(U2bColumnDefinitionBrakePoint.Desktop);
                        break;
                    default:
                        // todo: is this correct?!
                        this._tableBrakePointChange$.next(U2bColumnDefinitionBrakePoint.Mobile);
                        break;
                }

                this._widgetCompactHeaderView$.next(['xs', 'sm'].includes(highPrioMqAlias));
            });
    }

}
