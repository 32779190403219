import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { Season, SeasonRAW } from '@shared/models/seasons';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({
  providedIn: 'root'
})
export class SeasonApiService extends BcmApiService {
  private RESOURCE_ENDPOINT = 'seasons';

  getAllSeasons(params: HttpParams = new HttpParams()): Observable<Season[]> {
    return super.get<SeasonRAW[]>('seasons', params)
        .pipe(parseHttpResponseList<SeasonRAW, Season>(Season));
  }

  getSeasonById(id: number, params: HttpParams = new HttpParams()): Observable<Season> {
      return super.get<SeasonRAW>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
          .pipe(parseHttpResponseItem<SeasonRAW, Season>(Season));
  }

  createSeason(season: Season): Observable<Season> {
      return super.post(`seasons`, season);
  }

  updateSeasonById(season: Season): Observable<number> {
      return super.put(`seasons/${season.id}`, season);
  }

  deleteSeasonById(season: Season): Observable<number> {
      return super.delete(`seasons/${season.id}`);
  }
}
