import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { Subject } from 'rxjs';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { InvoicesService } from '@modules/bcm/accounting/invoices/invoices.service';
import { WinterStorageBoatAssignment } from '@shared/models/winter-storage-boat-assignment';
import { filter, map, takeUntil } from 'rxjs/operators';
import { InvoicePosition } from '@shared/models/invoice-position';

@Component({
    selector: 'berth-reservation',
    templateUrl: './winter-storage-assignment-invoice-positions.component.html',
    styleUrls: ['./winter-storage-assignment-invoice-positions.component.scss'],
    providers: [
        InvoicesService
    ]
})
export class WinterStorageAssignmentInvoicePositionsComponent implements OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    winterStorageAssignment: WinterStorageBoatAssignment;

    subscription = false;

    formGroup: UntypedFormGroup;

    isSaving: boolean;

    positions: InvoicePosition[] = [];

    owner: Person | Company;

    constructor(public dialogRef: MatDialogRef<WinterStorageAssignmentInvoicePositionsComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data: {
                    winterStorageAssignment?: WinterStorageBoatAssignment,
                    subscription?: boolean
                },
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _formBuilder: UntypedFormBuilder) {
        this.dialogRef.addPanelClass('default-dialog');
        this.dialogRef.disableClose = true;
        this.winterStorageAssignment = data?.winterStorageAssignment;
        this.subscription = data?.subscription || false;

        this.owner = this.winterStorageAssignment.person || this.winterStorageAssignment.company;

        this._createForm();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _createForm(): void {

        this.formGroup = this._formBuilder.group({
                ownerPerson: [this.winterStorageAssignment.person],
                ownerCompany: [this.winterStorageAssignment.company],
                deviatingInvoiceRecipient: [!this.owner],
                invoiceRecipientType: ['person'],
                winterStorageAssignment: [this.winterStorageAssignment], // virtual only ..
            }
        );

        setTimeout(() => {
            this.formGroup.get('invoiceForm')?.valueChanges
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    map(value => value?.positions),
                    filter(value => !!value)
                )
                .subscribe(positions => {
                    this.positions = positions;
                    this.formGroup.markAsDirty();
                });
        }, 1000);

    }


    onClickClose(): void {

        if (this.formGroup.dirty) {
            return this._confirmDialogService
                .useWarnTheme()
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    text: 'Ja, beenden'
                })
                .setNoButton({
                    text: 'Abbrechen'
                })
                .openWithCallback(() => this.dialogRef.close());
        }

        this.dialogRef.close();
    }

    onClickSaveAndClose(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
        } else {

            this.isSaving = true;

            const {personForm, companyForm, deviatingInvoiceRecipient} = this.formGroup.value;

            let person: Person = null;
            let company: Company = null;

            if (!deviatingInvoiceRecipient) {
                // kein abweichender Empfänger ... wir nehmen Besitzer des Assets
                if (this.winterStorageAssignment.person?.id) {
                    person = this.winterStorageAssignment.person;
                } else if (this.winterStorageAssignment.company?.id) {
                    company = this.winterStorageAssignment.company;
                }
            } else {
                // abweichender Empfänger ... wir nehmen Werte aus FormGroup
                if (this.formGroup.get('invoiceRecipientType').value === 'person') {

                    if (personForm?.person?.id) {
                        person = personForm.person;
                    } else {
                        delete personForm.person;
                        person = {...personForm};
                    }

                } else if (this.formGroup.get('invoiceRecipientType').value === 'company') {

                    if (companyForm?.company?.id) {
                        company = companyForm.company;
                    } else {
                        delete companyForm.company;
                        company = {...companyForm};
                    }

                }
            }

            const data = {
                winterStorageAssignment: this.winterStorageAssignment,
                positions: this.positions,
                subscriptionPeriod: (this.subscription) ? this.formGroup.get('subscriptionPeriodForm').value : null,
                person,
                company
            };

            this.dialogRef.close(data);
            return;

        }
    }
}
