export enum TseBusinessTransactionType {
    REVENUE = '[0] = Revenue',
    DISCOUNT = '[1] = Discount',
    DEPOSIT_ON_EMPTIES = '[2] = DepositOnEmpties',
    SINGLE_PURPOSE_VOUCHER = '[3] = SinglePurposeVoucher',
    MULTI_PURPOSE_VOUCHER = '[4] = MultiPurposeVoucher',
    TIP = '[5] = Tip',
    COMPANY_TIP = '[6] = CompanyTip',
    DEPOSIT = '[7] = Deposit',
    PAY_OUT = '[10] = PayOut',
    PAY_IN = '[11] = PayIn',
    MONEY_TRANSFER = '[90] = MoneyTransfer',
    CASH_DIFFERENCE = '[91] = CashDifference',
    GRANT_GENUINE = '[92] = GrantGenuine',
    GRANT_UNREAL = '[93] = GrantUnreal',
}



