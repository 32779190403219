import { Injectable } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { navigation } from './navigation';
import { navigationDmyv } from './navigation-dmyv';
import { navigationLoggedIn } from './navigation-logged-in';
import { navigationAdmin } from './navigation-admin';
import { getServiceRequestNavItem } from './core-navigation-bcm';
import { ListItemType, U2bNavigationItem } from './navigation.shared';
import { debounceTime, startWith } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { Tenant } from '@shared/models/tenant';
import { NavigationSlugs } from './navigation-slugs';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmNavigationService } from '@modules/bcm/bcm-navigation.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({providedIn: 'root'})
export class NavigationService {

    private readonly navigationChange = new Subject<NavigationSlugs>();

    public get navigationChange$(): Observable<NavigationSlugs> {
        return this.navigationChange.asObservable();
    }

    // todo: bcmNavService should not be in here..
    constructor(private _fuseNavigationService: FuseNavigationService,
                private _bcmNavigationService: BcmNavigationService,
                private authService: AuthService,
                private _fuseConfigService: FuseConfigService,
                private _router: Router,
                private _activatedRoute: ActivatedRoute,
                private _bcmService: BcmService) {
        this.registerNavigations();
        this.registerSubscription();
    }

    public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this._router.navigate(commands, extras);
    }

    public navigateRelativeToActivatedRoute(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.navigate(commands, {...extras, relativeTo: this._activatedRoute});
    }

    public changeNavigationTo(nextNavigation: NavigationSlugs): void {
        return this.navigationChange.next(nextNavigation);
    }

    private registerSubscription(): void {
        const isLoggedIn = this.authService.isLoggedIn$;
        combineLatest([
            isLoggedIn,
            this._bcmService.selectedTenant$,
            this.navigationChange$,
            // this._userService.userPermissionsChanged
        ])
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
            .pipe(startWith([
                false,
                this._bcmService.tenant,
                NavigationSlugs.Main
            ]))
            .subscribe(([loggedIn, tenant, navigationSlug]: [boolean, Tenant, NavigationSlugs/*, Permission[]*/]) => {

                if (!loggedIn) {
                    this._fuseNavigationService.setCurrentNavigation(NavigationSlugs.Main);
                    return;
                }

                if (tenant) {
                    this._fuseNavigationService.unregister(NavigationSlugs.MainBcm);
                    this._fuseNavigationService.register(
                        NavigationSlugs.MainBcm,
                        this._bcmNavigationService.getNavigation()
                    );
                    this._fuseNavigationService.setCurrentNavigation(NavigationSlugs.MainBcm);
                    return;
                }

                if (!tenant && navigationSlug === NavigationSlugs.MainBcm) {
                    this._fuseNavigationService.setCurrentNavigation(NavigationSlugs.MainLoggedIn);
                    return;
                }

                if (navigationSlug === NavigationSlugs.MainAdmin) {
                    this._fuseConfigService.config = {
                        ...this._fuseConfigService.config,
                        colorTheme: 'theme-admin'
                    };
                } else {
                    this._fuseConfigService.config = this._fuseConfigService.defaultConfig;
                }

                this._fuseNavigationService.setCurrentNavigation(navigationSlug);
            });
    }

    private registerNavigations(): void {
        this.authService
            .userData$
            .subscribe(userData => {
                if (!userData) {
                    return;
                }
                this.setUpAdditionalNavigationItems(userData);

                this._fuseNavigationService.register(NavigationSlugs.Main, navigation);
                this._fuseNavigationService.register(NavigationSlugs.MainBcm, {});
                this._fuseNavigationService.register(NavigationSlugs.MainDmyv, navigationDmyv);
                this._fuseNavigationService.register(NavigationSlugs.MainLoggedIn, navigationLoggedIn);
                this._fuseNavigationService.register(NavigationSlugs.MainAdmin, navigationAdmin);
            });
    }

    private setUpAdditionalNavigationItems(userData: any): void {
        const navItemServiceRequest = getServiceRequestNavItem();
        const othersNavGroup: U2bNavigationItem = {
            id: 'others',
            title: 'Sonstiges',
            type: ListItemType.Group,
            children: [
                navItemServiceRequest,
            ]
        };
        const roles = userData?.groups || [];

        if (roles.includes('dmyv_admin') || roles.includes('dmyv_sbv_admin')) {
            navigationLoggedIn
                .find(item => item.id === 'modules')?.children
                .push({
                    id: 'dmyv',
                    title: 'DMYV',
                    type: 'item',
                    icon: 'event_note',
                    url: 'dmyv'
                });
        }

        if (roles.includes('u2b_super_admin') || roles.includes('u2b_admin') || roles.includes('u2b_support')) {
            navigationLoggedIn
                .find(item => item.id === 'modules')?.children
                .push({
                    id: 'admin',
                    title: 'Administration',
                    type: 'item',
                    icon: 'adjust',
                    url: 'admin'
                });
        }

        navigation.push(othersNavGroup);
        navigationLoggedIn.push(othersNavGroup);
        navigationAdmin.push(othersNavGroup);
    }
}
