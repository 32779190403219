import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, timer } from 'rxjs';
import { TIMEOUT_FORM_SAVING_MIN } from '@shared/constants/timeouts';
import { map, shareReplay, tap } from 'rxjs/operators';

type LAST_INSERT_ID = number;
type EFFECTED_ROWS = number;

class NoApiEndpointError extends Error {
    name = 'NoApiEndpointError';

    constructor(className: string) {
        super(`No API endpoint set in "${className}"`);
    }
}

interface CacheEntry<T> {
    observable: Observable<T>;
    expiration: number;
}

export abstract class BaseApiService {

    private cache = new Map<string, CacheEntry<any>>();

    private readonly cacheDuration = 5000; // Cache duration in milliseconds (5 seconds)

    protected abstract className: string;

    protected abstract endpoint: string;

    protected _suppressErrorForNextRequest = false;

    protected constructor(private http: HttpClient) {
    }

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.requestWithCache<T>('GET', path, params, 'json') as Observable<T>;
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
        return this.requestWithCache('GET', path, params, 'blob');
    }

    protected getBlobWithProgress(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.get(`${this.endpoint}${path}`, {
            params,
            reportProgress: true,
            observe: 'events',
            responseType: 'blob',
            headers: this.getHeaders()
        });
    }

    protected post<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.post<T>(`${this.endpoint}${path}`, body, {params, headers: this.getHeaders()}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected postWithBlobResponse<T = LAST_INSERT_ID>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.post<T>(`${this.endpoint}${path}`, body, {
                params,
                responseType: 'blob' as 'json',
                headers: this.getHeaders()
            }),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected put<T = EFFECTED_ROWS>(path: string, body: object = {}, params: HttpParams = new HttpParams()): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return combineLatest([
            this.http.put<T>(`${this.endpoint}${path}`, body, {params, headers: this.getHeaders()}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [T, number]) => response));
    }

    protected delete<T = EFFECTED_ROWS>(path, params: HttpParams = new HttpParams(), body?: unknown): Observable<T> {

        if (!this.endpoint) {
            throw new NoApiEndpointError(this.className);
        }

        return this.http.delete<T>(`${this.endpoint}${path}`, {params, body, headers: this.getHeaders()});
    }

    private requestWithCache<T>(method: 'GET' | 'POST' | 'PUT' | 'DELETE',
                                path: string,
                                params: HttpParams,
                                responseType: 'json' | 'blob',
                                body: any = null): Observable<Blob> | Observable<T> {
        if (!this.endpoint) {
            throw new NoApiEndpointError(this.constructor.name);
        }

        const cacheKey = this.createCacheKey(path, params, body);
        const now = Date.now();
        const cached = this.cache.get(cacheKey);

        if (cached && cached.expiration > now) {
            return cached.observable;
        }

        let request$: Observable<Blob> | Observable<T>;

        if (responseType === 'blob') {
            request$ = this.http
                .request(method, `${this.endpoint}${path}`, {
                    body,
                    params,
                    responseType,
                    headers: this.getHeaders()
                })
                .pipe(
                    shareReplay(1),
                    tap(() => this.setCacheExpiration(cacheKey))
                ) as Observable<Blob>;
        } else {
            request$ = this.http
                .request<T>(method, `${this.endpoint}${path}`, {
                    body,
                    params,
                    responseType,
                    headers: this.getHeaders()
                })
                .pipe(
                    shareReplay(1),
                    tap(() => this.setCacheExpiration(cacheKey))
                ) as Observable<T>;
        }

        this.cache.set(cacheKey, {observable: request$, expiration: now + this.cacheDuration});

        return request$;
    }

    private createCacheKey(path: string, params: HttpParams, body?: any): string {
        const bodyKey = body ? JSON.stringify(body) : '';
        return `${path}?${params.toString()}&body=${bodyKey}`;
    }

    private setCacheExpiration(key: string): void {
        timer(this.cacheDuration).subscribe(() => this.cache.delete(key));
    }

    private getHeaders(): HttpHeaders | undefined {
        let headers: HttpHeaders;

        if (this._suppressErrorForNextRequest) {
            headers = new HttpHeaders().append('suppress-errors', 'true');
            this._suppressErrorForNextRequest = false;
        }

        return headers;
    }
}
