import { Directive, Input, TemplateRef, ViewContainerRef, } from '@angular/core';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import { BcmTenantService } from '@modules/bcm/bcm-tenant.service';

@Directive({
    selector: '[hasTenantPermission],[notHasTenantPermission]',
})
export class HasTenantPermissionDirective {
    private _logicalOperator: 'and' | 'or' = 'and';
    private _negateCondition = false;
    private permissions: BcmTenantPermission[];
    private isHidden = true;
    private elseTemplate: TemplateRef<any>;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private bcmTenantService: BcmTenantService,
    ) {

    }

    @Input()
    set hasTenantPermission(permissions: BcmTenantPermission | BcmTenantPermission[]) {
        this.preparePermissions(permissions);
    }

    @Input()
    set notHasTenantPermission(permissions: BcmTenantPermission | BcmTenantPermission[]) {
        this._negateCondition = true;
        this.preparePermissions(permissions);
    }

    @Input()
    set hasTenantPermissionElse(elseTemplate: TemplateRef<any>) {
        this.elseTemplate = elseTemplate;
        this.updateView();
    }

    @Input()
    set hasTenantPermissionOperator(value: 'and' | 'or') {
        this._logicalOperator = value;
    }

    private preparePermissions(permissions: BcmTenantPermission | BcmTenantPermission[]): void {
        this.permissions = Array.isArray(permissions) ? permissions : [permissions];
        this.updateView();
    }

    private updateView(): void {
        this.viewContainer.clear();

        let result = this.checkPermission();

        if (this._negateCondition) {
            result = !result;
        }

        if (result) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isHidden = false;
        } else {
            this.isHidden = true;

            if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            } else {
                this.viewContainer.clear();
            }
        }
    }

    private checkPermission(): boolean {
        switch (this._logicalOperator) {
            case 'and':
                return this.bcmTenantService.hasAllPermissionsOf(...this.permissions);
            case 'or':
                return this.bcmTenantService.hasOnePermissionOf(...this.permissions);
        }
    }
}
