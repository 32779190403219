import { IntegerFormatValidator } from './integer-format';
import { NumberFormatValidator } from './number-format';
import { NumberMinValidator } from './number-min';
import { NumberMaxValidator } from './number-max';
import { ValidatorFn } from '@angular/forms';
import { NoZeroAllowedValidator } from '@shared/validators/numeric/no-zero-allowed';

export class U2bNumericValidators {

    public static integerFormat(message?: string): ValidatorFn {
        return new IntegerFormatValidator(message).asValidatorFn();
    }

    public static numberFormat(message?: string): ValidatorFn {
        return new NumberFormatValidator(message).asValidatorFn();
    }

    public static numberMin(min: number, message?: string): ValidatorFn {
        return new NumberMinValidator(min, message).asValidatorFn();
    }

    public static numberMax(max: number, message?: string): ValidatorFn {
        return new NumberMaxValidator(max, message).asValidatorFn();
    }

    public static noZeroAllowed(message?: string): ValidatorFn {
        return new NoZeroAllowedValidator(message).asValidatorFn();
    }

}
