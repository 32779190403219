import { NgModule } from '@angular/core';
import { DateTimeInputComponent } from './date-time-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import {ExtendedModule, FlexModule} from '@angular/flex-layout';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        CommonModule,
        MatFormFieldModule,
        FlexModule,
        MatInputModule,
        DirectivesModule,
        MatDatepickerModule,
        MatSelectModule,
        SharedModule,
        MatButtonModule,
        MatTooltipModule,
        ExtendedModule,
        MatAutocompleteModule
    ],
    declarations: [DateTimeInputComponent],
    exports: [DateTimeInputComponent]
})
export class DateTimeInputModule {

}
