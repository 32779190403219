<u2b-modal>
    <div u2bModalHeadline>Ablaufende Reservierungen</div>

    <table class="simple table-sidebar">
        <thead>
            <tr>
                <th></th>
                <th>Liegeplatz</th>
                <th>Reserviert bis</th>
                <th>Notiz</th>
            </tr>
        </thead>

        <ng-container *ngFor="let berth of reservedAssignments">
            <tbody>
            <tr *ngIf="berth.endsToday">
                <td>
                    <mat-icon color="error" [matTooltip]="'endet heute'">info icon</mat-icon>
                </td>
                <td>{{berth.berth.handle}}</td>
                <td>{{berth.to | dfnsFormat : 'dd.MM.yyyy HH:mm'}}</td>
                <td>{{berth.berth.note}}</td>

            </tr>
            <tr *ngIf="berth.endsThisWeek && !berth.endsToday">
                <td>
                    <mat-icon color="accent" [matTooltip]="'endet diese Woche'">info icon</mat-icon>
                </td>
                <td>{{berth.berth.handle}}</td>
                <td>{{berth.to | dfnsFormat : 'dd.MM.yyyy HH:mm'}}</td>
                <td>{{berth.berth.note}}</td>
            </tr>
            </tbody>
        </ng-container>
    </table>
</u2b-modal>
