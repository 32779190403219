import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { ITenant, Tenant } from '@shared/models/tenant';
import { tap } from 'rxjs/operators';
import { BankAccount } from '@shared/models/tenant-bank-account';
import { IBankAccount } from '@shared/models/bank-account';

@Injectable({
    providedIn: 'root'
})
export class TenantApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'tenants';

    getAll(params: HttpParams = new HttpParams()): Observable<Tenant[]> {
        super.disableSyncForNextRequest();
        return super.get<ITenant[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<ITenant, Tenant>(Tenant));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Tenant> {
        super.disableSyncForNextRequest();
        return super.get<ITenant>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<ITenant, Tenant>(Tenant)) as Observable<Tenant>;
    }

    add(body: object = {}): Observable<any> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}`, body)
            // toDO: is this the right place?
            .pipe(tap(() => {
                this.getOne(this.bcmService.tenant.id)
                    .subscribe((tenant) => this.bcmService.tenant = tenant);
            }));
    }

    // AVV
    getAvv(tenant: Tenant, params: HttpParams = new HttpParams()): Observable<any> {
        return super.get(`${this.RESOURCE_ENDPOINT}/avv`, params);
    }

    getAvvPdf(tenant: Tenant, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/avv/pdf`, params);
    }

    generateAvv(tenant: Tenant, signature: string, signatureText: string): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/avv`, {signature, signatureText, onlyGenerate: true});
    }

    saveAvv(tenant: Tenant, signature: string, signatureText: string): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/avv`, {signature, signatureText});
    }

    getAllBankAcc(params: HttpParams = new HttpParams()): Observable<BankAccount[]> {
        return super.get<IBankAccount[]>(`${this.RESOURCE_ENDPOINT}/bank-data`, params)
            .pipe(parseHttpResponseList<IBankAccount, BankAccount>(BankAccount));
    }

    getBankAccByID(id: number, params: HttpParams = new HttpParams()): Observable<BankAccount> {
        return super.get<IBankAccount>(`${this.RESOURCE_ENDPOINT}/bank-data/${id}`, params)
            .pipe(parseHttpResponseItem<IBankAccount, BankAccount>(BankAccount));
    }

    createBankAcc(body: object = {}): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/bank-data`, body);
    }

    updateBankAccById(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/bank-data/${id}`, body);
    }

    deleteBankAcc(id: number): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/bank-data/${id}`);
    }

    // ident numbers
    setIdentNumbers(tenant: Tenant, body = {}, params: HttpParams = new HttpParams()): Observable<any> {
        return super.post(`${this.RESOURCE_ENDPOINT}/ident-numbers`, body, params);
    }
}

