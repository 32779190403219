import { AbstractControl } from '@angular/forms';

export function removeErrorFromControls(errorName: string, controls: AbstractControl[]): void {
    (controls || []).forEach(control => {
        if (control?.hasError && control?.hasError(errorName)) {
            delete control.errors[errorName];
            control.updateValueAndValidity();
        }
    });
}

export function removeErrorFromControl(errorName: string, control: AbstractControl): void {
    removeErrorFromControls(errorName, [control]);
}
