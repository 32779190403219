import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormWidgetBerthModule } from '@modules/bcm/@work-flows/@form-widgets/berth/form-widget-berth.module';
import { FormWidgetBoatModule } from '@modules/bcm/@work-flows/@form-widgets/boat/form-widget-boat.module';
import { FormWidgetCompanyModule } from '@modules/bcm/@work-flows/@form-widgets/company/form-widget-company.module';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DirectivesModule } from '@shared/directives/directives.module';
import { PremiumFormWidgetElectricMeterModule } from '@modules/bcm/@work-flows/@form-widgets/@premium/electric-meter/premium-form-widget-electric-meter.module';
import { FormWidgetBerthFeeModule } from '@modules/bcm/@work-flows/@form-widgets/berth-fee/form-widget-berth-fee.module';
import { FormWidgetTenantRelationModule } from '@modules/bcm/@work-flows/@form-widgets/tenant-relation/form-widget-tenant-relation.module';
import { FormWidgetContractModule } from '@bcm-work-flows/@form-widgets/contract/form-widget-contract.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { FormWidgetContractCoOwnerModule } from '@bcm-work-flows/@form-widgets/contract-co-owner/form-widget-contract-co-owner.module';
import { FormWidgetDateRangeModule } from '@bcm-work-flows/@form-widgets/date-range/form-widget-date-range.module';
import { FormWidgetBerthOnlyModule } from '@bcm-work-flows/@form-widgets/berth-only/form-widget-berth-only.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { CompanyContactComponent } from '@bcm-work-flows/company-contact/company-contact.component';
import { FormWidgetCompanyContactModule } from '@bcm-work-flows/@form-widgets/company-contact/form-widget-company-contact.module';

@NgModule({
    declarations: [CompanyContactComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        FlexModule,
        DragDropModule,
        ScrollingModule,

        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRadioModule,
        MatDividerModule,

        FormWidgetCompanyContactModule,
        FormWidgetBerthModule,
        FormWidgetBoatModule,
        FormWidgetCompanyModule,
        FormWidgetContractModule,
        FormWidgetContractCoOwnerModule,
        DirectivesModule,
        PremiumFormWidgetElectricMeterModule,
        FormWidgetBerthFeeModule,
        FormWidgetTenantRelationModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        FormWidgetDateRangeModule,
        FormWidgetBerthOnlyModule,
        MatCheckboxModule,
        MatInputModule,
    ],
    exports: [CompanyContactComponent]
})
export class CompanyContactModule {

}
