import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { isFunction } from '@shared/functions/is-function';

export class U2BRequiredValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public message = 'Bitte Wert eingeben',
                private _callback?: () => boolean) {
        super();
        this.validationError = new BaseValidationError('required', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        if (isFunction(this._callback) && this._callback()) {
            return Validators.required(control) ?
                this.IS_INVALID :
                this.IS_VALID;
        }

        return Validators.required(control) ?
            this.IS_INVALID :
            this.IS_VALID;
    }
}
