import { Injectable, NgZone } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
    providedIn: 'root'
})
export class AppNotificationService {

    constructor(private readonly toastr: ToastrService,
                private readonly zone: NgZone) {
    }

    showSuccess(message: string, header?: string): ActiveToast<any> {
        return this._open(message, header, 'success');
    }

    showInfo(message: string, header?: string): ActiveToast<any> {
        return this._open(message, header, 'info');
    }

    showWarning(message: string, header?: string): ActiveToast<any> {
        return this._open(message, header, 'warning');
    }

    showError(message: string, header?: string): ActiveToast<any> {
        return this._open(message, header, 'error');
    }

    private _open(message: string, title?: string, type?: string, displayProgressBar = true, disableTimeOut = false): ActiveToast<any> {
        const options = {
            closeButton: true,
            newestOnTop: true,
            tapToDismiss: true,
            enableHtml: true,
            progressBar: displayProgressBar,
            disableTimeOut,
            timeOut: 2500, // Wunsch von Sandra: https://trello.com/c/vmgqYmai/1914-bugture-gr-lp-editor-liegeplatz-schwarze-meldung-an-pier-ausrichten-anzeigezeit-verk%C3%BCrzen
        } as IndividualConfig;
        return this.zone.run(
            () => {
                return this.toastr.show(message, title, options, `toast-${type}`);
            }
        );
    }
}
