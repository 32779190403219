<div class="message-box {{type}} {{customClass?.includes('mb-') ? '' : 'mb-0'}} {{customClass}}">
    <div class="headline"
         fxLayoutAlign="space-between center"
         [class.collapsable]="collapsable"
         [class.collapsed]="!isCollapsed"
         [class.display-none]="!collapsable && !headerRef.childNodes.length"
         (click)="collapsable ? isCollapsed = !isCollapsed : null; $event.stopPropagation()">
        <div #headerRef class="headline-ref"><ng-content select="header"></ng-content></div>
        <ng-container *ngIf="collapsable">
            <ng-container *ngIf="header">
                <span>{{header}}</span>
            </ng-container>
            <ng-container *ngIf="isCollapsed">
                <mat-icon>keyboard_arrow_up</mat-icon>
            </ng-container>
            <ng-container *ngIf="!isCollapsed">
                <mat-icon>keyboard_arrow_down</mat-icon>
            </ng-container>
        </ng-container>
    </div>
    <ng-template #main><ng-content></ng-content></ng-template>
    <ng-container *ngIf="collapsable; else defaultBlock">
        <div class="content" [class.collapsed]="!isCollapsed">
            <ng-container *ngTemplateOutlet="main"></ng-container>
        </div>
    </ng-container>
    <ng-template #defaultBlock>
        <ng-container *ngTemplateOutlet="main"></ng-container>
    </ng-template>
</div>
