import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from '@bcmApiServices/bcm-api.service';
import { TseFiscalClient } from '@shared/models/tse/TseFiscalClient';
import { TseFiscalResponse } from '@shared/models/tse/TseFiscalResponse';
import { TsePayment } from '@shared/models/tse/TsePayment';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { BcmCashRegisterUser } from '@shared/models/bcm-cash-register-user';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { InvoicePosition } from '@shared/models/invoice-position';
import { BcmFinancialRecordPdfData } from '@shared/models/bcm-financial-record';
import { BcmPaymentType, Person } from '@shared/models/person';
import { Company } from '@shared/models/company';
import { BcmReceipt } from '@shared/models/bcm-receipt';
import { TsePaymentType } from '@shared/models/tse/TsePaymentType';

@Injectable({
    providedIn: 'root'
})
export class TseApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'tse';

    getClient(cashRegister: BcmCashRegister): Observable<TseFiscalClient> {
        return super.get<TseFiscalClient>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}`);
    }

    createCashRegister(storeNumber: string, terminalNumber: string, title: string, note: string, cashRegisterUsers: BcmCashRegisterUser[]): Observable<BcmCashRegister> {
        return super.put<BcmCashRegister>(`${this.RESOURCE_ENDPOINT}/client/create`, {
            storeNumber,
            terminalNumber,
            title,
            note,
            cashRegisterUsers
        });
    }

    getClientId(storeNumber: string, terminalNumber: string): Observable<string> {
        const params = new HttpParams()
            .set('storeNumber', storeNumber)
            .set('terminalNumber', terminalNumber);

        return super.get<string>(`${this.RESOURCE_ENDPOINT}/client/id`, params);
    }

    getEndOfDayDocument(cashRegister: BcmCashRegister, closeMonth: boolean = false): Observable<TseDocument> {
        const params = new HttpParams()
            .set('closeMonth', closeMonth);

        return super.get<TseDocument>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/eod`, params);
    }

    openCashRegister(cashRegister: BcmCashRegister, amount: number): Observable<any> {
        return super.post<any>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/open`, {
            cashRegister,
            amount
        });
    }

    closeCashRegister(cashRegister: BcmCashRegister, amount: number, differenceReason: string,
                      differenceSum: number, cashAdd: number, cashLift: number, closeMonth: boolean = false): Observable<any> {
        return super.post<any>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/close`, {
            cashRegister,
            amount,
            differenceReason,
            closeMonth,
            differenceSum,
            cashAdd,
            cashLift
        });
    }

    bookCashDifference(cashRegister: BcmCashRegister, reason: string, amount: number): Observable<TseFiscalResponse> {
        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/money/difference`, {
            reason,
            amount
        });
    }

    bookCashLift(cashRegister: BcmCashRegister, amount: number): Observable<TseFiscalResponse> {
        return super.post<TseFiscalResponse>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/money/lift`, {amount});
    }

    getActualStock(cashRegister: BcmCashRegister): Observable<TsePayment[]> {
        return super.post<TsePayment[]>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/money/actual`, {});
    }

    createDocument(
        cashRegister: BcmCashRegister,
        document: TseDocument,
        positions: InvoicePosition[],
        amount: number,
        totalTax: number,
        pdfData: BcmFinancialRecordPdfData,
        paymentType: TsePaymentType,
        person: Person,
        company: Company,
        receiptToCancel?: BcmReceipt,
        addPositionsBackToCoaster?: boolean
    ): Observable<BcmReceipt> {
        return super.put<BcmReceipt>(`${this.RESOURCE_ENDPOINT}/client/${cashRegister.clientId}/document/create`, {
            document,
            positions,
            pdfData,
            paymentType,
            person,
            company,
            amount,
            totalTax,
            receiptToCancel,
            addPositionsBackToCoaster
        });
    }
}
