import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { AbstractControl, ValidationErrors } from '@angular/forms';

export class U2BDateRequiredValidator extends BaseValidator {

    public validationError: BaseValidationError = new BaseValidationError('required', this.message || 'Bitte gib ein korrektes Datumsformat ein. TT.MM.JJJJ');

    constructor(private message?: string) {
        super();
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        if (!control.value) {
            if (control.errors && control.errors['matDatepickerParse'] && control.errors['matDatepickerParse'].text !== '') {
                return this.IS_VALID;
            }
            return this.IS_INVALID;
        }

        return this.IS_VALID;
    }
}
