import { Injectable } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmReleaseNotesApi } from '@bcmServices/release-notes/bcm-release-notes.api';
import { BaseState } from '@bcmServices/base.state';
import { BcmReleaseNote, BcmReleaseNoteDto } from '@bcmServices/release-notes/bcm-release-note';
import { HttpClient } from '@angular/common/http';
import { BcmService } from '@modules/bcm/bcm.service';
import { DomSanitizer } from '@angular/platform-browser';

class BcmReleaseNotesState extends BaseState<BcmReleaseNote> {
    cache: BcmReleaseNote[] = [];
}

@Injectable({providedIn: 'root'})
export class BcmReleaseNotesFacade extends BcmReleaseNotesApi {

    private readonly state = new BcmReleaseNotesState();

    public get selected(): BcmReleaseNote {
        return this.state.getSelected as BcmReleaseNote;
    }

    constructor(private appNotificationService: AppNotificationService,
                private domSanitizer: DomSanitizer,
                http: HttpClient,
                bcmService: BcmService) {
        super(http, bcmService);
    }

    isUpdating$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    getList$(): Observable<BcmReleaseNote[]> {
        return this.state.list$;
    }

    loadReleaseNotes(): Observable<BcmReleaseNote[]> {
        return this.getAllReleaseNotes()
            .pipe(tap(releaseNotes => this.state.setList(releaseNotes)));
    }

    loadReleaseNote(releaseNoteId: number): Subscription {
        const cachedReleaseNote = this.loadReleaseNoteFromCache(releaseNoteId);
        if (cachedReleaseNote !== undefined) {
            return of(cachedReleaseNote).subscribe();
        }
        return this.getReleaseNote(releaseNoteId)
            .pipe(tap(result => {
                result.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(result.releaseNotes);
                this.state.setSelected(result);
                this.addReleaseNoteToCache(result);
            }))
            .subscribe();
    }

    addReleaseNote(createData: BcmReleaseNoteDto): Subscription {
        this.state.startUpdating();
        return this.postReleaseNote(createData)
            .subscribe(
                (createdReleaseNote) => {
                    this.appNotificationService.showSuccess('Release-Note gespeichert');
                    this.state.addToList(createdReleaseNote);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    updateReleaseNote(releaseNote: BcmReleaseNote, updateData: BcmReleaseNoteDto): Subscription {
        this.state.startUpdating();
        return this.putReleaseNote(releaseNote, updateData)
            .subscribe(
                (updatedReleaseNote) => {
                    this.appNotificationService.showSuccess('Änderung(en) gespeichert');
                    this.state.replaceInList(releaseNote, updatedReleaseNote);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    removeReleaseNote(releaseNote: BcmReleaseNote): Subscription {
        this.state.startUpdating();
        return this.deleteReleaseNote(releaseNote)
            .subscribe(
                () => {
                    this.appNotificationService.showSuccess('Release-Note wurde entfernt.');
                    this.state.removeFromList(releaseNote);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    addReleaseNoteToCache(releaseNote: BcmReleaseNote): void {
        const foundIndex = this.state.cache.findIndex(item => item.id === releaseNote.id);

        if (foundIndex > -1) {
            this.state.cache[foundIndex] = releaseNote;
        } else {
            this.state.cache.push(releaseNote);
        }
    }

    loadReleaseNoteFromCache(releaseNoteId: number): BcmReleaseNote | undefined {
        return this.state.cache.find(r => r.id === releaseNoteId);
    }

    private handleError(error: Error): void {
        throw error;
    }
}
