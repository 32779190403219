import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgIconsNames } from '@core/modules/svg-icons/svg-icon.names';

@Injectable({providedIn: 'root'})
export class SvgIconService {

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
    }

    public registerIcons(): void {
        Object.values(SvgIconsNames).forEach(iconName => {
            this.matIconRegistry.addSvgIcon(
                iconName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`./assets/icons/svg/${iconName}.svg`)
            );
        });
    }


}
