import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class U2BRequiredTrueValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public message = 'Bitte Checkbox anhaken',
                private _callback?: () => boolean) {
        super();
        this.validationError = new BaseValidationError('required', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return Validators.requiredTrue(control) ?
            this.IS_INVALID :
            this.IS_VALID;
    }
}
