<mat-form-field fxFlex="100" style="width: 100%" [appearance]="appearance" floatLabel="always">
    <mat-label>
        <ng-container *ngIf="multiSelect">Kostenstellen</ng-container>
        <ng-container *ngIf="!multiSelect">Kostenstelle</ng-container>
    </mat-label>

    <ng-container *ngIf="multiSelect; else singleSelect">
        <mat-select data-test="" [formControl]="documentCategoryFC" multiple
                    [compareWith]="compareObjectsById">
            <mat-option *ngFor="let costCenter of (filteredCostCenters$ | async) | orderBy : 'name' : true"
                        [value]="costCenter">
                {{costCenter.name}}
            </mat-option>
        </mat-select>
    </ng-container>

    <ng-template #singleSelect>
        <input type="text" matInput [formControl]="documentCategoryFC"
               [matAutocomplete]="costCenterSelector">

        <mat-autocomplete #costCenterSelector="matAutocomplete" [displayWith]="displayWith">
            <ng-container
                    *ngFor="let costCenter of (filteredCostCenters$ | async) | orderBy : 'name' : true">
                <mat-option [value]="costCenter">
                    <span>{{ costCenter.name }}</span><br>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </ng-template>

    <button type="button" matSuffix mat-icon-button matTooltip="Felder Leeren"
            [disabled]="!documentCategoryFC.value || documentCategoryFC.disabled"
            (click)="documentCategoryFC.setValue(null)">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-error>{{documentCategoryFC.errors | firstErrorMessage}}</mat-error>
</mat-form-field>
