import { LegacyErrorStateMatcher as ErrorStateMatcher } from '@angular/material/legacy-core';
import { FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';

export class DefaultInputErrorStateMatcher implements ErrorStateMatcher {
    constructor(private ngControl: NgControl) {
    }
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(this.ngControl && this.ngControl.invalid && (this.ngControl.dirty || this.ngControl.touched || isSubmitted));
    }
}
