<div [formGroup]="signatureFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid"
     class="with-loading-overlay">

    <mat-checkbox formControlName="sign">
        {{conditionCheckboxLabel}}
    </mat-checkbox>

    <ng-container *ngIf="signatureFormGroup.get('sign').value === true">

        <div fxFlex="100" fxLayout="column" class="mt-24">
            <strong>
                Wie soll die Unterschrift erfolgen?
            </strong>

            <mat-radio-group class="radio-group"
                             [(ngModel)]="signatureType"
                             [ngModelOptions]="{standalone: true}"
                             (ngModelChange)="signatureTypeChanged($event)">
                <mat-radio-button [value]="SignatureTypes.Text">In Textform über Tastatur</mat-radio-button>
                <mat-radio-button [value]="SignatureTypes.Sign">Als Signatur</mat-radio-button>
            </mat-radio-group>
        </div>

        <ng-container *ngIf="signatureType === SignatureTypes.Text">
            <div fxFlex="100">
                <p>
                    Gib bitte Deinen vollen Namen als digitale Unterschrift und Bestätigung ein. Diese Unterschrift wird
                    in dem Auftragsverarbeitungsvertrag verwendet.
                </p>
            </div>

            <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
                <mat-label>Deine Unterschrift:</mat-label>
                <input matInput data-test="FI-Name" type="text" formControlName="signatureText"
                       autocomplete="new-password">
                <mat-error>{{signatureFormGroup.get('signatureText').errors | firstErrorMessage}}</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="signatureType === SignatureTypes.Sign">
            <div fxFlex="100">
                Deine Signatur:
                <signature-pad [options]="signaturePadOptions"
                               (onBeginEvent)="drawStart()"
                               (onEndEvent)="drawComplete()"></signature-pad>
                <ng-container
                    *ngIf="signatureFormGroup.get('signature').invalid && signatureFormGroup.get('signature').touched">
                    <div>
                        <mat-error>{{signatureFormGroup.get('signature').errors | firstErrorMessage}}</mat-error>
                    </div>
                </ng-container>
            </div>

            <div class="pt-8">
                <button type="button"
                        mat-stroked-button
                        (click)="clearSignature()"
                        [disabled]="!isSigned">
                    <mat-icon>undo</mat-icon>
                    Signatur zurücksetzen
                </button>
            </div>

        </ng-container>
    </ng-container>
</div>