import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ButtonBrowserBackService } from '@core/components/button-browser-back/button-browser-back.service';

@Directive({
    selector: '[u2bBrowserBackOnClick]'
})
export class BrowserBackOnClickDirective {

    constructor(private _location: Location,
                private _buttonBrowserBackService: ButtonBrowserBackService) {
    }

    @HostListener('click', ['$event'])
    private _onClick(event): void {
        event.preventDefault();

        const previousUrl = this._buttonBrowserBackService.getPreviousUrl();

        if (previousUrl?.includes('/new')) {
            this._location.historyGo(-2);
        } else {
            this._location.back();
        }

        return;
    }

}
