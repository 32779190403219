import { DataFilterOperator } from './filter-operator';

// todo: do we need strings as enum values?
export enum FilterFieldType {
    Date = 'date',
    Text = 'text',
    Numeric = 'numeric',
    Time = 'time',
    Select = 'select',
    AutoComplete = 'autocomplete',
    Boolean = 'boolean'
}

export type AvailableFilterField = {
    field: string;
    fieldType: FilterFieldType;
    operators?: DataFilterOperator[];
};

export type AvailableFilterFields = AvailableFilterField[];
