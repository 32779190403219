import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SecuredImageComponent } from '@shared/components/secured-image/secured-image.component';

@NgModule({
    declarations: [SecuredImageComponent],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
    exports: [SecuredImageComponent]
})
export class SecuredImageModule {}
