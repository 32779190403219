import { AppUser } from '@core/services/user.service';
import { Avv, IAvv } from '@shared/models/avv';
import { BcmTenantPermission } from '@modules/bcm/bcm-tenant-permission';
import {
    BcmSupportedCurrencyCodes,
    BcmSupportedCurrencySymbols
} from '@modules/bcm/@shared/enums/supported-currencies';
import { BcmVersions } from '@modules/bcm/bcm-versions';
import { BankAccount, IBankAccount } from '@shared/models/tenant-bank-account';
import { TenantRegisterType } from '@modules/bcm/@shared/enums/tenant-register-type';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { Country } from '@shared/models/country';

export interface ITenant {
    id: number;
    disabled: boolean;
    disableReason: string;
    isUpdating: string;
    name: string;
    logo: string;
    street: string;
    postCode: string;
    postcode?: string;
    city: string;
    country: Country;
    fullAddress: string;
    bankAccounts: IBankAccount[];
    mail: string;
    mail2: string;
    phone: string;
    fax: string;
    mobile: string;
    note: string;
    social_link_facebook: string;
    social_link_twitter: string;
    social_link_linked_in: string;
    social_link_xing: string;
    social_link_youtube: string;
    social_link_instagram: string;
    form: string;
    executiveDirector: string;
    vatNo: string;
    registerName: string;
    registerNumber: string;
    registerType: TenantRegisterType;
    website: string;
    primaryPhone: string;
    primaryMail: string;
    isDemo: boolean;
    isDemoUntil: string;
    bcm_version: BcmVersions;
    hasPremiumUntil: string;
    bcm_max_users: number;
    numberOfBerths: number;
    users: AppUser[];
    avv: IAvv;

    timeZone: string;
    localeISO639: string;
    countryCodeISO3166: string;
    currencyCode: BcmSupportedCurrencyCodes;
    currencySymbol: BcmSupportedCurrencySymbols;

    permissions: BcmTenantPermission[];
    modules: any[];

    lat: number;
    long: number;

    priceType: 'net' | 'gross';

    appInvitationCode: string;
}

export class Tenant {
    id: number;
    readonly disabled: boolean;
    readonly disableReason: string;
    readonly isUpdating: string;
    name: string;
    logo: string;
    street: string;
    postCode: string;
    city: string;
    country: Country;
    fullAddress: string;
    bankAccounts: BankAccount[] = [];
    primaryBankAccount: BankAccount;
    mail: string;
    mail2: string;
    phone: string;
    fax: string;
    mobile: string;
    note: string;
    // tslint:disable:variable-name
    social_link_facebook: string;
    social_link_twitter: string;
    social_link_linked_in: string;
    social_link_xing: string;
    social_link_youtube: string;
    social_link_instagram: string;
    form: string;
    executiveDirector: string;
    vatNo: string;
    registerName: string;
    registerNumber: string;
    registerType: TenantRegisterType;
    numberOfBerths: number;
    website: string;
    primaryPhone: string;
    primaryMail: string;
    isDemo: boolean;
    isDemoUntil: Date;
    hasPremiumUntil: Date;
    bcm_version: BcmVersions;
    bcm_max_users: number;
    users: AppUser[];
    avv: Avv;

    timeZone: string;
    localeISO639: string;
    countryCodeISO3166: string;
    currencyCode: BcmSupportedCurrencyCodes;
    currencySymbol: BcmSupportedCurrencySymbols;

    permissions: BcmTenantPermission[];
    modules: any[];

    lat: number;
    long: number;
    managePricesInNet: boolean;

    appInvitationCode: string;

    /**
     * Constructor
     *
     * @param tenant
     */
    constructor(tenant = {} as ITenant) {
        this.id = tenant.id;
        this.disabled = tenant.disabled;
        this.disableReason = tenant.disableReason;
        this.isUpdating = tenant.isUpdating;
        this.name = tenant.name;
        this.logo = tenant.logo; // || 'assets/images/logos/placeholder.png';
        this.street = tenant.street;
        this.postCode = tenant.postCode || tenant.postcode;
        this.city = tenant.city;
        this.country = tenant.country;
        this.bankAccounts = (tenant.bankAccounts || []).map(bankAccount => new BankAccount(bankAccount));
        this.mail = tenant.mail;
        this.mail2 = tenant.mail2;
        this.phone = tenant.phone;
        this.fax = tenant.fax;
        this.mobile = tenant.mobile;
        this.note = tenant.note;
        this.social_link_facebook = tenant.social_link_facebook;
        this.social_link_twitter = tenant.social_link_twitter;
        this.social_link_linked_in = tenant.social_link_linked_in;
        this.social_link_xing = tenant.social_link_xing;
        this.social_link_youtube = tenant.social_link_youtube;
        this.social_link_instagram = tenant.social_link_instagram;
        this.form = tenant.form;
        this.executiveDirector = tenant.executiveDirector;
        this.vatNo = tenant.vatNo;
        this.registerName = tenant.registerName;
        this.registerNumber = tenant.registerNumber;
        this.registerType = tenant.registerType;
        this.website = tenant.website;
        this.isDemo = tenant.isDemo;
        this.isDemoUntil = tryParseDate(tenant.isDemoUntil);
        this.bcm_version = tenant.bcm_version;
        this.hasPremiumUntil = tryParseDate(tenant.hasPremiumUntil);
        this.bcm_max_users = tenant.bcm_max_users;
        this.numberOfBerths = tenant.numberOfBerths;
        this.users = tenant.users;
        this.avv = tenant.avv ? new Avv(tenant.avv) : null;

        this.timeZone = tenant.timeZone || 'Europe/Berlin';
        this.localeISO639 = tenant.localeISO639 || 'de';
        this.countryCodeISO3166 = tenant.countryCodeISO3166 || 'DE';
        this.currencyCode = tenant.currencyCode || BcmSupportedCurrencyCodes.EUR;
        this.currencySymbol = tenant.currencySymbol || BcmSupportedCurrencySymbols.EUR;

        this.lat = tenant.lat;
        this.long = tenant.long;

        this.managePricesInNet = tenant.priceType === 'net';

        this.permissions = tenant.permissions || [];
        this.modules = tenant.modules || [];

        this.fullAddress = this.getAddressString();
        this._setPrimaryBankAccount(tenant.bankAccounts);
        this.appInvitationCode = tenant.appInvitationCode;
    }

    public setBankAccounts(bankAccounts: BankAccount[]): void {
        this.bankAccounts = bankAccounts;
        this._setPrimaryBankAccount(bankAccounts);
    }

    public getAddressString(separator = ', ', includeName = false, includeCountry = false): string {
        const address = [];
        let helper = '';

        if (includeName && this.name) {
            address.push(this.name);
        }

        if (this.street) {
            address.push(this.street);
        }

        if (this.postCode) {
            helper = this.postCode;
        }

        if (this.city) {
            helper += helper ? ' ' + this.city : '';
        }

        address.push(helper);

        if (includeCountry && this.country) {
            address.push(this.country?.de);
        }

        return address.join(separator);
    }

    private _setPrimaryBankAccount(bankAccounts: BankAccount[] = []): void {
        if (bankAccounts?.length) {
            this.primaryBankAccount = bankAccounts.find((bankAccount) => bankAccount.isPrimary) || bankAccounts[0];
        }
    }
}
