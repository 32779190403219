<div class="fuse-search-bar" [ngClass]="{'expanded':!collapsed}">

    <div class="fuse-search-bar-content">

        <label for="fuse-search-bar-input">
            <ng-container *ngIf="collapsed">
                <button mat-icon-button class="fuse-search-bar-expander" aria-label="Expand Search Bar"
                        (click)="expand()">
                    <mat-icon class="s-24 secondary-text">search</mat-icon>
                </button>
            </ng-container>

            <ng-container *ngIf="!collapsed">
                <button mat-icon-button class="fuse-search-bar-expander">
                    <ng-container *ngIf="searching">
                        <mat-spinner [diameter]="20"></mat-spinner>
                    </ng-container>
                    <ng-container *ngIf="!searching">
                        <mat-icon class="s-24 secondary-text">search</mat-icon>
                    </ng-container>
                </button>
            </ng-container>
        </label>

        <input id="fuse-search-bar-input" type="text" placeholder="Wonach suchst Du?"
               [(ngModel)]="searchTerm"
               (ngModelChange)="search($event)"
               (focus)="hasFocus(true)"
               (blur)="hasFocus(false)"
               #searchInput
               fxFlex>

        <button mat-icon-button class="fuse-search-bar-collapser" (click)="collapse()"
                aria-label="Collapse Search Bar">
            <mat-icon class="s-24 secondary-text">close</mat-icon>
        </button>

    </div>

</div>