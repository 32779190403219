import { BcmBaseBulkChangesButton } from '@modules/bcm/@core/state-management/bcm-base-bulk-changes.button';

export class BcmBaseBulkChanges<Entity> {

    private _buttons: BcmBaseBulkChangesButton<Entity>[];

    private _labelFn: (items: Entity[]) => string;

    private _disableSelectionCheckBoxFn: (item: Entity, items: Entity[]) => boolean;

    public get buttons(): BcmBaseBulkChangesButton<Entity>[]{
        return this._buttons;
    }

    public setButtons(...buttons: BcmBaseBulkChangesButton<Entity>[]): BcmBaseBulkChanges<Entity> {
        this._buttons = buttons;
        return this;
    }

    public setLabelFn(labelFn: (items: Entity[]) => string): BcmBaseBulkChanges<Entity> {
        this._labelFn = labelFn;
        return this;
    }

    public setDisableSelectionCheckBoxFn(disableSelectionCheckBoxFn: (item: Entity, selectedItems: Entity[]) => boolean): BcmBaseBulkChanges<Entity> {
        this._disableSelectionCheckBoxFn = disableSelectionCheckBoxFn;
        return this;
    }

    public getLabel(items: Entity[]): string | undefined {
        return (this._labelFn && this._labelFn(items)) || undefined;
    }

    public disableSelectionCheckBox(item: Entity, items: Entity[]): boolean {
        return items?.length && (this._disableSelectionCheckBoxFn && this._disableSelectionCheckBoxFn(item, items)) || false;
    }
}
