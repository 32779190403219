import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { SignaturePad } from 'angular2-signaturepad';
import { BcmService } from '@modules/bcm/bcm.service';

enum SignatureTypes {
    Text = 'text',
    Sign = 'sign'
}

@Component({
    selector: 'form-widget-signature',
    templateUrl: './form-widget-signature.component.html',
    styleUrls: ['./form-widget-signature.component.scss']
})
export class FormWidgetSignatureComponent implements AfterViewInit, OnInit, OnDestroy {

    @ViewChild(SignaturePad) signaturePad: SignaturePad;

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    conditionCheckboxLabel = 'Jetzt signieren';

    signatureFormGroup: UntypedFormGroup;

    isSigned: boolean;

    SignatureTypes = SignatureTypes;

    signatureType: SignatureTypes;

    signaturePadOptions: object = { // passed through to szimek/signature_pad constructor
        // minWidth: 5,
        canvasWidth: 500 * Math.max(window.devicePixelRatio || 1, 1),
        canvasHeight: 150 * Math.max(window.devicePixelRatio || 1, 1)
    };

    constructor(private _formBuilder: UntypedFormBuilder,
                private _bcmService: BcmService) {
        this.signatureFormGroup = this._formBuilder.group({
            sign: [false],
            signature: [{value: null, disabled: true}, [U2bValidators.required('Keine digitale Signatur eingezeichnet')]],
            signatureText: [{value: null, disabled: true}, [U2bValidators.required('Bitte Unterschrift angeben')]],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('signatureForm', this.signatureFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('signatureForm');
    }

    ngAfterViewInit(): void {
        // this.signaturePad.clear();
    }

    signatureTypeChanged(signatureType: SignatureTypes): void {
        switch (signatureType) {
            case SignatureTypes.Text:
                this.signatureFormGroup.get('signature').disable();
                this.signatureFormGroup.get('signatureText').enable();
                break;
            case SignatureTypes.Sign:
                this.signatureFormGroup.get('signature').enable();
                this.signatureFormGroup.get('signatureText').disable();
                break;
        }
    }

    drawComplete(): void {
        this.isSigned = true;
        this.saveSignature();
    }

    drawStart(): void {
        // ...
    }

    saveSignature(): void {
        // .toDataURL(); // save image as PNG
        // .toDataURL("image/jpeg"); // save image as JPEG
        // .toDataURL("image/svg+xml"); // save image as SVG
        this.signatureFormGroup.get('signature').setValue(this.signaturePad.toDataURL());
        this.signatureFormGroup.get('signature').updateValueAndValidity();
    }

    clearSignature(): void {
        this.isSigned = false;
        this.signaturePad.clear();
        this.signatureFormGroup.get('signature').reset();
        this.signatureFormGroup.get('signature').updateValueAndValidity();
    }

}
