import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BcmCashRegistersApiService } from '@bcmApiServices/bcm-cash-registers-api.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';

@Component({
    selector: 'app-open-cash-register-dialog',
    templateUrl: './open-cash-register-dialog.component.html',
    styleUrls: ['./open-cash-register-dialog.component.scss']
})
export class OpenCashRegisterDialogComponent implements OnInit, OnDestroy {

    _unsubscribeAll = new Subject();

    formGroup: UntypedFormGroup;

    cashRegister: BcmCashRegister;

    previousDayCash: number;

    previousDayCashError = false;

    isLoading = true;

    constructor(public dialogRef: MatDialogRef<OpenCashRegisterDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: { cashRegister: BcmCashRegister },
                private _confirmDialogService: ConfirmDialogService,
                private _informationDialogService: InformationDialogService,
                private _formBuilder: UntypedFormBuilder,
                private _cashRegisterService: CashRegisterService,
                private _cashRegisterApiService: BcmCashRegistersApiService) {
        this.cashRegister = this.data?.cashRegister;
    }

    ngOnInit(): void {
        this._createForm();

        if (this.cashRegister.closedOn) {
            this._cashRegisterApiService.getLatestEndOfDayDocument(this.cashRegister)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe({
                    next: document => {
                        this._setFormValue(document.actualClosingCash);
                    },
                    error: () => {
                        this.previousDayCashError = true;
                        this._setFormValue(null);
                    }
                });
        } else {
            this._setFormValue(null);
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _setFormValue(value: number) {
        this.previousDayCash = value ?? null;
        this.formGroup.get('cash').setValue(value || 0);
        this.formGroup.get('cash').enable();
        this.isLoading = false;
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            cash: [{value: null, disabled: true}, [U2bValidators.required('Bitte gib den aktuellen Kassenbestand an.')]]
        });
    }

    async openCashRegister() {
        const cash = Number(this.formGroup.get('cash').value);

        if (cash == null) {
            this._informationDialogService
                .useWarnTheme()
                .setBody('Die Eingabe unter Kassenbestand ist ungültig.')
                .open();
            return;
        }

        if (isNaN(cash) || cash < 0) {
            this._informationDialogService
                .useWarnTheme()
                .setBody('Ein negativer Kassenbestand ist nicht erlaubt.')
                .open();
            return;
        }

        if (cash === 0) {
            this._confirmDialogService
                .useWarnTheme()
                .setBody('Als Kassenbestand wurde 0,- € angegeben, ist das korrekt?')
                .openAndReturnResult()
                .subscribe(result => {
                    if (result) {
                        this._openCashRegister(cash);
                    }
                });
            return;
        }

        this._openCashRegister(cash);
    }

    private _openCashRegister(cash: number) {
        this.isLoading = true;

        this._cashRegisterService.openCashRegister(this.cashRegister, cash)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: () => this.dialogRef.close(),
                error: () => this.isLoading = false
            });
    }
}
