import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { isPlainObject } from '@shared/functions/is-plain-object';

export class ProductExistsValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('productExists', message || 'Falsche Eingabe. Bitte Produkt aus der Liste wählen.');
    }

    public validate({value}: AbstractControl): ValidationErrors {
        return !value || (isPlainObject(value) && value?.id) ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
