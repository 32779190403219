import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

// ToDo: is this validator required? Since all fields have required validator itself?!
export class PhoneGroupValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public message = 'Bitte gib eine Nummer und den Typ an') {
        super();

        this.validationError = new BaseValidationError('phoneGroup', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        const typeField = control.get('type');
        const numberField = control.get('number');

        if (!typeField || !numberField) {
            return this.IS_VALID;
        }

        const hasType = !!typeField.value;
        const hasNumber = !!numberField.value;

        if (hasType && !hasNumber || !hasType && hasNumber) {
            return this.IS_INVALID;
        }

        return this.IS_VALID;
    }
}
