import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FuseUtils } from '@fuse/utils';
import { Season } from '@shared/models/seasons';
import { map, tap } from 'rxjs/operators';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import { AppNotificationService } from '@core/services/app-notification.service';

@Injectable()
export class SeasonsService implements Resolve<any> {

    onSeasonsChanged: BehaviorSubject<Season[]>;
    onSeasonDataChanged: BehaviorSubject<Season>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;

    seasons: Season[];
    season: Season;

    searchText: string;
    filterBy: string;

    constructor(
        private _seasonApiService: SeasonApiService,
        private _appNotificationService: AppNotificationService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
    ) {
        this.onSeasonsChanged = new BehaviorSubject([]);
        this.onSeasonDataChanged = new BehaviorSubject(null);
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();

        this.onSearchTextChanged.subscribe(searchText => {
            this.searchText = searchText;
            this.getSeasons();
        });

        this.onFilterChanged.subscribe(filter => {
            this.filterBy = filter;
            this.getSeasons();
        });
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Season[]> {
        return this.getSeasons();
    }

    getSeasons(): Observable<Season[]> {
        return this._seasonApiService.getAllSeasons()
            .pipe(
                map((response: Season[]) => {
                    this.seasons = response;

                    if (this.searchText && this.searchText !== '') {
                        this.seasons = FuseUtils.filterArrayByString(this.seasons, this.searchText) as Season[];
                    }

                    this.onSeasonsChanged.next(this.seasons);
                    return this.seasons;
                })
            );
    }

    getSeason(id): Observable<Season> {
        return this._seasonApiService.getSeasonById(id)
            .pipe(
                map((season: Season) => {
                    if (!season) {
                        this._appNotificationService.showError('Die Angeforderte Seite konnte nicht gefunden werden.');
                        this._router.navigate(['../'], {relativeTo: this._activatedRoute});
                        return;
                    }

                    this.season = season;
                    this.onSeasonDataChanged.next(this.season);
                    return season;
                })
            );
    }

    updateSeason(season: Season): Observable<any> {
        return this._seasonApiService.updateSeasonById(season);
    }

    addSeason(season: Season): Observable<any> {
        return this._seasonApiService.createSeason(season)
            .pipe(
                tap((response) => {
                    this.seasons.push(new Season(response));
                })
            );
    }

    deleteSeason(season: Season): Observable<any> {
        const request = this._seasonApiService.deleteSeasonById(season);

        request.subscribe(() => {
            const seasonIndex = this.seasons.indexOf(season);
            this.seasons.splice(seasonIndex, 1);
            this.onSeasonsChanged.next(this.seasons);
        });
        return request;
    }
}
