<u2b-modal>

    <div u2bModalHeadline>Land</div>

    <form-widget-countries [parentFormGroup]="formGroup"></form-widget-countries>

    <button modalButtonRight
            mat-flat-button
            color="accent"
            (click)="save()">
        <mat-icon>save</mat-icon>
        Übernehmen
    </button>

</u2b-modal>
