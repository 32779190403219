import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Berth } from '@shared/models/berth';

@Component({
    selector: 'bcm-berth-link',
    templateUrl: './berth-link.component.html'
})
export class BerthLinkComponent implements OnChanges {

    @Input()
    public berth!: Berth;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    @Input()
    public berthHandleOnly = false;

    @Input()
    public pierHandleOnly = false;

    ngOnChanges(): void {
        const berth = this.berth;

        if (this.berthHandleOnly) {
            this.viewData = berth.handle || '-';
        } else if (this.pierHandleOnly) {
            this.viewData = berth.pier?.handle || '-';
        } else {

            this.viewData = [
                (berth?.handle || null),
                (berth?.pier?.handle || null),
                this.additionalInfo,
            ].filter(item => item != null)
                .join(' - ');
        }
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
