import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexModule } from '@angular/flex-layout';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { FormWidgetAvvModule } from '@modules/bcm/@work-flows/@form-widgets/core/avv/form-widget-avv.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { ReadPrivacyAndConditionsComponent } from '@modules/bcm/@work-flows/read-privacy-and-conditions/read-privacy-and-conditions.component';

@NgModule({
    declarations: [ReadPrivacyAndConditionsComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        DragDropModule,
        FlexModule,

        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatFormFieldModule,

        FormWidgetAvvModule,
        MatDividerModule,
        MatProgressSpinnerModule,
    ],
    exports: [ReadPrivacyAndConditionsComponent]
})
export class ReadPrivacyAndConditionsModule {

}
