import { tryParseDate } from '@shared/functions/try-parse-date';
import { SafeHtml } from '@angular/platform-browser';
import { ITenant } from '@shared/models/tenant';

export enum ReleaseNoteType {
    MAJOR = 'major',
    MINOR = 'minor',
    BUGFIX = 'bugfix',
}

export const releaseNoteTypeTranslations: {[key in ReleaseNoteType]: string} = {
    [ReleaseNoteType.MAJOR]: 'Major Change',
    [ReleaseNoteType.MINOR]: 'Minor Change',
    [ReleaseNoteType.BUGFIX]: 'Bug Fix',
};

export interface BcmReleaseNoteDto {
    id: number;
    releaseNumber: string;
    releaseNotes: string;
    releaseDate: string;
    releaseType: ReleaseNoteType;
    releaseReason: string;
}

export class BcmReleaseNote {
    id: number;
    releaseNumber: string;
    releaseNotes: string;
    releaseDate: Date;
    releaseType: ReleaseNoteType;
    releaseTypeView: string;
    releaseReason: string;
    safeHtml: SafeHtml; // only front end variable

    constructor(releaseNote: BcmReleaseNoteDto) {
        this.id = releaseNote.id;
        this.releaseNumber = releaseNote.releaseNumber;
        this.releaseNotes = releaseNote.releaseNotes;
        this.releaseDate = tryParseDate(releaseNote.releaseDate);
        this.releaseType = releaseNote.releaseType;
        this.releaseTypeView = releaseNoteTypeTranslations[releaseNote.releaseType];
        this.releaseReason = releaseNote.releaseReason;
    }
}
