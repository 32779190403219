import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from '@modules/bcm/@shared/services';
import { IUnit } from '@shared/models/unit';

@Injectable({
    providedIn: 'root'
})
export class ProductUnitApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'products/units';

    getAll(params: HttpParams = new HttpParams()): Observable<IUnit[]> {
        return super.get<IUnit[]>(this.RESOURCE_ENDPOINT, params);
    }

    getByUniqueName(uniqueName: string, params: HttpParams = new HttpParams()): Observable<IUnit> {
        return super.get<IUnit>(`${this.RESOURCE_ENDPOINT}/${uniqueName}`, params);
    }

}
