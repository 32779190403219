import {
    Directive,
    HostListener,
    ElementRef,
    Optional, Input, ChangeDetectorRef, OnInit,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { InvalidControlScrollContainerDirective } from './invalid-control-scroll-container.directive';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';

@Directive({
    selector: '[appInvalidControlScroll]'
})
export class InvalidControlScrollDirective implements OnInit {

    private _firstInvalidControl: HTMLElement;

    private get containerEl(): HTMLElement | Window {

        if (this.scrollContainerDir) {
            return this.scrollContainerDir.containerEl;
        } else if  (this.scrollContainer) {
            return this.scrollContainer;
        }

        return window;
    }

    @Input()
    scrollContainer: HTMLElement;

    @HostListener('ngSubmit')
    onSubmit(): void {
        if (this.formGroupDir.control.invalid) {
            this.scrollToFirstInvalidControl();
        }
    }

    constructor(
        private el: ElementRef,
        private formGroupDir: FormGroupDirective,
        private changeDetectorRef: ChangeDetectorRef,
        @Optional() private scrollContainerDir: InvalidControlScrollContainerDirective
    ) {
    }

    ngOnInit(): void {
        fromEvent(this.containerEl, 'scroll')
            .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME))
            .subscribe(() => {
                if (this._firstInvalidControl) {
                    this._firstInvalidControl.focus();
                    this.changeDetectorRef.detectChanges();
                    this._firstInvalidControl = null;
                }
            });
    }

    private scrollToFirstInvalidControl(): void {
        this._firstInvalidControl = this.el.nativeElement.querySelector('input.ng-invalid');

        this.containerEl.scroll({
            top: this.getTopOffset(this._firstInvalidControl),
            left: 0,
            // behavior: 'smooth' // <-- not behaving like expected
        });
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 50;
        const controlElTop = controlEl?.getBoundingClientRect()?.top;

        if (this.scrollContainerDir && this.containerEl instanceof HTMLElement) {
            const containerTop = this.containerEl.getBoundingClientRect().top;
            const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;

            return absoluteControlElTop - containerTop - labelOffset;
        } else {
            const absoluteControlElTop = controlElTop + window.scrollY;

            return absoluteControlElTop - labelOffset;
        }
    }
}
