import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BcmApiService } from './bcm-api.service';
import { downloadFile } from '@shared/functions/download-file';
import { GetFromToRangeDialogComponent } from '@sharedComponents/dialogs/get-from-to-range-dialog/get-from-to-range-dialog.component';
import { BcmService } from '../../../bcm.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { format } from '@core/date.facade';
import { TranslationService } from '@core/translation/translation.service';
import { lastValueFrom } from 'rxjs';
import { startOfYear } from 'date-fns';

@Injectable({
    providedIn: 'root'
})
export class BcmExportExcelApiService extends BcmApiService {

    constructor(private _matDialog: MatDialog,
                _http: HttpClient,
                _bcmService: BcmService) {
        super(_http, _bcmService);
    }

    exportPersons(): void {
        this.export('persons', 'Personen');
    }

    exportPersonTenantRelations(): void {
        this.export('persons/tenant-relations', 'Personen Beziehungen');
    }

    exportPersonsAndCompanies(): void {
        this.export('persons-companies', 'Personen & Organisationen');
    }

    exportCompanies(): void {
        this.export('companies', 'Organisationen');
    }

    exportCompanyTenantRelations(): void {
        this.export('companies/tenant-relations', 'Organisationen Beziehungen');
    }

    exportProducts(): void {
        this.export('products', 'Produkte');
    }

    exportBoats(): void {
        this.export('boats', TranslationService.translate('boats', undefined, 'Boote'));
    }

    exportBerths(): void {
        this.export('berths', 'Liegeplan');
    }

    exportBerthsAssignments(): void {
        this.export('berthsAssignments', 'Liegeplatzbelegung');
    }

    exportElectricMeters(): void {
        this.export('electric-meters', 'Stromzähler');
    }

    exportKeys(persons = false): void {
        this.export('keys' + (persons ? '/persons' : ''), 'Schlüssel');
    }

    exportSeasons(): void {
        this.export('seasons', 'Saison');
    }

    exportVouchers(): void {
        this.export('vouchers', TranslationService.translate('vouchers'));
    }

    async exportElectricMeterReadings(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        this.export('electric-meter-readings', 'Stromzählerablesungen', params);
    }

    async exportInvoicePostions(): Promise<void> {
        this.export('invoicePositions', 'Bierdeckel-Positionen');
    }

    async exportInvoices(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const now = new Date();
        dialogRef.componentInstance.allowTypeAll = false;
        dialogRef.componentInstance.dateFieldsOptional = false;
        dialogRef.componentInstance.fromDateDefault = startOfYear(now);
        dialogRef.componentInstance.fromDateMax = now;
        dialogRef.componentInstance.toDateDefault = now;
        dialogRef.componentInstance.toDateMax = now;

        const range = await dialogRef
            .afterClosed()
            .toPromise();

        if (range === false) {
            return;
        }

        const params = new HttpParams()
            .set('from', range?.from ? format(range?.from, 'yyyy-MM-dd') : '')
            .set('to', range?.to ? format(range.to, 'yyyy-MM-dd') : '');

        this.export('invoices', TranslationService.translate('invoices'), params);
    }

    async exportOrders(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await lastValueFrom(dialogRef.afterClosed(), {defaultValue: false});

        if (range === false) {
            return;
        }

        const from = range?.from ? format(range?.from, 'yyyy-MM-dd') : '';
        const to = range?.to ? format(range.to, 'yyyy-MM-dd') : '';

        const params = new HttpParams()
            .set('from', from)
            .set('to', to);

        this.exportNew('orders', `Arbeitsaufträge${from ? '_' + from : ''}${to ? '_' + to : ''}`, params);
    }

    exportCostCenters(): void {
        this.export('cost-centers', 'Kostenstellen');
    }

    async exportPositionsByCostCenters(): Promise<void> {
        const dialogRef = this._matDialog.open(GetFromToRangeDialogComponent, {disableClose: true});

        const range = await lastValueFrom(dialogRef.afterClosed(), {defaultValue: false});

        if (range === false) {
            return;
        }

        const from = range?.from ? format(range?.from, 'yyyy-MM-dd') : '';
        const to = range?.to ? format(range.to, 'yyyy-MM-dd') : '';

        const params = new HttpParams()
            .set('from', from)
            .set('to', to);

        this.export('cost-center-positions', `Positionen_mit_Kostenstelle${from ? '_' + from : ''}${to ? '_' + to : ''}`, params);
    }

    private export(endpoint: string, name: string, params = new HttpParams()): void {
        super.getBlob(`export/excel/${endpoint}`, params.set('workSheetName', name))
            .subscribe((file) => downloadFile(file, name, 'xlsx'));
    }

    private exportNew(endpoint: string, name: string, params = new HttpParams()): void {
        super.getBlob(`export/${endpoint}/excel`, params.set('workSheetName', name))
            .subscribe((file) => downloadFile(file, name, 'xlsx'));
    }

}
