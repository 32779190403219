import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Person } from '@shared/models/person';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { U2bAddressValidators } from '@shared/validators/address/address-validators';
import { U2bValidators } from '@shared/validators/validators';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PersonApiService } from '@modules/bcm/@shared/services';
import { AppNotificationService } from '@core/services/app-notification.service';
import { isString } from '@shared/functions/is-string';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { HttpParams } from '@angular/common/http';


@Component({
    selector: 'get-address-dialog',
    templateUrl: './get-address-dialog.component.html',
    encapsulation: ViewEncapsulation.None
})
export class GetAddressDialogComponent {
    dialogTitle: string;
    memberForm: UntypedFormGroup;
    member: Person;
    members$: Observable<Person[]>;
    filteredMembers$: Observable<Person[]>;
    includeKinshipFields: boolean;
    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<GetAddressDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private data: { excludedPerson?: Person, includeKinshipFields?: boolean },
        private _formBuilder: UntypedFormBuilder,
        private _personApiService: PersonApiService,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogTitle = 'Person wählen';
        this.includeKinshipFields = this.data && this.data.includeKinshipFields;
        this.memberForm = this._createForm();

        if (this.includeKinshipFields) {
            this.memberForm.addControl('kinshipDegreePrefix', new UntypedFormControl(''));
            this.memberForm.addControl('kinshipDegree', new UntypedFormControl('', [U2bValidators.required('Bitte gib den Verwandtschaftsgrad an')]));
        }

        this.filteredMembers$ = this.memberForm.get('member').valueChanges
            .pipe(
                startWith(this.members$),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                switchMap((value) => isString(value) ? this._filterMembers({name: value}) : this.members$)
            );

        this._loadMembers();
    }

    save(): void {
        if (this.memberForm.invalid) {
            this.memberForm.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        let person: Person;

        if (this.includeKinshipFields) {
            person = new Person({
                ...this.memberForm.get('member').value,
                kinshipDegreePrefix: this.memberForm.get('kinshipDegreePrefix').value,
                kinshipDegree: this.memberForm.get('kinshipDegree').value,
            });
        } else {
            person = new Person(this.memberForm.get('member').value);
        }

        this.dialogRef.close(person);
        this.isSaving = false;
    }

    public displayMemberWith(member: Person): string {
        return member ? member.firstName + ' ' + member.lastName : '';
    }

    private _filterMembers(filter: { name: string } = {name: ''}): Observable<Person[]> {
        return this.members$
            .pipe(
                map((members: Person[]) => members.filter(member => (member.firstName + member.lastName).toLowerCase().includes(filter.name.toLowerCase())))
            );
    }

    private _createForm(): UntypedFormGroup {
        return this._formBuilder.group({
            member: [
                '',
                [
                    U2bValidators.required('Bitte Person auswählen'),
                    U2bAddressValidators.addressExists()
                ]
            ]
        });
    }

    private _loadMembers(): void {
        this.members$ = this._personApiService
            .getAll(new HttpParams().set('includeBoats', 'true'))
            .pipe(map((persons: Person[]) => {

                if (this.data && typeof this.data === 'object' && this.data.excludedPerson instanceof Person) {
                    persons = persons.filter((person: Person) => person.id !== this.data.excludedPerson.id);
                }

                return persons;
            }));
    }
}
