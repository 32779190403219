import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { BerthValidatorsModule } from '@shared/validators/berth/berth-validators.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OrderModule } from 'ngx-order-pipe';
import { FormWidgetVoucherComponent } from './form-widget-voucher.component';
import { NumberInputModule } from '@sharedComponents/form/number-input/number-input.module';
import { ProductPriceLabelModule } from '@sharedComponents/product-price-label/product-price-label.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [FormWidgetVoucherComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,

        BerthValidatorsModule,
        DirectivesModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        OrderModule,
        NumberInputModule,
        ProductPriceLabelModule,
        BcmPipesModule,
        MatTooltipModule,
        MatDividerModule,
        TranslationModule,
    ],
    exports: [FormWidgetVoucherComponent]
})
export class FormWidgetVoucherModule {
    
}
