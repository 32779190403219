import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { GetProductSubscriptionDatesDialogComponent } from './get-product-subscription-dates-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { NumberInputModule } from '../../form/number-input/number-input.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DirectivesModule } from '@shared/directives/directives.module';
import { ProductPriceLabelModule } from '../../product-price-label/product-price-label.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { DateTimeInputModule } from '@sharedComponents/form/date-time-input/date-time-input.module';
import { DateFnsModule } from 'ngx-date-fns';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import { MatExpansionModule } from '@angular/material/expansion';
import {ProductDynamicPriceModule} from '@bcm-work-flows/product-dynamic-price/product-dynamic-price.module';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [
        GetProductSubscriptionDatesDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,

        FuseSharedModule,
        FuseWidgetModule,
        DragDropModule,
        NumberInputModule,
        SharedModule,
        MatSelectModule,
        MatDatepickerModule,
        DirectivesModule,
        ProductPriceLabelModule,
        BcmPipesModule,
        DateTimeInputModule,
        DateFnsModule,
        MatCheckboxModule,
        MatRadioModule,
        MatExpansionModule,
        ProductDynamicPriceModule,
        MatTooltipModule,
    ],
})
export class GetProductSubscriptionDatesDialogModule {
}
