import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { CostCenterInputComponent } from '@modules/bcm/cost-centers/cost-center-input/cost-center-input.component';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [CostCenterInputComponent],
    exports: [CostCenterInputComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatLegacyInputModule,
        MatLegacyAutocompleteModule,
        OrderModule,
        SharedModule,
        MatLegacySelectModule,
        MatLegacyTooltipModule,
    ]
})
export class CostCenterInputModule {
}
