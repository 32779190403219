import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from 'hmr';
import { Logger } from '@core/services/logger.service';
import { installAllPatches } from '@core/patches/installer';

if ( environment.production )
{
    enableProdMode();
    Logger.enableProductionMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule)
    .then(ref => {
        if (window['ngRef']) {
            window['ngRef'].destroy();
        }
        window['ngRef'] = ref;
        return ref;
    });

if ( environment.hmr )
{
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else
{
    bootstrap().catch(err => console.error(err));
}

installAllPatches();
