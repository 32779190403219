import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';

@Injectable({
    providedIn: 'root'
})
export class LogApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'logs';

    add(body: {category: string, error: Error, stack: any}): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }
}
