import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from '@modules/bcm/@shared/services';
import { ITaxRate } from '@shared/models/tax-rate';

@Injectable({
    providedIn: 'root'
})
export class ProductTaxRateApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'products/tax-rates';

    getAll(params: HttpParams = new HttpParams()): Observable<ITaxRate[]> {
        return super.get<ITaxRate[]>(this.RESOURCE_ENDPOINT, params);
    }

}
