import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { SalutationNew } from '@shared/models/salutation';
import { AppUser, UserService } from '@core/services/user.service';
import { BcmService } from '@modules/bcm/bcm.service';

@Component({
    selector: 'form-widget-user',
    templateUrl: './form-widget-user.component.html'
})
export class FormWidgetUserComponent implements OnInit, OnDestroy {

    @Input()
    parentFormGroup: UntypedFormGroup;

    userFormGroup: UntypedFormGroup;

    salutation = SalutationNew;

    user: AppUser;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _userService: UserService,
                private bcmService: BcmService) {

        this.user = this._userService.user || {} as AppUser;

        this.userFormGroup = this._formBuilder.group({
            salutation: [this.user.salutation, [U2bValidators.required('Bitte Anrede angeben')]],
            title: [this.user.title],
            firstName: [this.user.firstName, [U2bValidators.required('Bitte Vornamen angeben')]],
            lastName: [this.user.lastName, [U2bValidators.required('Bitte Nachnamen angeben')]],
            street: [this.user.street, [U2bValidators.required('Bitte Strasse angeben')]],
            postcode: [
                this.user.postcode,
                [
                    U2bValidators.required('Bitte PLZ angeben'),
                    // todo: add country based validator!
                    // U2bValidators.postCode(this.bcmService.tenant)
                ]
            ],
            city: [this.user.city, [U2bValidators.required('Bitte Stadt angeben')]],
            country: [this.user.country || 'Deutschland'],
            email: [this.user.email, [U2bValidators.required('Bitte gültige E-Mail Adresse angeben'), U2bValidators.email()]],
            phone: [this.user.phone],
            mobilePhone: [this.user.mobilePhone]
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('user', this.userFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('user');
    }
}
