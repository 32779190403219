import { tryParseDate } from '@shared/functions/try-parse-date';
import { BcmUser } from '@modules/bcm/bcm-user';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { BcmPaymentType, BcmPaymentTypeFromString, IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { ReceiptTypes } from '@shared/invoice-types';

export interface BcmReceiptDto {
    id?: number;

    receiptNumber: string;
    receiptType: ReceiptTypes;
    paymentType: BcmPaymentType;
    documentContent: string;
    pdfDataUrl?: string;

    revenue?: number;
    saldo?: number;
    reprintCount?: number;

    positions: InvoicePositionDto[];
    createdBy?: BcmUser;

    bcm_persons_id: number;
    bcm_companies_id: number;

    person?: IPerson;
    company?: ICompany;

    cancellationReceipt?: BcmReceiptDto;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmReceipt {
    id: number;

    receiptNumber: string;
    receiptType: ReceiptTypes;
    paymentType: BcmPaymentType;
    tseDocument: TseDocument;
    pdfDataUrl: string;

    revenue: number;
    saldo: number;
    reprintCount: number;

    positions: InvoicePosition[];
    createdBy: BcmUser;

    person: Person;
    company: Company;

    cancellationReceipt?: BcmReceipt;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(dto: BcmReceiptDto) {
        this.id = dto.id;

        this.receiptNumber = dto.receiptNumber;
        this.receiptType = dto.receiptType;
        this.paymentType = BcmPaymentTypeFromString[dto.paymentType];
        this.tseDocument = JSON.parse(dto.documentContent);
        this.pdfDataUrl = dto.pdfDataUrl;

        this.revenue = dto.revenue;
        this.saldo = dto.saldo;

        this.positions = (dto.positions || []).map(position => new InvoicePosition(position));
        this.createdBy = dto.createdBy ? new BcmUser(dto.createdBy) : null;

        this.person = dto.person ? new Person(dto.person) : null;
        this.company = dto.company ? new Company(dto.company) : null;

        this.cancellationReceipt = dto.cancellationReceipt ? new BcmReceipt(dto.cancellationReceipt) : null;

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;
    }

}


