import { HttpErrorResponse } from '@angular/common/http';

export class AppErrorService {

    getClientMessage(error: {message: string, error: any}): string {
        if (!navigator.onLine) {
            return 'Du bist nicht mit dem Internet verbunden.';
        }
        return error.message ? error.message : error.error.message;
    }

    getClientStack(error: Error): string {
        return error.stack;
    }

    getServerMessage(error: HttpErrorResponse): string {
        return error.error ? error.error.message : error.message;
    }

    getServerStack(error: HttpErrorResponse): string {
        // console.log(error);
        // handle stack trace
        return 'stack';
    }
}
