<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button data-test="B-Dialog schließen" type="button" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form>

            <mat-vertical-stepper linear (animationDone)="scrollTop()">

                <ng-template matStepperIcon="edit">
                    <mat-icon>edit</mat-icon>
                </ng-template>

                <mat-step [stepControl]="formGroup.get('tenant')">
                    <ng-template matStepLabel>Kundendaten</ng-template>

                    <div class="py-16">
                        <form-widget-tenant [parentFormGroup]="formGroup"></form-widget-tenant>
                    </div>
                </mat-step>

                <mat-step [stepControl]="formGroup.get('user')">
                    <ng-template matStepLabel>Admin Account</ng-template>

                    <div class="py-16">
                        <form-widget-tenant-admin-account
                                [parentFormGroup]="formGroup"></form-widget-tenant-admin-account>
                    </div>
                </mat-step>

            </mat-vertical-stepper>

        </form>

        <!--        <small>* Pflichtfeld</small><br>-->

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" type="button" color="warn" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>

        <ng-template #saving>
            <mat-icon>save</mat-icon>
            <span>Neukunde speichern</span>
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-template>

        <ng-template #notSaving>
            <mat-icon>save</mat-icon>
            Neukunde speichern
        </ng-template>

        <div class="button-group">
            <ng-container *ngIf="stepper?.selectedIndex === stepper?.steps?.length - 1; else continueButton">
                <ng-container *ngIf="stepper?.selectedIndex > 0">
                    <button mat-raised-button (click)="goBack()">
                        Zurück
                    </button>
                </ng-container>
                <button data-test="B-Speichern" type="button" mat-raised-button
                        (click)="save()"
                        class="save-button mat-accent ml-0"
                        [disabled]="!formGroup.dirty || isSaving"
                        aria-label="Speichern">
                    <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
                </button>
            </ng-container>

            <ng-template #continueButton>
                <ng-container *ngIf="stepper?.selectedIndex > 0">
                    <button mat-raised-button (click)="goBack()">
                        Zurück
                    </button>
                </ng-container>
                <button mat-raised-button (click)="goForward()">
                    Weiter
                </button>
            </ng-template>
        </div>

    </div>
</div>
