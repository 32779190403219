import { Boat, IBoat } from './boat';
import { BcmOrder, BcmOrderDto } from './bcm-order';

export interface BcmOrderBoatWeighingDto {
    id: number;
    bcm_orders_id: number;
    bcm_weighings_id: number;
    bcm_boats_id: number;
    tareWeight: number;
    beltWeight: number;
    beltName: string;
    weight: number;
    scaleCode: string;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;

    order: BcmOrderDto;
    boat: IBoat;
}

export class BcmOrderBoatWeighing {
    id: number;
    bcm_orders_id: number;
    bcm_weighings_id: number;
    bcm_boats_id: number;
    tareWeight: number;
    beltWeight: number;
    beltName: string;
    weight: number;
    scaleCode: string;
    readonly insertedOn;
    readonly insertedBy;
    readonly lastUpdateOn;
    readonly lastUpdateBy;

    order: BcmOrder | null;
    boat: Boat | null;

    constructor(oderBoatWeighing: BcmOrderBoatWeighingDto) {
        this.id = oderBoatWeighing.id;
        this.bcm_orders_id = oderBoatWeighing.bcm_orders_id;
        this.bcm_weighings_id = oderBoatWeighing.bcm_weighings_id;
        this.bcm_boats_id = oderBoatWeighing.bcm_boats_id;
        this.tareWeight = oderBoatWeighing.tareWeight;
        this.beltWeight = oderBoatWeighing.beltWeight;
        this.beltName = oderBoatWeighing.beltName;
        this.weight = oderBoatWeighing.weight;
        this.scaleCode = oderBoatWeighing.scaleCode;
        this.insertedOn = oderBoatWeighing.insertedOn;
        this.insertedBy = oderBoatWeighing.insertedBy;
        this.lastUpdateOn = oderBoatWeighing.lastUpdateOn;
        this.lastUpdateBy = oderBoatWeighing.lastUpdateBy;

        this.order = oderBoatWeighing.order ? new BcmOrder(oderBoatWeighing.order) : null;
        this.boat = oderBoatWeighing.boat ? new Boat(oderBoatWeighing.boat) : null;
    }
}
