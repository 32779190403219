import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class U2BPatternValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public pattern: RegExp,
                public message = 'Bitte Eingabe überprüfen') {
        super();
        this.validationError = new BaseValidationError('pattern', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        // optional
        if (!control.value) {
            return this.IS_VALID;
        }

        return this.pattern.test(control.value)
            ? this.IS_VALID
            : this.IS_INVALID;
    }
}
