import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenCashRegisterDialogComponent } from './open-cash-register-dialog/open-cash-register-dialog.component';
import { CloseCashRegisterDialogComponent } from './close-cash-register-dialog/close-cash-register-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FuseSharedModule } from '@fuse/shared.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';



@NgModule({
  declarations: [
    OpenCashRegisterDialogComponent,
    CloseCashRegisterDialogComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        DragDropModule,
        FuseSharedModule,
        BcmPipesModule
    ]
})
export class CashRegisterDialogModule { }
