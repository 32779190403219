import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormWidgetBuildingCostSubsidyComponent } from './form-widget-building-cost-subsidy.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OrderModule } from 'ngx-order-pipe';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';

@NgModule({
    declarations: [FormWidgetBuildingCostSubsidyComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        OrderModule,
        MatTabsModule,
        BcmPipesModule,
    ],
    exports: [FormWidgetBuildingCostSubsidyComponent]
})
export class FormWidgetBuildingCostSubsidyModule {
    
}
