import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { BaseInputComponent } from '@sharedComponents/form/base-input.component';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import { DefaultInputErrorStateMatcher } from '@sharedComponents/form/base-input.error-state-matcher';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatLegacyAutocomplete, MatLegacyAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { isString } from '@shared/functions/is-string';

export interface ChipListInputItem {
    [key: string]: any;
    disabled?: boolean;
    disabledReason?: string;
}

@Component({
    selector: 'u2b-chip-list-input',
    templateUrl: './chip-list-input.component.html',
    styleUrls: ['./chip-list-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ChipListInputComponent),
            multi: true
        },
        {
            provide: MatFormFieldControl,
            useExisting: ChipListInputComponent
        }
    ],
})
export class ChipListInputComponent extends BaseInputComponent<ChipListInputItem[]> implements OnInit {

    itemCtrl = new FormControl();

    errorStateMatcher: DefaultInputErrorStateMatcher;

    controlType = 'chip-list';

    selectedItems: ChipListInputItem[] = [];

    _allItems: ChipListInputItem[];

    get allItems() {
        if (this.disableConditionCallback) {
            this.disableConditionCallback(this.selectedItems, this._allItems);
        }
        return this._allItems;
    }

    filteredItems: Observable<ChipListInputItem[]>;

    @ViewChild('chipListAutocomplete', { static: false }) matAutocomplete?: MatLegacyAutocomplete;

    @Input() placeholder = 'Hier tippen...';

    @Input() hasRemove: boolean;

    @Input() dataObservable: Observable<ChipListInputItem[]>;

    @Input() dataIdentAttribute = 'name';

    @Input() disableConditionCallback?: (list: ChipListInputItem[], currentItem: ChipListInputItem) => void;

    ngOnInit(): void {
        super.ngOnInit();

        if (this.ngControl) {
            this.errorStateMatcher = new DefaultInputErrorStateMatcher(this.ngControl);
        }

        if (this.dataObservable) {
            this.dataObservable.subscribe(data => {

                this._allItems = data;

                this.filteredItems = this.itemCtrl.valueChanges.pipe(
                    startWith(null),
                    map((item: string | null) => (isString(item) ? this._filter(item) : this.allItems.slice()))
                );
            });
        }
    }

    displayWith(item: ChipListInputItem): string {
        return item?.[this.dataIdentAttribute] || '';
    }

    selected(event: any): void {
        const selectedItem = event.option.value;

        if (!selectedItem) {
            return;
        }

        if (!this.selectedItems.includes(selectedItem)) {
            this.selectedItems.push(selectedItem);
        }

        if (!isString(this.itemCtrl.value)) {
            this.itemCtrl.setValue(null);
        }

        this.value = [...this.selectedItems];
    }

    clearInput(): void {
        this.itemCtrl.setValue(null);
    }

    addChip($event: MatChipInputEvent, input: HTMLInputElement): void {
        if (this.dataObservable || !$event.value) {
            return;
        }
        input.value = null;
        this.selectedItems.push({[this.dataIdentAttribute]: $event.value});
        this.value = [...this.selectedItems];
    }

    removeChip(chip: ChipListInputItem, trigger: MatLegacyAutocompleteTrigger): void {
        this.selectedItems.splice(this.selectedItems.findIndex(currChip => currChip[this.dataIdentAttribute] === chip[this.dataIdentAttribute]), 1);
        this.value = [...this.selectedItems];
        this.itemCtrl.updateValueAndValidity();
        trigger.updatePosition();
    }

    writeValue(obj: any): void {
        super.writeValue(obj);
        this.selectedItems = super.value || [];
    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        return this.allItems.filter(item => item[this.dataIdentAttribute].toLowerCase().includes(filterValue));
    }
}
