import { NgModule } from '@angular/core';
import { NumberInputComponent } from './number-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';

@NgModule({
    imports: [FormsModule, ReactiveFormsModule, MatIconModule, CommonModule, MatInputModule],
    declarations: [NumberInputComponent],
    exports: [NumberInputComponent]
})
export class NumberInputModule {

}
