import { NgModule } from '@angular/core';
import { FirstErrorMessagePipe, PrettyIbanPipe, SafePipe } from '@shared/pipes';
import { BackButtonDirective } from './directives/back-button.directive';
import { DateTimeFormatPipe } from '@shared/pipes/date-time-format';
import { DateFormatPipe } from '@shared/pipes/date-format';
import { StripEmptyDateTimePipe } from '@shared/pipes/strip-empty-date-time';
import { MessageComponent } from '@shared/components/message-box/message.component';
import { CommonModule } from '@angular/common';
import { ToJsDatePipe } from '@shared/pipes/to-js-date';
import { FirstErrorMessageNewPipe } from '@shared/pipes/first-error-new.pipe';
import { FlexModule } from '@angular/flex-layout';
import { FormControlPipe } from '@shared/pipes/form-control.pipe';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { MaterialModulesModule } from '@shared/material-modules.module';
import { MimeDescriptionPipe } from '@shared/pipes/mime-to-description';
import { ReplaceStringPipe } from '@shared/pipes/replace-string-pipe';

/*
* TODO: ... clean up the modules ...
* DO declare components, pipes, directives, and export them.
* DO import FormsModule, ReactiveFormsModule and other (3rd-party) modules you need.
* DO import the SharedModule into any other Feature Modules.
* DO NOT provide app-wide singleton services in your SharedModule. Instead move these to the CoreModule.
* DO NOT import the SharedModule into the AppModule.
* */
@NgModule({
    declarations: [
        FirstErrorMessagePipe,
        FirstErrorMessageNewPipe,
        PrettyIbanPipe,
        DateTimeFormatPipe,
        DateFormatPipe,
        StripEmptyDateTimePipe,
        BackButtonDirective,
        SafePipe,
        MessageComponent,
        ToJsDatePipe,
        FormControlPipe,
        MimeDescriptionPipe,
        ReplaceStringPipe,
        PageHeaderComponent,
    ],
    exports: [
        FirstErrorMessagePipe,
        FirstErrorMessageNewPipe,
        PrettyIbanPipe,
        DateTimeFormatPipe,
        DateFormatPipe,
        StripEmptyDateTimePipe,
        BackButtonDirective,
        SafePipe,
        MessageComponent,
        ToJsDatePipe,
        FormControlPipe,
        PageHeaderComponent,
        MaterialModulesModule,
        MimeDescriptionPipe,
        ReplaceStringPipe,
        CommonModule,
        FlexModule,
    ],
    imports: [
        CommonModule,
        FlexModule,
        MaterialModulesModule,
    ],
})
export class SharedModule {

}
