<div class="dialog-content-wrapper">
    <mat-toolbar class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Neues Bild hochladen</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="matDialogRef.close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 pb-0 m-0" fusePerfectScrollbar>

        <ng-container *ngIf="resizeToWidth && resizeToHeight">
            <u2b-message type="warning">
                Sollte das Bild die maximale Breite ({{resizeToWidth}} Pixel) oder Höhe ({{resizeToHeight}} Pixel) überschreiten, wird es automatisch
                verkleinert. Dies kann in manchen Fällen die Bildqualität beeinflussen.
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="resizeToWidth && !resizeToHeight">
            <u2b-message type="warning">
                Sollte das Bild die maximale Breite von {{resizeToWidth}} Pixel überschreiten, wird es automatisch verkleinert.
                Dies kann in manchen Fällen die Bildqualität beeinflussen.
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="!resizeToWidth && resizeToHeight">
            <u2b-message type="warning">
                Sollte das Bild die maximale Höhe von {{resizeToHeight}} Pixel überschrieten, wird es automatisch
                verkleinert. Dies kann in manchen Fällen die Bildqualität beeinflussen.
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="loadingFile">
            <div class="image-loader mb-16">
                <mat-spinner mode="indeterminate" [diameter]="20"></mat-spinner>
            </div>
            <!--        <mat-progress-bar mode="query" *ngIf="loadingFile" class="mb-16"></mat-progress-bar>-->
        </ng-container>

        <input hidden
               type="file"
               #fileInput
               accept="image/*"
               (change)="fileChangedEvent($event)"/>

        <ngx-file-drop (onFileDrop)="dropped($event)" [class.has-cropper]="showCropper">
            <ng-template ngx-file-drop-content-tmp>
                <div class="center">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="maintainAspectRatio"
                        [aspectRatio]="aspectRatio"
                        [resizeToWidth]="resizeToWidth"
                        [resizeToHeight]="resizeToHeight"
                        [cropperMinWidth]="cropperMinWidth"
                        [cropperMinHeight]="cropperMinHeight"
                        [canvasRotation]="canvasRotation"
                        [transform]="transform"
                        [onlyScaleDown]="true"
                        [roundCropper]="false"
                        [format]="'jpeg'"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (loadImageFailed)="loadingImageFailed()"
                    ></image-cropper>
                </div>
            </ng-template>
        </ngx-file-drop>

    </div>

    <ng-container *ngIf="showCropper">
        <div mat-dialog-actions class="m-0 px-16" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="rotateLeft()">
                <mat-icon>rotate_left</mat-icon>
            </button>
            <button mat-icon-button (click)="rotateRight()">
                <mat-icon>rotate_right</mat-icon>
            </button>
            <button mat-icon-button (click)="flipHorizontal()">
                <mat-icon>swap_horiz</mat-icon>
            </button>
            <button mat-icon-button (click)="flipVertical()">
                <mat-icon>swap_vert</mat-icon>
            </button>
            <button mat-icon-button (click)="zoomIn()">
                <mat-icon>zoom_in</mat-icon>
            </button>
            <button mat-icon-button (click)="zoomOut()" [disabled]="scale <= 1">
                <mat-icon>zoom_out</mat-icon>
            </button>
            <button mat-icon-button (click)="resetImage()" [disabled]="!wasManipulated">
                <mat-icon>restore</mat-icon>
            </button>
        </div>
    </ng-container>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button data-test="B-Bilder durchsuchen" mat-flat-button
                (click)="fileInput.click()"
                aria-label="Add file"
                style="margin-right: auto;">
            <mat-icon>add_photo_alternate</mat-icon>
            Bilder durchsuchen
        </button>

        <button data-test="B-Bild übernehmen" mat-flat-button
                color="accent"
                class="save-button"
                (click)="isSaving = true; matDialogRef.close({file: file, fileUrl: croppedImageFile})"
                [disabled]="isSaving || !file"
                aria-label="Bild übernehmen">
            Bild übernehmen
        </button>

    </div>
</div>
