<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Zeitraum auswählen</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <ng-container *ngIf="formGroup.errors">
                <u2b-message type="warning">
                    {{formGroup.errors | firstErrorMessage}}
                </u2b-message>
            </ng-container>

            <p>
                Bitte wähle einen Zeitraum (optional).
            </p>

            <div class="mb-16">
                <mat-form-field appearance="outline" fxFlex="100"
                                floatLabel="always">
                    <mat-label>Schnellauswahl</mat-label>
                    <mat-select (selectionChange)="setDates($event)" [ngModel]="defaultType" [ngModelOptions]="{standalone: true}">
                        <ng-container *ngFor="let type of types">
                            <mat-option [value]="type">{{fromToRangeButtonTranslation[type]}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="input-row"
                 fxLayout="row wrap"
                 fxLayoutGap="12px grid">

                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>von</mat-label>
                    <input matInput data-test="FI-von" formControlName="from"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="fromDatePicker"
                           [max]="fromDateMax">
                    <mat-datepicker-toggle data-test="FIT-von" matSuffix
                                           [for]="fromDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromDatePicker></mat-datepicker>
                    <mat-error>{{formGroup.get('from').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <mat-form-field fxFlex="50" appearance="outline" floatLabel="always">
                    <mat-label>bis</mat-label>
                    <input matInput data-test="FI-bis" formControlName="to"
                           appMaskDate placeholder="TT.MM.JJJJ"
                           [matDatepicker]="untilDatePicker"
                           [max]="toDateMax">
                    <mat-datepicker-toggle data-test="FIT-bis" matSuffix
                                           [for]="untilDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #untilDatePicker></mat-datepicker>
                    <mat-error>{{formGroup.get('to').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>
        <button data-test="B-Speichern" mat-flat-button
                color="accent"
                (click)="save()"
                class="save-button mat-accent"
                aria-label="Speichern">
            Weiter
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
    </div>
</div>
