import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class U2BMinlengthValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public minLength: number, public message = `Es müssen mindestens ${minLength} Zeichen angegeben werden`) {
        super();
        this.validationError = new BaseValidationError('minLength', message);
    }

    public validate({value}: AbstractControl): ValidationErrors | null {
        return !value || `${value}`.length >= this.minLength ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
