import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { format } from '@core/date.facade';

export class U2BMaxDateValidator extends BaseValidator {

    public validationError: BaseValidationError = new BaseValidationError('maxDate', this.message);

    constructor(public maxDate: Date,
                public message?: string) {
        super();
    }

    public validate({value}: AbstractControl): ValidationErrors | null {

        if (!value) {
            return this.IS_VALID;
        }

        if (value <= this.maxDate) {
            return this.IS_VALID;
        }

        if (!this.message && this.maxDate) {
            this.validationError.message = `Das Datum darf nicht hinter dem ${format(this.maxDate, 'dd.MM.yyyy')} liegen.`;
        }

        return this.IS_INVALID;
    }
}
