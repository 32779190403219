export interface BcmNoteDto {
    id: number;
    note: string;
    insertedOn: string;
    insertedBy: string;
    lastUpdateOn: string;
    lastUpdateBy: string;
}

export class BcmNote {
    id;
    note;
    readonly insertedOn;
    readonly insertedBy;
    readonly lastUpdateOn;
    readonly lastUpdateBy;

    constructor(bcmNoteDto: BcmNoteDto  = {} as BcmNoteDto) {
        this.id = bcmNoteDto.id;
        this.note = bcmNoteDto.note;
        this.insertedOn = bcmNoteDto.insertedOn;
        this.insertedBy = bcmNoteDto.insertedBy;
        this.lastUpdateOn = bcmNoteDto.lastUpdateOn;
        this.lastUpdateBy = bcmNoteDto.lastUpdateBy;
    }
}
