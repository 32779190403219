import { IProduct, Product } from '@shared/models/product';

export interface BcmOrderWorkPackageInvoicePositionsDto {
    id: number;
    bcm_order_work_packages_id: number;
    bcm_products_id: number;
    product: IProduct;
    quantity: number;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
}

export class BcmOrderWorkPackageInvoicePosition {
    id: number;
    bcm_order_work_packages_id: number;
    bcm_products_id: number;
    product: Product;
    quantity: number;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;

    constructor(bcmOrderWorkPackageInvoicePositionDto: BcmOrderWorkPackageInvoicePositionsDto = {} as BcmOrderWorkPackageInvoicePositionsDto) {
        this.id = bcmOrderWorkPackageInvoicePositionDto.id;
        this.bcm_order_work_packages_id = bcmOrderWorkPackageInvoicePositionDto.bcm_order_work_packages_id;
        this.bcm_products_id = bcmOrderWorkPackageInvoicePositionDto.bcm_products_id;
        this.product = bcmOrderWorkPackageInvoicePositionDto.product
            ? new Product(bcmOrderWorkPackageInvoicePositionDto.product)
            : null;
        this.quantity = bcmOrderWorkPackageInvoicePositionDto.quantity;
        this.insertedOn = bcmOrderWorkPackageInvoicePositionDto.insertedOn;
        this.insertedBy = bcmOrderWorkPackageInvoicePositionDto.insertedBy;
        this.lastUpdateOn = bcmOrderWorkPackageInvoicePositionDto.lastUpdateOn;
        this.lastUpdateBy = bcmOrderWorkPackageInvoicePositionDto.lastUpdateBy;
    }
}

