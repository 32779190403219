export enum TranslationLanguage {
    DE = 'de'
}

export declare type HashMap<T = any> = Record<string, T>;
export declare type Translation = HashMap;
export declare type PersistStorage = Pick<Storage, 'getItem' | 'setItem' | 'removeItem'>;
export declare type TranslateParams = string | string[];
export declare type TranslateObjectParams = TranslateParams | HashMap | Map<string, HashMap>;
export interface LangDefinition {
    id: string;
    label: string;
}
export declare type AvailableLangs = string[] | LangDefinition[];
