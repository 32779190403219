import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseStateService } from '@bcmServices/base-state.service';
import {
    BcmUserSettingKey,
    BcmUserSettings,
    BcmUserSettingsSection,
    BcmUserTableSettingKey
} from '@shared/models/bcm-settings-user';
import { cloneDeep } from '@shared/functions/clone-deep';

@Injectable({providedIn: 'root'})
export class BcmUserSettingsStateService extends BaseStateService<unknown> {

    private settings$ = new BehaviorSubject<BcmUserSettings>({} as BcmUserSettings);

    constructor() {
        super(undefined);
    }

    getSettings$(): Observable<BcmUserSettings> {
        return this.settings$.asObservable();
    }

    setSettings(settings: BcmUserSettings): void {
        this.settings$.next(settings || {} as BcmUserSettings);
    }

    updateSingleSetting<T extends BcmUserSettingsSection>(
        section: BcmUserSettingKey | BcmUserTableSettingKey | string,
        settingsSection: T
    ): void {
        const settings = this.settings$.getValue();
        this.settings$.next({
            ...settings,
            [section]: {
                ...settings[section],
                ...cloneDeep(settingsSection)
            }
        });
    }

}
