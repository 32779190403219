import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { BcmCashRegister } from '@shared/models/bcm-cash-register';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CashRegisterService } from '@bcmServices/cash-registers/cash-register.service';
import { U2bNumericValidators } from '@shared/validators/numeric';
import { TsePaymentType } from '@shared/models/tse/TsePaymentType';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'app-close-cash-register-dialog',
    templateUrl: './close-cash-register-dialog.component.html',
    styleUrls: ['./close-cash-register-dialog.component.scss']
})
export class CloseCashRegisterDialogComponent implements OnInit, OnDestroy {

    _unsubscribeAll = new Subject();

    formGroup: UntypedFormGroup;

    cashRegister: BcmCashRegister;

    differenceSum = 0;

    isLoading: boolean;

    init = false;

    openingCash: number;

    constructor(public dialogRef: MatDialogRef<CloseCashRegisterDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) private data: { cashRegister: BcmCashRegister },
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _informationDialogService: InformationDialogService,
                private _formBuilder: UntypedFormBuilder,
                private _cashRegisterService: CashRegisterService) {
        this.cashRegister = data?.cashRegister;
    }

    ngOnInit(): void {
        this._createForm();

        this._cashRegisterService.getActualStock(this.cashRegister)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: payments => {

                    this.openingCash = payments
                        .filter(payment => payment.paymentType === TsePaymentType.CASH && payment.caption === 'OPENING')
                        .flatMap(payment => payment.amount)
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                    const cash = payments
                        .filter(payment => payment.paymentType === TsePaymentType.CASH)
                        .flatMap(payment => payment.amount)
                        .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                    this.formGroup.get('targetCash').setValue(roundNumber(cash, RoundNumberFactor.TwoDecimals));
                    this.formGroup.get('actualCash').setValue(roundNumber(cash, RoundNumberFactor.TwoDecimals));

                    this.init = true;
                },
                error: () => {
                    this._appNotificationService.showError('Fehler beim Laden des Kassenbestands. Bitte versuche es später erneut.');
                    this.dialogRef.close();
                }
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _createForm(): void {
        this.formGroup = this._formBuilder.group({
            targetCash: [0],
            actualCash: [0, [U2bValidators.required('Bitte gib den aktuellen Kassenbestand an.')]],
            differenceReason: [null, [U2bValidators.required('Bitte nenne den Grund für die Differenz des Kassenbestands.')]],
            cashAdd: [0, [U2bValidators.required('Bitte gebe den Betrag an, den du einlegen möchtest.'), U2bNumericValidators.numberMin(0, 'Betrag kann nicht kleiner als Null sein.')]],
            cashLift: [0, [U2bValidators.required('Bitte gebe den Betrag an, den du abschöpfen möchtest.'), U2bNumericValidators.numberMin(0, 'Betrag kann nicht kleiner als Null sein.')]],
            cashAddEnabled: [false],
            cashLiftEnabled: [false]
        });

        this.formGroup.get('cashAddEnabled').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(enabled => {
                if (enabled) {
                    this.formGroup.get('cashAdd').enable();
                } else {
                    this.formGroup.get('cashAdd').setValue(0, {emitEvent: false});
                }
            });

        this.formGroup.get('cashLiftEnabled').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(enabled => {
                if (enabled) {
                    this.formGroup.get('cashLift').enable();
                } else {
                    this.formGroup.get('cashLift').setValue(0, {emitEvent: false});
                }
            });

        this.formGroup.get('targetCash').disable({emitEvent: false});
        this.formGroup.get('differenceReason').disable({emitEvent: false});

        this.formGroup.get('actualCash').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((actualCash) => {

                if (actualCash === null) {
                    return;
                }

                const targetCash = parseFloat(this.formGroup.get('targetCash').value);
                actualCash = parseFloat(actualCash);

                this.differenceSum = actualCash - targetCash;

                if (this.differenceSum === 0) {
                    this.formGroup.get('differenceReason').disable({emitEvent: false});
                } else {
                    this.formGroup.get('differenceReason').enable({emitEvent: false});
                }

            });
    }

    async closeCashRegister(): Promise<void> {

        const {actualCash} = this.formGroup.getRawValue();

        if (actualCash == null) {
            this._informationDialogService
                .useWarnTheme()
                .setBody('Die Eingabe unter Kassenbestand ist ungültig.')
                .open();
            return;
        }

        if (isNaN(actualCash) || actualCash < 0) {
            this._informationDialogService
                .useWarnTheme()
                .setBody('Ein negativer Kassenbestand ist nicht erlaubt.')
                .open();
            return;
        }

        let result = true;

        if (actualCash === 0) {
            result = await this._confirmDialogService
                .useWarnTheme()
                .setBody('Als Kassenbestand wurde 0,- € angegeben, ist das korrekt?')
                .openAndReturnResult()
                .toPromise();
        }

        if (!result) {
            return;
        }

        if (this.openingCash === actualCash) {
            this._confirmDialogService
                .useWarnTheme()
                .setBody('Der Kassenbestand entspricht dem Eröffnungsbetrag. Ist es korrekt, dass keine Barzahlungen getätigt wurden?')
                .openAndReturnResult()
                .subscribe(result2 => {
                    if (result2) {
                        this._closeCashRegister();
                    }
                });
            return;
        }

        this._closeCashRegister();
    }

    _closeCashRegister(): void {

        this.isLoading = true;

        const {actualCash, differenceReason, cashAdd, cashLift} = this.formGroup.getRawValue();

        this._cashRegisterService.closeCashRegister(this.cashRegister, actualCash, this.differenceSum, differenceReason, cashAdd, cashLift)
            .subscribe({
                next: () => this.dialogRef.close(),
                error: () => this.isLoading = false
            });

    }

}
