import { IProduct, Product } from '@shared/models/product';
import { ITaxRate } from '@shared/models/tax-rate';
import { IUnit } from '@shared/models/unit';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BcmDynamicPrice, BcmDynamicPriceDto } from '@shared/models/bcm-dynamic-price';
import { BcmVoucher, BcmVoucherDto } from '@shared/models/bcm-voucher';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';
import { getReadableDateRange } from '@shared/functions/get-readable-date-range';
import { nanoid } from 'nanoid';
import { Boat, IBoat } from '@shared/models/boat';
import { TenantRelation, TenantRelationRaw } from '@shared/models/tenant-relation';
import { ProductSubscription, ProductSubscriptionDto } from '@shared/models/product-subscription';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export interface InvoicePositionDto {
    id?: number;
    bcm_berth_has_assignments_id?: number;
    bcm_electric_meter_has_boats_id?: number;
    bcm_electric_meter_has_readings_id?: number;
    selected?: boolean;
    lastInvoiceDate?: string;
    product?: IProduct | Product;
    account?: string;
    itemNumber?: string;
    quantity: number;
    title: string;
    subTitle?: string;
    unit: IUnit;
    price: number | string; // decimal ?
    taxRate: ITaxRate;
    discountPercentage?: number;
    vestingPeriodFrom?: Date | string;
    vestingPeriodUntil?: Date | string;
    vestingPeriodText?: string;
    vestingPeriodFromDate?: Date | string;
    vestingPeriodUntilDate?: Date | string;
    taxRateValue?: number | string;
    sortIndex?: number;
    isNew?: boolean;

    editMode?: boolean;
    positionId?: number;

    // tslint:disable-next-line:variable-name
    bcm_invoices_id?: number;
    // tslint:disable-next-line:variable-name
    bcm_receipts_id?: number;

    insertedOn?: string;
    insertedBy?: string;

    dynamicPrice?: BcmDynamicPriceDto | BcmDynamicPrice;

    fromTenantRelation?: boolean;
    tenantRelation?: TenantRelationRaw | TenantRelation;
    tenantRelationAssignment?: TenantRelationAssignmentDto | TenantRelationAssignment;

    fromBerth?: boolean;

    subscription?: ProductSubscriptionDto;

    tseVatIdentificationId?: number;

    voucher?: BcmVoucherDto | BcmVoucher;

    person?: IPerson;
    company?: ICompany;
    boat?: IBoat;

    invoice?: IInvoice;
    receipt?: BcmReceiptDto;

    voucherRemainingAmount?: number;

    priceLock?: boolean;
    titleLock?: boolean;
    accountLock?: boolean;
    itemNumberLock?: boolean;
    unitLock?: boolean;
    taxRateLock?: boolean;

    costCenter?: BcmCostCenterDto;

    bcm_cost_centers_id?: number;

    berthAssignmentUuid?: string;
    electricMeterAssignmentUuid?: string;
    electricMeterReadingUuid?: string;
}

export class InvoicePosition {
    id: number;

    // tslint:disable-next-line:variable-name
    bcm_berth_has_assignments_id?: number;
    // tslint:disable-next-line:variable-name
    bcm_electric_meter_has_readings_id?: number;
    // tslint:disable-next-line:variable-name
    bcm_electric_meter_has_boats_id?: number;

    selected?: boolean;
    lastInvoiceDate?: string;
    isNew: boolean;
    product: Product;
    account?: string;
    itemNumber?: string;
    quantity: number;
    title: string;
    subTitle: string;
    unit: IUnit;
    price: number;
    taxRate: ITaxRate;
    discountPercentage: number;
    vestingPeriodFrom?: string;
    vestingPeriodUntil?: string;
    vestingPeriodText?: string;
    vestingPeriodFromDate?: Date;
    vestingPeriodUntilDate?: Date;
    taxRateValue: number;
    sortIndex?: number;

    editMode: boolean;
    positionId: number;

    // tslint:disable-next-line:variable-name
    bcm_invoices_id?: number;
    // tslint:disable-next-line:variable-name
    bcm_receipts_id?: number;

    insertedOn: Date;
    insertedBy: string;

    // helper
    uuid: string;
    userName: string;

    dynamicPrice?: BcmDynamicPrice;

    fromTenantRelation?: boolean;
    tenantRelation?: TenantRelation;
    tenantRelationAssignment?: TenantRelationAssignment;

    fromBerth?: boolean;

    subscription?: ProductSubscription;

    tseVatIdentificationId?: number;

    voucher?: BcmVoucher;

    person?: Person;
    company?: Company;
    boat?: Boat;

    invoice: Invoice;
    receipt: BcmReceipt;

    voucherRemainingAmount: number;

    priceLock?: boolean;
    titleLock?: boolean;
    accountLock?: boolean;
    itemNumberLock?: boolean;
    unitLock?: boolean;
    taxRateLock?: boolean;

    costCenter?: BcmCostCenter;

    // tslint:disable-next-line:variable-name
    bcm_cost_centers_id?: number;

    berthAssignmentUuid?: string;
    electricMeterAssignmentUuid?: string;
    electricMeterReadingUuid?: string;

    get totalPrice(): number {
        // const totalPrice = (this.dynamicPrice?.rulePrice || this.price) * this.quantity || 0;
        const totalPrice = this.price * this.quantity || 0;
        return totalPrice - (totalPrice / 100 * (this.discountPercentage || 0));
    }

    constructor(pos: InvoicePositionDto = {} as InvoicePositionDto, resetTaxRateValue = false) {
        this.id = pos.id;
        this.uuid = nanoid();
        this.isNew = pos.isNew || !(pos.id > 0);
        this.selected = false;
        this.lastInvoiceDate = pos.lastInvoiceDate;
        this.product = pos.product ? new Product(pos.product as IProduct) : null;
        this.account = pos.account;
        this.itemNumber = pos.itemNumber;
        this.quantity = pos.quantity;
        this.title = pos.title || this.product?.name;
        this.subTitle = pos.subTitle || null;
        this.unit = pos.unit;
        this.price = pos.price != null ? parseFloat(pos.price as string) : this.product?.price;
        this.taxRate = pos.taxRate != null ? pos.taxRate : this.product?.taxRate;
        this.discountPercentage = pos.discountPercentage || null;
        this.vestingPeriodFrom = pos.vestingPeriodFrom as string;
        this.vestingPeriodUntil = pos.vestingPeriodUntil as string;

        if (resetTaxRateValue) {
            this.taxRateValue = pos.taxRate?.value != null
                ? pos.taxRate.value
                : pos.product?.taxRate?.value != null
                    ? pos.product.taxRate.value
                    : 0;
        } else {
            this.taxRateValue = pos.taxRateValue != null
                ? parseFloat(pos.taxRateValue as string)
                : pos.taxRate?.value != null
                    ? pos.taxRate.value
                    : pos.product?.taxRate?.value != null
                        ? pos.product.taxRate.value
                        : 0;
        }

        this.person = pos.person ? new Person(pos.person) : null;
        this.company = pos.company ? new Company(pos.company) : null;
        this.boat = pos.boat ? new Boat(pos.boat) : null;

        this.vestingPeriodFromDate = tryParseDate(pos.vestingPeriodFrom);
        this.vestingPeriodUntilDate = tryParseDate(pos.vestingPeriodUntil);
        this.sortIndex = pos?.sortIndex;

        this.vestingPeriodText = getReadableDateRange(this.vestingPeriodFromDate, this.vestingPeriodUntilDate);

        this.editMode = pos.editMode;
        this.positionId = pos.positionId;

        // tslint:disable-next-line:variable-name
        this.bcm_invoices_id = pos.bcm_invoices_id;
        this.bcm_receipts_id = pos.bcm_receipts_id;
        this.insertedOn = tryParseDate(pos.insertedOn);
        this.insertedBy = pos.insertedBy;

        this.dynamicPrice = pos.dynamicPrice ? new BcmDynamicPrice({
            ...pos.dynamicPrice,
            taxRate: pos.taxRate
        } as BcmDynamicPriceDto) : null;

        this.voucher = pos.voucher ? new BcmVoucher(pos.voucher as BcmVoucherDto) : null;
        this.invoice = pos.invoice ? new Invoice(pos.invoice) : null;
        this.receipt = pos.receipt ? new BcmReceipt(pos.receipt) : null;

        this.voucherRemainingAmount = pos.voucherRemainingAmount || 0;

        this.fromTenantRelation = pos.fromTenantRelation || pos.tenantRelation?.id > 0;

        if (pos.tenantRelationAssignment) {
            this.tenantRelation = new TenantRelation(pos.tenantRelation as TenantRelationRaw);
            this.tenantRelationAssignment = new TenantRelationAssignment(pos.tenantRelationAssignment as TenantRelationAssignmentDto);
        }
        // todo: ask pascal where "personTenantRelation" and "companyTenantRelation" is coming from
        /* else if (pos.personTenantRelation) {
            this.tenantRelation = new TenantRelation(pos.personTenantRelation.tenantRelation);
            this.tenantRelationAssignment = new TenantRelationAssignment(pos.personTenantRelation);
        } else if (pos.companyTenantRelation) {
            this.tenantRelation = new TenantRelation(pos.companyTenantRelation.tenantRelation);
            this.tenantRelationAssignment = new TenantRelationAssignment(pos.companyTenantRelation);
        }*/

        this.fromBerth = pos.fromBerth;
        this.bcm_berth_has_assignments_id = pos.bcm_berth_has_assignments_id;
        this.bcm_electric_meter_has_readings_id = pos.bcm_electric_meter_has_readings_id;
        this.bcm_electric_meter_has_boats_id = pos.bcm_electric_meter_has_boats_id;

        this.subscription = pos.subscription ? new ProductSubscription(pos.subscription) : null;

        this.priceLock = pos.priceLock;
        this.titleLock = pos.titleLock;
        this.accountLock = pos.accountLock;
        this.itemNumberLock = pos.itemNumberLock;
        this.taxRateLock = pos.taxRateLock;
        this.unitLock = pos.unitLock;

        this.costCenter = pos.costCenter ? new BcmCostCenter(pos.costCenter) : undefined;

        this.bcm_cost_centers_id = pos.bcm_cost_centers_id;

        this.berthAssignmentUuid = pos.berthAssignmentUuid;
        this.electricMeterAssignmentUuid = pos.electricMeterAssignmentUuid;
        this.electricMeterReadingUuid = pos.electricMeterReadingUuid;
    }
}
