import { Injectable } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { U2bConfirmDialogComponent } from '@shared/components';
import { DialogButton } from '@shared/components/dialogs/confirm-dialog/dialog-button';
import { isFunction } from '@shared/functions/is-function';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {
    ConfirmDialogFormControl,
    ConfirmDialogFormControlBase, ConfirmDialogFormSelectControl
} from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog-form-control';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class ConfirmDialogService {

    private _title: string;

    private _theme: ThemePalette = 'accent';

    private _body: SafeHtml;

    private _yesButton: DialogButton;

    private _noButton: DialogButton;

    private _dialogConfig: MatDialogConfig;

    private _confirmDialogFormControls: ConfirmDialogFormControlBase[] = [];

    constructor(private _matDialog: MatDialog,
                private domSanitizer: DomSanitizer) {
    }

    public setTitle(title: string): ConfirmDialogService {
        this._title = title;
        return this;
    }

    public setTheme(theme: ThemePalette, ignoreButtonsOnTheming = false): ConfirmDialogService {
        this._theme = theme;
        if (ignoreButtonsOnTheming) {
            return this;
        }
        return this.setYesButton({color: theme}).setNoButton({color: theme === 'warn' ? 'primary' : null});
    }

    public useWarnTheme(ignoreButtonsOnTheming = false): ConfirmDialogService {
        this._theme = 'warn';
        if (!this._title) {
            this._title = 'Achtung';
        }
        if (ignoreButtonsOnTheming) {
            return this;
        }
        return this.setYesButton({color: this._theme}).setNoButton({color: 'primary'});
    }

    public setBody(body: string): ConfirmDialogService {
        this._body = this.domSanitizer.bypassSecurityTrustHtml(body);
        return this;
    }

    public appendInputToBody(confirmDialogFormControl: ConfirmDialogFormControl): ConfirmDialogService {
        this._confirmDialogFormControls.push(confirmDialogFormControl);
        return this;
    }

    public appendMultipleInputToBody(confirmDialogFormControl: ConfirmDialogFormControl[]): ConfirmDialogService {
        this._confirmDialogFormControls.push(...confirmDialogFormControl);
        return this;
    }

    public appendSelectToBody<T>(selectControl: ConfirmDialogFormSelectControl<T>): ConfirmDialogService {
        this._confirmDialogFormControls.push(selectControl);
        return this;
    }

    public setYesButton(yesButton: DialogButton): ConfirmDialogService {
        this._yesButton = {...{color: this._theme, text: 'Ja'}, ...yesButton};
        return this;
    }

    public setNoButton(noButton: DialogButton): ConfirmDialogService {
        this._noButton = {...{color: null, text: 'Nein'}, ...noButton};
        return this;
    }

    public setDialogConfig(dialogConfig: MatDialogConfig): ConfirmDialogService {
        this._dialogConfig = dialogConfig;
        return this;
    }

    public openWithCallback(onYesButtonClickCallback: () => void,
                            onNoButtonClickCallback?: () => void,
                            onDismissCallback?: () => void): void {
        this._open(onYesButtonClickCallback, onNoButtonClickCallback, onDismissCallback || onNoButtonClickCallback);
    }

    public open(): void {
        this._open();
    }

    public openAndReturnResult<T = boolean>(): Observable<T> {
        const confirmDialogRef = this._matDialog.open(U2bConfirmDialogComponent, {
            ...this._dialogConfig,
            data: {
                ...this._dialogConfig?.data,
                confirmDialogFormControls: this._confirmDialogFormControls
            }
        });
        const component = confirmDialogRef.componentInstance;

        if (this._title) {
            component.confirmTitle = this._title;
        }

        if (this._theme) {
            component.confirmTitleTheme = this._theme;
        }

        if (this._body) {
            component.confirmBody = this._body;
        }

        if (this._yesButton) {
            component.yesButton = this._yesButton;
        }

        if (this._noButton) {
            component.noButton = this._noButton;
        }

        return confirmDialogRef
            .afterClosed()
            .pipe(
                map((value: T) => (typeof value === 'boolean' ? value === true : value) as T),
                finalize(() => this._reset())
            );
    }

    private _open(onYesButtonClickCallback?: () => void,
                  onNoButtonClickCallback?: () => void,
                  onDismissCallback?: () => void): void {

        const confirmDialogRef = this._matDialog.open(U2bConfirmDialogComponent, this._dialogConfig);
        const component = confirmDialogRef.componentInstance;

        if (this._title) {
            component.confirmTitle = this._title;
        }

        if (this._theme) {
            component.confirmTitleTheme = this._theme;
        }

        if (this._body) {
            component.confirmBody = this._body;
        }

        if (this._yesButton) {
            component.yesButton = this._yesButton;
        }

        if (this._noButton) {
            component.noButton = this._noButton;
        }

        confirmDialogRef
            .afterClosed()
            .subscribe(value => {
                if (value === true && isFunction(onYesButtonClickCallback)) {
                    return onYesButtonClickCallback();
                }

                if (value === false) {
                    if (isFunction(onNoButtonClickCallback)) {
                        onNoButtonClickCallback();
                    }
                    return;
                }

                if (isFunction(onDismissCallback)) {
                    onDismissCallback();
                }

                return;
            })
            .add(() => this._reset());
    }

    private _reset(): void {
        this._title = null;
        this._theme = 'accent';
        this._body = null;
        this._yesButton = null;
        this._noButton = null;
        this._dialogConfig = null;
        this._confirmDialogFormControls = [];
    }
}
