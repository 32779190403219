import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';
import { Person } from '@shared/models/person';

export class AddressExistsValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('addressExists', message || 'Falsche Eingabe. Bitte Person aus der Liste wählen.');
    }

    public validate({value}: AbstractControl): ValidationErrors {
        return !value || (value instanceof Person && value.id != null) ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
