export const TINY_MCE_PLACEHOLDERS = [
    {
        section: 'Datum',
        placeholders: [
            '[DATUM]',
            '[JAHR]',
            '[MONAT]',
            '[MONAT_TEXT]',
        ]
    },
    {
        section: 'Person',
        placeholders: [
            '[P_ANREDE_MAIL]',
            '[P_ANREDE_BRIEF]',
            '[P_ADRESSE]',
            '[P_ADRESSE_UMBRUCH]',
            '[P_ANREDE]',
            '[P_NAME]',
            '[P_STR]',
            '[P_PLZ]',
            '[P_STADT]',
            '[P_LAND]',
            '[P_MAIL]',
            '[P_TEL]',
            '[P_MOBIL]',
            '[P_FAX]',
            '[P_WEB]',
            '[P_GEBURTSDATUM]',
        ]
    },
    {
        section: 'Boot',
        placeholders: [
            '[BOOT_NAME]',
            '[BOOT_TYP]',
            '[BOOT_KENNZEICHEN]',
            '[BOOT_HIN]',
            '[BOOT_LAENGE]',
            '[BOOT_BREITE]',
        ]
    },
    {
        section: 'Liegeplatz',
        placeholders: [
            '[LP_NAME]',
            // '[LP_KOSTEN_TABELLE]',
        ]
    },
    {
        section: 'Vertragsanhänge',
        placeholders: [
            '[ANHANG_TABELLE]',
        ]
    }
];

export const DEFAULT_DEBOUNCE_TIME = 300;
export const DEFAULT_DEBOUNCE_TIME_SEARCH = 90;
