import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxFileDropModule } from 'ngx-file-drop';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { SharedModule } from '@shared/shared.module';
import { U2bImageCropperDialogComponent } from './image-cropper-dialog.component';

@NgModule({
    declarations: [
        U2bImageCropperDialogComponent
    ],
    imports: [
        ImageCropperModule,
        NgxFileDropModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatProgressBarModule,
        FuseSharedModule,
        MatProgressSpinnerModule,
        SharedModule
    ],
})
export class U2bImageCropperDialogModule {
}
