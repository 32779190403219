import {
    U2bColumnDefinitionExternalComponent, U2bColumnPipeName,
    U2bFilterSelectOptionsRelations,
    U2bTableData
} from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';
import { Company } from '@shared/models/company';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { DataFilterOperator } from '@core/datafilter/filter-operator';
import { FILTER_OPERATORS_DEFAULT_SELECT, FILTER_OPERATORS_DEFAULT_STRING } from '@core/datafilter/constants';

export const companiesTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Companies,
    columnDefinitions: [
        {
            property: 'name',
            name: 'Name der Organisation',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'form',
            name: 'Gesellschaftsform',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'street',
            name: 'Strasse',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'postCode',
            name: 'Postleitzahl',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'city',
            name: 'Ort',
            isActive: getIsActive(),
            filter: {},
        },
        {
            property: 'country',
            name: 'Land',
            isActive: getIsActive(false, false, false),
            parseItem: (item: Company) => item?.country?.de,
            filter: {
                property: 'country',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: 'country',
                operators: FILTER_OPERATORS_DEFAULT_SELECT,
                selectOptionsRelation: U2bFilterSelectOptionsRelations.Countries,
                selectOptionsFilterBy: ['de'],
                selectOptionsDisplayWith: 'de'
            }
        },
        {
            property: 'tenantRelations',
            name: 'Beziehung',
            isActive: getIsActive(),
            externalComponent: U2bColumnDefinitionExternalComponent.TenantRelationColoredName,
            parseItem: (item: Company) => item.activeAndNextTenantRelationAssignments,
            filter: {
                property: 'activeAndNextTenantRelationAssignments',
                fieldType: FilterFieldType.AutoComplete,
                compareAttribute: ['tenantRelation.id', 'id'],
                operators: [
                    DataFilterOperator.Contains,
                    DataFilterOperator.NotContains,
                ],
                selectOptionsRelation: U2bFilterSelectOptionsRelations.TenantRelations,
                selectOptionsFilterBy: ['name'],
                selectOptionsDisplayWith: 'name',
            }
        },
        {
            property: 'note',
            name: 'Notiz',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'phone',
            name: 'Rufnummer',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'mail',
            name: 'E-Mail',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'taxNumber',
            name: 'Steuernummer',
            isActive: getIsActive(false, false, false),
            filter: {}
        },
        {
            property: 'taxNumber',
            name: 'Steuer-Nr.',
            isActive: getIsActive(false, false, false),
            excludeIf: tenant => tenant.form !== 'Binnenhafen',
            filter: {},
        },
        {
            property: 'invoicePaymentType',
            name: 'Zahlart',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'Kreditinstitut',
            name: 'Kreditinstitut',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'accountHolder',
            name: 'Kontoinhaber',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'SEPAMandatId',
            name: 'SEPA Mandat ID',
            isActive: getIsActive(false, false, false),
            filter: {},
        },
        {
            property: 'SEPAMandatSignDate',
            name: 'SEPA Datum',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Date,
            filter: {
                fieldType: FilterFieldType.Date
            }
        },
        {
            property: 'entryDate',
            name: 'Eintritt',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Date,
            filter: {
                fieldType: FilterFieldType.Date
            }
        },
        {
            property: 'exitDate',
            name: 'Austritt',
            isActive: getIsActive(false, false, false),
            pipe: U2bColumnPipeName.Date,
            filter: {
                fieldType: FilterFieldType.Date
            }
        },
        {
            property: 'invoicesByMail',
            name: 'Belege per Mail',
            isActive: getIsActive(false, false, false),
            parseItem: (company: Company) => company.invoicesByMail ?  '✔' : '',
            filter: {},
        },
        {
            property: 'invoicesByPost',
            name: 'Belege per Post',
            isActive: getIsActive(false, false, false),
            parseItem: (company: Company) => company.invoicesByPost ?  '✔' : '',
            filter: {},
        },
    ],
};
