/**
 * Array.from(arr) and arr.slice(0) are not cloning deep...
 *
 * ES6 spreading is creating shallow clone, which does clone without reference only on first level.
 * Multidimensional arrays are not doing a deep clone:
 * newArr = [...arr];
 *
 * Only woks, if there are no arrays with objects with nested arrays:
 * const clone = (items) => items.map(item => Array.isArray(item) ? clone(item) : item);
 *
 * ONLY WORKS FOR Number and String and Object literal without function or Symbol properties:
 * JSON.parse(JSON.stringify(obj))
 *
 *      // undefined will convert to null
 *      JSON.parse(JSON.stringify([1, undefined, 2])) // -> [1, null, 2]
 *
 *      // DOM nodes are converted to empty objects
 *      JSON.parse(JSON.stringify([document.body, document.querySelector('p')])) // -> [{}, {}]
 *
 *      // JS dates are converted to strings
 *      JSON.parse(JSON.stringify([new Date()])) // -> ["2019-03-04T10:09:00.419Z"]
 *
 * Solution with third party tool:
 * https://www.npmjs.com/package/lodash.clonedeep
 *
 * Since our use cases have no circular references yet, JSON parse + stringify is the simplest solution
 */
const clone = (items) => JSON.parse(JSON.stringify(items));

export function cloneDeep<T = any>(array: T): T {
    return clone(array);
}
