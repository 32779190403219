import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '../../../@shared/constants';
import { isString } from '@shared/functions/is-string';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmVoucher } from '@shared/models/bcm-voucher';
import { BcmVouchersFacade } from '@modules/bcm/@core/state-management/vouchers/bcm-vouchers-facade';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import {
    MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
    MatLegacyAutocompleteTrigger as MatAutocompleteTrigger
} from '@angular/material/legacy-autocomplete';

@UntilDestroy()
@Component({
    selector: 'form-widget-voucher',
    templateUrl: './form-widget-voucher.component.html',
})
export class FormWidgetVoucherComponent implements OnInit, OnDestroy, OnChanges {

    @ViewChild('autocompleteInput', {read: MatAutocompleteTrigger}) voucherSelector: MatAutocompleteTrigger;

    @Input()
    personOrCompanyName?: string;

    @Input()
    topListLabel!: string;

    @Input()
    inputLabel!: string;

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    selectVoucher: BcmVoucher;

    @Input()
    filteredVouchers: BcmVoucher[] = null;

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    givenVouchers: BcmVoucher[];

    @Input()
    voucherRequired = false;

    @Input()
    slimmedView = false;

    @Input()
    index?: number = null;

    @Input()
    clearAfterSelection = false;

    @Output()
    voucherRemoved: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    voucherSelected: EventEmitter<BcmVoucher> = new EventEmitter<BcmVoucher>();

    @Output()
    voucherUpdated: EventEmitter<{ index: number, voucher: BcmVoucher }> = new EventEmitter<{
        index: number,
        voucher: BcmVoucher
    }>();

    voucherFormGroup: UntypedFormGroup;

    vouchers: BcmVoucher[];

    voucher: BcmVoucher;

    loading: boolean;

    topList: BcmVoucher[] = [];
    bottomList: BcmVoucher[] = [];

    filteredTopList$: Observable<BcmVoucher[]>;
    filteredBottomList$: Observable<BcmVoucher[]>;

    formControlName = 'voucherForm';

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _vouchersFacade: BcmVouchersFacade) {
    }

    ngOnInit(): void {
        this.voucher = this.selectVoucher;

        this._createForm();
        this._loadVouchers();

        setTimeout(() => {
            if (this.index !== null) {
                this.formControlName = `voucher${this.index}Form`;
            }
            this.parentFormGroup.addControl(this.formControlName, this.voucherFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl(this.formControlName);
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!this.voucherFormGroup) {
            return;
        }

        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'filteredVouchers': {
                        this._loadVouchers();
                        break;
                    }
                }
            }
        }

        if (this.selectVoucher) {
            this.voucherFormGroup.patchValue({
                voucher: this.selectVoucher
            });
        }


    }

    private _createForm(): void {
        this.voucherFormGroup = this._formBuilder.group({
            voucher: [this.selectVoucher, this.voucherRequired ? [U2bValidators.required('Bitte Auswahl treffen')] : []],
        });

        if (!this.voucherRequired || this.selectVoucher) {
            this.voucherFormGroup.markAsPristine();
        }

        // this.voucherFormGroup
        //     .get('voucher')
        //     .valueChanges
        //     .pipe(distinctUntilChanged((previous, current) => previous.id !== current.id))
        //     .subscribe((value: BcmVoucher) => {
        //         this.voucher = value;
        //         this.updateVoucher.next({index: this.index, voucher: value});
        //     });
    }

    private _loadVouchers(): void {

        this.loading = true;

        let vouchers$;

        if (this.filteredVouchers !== null) {
            vouchers$ = of(this.filteredVouchers);
        } else {
            vouchers$ = this._vouchersFacade.loadList();
        }


        vouchers$.subscribe((vouchers) => {
            this.topList = [];
            this.bottomList = [];

            for (const voucher of vouchers) {
                if ((this.givenVouchers || []).find(givenVoucher => givenVoucher.id === voucher.id)) {
                    this.topList.push(voucher);
                } else {
                    this.bottomList.push(voucher);
                }
            }
        })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.voucherFormGroup.get('voucher').valueChanges
            .pipe(
                untilDestroyed(this),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterVouchers(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.voucherFormGroup.get('voucher').valueChanges
            .pipe(
                untilDestroyed(this),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterVouchers(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterVouchers(filter: string, vouchers: BcmVoucher[]): BcmVoucher[] {
        return vouchers
            .filter(voucher => voucher.title?.toLowerCase().includes(filter.toLowerCase()));
    }

    public displayVoucherWith(voucher: BcmVoucher): string {
        if (!voucher?.id) {
            return '';
        } else {
            return voucher.title;
        }
    }

    onClickRemoveVoucher(event: MouseEvent): void {
        if (this.index !== null) {
            const interval = setInterval(() => {
                if (this.voucherSelector.panelOpen) {
                    this.voucherSelector.closePanel();
                    clearInterval(interval);
                }
            }, 10);
            this.voucherRemoved.next(this.index);
        } else {
            this.voucherFormGroup.get('voucher').setValue(null);
            this.voucherFormGroup.markAsDirty();
        }
    }

    optionSelected(event: MatAutocompleteSelectedEvent): void {
        if (event.option.value?.id) {
            this.voucherSelected.next(event.option.value);
            this.voucherUpdated.next({index: this.index, voucher: this.voucherFormGroup.get('voucher').value});
            if (this.clearAfterSelection) {
                this.voucherFormGroup.get('voucher').reset({emitEvent: false});
            }
            this._loadVouchers();
        }
    }

}
