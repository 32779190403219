export interface BcmOrderWorkPackageTodoDto {
    id: number;
    bcm_order_work_packages_id: number;
    todo: string;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
}

export class BcmOrderWorkPackageTodo {
    id: number;
    bcm_order_work_packages_id: number;
    todo: string;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;

    constructor(bcmOrderWorkPackageTodoDto: BcmOrderWorkPackageTodoDto = {} as BcmOrderWorkPackageTodoDto) {
        this.id = bcmOrderWorkPackageTodoDto.id;
        this.bcm_order_work_packages_id = bcmOrderWorkPackageTodoDto.bcm_order_work_packages_id;
        this.todo = bcmOrderWorkPackageTodoDto.todo;
        this.insertedOn = bcmOrderWorkPackageTodoDto.insertedOn;
        this.insertedBy = bcmOrderWorkPackageTodoDto.insertedBy;
        this.lastUpdateOn = bcmOrderWorkPackageTodoDto.lastUpdateOn;
        this.lastUpdateBy = bcmOrderWorkPackageTodoDto.lastUpdateBy;
    }
}

