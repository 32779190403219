import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'blog',
        title: 'Blog',
        type: 'item',
        url: 'blog'
    },
    {
        id: 'shop',
        title: 'Shop',
        type: 'item',
        url: 'shop'
    },
    {
        id: 'login',
        title: 'Anmelden',
        type: 'item',
        url: 'login'
    },
];
