import { Injectable } from '@angular/core';
import { Tenant } from '@shared/models/tenant';
import { SignAvvComponent } from '@modules/bcm/@work-flows/sign-avv/sign-avv.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NewBcmComponent } from '@modules/bcm/@work-flows/new-bcm/new-bcm.component';
import { ReadPrivacyAndConditionsComponent } from '@modules/bcm/@work-flows/read-privacy-and-conditions/read-privacy-and-conditions.component';
import { combineLatest, Observable } from 'rxjs';
import { WordPressService } from '@modules/bcm/@shared/services/word-press.service';
import { UserService } from '@core/services/user.service';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { GetProductDialogComponent } from '@sharedComponents/dialogs/get-product-dialog/get-product-dialog.component';
import { Product } from '@shared/models/product';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { BcmSettingsFacade } from '@bcmServices/settings/bcm-settings-facade';
import { Company } from '@shared/models/company';
import { Person } from '@shared/models/person';
import { ContractCreatorComponent } from '@bcm-work-flows/contract-creator/contract-creator.component';
import { Boat } from '@shared/models/boat';
import { BerthReservationComponent } from '@bcm-work-flows/berth-reservation/berth-reservation.component';
import { Berth } from '@shared/models/berth';
import { BookingType } from '@modules/bcm/@shared/enums/berth-reservation-type';
import { BcmWorkflowApiService } from '@modules/bcm/@shared/services';
import { BerthBoatAssignment, NewBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { AssignUserComponent } from '@bcm-work-flows/docksite-order/assign-user/assign-user.component';
import { BcmOrder } from '@shared/models/bcm-order';
import { InformationDialogService } from '@shared/components/dialogs/information-dialog/information-dialog.service';
import { DocksiteOrderComponent } from '@bcm-work-flows/docksite-order/docksite-order.component';
import { BcmUser } from '../bcm-user';
import { NewCustomerComponent } from '@bcm-work-flows/new-customer/new-customer.component';
import { TranslationService } from '@core/translation/translation.service';

const WORD_PRESS_PAGE_ID_CONDITIONS = 260;
const WORD_PRESS_PAGE_ID_DATA_PROTECTION = 127;

@Injectable({providedIn: 'root'})
export class WorkFlowService {

    constructor(private _matDialog: MatDialog,
                private _userService: UserService,
                private _confirmDialogService: ConfirmDialogService,
                private _appNotificationService: AppNotificationService,
                private _wordPressService: WordPressService,
                private _informationDialogService: InformationDialogService,
                private _bcmWorkflowApiService: BcmWorkflowApiService,
                private bcmSettingsFacade: BcmSettingsFacade) {
    }

    newBerthReservation(berth?: Berth, berthAssignmentType?: BookingType): Observable<NewBerthBoatAssignment | undefined> {
        const matDialogRef = this._matDialog.open(
            BerthReservationComponent,
            {data: {berth, berthAssignmentType}}
        );

        return matDialogRef.afterClosed();
    }

    newDocksiteOrder(): Observable<boolean> {
        const matDialogRef = this._matDialog.open(DocksiteOrderComponent, {
            data: {

            }
        });

        return matDialogRef.afterClosed();
    }

    assignUserToOrder(order: BcmOrder, users: BcmUser[]): Observable<BcmOrder> {
        const matDialogRef = this._matDialog.open(AssignUserComponent, {
            data: {
                order: order,
                users: users
            }
        });

        return matDialogRef.afterClosed();
    }

    cancelBerthReservation(assignment: BerthBoatAssignment, doneFn: () => void): void {
        const title = assignment.type === BookingType.Reservation
            ? 'Reservierung'
            : 'Buchung';
        this._confirmDialogService
            .useWarnTheme()
            .setBody(
                `Möchtest Du die ${title} wirklich stornieren?`
            )
            .appendInputToBody({
                name: 'cancelInvoice',
                type: 'checkbox',
                label: TranslationService.translate('cancelInvoiceIfExists'),
                defaultValue: true
            })
            .appendInputToBody({
                name: 'removeInvoicePositions',
                type: 'checkbox',
                label: TranslationService.translate('deletePositionsIfExists'),
                defaultValue: true
            })
            .openAndReturnResult<{ cancelInvoice: boolean, removeInvoicePositions: boolean }>()
            .subscribe(result => {
                if (!result) {
                    return;
                }

                this._bcmWorkflowApiService
                    .berthReservationCancellation({assignment, cancelInvoice: result.cancelInvoice, removeInvoicePositions: result.removeInvoicePositions})
                    .subscribe((response: {result: boolean, errors: Error[]}) => {
                        this._appNotificationService.showSuccess('Reservierung storniert.');

                        if (response.errors?.length) {
                            this._informationDialogService
                                .setBody(
                                    '<p>Die Stornierung war teilweise erfolgreich.</p>' +
                                    '<p>Folgende Probleme sind aufgetreten:</p>' +
                                    response.errors.map(error => `<p>${error.message}</p>`)
                                );
                        }

                        if (typeof doneFn === 'function') {
                            doneFn();
                        }
                    });
            });
    }

    signAVV(): Promise<any> {
        const confirmDialogRef = this._matDialog.open(SignAvvComponent);

        return new Promise((resolve, reject) => {
            confirmDialogRef
                .afterClosed()
                .subscribe(resolve, reject);
        });
    }

    privacyAndConditions(): void {
        combineLatest([
            this._wordPressService.getPage(WORD_PRESS_PAGE_ID_CONDITIONS),
            this._wordPressService.getPage(WORD_PRESS_PAGE_ID_DATA_PROTECTION)
        ]).subscribe(([privacyPage, conditionsPage]) => {

            if (!this._userService.user) {
                return;
            }

            const {privacyAndConditions} = this._userService.user;

            if (!privacyAndConditions?.readPrivacyOn
                || !privacyAndConditions?.readConditionsOn
                || privacyPage.modified > privacyAndConditions.readPrivacyOn
                || conditionsPage.modified > privacyAndConditions.readConditionsOn) {
                const matDialogRef = this._matDialog.open(ReadPrivacyAndConditionsComponent);
                matDialogRef.componentInstance.privacyPage = privacyPage;
                matDialogRef.componentInstance.conditionsPage = conditionsPage;
            }
        });
    }

    newBcm(): Promise<Tenant> {
        const confirmDialogRef = this._matDialog.open(NewBcmComponent, {disableClose: true});

        return new Promise((resolve, reject) => {
            confirmDialogRef
                .afterClosed()
                .subscribe(resolve, reject);
        });
    }

    newCustomer(): Observable<Tenant> {
        return this._matDialog.open(NewCustomerComponent, {disableClose: true}).afterClosed();
    }

    async checkElectricMeterProduct(isUpdate = false): Promise<any> {
        const {user} = this._userService;
        const settings = await this.bcmSettingsFacade
            .loadSettings()
            .toPromise();

        const productID = settings[BcmSettingsSectionName.ElectricMeter]?.productID;

        if (!isUpdate && productID != null && productID > 0) {
            return;
        }

        return new Promise<boolean>((resolve) => {
            this._confirmDialogService
                .setDialogConfig({
                    disableClose: true
                })
                .setTheme('accent')
                .setTitle('Stromgebühr festlegen')
                .setNoButton({text: 'Jetzt nicht...'})
                .setYesButton({text: 'Stromgebühr festlegen!'})
                .setBody(
                    `<h2>Hallo ${user.firstName},</h2>
                    <p>
                        für das Modul Stromzähler muss eine Stromgebühr hinterlegt werden, welche für die Berechnung der Stromkosten bei
                        Ablesungen der Stromzähler verwendet wird.
                    </p>
                    <p>
                        Bitte wähle ein Produkt der Warengruppe <strong>Stromgebühr</strong> aus der Liste und speichere diese
                        Einstellung.
                    </p>`
                )
                .openWithCallback(async () => {
                    const dialogRef = this._matDialog.open(GetProductDialogComponent, {
                        data: {
                            categories: ['Stromgebühr'],
                            showQuantityField: false
                        }
                    });
                    const {product}: { product: Product, quantity: number } = await dialogRef.afterClosed().toPromise();

                    if (product instanceof Product) {
                        this.bcmSettingsFacade
                            .updateSection(BcmSettingsSectionName.ElectricMeter, {
                                productID: product.id
                            })
                            .add(() => resolve(true));
                    }

                    resolve(false);
                }, () => resolve(isUpdate));
        });
    }

    createContractForPerson(person: Person, boat?: Boat): Observable<any> {
        return this._matDialog.open(ContractCreatorComponent, {data: {person, boat}}).afterClosed();
    }

    createContractForCompany(company: Company, boat?: Boat): Observable<any> {
        return this._matDialog.open(ContractCreatorComponent, {data: {company, boat}}).afterClosed();
    }
}
