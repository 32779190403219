import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '@core/translation/translation.service';
import { HashMap } from '@core/translation/translation-models';

@Pipe({
    name: 'translate'
})
export class TranslationPipe implements PipeTransform {
    transform(key: string, params?: HashMap,  fallback?: string): string {
        return TranslationService.translate(key, params, fallback);
    }
}
