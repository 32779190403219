<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Auftragsverarbeitungsvertrag (AVV)</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content fusePerfectScrollbar>

        <h2>Warum ein AVV?</h2>

        <p>
            Ein Auftragsverarbeitungsvertrag (AVV) wird nötig, wenn personenbezogene Daten im Auftrag an Dritte
            weitergegeben und von ihnen verarbeitet oder genutzt werden. Die rechtlichen Vorschriften zur
            Datenweitergabe sind in der EU-Datenschutz-Grundverordnung (DSGVO) festgelegt.
        </p>

        <p>
            Gesetzlich geregelt war die Auftragsdatenverarbeitung vor der DSGVO in § 11 BDSG (Bundesdatenschutzgesetz).
            Nun finden sich die entsprechenden Regelungen in Art. 28 DSGVO.
        </p>

        <mat-divider class="my-12"></mat-divider>

        <form-widget-avv [parentFormGroup]="formGroup"></form-widget-avv>
    </div>

    <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

        <ng-template #saving>
            <mat-icon>save</mat-icon>
            <span>Speichert...</span>
            <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
        </ng-template>

        <ng-template #notSaving>
            <mat-icon>save</mat-icon>
            <span>AVV bestätigen</span>
        </ng-template>

        <button data-test="B-Jetzt nicht..." type="button" mat-flat-button (click)="onClickClose()" [disabled]="isSaving">
            Jetzt nicht...
        </button>

        <button data-test="B-AVV bestätigen" type="button" mat-flat-button color="accent" [disabled]="isSaving" (click)="onClickSaveAndClose()">
            <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
        </button>

    </div>
</div>
