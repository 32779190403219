import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';

@Component({
    selector: 'form-widget-conditions-and-privacy',
    templateUrl: './form-widget-conditions-and-privacy.component.html'
})
export class FormWidgetConditionsAndPrivacyComponent implements OnInit, OnDestroy {

    @Input()
    parentFormGroup: UntypedFormGroup;

    conditionsAndPrivacyFormGroup: UntypedFormGroup;

    constructor(private _formBuilder: UntypedFormBuilder) {
        this.conditionsAndPrivacyFormGroup = this._formBuilder.group({
            conditions: [false, [U2bValidators.requiredTrue('Bitte akzeptiere unsere AGB.')]],
            privacy: [false, [U2bValidators.requiredTrue('Bitte akzeptiere unsere Datenschutzerklärung.')]],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('conditionsAndPrivacy', this.conditionsAndPrivacyFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('conditionsAndPrivacy');
    }
}
