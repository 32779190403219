<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Auftrag Nutzer zuweisen</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="onClickClose()"
                    aria-label="Dialog schließen" type="button">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content>
        <div fxFlex="100">

            <form [formGroup]="form">
                <mat-form-field appearance="fill" fxFlex="100">
                    <mat-label>Benutzer</mat-label>
                    <mat-select data-test="FS-Benutzer wählen"
                                placeholder="Bitte einen User wählen"
                                formControlName="user">
                        <mat-option *ngFor="let user of this.data.users" [value]="user">
                            {{user.user?.firstName}} {{ user.user?.lastName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

        </div>
    </div>

    <div mat-dialog-actions class="m-0 p-8 p-sm-16" fxLayout="row" fxLayoutAlign="space-between center">

        <button data-test="B-Abbrechen" mat-flat-button (click)="onClickClose()" type="button">
            Abbrechen
        </button>

        <div>
            <ng-template #saving>
                <mat-icon>save</mat-icon>
                <span>Speichert...</span>
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-template>

            <ng-template #notSaving>
                <mat-icon>save</mat-icon>
                <span>Speichern</span>
            </ng-template>

            <button type="submit" data-test="B-Speichern" mat-flat-button color="accent" [disabled]="isSaving"
                    (click)="onSave()">
                <ng-container *ngIf="isSaving; then saving else notSaving"></ng-container>
            </button>
        </div>

    </div>
</div>
