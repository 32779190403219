import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { Contract, ContractFileType } from '@shared/models/contract';
import { downloadFile } from '@shared/functions/download-file';
import { parseHttpResponseList } from '@shared/functions/parse-http-response';

@Injectable({providedIn: 'root'})
export class BcmContractsApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<Contract[]> {
        return super.get<Contract[]>('contracts', params)
            .pipe(parseHttpResponseList<Contract, Contract>(Contract));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.get<Contract>(`contracts/${id}`, params);
    }

    create(contract: Contract): Observable<Contract> {
        return super.post('contracts', contract);
    }

    update(contract: Contract): Observable<Contract> {
        return super.put(`contracts/${contract.id}`, contract);
    }

    remove(contract: Contract): Observable<any> {
        return super.delete(`contracts/${contract.id}`);
    }

    generateTestContract(contract: Contract, contractFileType: ContractFileType = ContractFileType.WYSIWYG): void {
        super.getBlob(`contracts/${contract.id}/${ContractFileType[contractFileType]}/generate-test`)
            .subscribe((file) => downloadFile(file, contract.title + '-test-' + ContractFileType[contractFileType], 'pdf'), console.error);
    }

    getWordTemplateContract(): void {
        super.getBlob(`contracts/get-word-template-contract`)
            .subscribe((file) => downloadFile(file, 'Vertrag-Vorlage.docx', 'docx'), console.error);
    }

    getWordTemplateTable(): void {
        super.getBlob(`contracts/get-word-template-table`)
            .subscribe((file) => downloadFile(file, 'Vertrag-Vorlage-Tabllen.docx', 'docx'), console.error);
    }

}
