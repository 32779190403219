import { IPerson, Person } from './person';
import { Product } from './product';
import { tryParseDate } from '@shared/functions/try-parse-date';

export interface KeyRaw {
    id: number;
    keyName: string;
    keyType: string;
    note: string;
    product: any;
    rentingList: any[];
}

export interface KeyAssignmentRaw extends KeyRaw {
    key: KeyRaw;
    person: IPerson;
    id: number;
    keyNumber: string;
    keyFeePayed: boolean;
    keyFeePayedOn: string;
    keyFeePayedBack: boolean;
    keyFeePayedBackOn: string;
    fromDate: string;
    note: string;
}

export class Key {
    id: number;
    keyName: string;
    keyType: string;
    note: string;
    rentingList: KeyAssignment[];
    product: Product;

    constructor(key = {} as KeyRaw) {
        this.id = key.id || null;
        this.keyName = key.keyName || '';
        this.keyType = key.keyType || '';
        this.note = key.note || '';
        this.rentingList = (key.rentingList || []).map(keyAssignment => new KeyAssignment(keyAssignment));
        this.product = key.product ? new Product(key.product) : null;
    }

    // show as fullName?
    toString(): string {
        return this.keyName.trim();
    }

    valueOf(): number {
        return this.id;
    }
}

export class KeyAssignment {
    key: Key;
    person: Person;
    id: number;
    keyNumber: string;
    keyFeePayed: boolean;
    keyFeePayedOn: Date;
    keyFeePayedBack: boolean;
    keyFeePayedBackOn: Date;
    fromDate: Date;
    note: string;

    constructor(keyAssignment = {} as KeyAssignmentRaw) {
        this.id = keyAssignment.id || null;
        this.key = new Key(keyAssignment.key);
        this.person = keyAssignment.person ? new Person(keyAssignment.person) : null;
        this.keyNumber = keyAssignment.keyNumber || '';
        this.keyFeePayed = keyAssignment.keyFeePayed || false;
        this.keyFeePayedOn = tryParseDate(keyAssignment.keyFeePayedOn);
        this.keyFeePayedBack = keyAssignment.keyFeePayedBack || false;
        this.keyFeePayedBackOn = tryParseDate(keyAssignment.keyFeePayedBackOn);
        this.fromDate = tryParseDate(keyAssignment.fromDate);
        this.note = keyAssignment.note || '';
    }

    patch(keyAssignment = {} as KeyAssignment | KeyAssignmentRaw): void {
        Object
            .keys(keyAssignment)
            .forEach(key => {
                if (this.hasOwnProperty(key)) {
                    this[key] = keyAssignment[key];
                }
            });
    }

    // show as fullName?
    toString(): string {
        return this.keyNumber.trim();
    }

    valueOf(): number {
        return this.id;
    }

}
