import { BcmFile } from './bcm-file';
import { TenantRelation } from '@shared/models/tenant-relation';
import { tryParseDate } from '@shared/functions/try-parse-date';


export interface IContract {
    id: number;
    title: string;
    content?: string;
    note: string;
    category: ContractCategory;
    tenantRelations?: TenantRelation[];
    attachments: ContractAttachment[];

    categoryName: string;
    creatorName: string;

    templateMailFile?: BcmFile;
    templatePrintFile?: BcmFile;

    hasDifferentTemplates: boolean;
    isWordTemplate: boolean;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    creator: { // ToDo: create interface
        firstName: string;
        lastName: string;
        [key: string]: any
    };
}

export class Contract implements IContract {
    id: number;
    title: string;
    content?: string;
    note: string;
    category: ContractCategory;
    tenantRelations?: TenantRelation[];
    attachments: ContractAttachment[];

    categoryName: string;
    creatorName: string;

    templateMailFile?: BcmFile;
    templatePrintFile?: BcmFile;

    hasDifferentTemplates: boolean;
    isWordTemplate: boolean;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    creator: { // ToDo: create interface
        firstName: string;
        lastName: string;
        [key: string]: any
    };

    constructor(contract) {
        if (!contract) {
            return;
        }
        this.id = contract.id;
        this.title = contract.title;
        this.content = contract.content;
        this.note = contract.note;
        this.category = new ContractCategory(contract.category);
        this.tenantRelations = (contract.tenantRelations || []).map(tenantRelation => new TenantRelation(tenantRelation));
        this.attachments = (contract.attachments || []).map(contractAttachment => new ContractAttachment(contractAttachment));
        this.templateMailFile = (contract.templateMailFile) ? new BcmFile(contract.templateMailFile) : null;
        this.templatePrintFile = (contract.templatePrintFile) ? new BcmFile(contract.templatePrintFile) : null;
        this.hasDifferentTemplates = contract.hasDifferentTemplates;
        this.isWordTemplate = contract.isWordTemplate;

        this.categoryName = this.category?.name || '';
        this.creator = contract.creator;
        this.creatorName = `${this.creator?.firstName} ${this.creator?.lastName}` || '';

        this.insertedOn = tryParseDate(contract.insertedOn);
        this.insertedBy = contract.insertedBy;
        this.lastUpdateOn = tryParseDate(contract.lastUpdateOn);
        this.lastUpdateBy = contract.lastUpdateBy;

    }
}

export class ContractAttachment {
    id?: number;
    title: string;
    category: string;
    file: BcmFile;
    contracts?: Contract[];
    selected?: boolean;

    mimeType: string;
    note: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(contractAttachment) {
        if (!contractAttachment) {
            return;
        }
        this.id = contractAttachment.id;
        this.title = contractAttachment.file?.name;
        this.mimeType = contractAttachment.file?.mimeType;
        this.note = contractAttachment.file?.note;
        this.category = contractAttachment.file?.categoryName;
        this.file = contractAttachment.file;
        this.contracts = (contractAttachment.contracts || []).map(contract => new Contract(contract));
        this.selected = contractAttachment.selected;

        this.insertedOn = tryParseDate(contractAttachment.insertedOn);
        this.insertedBy = contractAttachment.insertedBy;
        this.lastUpdateOn = tryParseDate(contractAttachment.lastUpdateOn);
        this.lastUpdateBy = contractAttachment.lastUpdateBy;

    }
}

export class ContractCategory {
    id?: number;
    uniqueId: string;
    name: string;
    note: string;
    editable: boolean;

    constructor(contractCategory) {
        if (!contractCategory) {
            return;
        }
        this.id = contractCategory.id;
        this.uniqueId = contractCategory.uniqueId;
        this.name = contractCategory.name;
        this.note = contractCategory.note;
        this.editable = contractCategory.editable;
    }
}

export enum ContractFileType {
    MAIL, PRINT, ATTACHMENT, WYSIWYG
}
