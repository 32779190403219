import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { NavbarComponent } from './navbar.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { RouterLink } from '@angular/router';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        FuseSharedModule,
        FuseNavigationModule,
        NgScrollbarModule,
        RouterLink
    ],
    exports: [
        NavbarComponent
    ]
})
export class NavbarModule {
}
