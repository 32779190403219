import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { base64ToFile, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { InformationDialogService } from '@shared/components/dialogs/information-dialog/information-dialog.service';
import { FilesApiService } from '@bcmApiServices/files.api-service';
import { U2bFilesApiService } from '@core/services/api/u2b-files.api-service';
import { Up2BoatFilesApiService } from '@modules/up2boat/@shared/services/up2boat-files.api-service';

export enum ImageCropperRatios {
    Square = 1,
    // ThreeToFour = 3 / 4,
    // WideScreen = 16 / 9
}

function blobToFile(theBlob, fileName, mimeType): File {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    theBlob.mimeType = mimeType;
    return theBlob;
}

@Component({
    selector: 'image-cropper-dialog',
    templateUrl: './image-cropper-dialog.component.html',
    styleUrls: ['./image-cropper-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class U2bImageCropperDialogComponent {

    filesApiService: U2bFilesApiService | Up2BoatFilesApiService | FilesApiService;

    imageChangedEvent: any = null;
    croppedImageFile: any = null;
    file: File;
    fileData: File;
    showCropper = false;
    loadingFile = false;
    isSaving = false;

    maintainAspectRatio = false;
    aspectRatio = ImageCropperRatios.Square;
    resizeToWidth: 1024;
    resizeToHeight: 1024;
    cropperMinWidth = 128;
    cropperMinHeight = 128;

    wasManipulated = false;
    transform: ImageTransform = {};
    canvasRotation = 0;
    rotation = 0;
    scale = 1;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private _data: any,
        public matDialogRef: MatDialogRef<U2bImageCropperDialogComponent>,
        private _informationDialogService: InformationDialogService,
        private _filesApiService: FilesApiService,
    ) {
        this.matDialogRef.addPanelClass('image-cropper-dialog');

        if (!_data.filesApiService) {
            this.filesApiService = _filesApiService;
        }

        if (this._data) {
            const {
                maintainAspectRatio = false,
                aspectRatio,
                resizeToWidth = 1024,
                resizeToHeight = 1024,
                cropperMinWidth,
                image
            } = this._data;
            this.maintainAspectRatio = maintainAspectRatio;
            this.aspectRatio = aspectRatio;
            this.resizeToWidth = resizeToWidth;
            this.resizeToHeight = resizeToHeight;
            this.cropperMinWidth = cropperMinWidth;

            if (this._data.filesApiService) {
                this._filesApiService = this._data.filesApiService;
            }

            if (image) {
                if (image?.file) {
                    this.fileData = image.file;
                    this.imageChangedEvent = {target: {files: [image.file]}};
                } else if (image.id) {
                    this.loadingFile = true;
                    this._filesApiService.getOne(image)
                        .subscribe((file) => {
                            this.fileData = file;
                            this.imageChangedEvent = {target: {files: [file]}};
                        });

                }
            }
        }
    }

    dropped(ngxFileDropEntries: NgxFileDropEntry[]): void {
        this.loadingFile = true;

        for (const droppedFile of ngxFileDropEntries) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file) => {
                    this.fileData = file;
                    this.imageChangedEvent = {target: {files: [file]}};
                });
            }
        }
    }

    fileChangedEvent(event: any): void {
        this.loadingFile = true;

        if (event?.target?.files instanceof FileList) {
            this.fileData = event.target.files[0];
        }

        this.imageChangedEvent = event;
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImageFile = event.base64;
        this.file = blobToFile(base64ToFile(event.base64), this.fileData.name, this.fileData.type);
    }

    imageLoaded(): void {
        this.showCropper = true;
        this.loadingFile = false;
    }

    rotateLeft(): void {
        this.wasManipulated = true;
        this.canvasRotation--;
        this.flipAfterRotate();
    }

    rotateRight(): void {
        this.wasManipulated = true;
        this.canvasRotation++;
        this.flipAfterRotate();
    }

    flipHorizontal(): void {
        this.wasManipulated = true;
        this.transform = {
            ...this.transform,
            flipH: !this.transform.flipH
        };
    }

    flipVertical(): void {
        this.wasManipulated = true;
        this.transform = {
            ...this.transform,
            flipV: !this.transform.flipV
        };
    }

    zoomOut(): void {
        this.wasManipulated = true;
        this.scale -= .1;
        this.transform = {
            ...this.transform,
            scale: this.scale < 1 ? 1 : this.scale
        };
    }

    zoomIn(): void {
        this.wasManipulated = true;
        this.scale += .1;
        this.transform = {
            ...this.transform,
            scale: this.scale
        };
    }

    resetImage(): void {
        this.scale = 1;
        this.rotation = 0;
        this.canvasRotation = 0;
        this.transform = {};
        this.wasManipulated = false;
    }

    loadingImageFailed(): void {
        this.loadingFile = false;

        this._informationDialogService
            .setTheme('warn')
            .setTitle('Falsche Bilddatei')
            .setBody('Es werden nur Bilddateien mit den Dateitypen png, gif oder jpg unterstützt.')
            .open();
    }

    private flipAfterRotate(): void {
        const flippedH = this.transform.flipH;
        const flippedV = this.transform.flipV;
        this.transform = {
            ...this.transform,
            flipH: flippedV,
            flipV: flippedH
        };
    }
}
