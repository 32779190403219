import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Season } from '@shared/models/seasons';
import { SeasonApiService } from '@bcmApiServices/seasons.api-service';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';

@Component({
    selector: 'form-widget-season',
    templateUrl: './form-widget-season.component.html'
})
export class FormWidgetSeasonComponent implements OnInit, OnDestroy {

    @Input()
    headline = 'Saison';

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    set selectSeason(value: Season) {
        if (value?.id > 0 && value?.id !== this._selectSeason?.id) {
            this._selectSeason = value;
            this.seasonFormGroup?.get('season')?.setValue(this._selectSeason);
        }
    }

    get selectSeason(): Season {
        return this._selectSeason;
    }

    private _selectSeason: Season;

    @Input()
    seasonRequired = true;

    @Input()
    slimmedView = false;

    @Output()
    seasonChangedManually: EventEmitter<void> = new EventEmitter<void>();

    seasonFormGroup: UntypedFormGroup;

    seasons: Season[];

    season: Season;

    loading: boolean;

    topList: Season[] = [];

    bottomList: Season[] = [];

    filteredTopList$: Observable<Season[]>;

    filteredBottomList$: Observable<Season[]>;

    private _unsubscribeAll = new Subject();

    constructor(private _formBuilder: UntypedFormBuilder,
                private _matDialog: MatDialog,
                private _seasonsApiService: SeasonApiService) {
    }

    ngOnInit(): void {
        this.season = this.selectSeason;

        this._createForm();
        this._loadSeasons();

        setTimeout(() => {
            this.parentFormGroup.addControl('seasonForm', this.seasonFormGroup);
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _createForm(): void {
        this.seasonFormGroup = this._formBuilder.group({
            season: [this.selectSeason, this.seasonRequired ? [U2bValidators.required('Bitte Saison angeben')] : []],
        });

        this.seasonFormGroup
            .get('season')
            .valueChanges
            .subscribe((value: Season) => {
                this.season = value;
            });
    }

    private _loadSeasons(): void {

        this.loading = true;

        this._seasonsApiService
            .getAllSeasons()
            .subscribe((seasons) => {
                const todayDate = new Date();
                const today = todayDate.getTime();
                const todayOneYear = new Date().setFullYear(todayDate.getFullYear() + 1);
                for (const season of seasons) {
                    if ((season.startDate.getTime() < today && season.endDate.getTime() > today) ||
                        (season.startDate.getTime() < todayOneYear && season.endDate.getTime() > todayOneYear)) {
                        this.topList.push(season);
                    } else {
                        this.bottomList.push(season);
                    }
                }
            })
            .add(() => this.loading = false);

        this.filteredTopList$ = this.seasonFormGroup.get('season').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterSeasons(value, this.topList) : this.topList)
            );

        this.filteredBottomList$ = this.seasonFormGroup.get('season').valueChanges
            .pipe(
                takeUntil(this._unsubscribeAll),
                startWith(''),
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                map((value) => isString(value) ? this._filterSeasons(value, this.bottomList) : this.bottomList)
            );
    }

    private _filterSeasons(filter: string, seasons: Season[]): Season[] {
        return seasons.filter(season => season.name.toLowerCase().includes(filter.toLowerCase()));
    }

    public displaySeasonWith(season: Season): string {
        if (!season?.id) {
            return '';
        } else {
            return season.name;
        }
    }

    onClickRemoveSeason(): void {
        this.seasonFormGroup.get('season').setValue(null);
        this.seasonFormGroup.markAsDirty();
    }
}
