import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { removeErrorFromControls } from '@core/functions/remove-error-from-controls';

export class U2BDateBeforeOtherDateValidator extends BaseValidator {

    public validationError: BaseValidationError = new BaseValidationError('dateBeforeOtherDate');

    constructor(private controlNameDate: string,
                private controlNameOtherDate: string,
                private controlLabelDate: string,
                private controlLabelOtherDate: string) {
        super();
    }

    public validate(formGroup: UntypedFormGroup): ValidationErrors | null {

        const dateControl = formGroup.get(this.controlNameDate);
        const otherDateControl = formGroup.get(this.controlNameOtherDate);

        const dateValue: Date = dateControl.value;
        const otherDateValue: Date = otherDateControl.value;

        if (!dateValue || !otherDateValue) {
            return null;
        }

        if (dateValue > otherDateValue) {
            dateControl.setErrors({
                wrongDateRange: {
                    message: `Das "${this.controlLabelDate}" Datum darf nicht hinter dem "${this.controlLabelOtherDate}" Datum liegen`
                }
            });
            otherDateControl.setErrors({
                wrongDateRange: {
                    message: `Das "${this.controlLabelOtherDate}" Datum darf nicht vor dem "${this.controlLabelDate}" Datum liegen`
                }
            });
        } else {
            removeErrorFromControls('wrongDateRange', [
                dateControl,
                otherDateControl,
            ]);
        }

        return this.IS_VALID;
    }
}
