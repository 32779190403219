import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Key } from '@shared/models/key';

@Component({
    selector: 'bcm-key-link',
    templateUrl: './key-link.component.html'
})
export class KeyLinkComponent implements OnChanges {

    @Input()
    public key!: Key;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(): void {
        const key = this.key;
        this.viewData = [
            (key.keyName || null),
            (key.keyType || null),
            this.additionalInfo,
        ].filter(item => item != null)
            .join(' - ');
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
