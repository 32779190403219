<div [formGroup]="userFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
        <mat-label>Anrede</mat-label>
        <mat-select data-test="FS-Anrede" formControlName="salutation">
            <mat-option [value]="salutation.None"></mat-option>
            <mat-option [value]="salutation.Male">Herr</mat-option>
            <mat-option [value]="salutation.Female">Frau</mat-option>
            <mat-option [value]="salutation.Divers">Divers</mat-option>
        </mat-select>
        <mat-error>{{userFormGroup.get('salutation').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <div fxFlex="50">&nbsp;</div>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
        <mat-label>Vorname</mat-label>
        <input matInput data-test="FI-Vorname" type="text" formControlName="firstName">
        <mat-error>{{userFormGroup.get('firstName').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
        <mat-label>Nachname</mat-label>
        <input matInput data-test="FI-Nachname" type="text" formControlName="lastName">
        <mat-error>{{userFormGroup.get('lastName').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>E-Mail</mat-label>
        <input matInput data-test="FI-E-Mail" type="text" formControlName="email">
        <mat-error>{{userFormGroup.get('email').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

</div>
