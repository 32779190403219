import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { BaseValidator } from '@shared/validators/base-validator';

export class IntegerFormatValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(message?: string) {
        super();

        this.validationError = new BaseValidationError('integerFormat', message || 'Nur ganze Zahlen erlaubt');
    }

    public static isInteger(nVal): boolean {
        return typeof nVal === 'number' && isFinite(nVal) && nVal > Number.MIN_SAFE_INTEGER &&
            nVal < Number.MAX_SAFE_INTEGER && Math.floor(nVal) === nVal;
    }

    public validate({value}: AbstractControl): ValidationErrors {
        return !value || !isNaN(value) || IntegerFormatValidator.isInteger(value) ?
            this.IS_VALID :
            this.IS_INVALID;
    }
}
