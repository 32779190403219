import { NgModule } from '@angular/core';
import { SignAvvModule } from '@modules/bcm/@work-flows/sign-avv/sign-avv.module';
import { ReadPrivacyAndConditionsModule } from '@modules/bcm/@work-flows/read-privacy-and-conditions/read-privacy-and-conditions.module';
import { ContractCreatorModule } from '@bcm-work-flows/contract-creator/contract-creator.module';
import { BerthReservationModule } from '@bcm-work-flows/berth-reservation/berth-reservation.module';
import { CompanyContactModule } from '@bcm-work-flows/company-contact/company-contact.module';
import { PersonCompanyRelationModule } from '@bcm-work-flows/person-company-relation/person-company-relation.module';
import { WinterStorageAssignmentInvoicePositionsModule } from '@bcm-work-flows/winter-storage-assignment-invoice-positions/winter-storage-assignment-invoice-positions.module';
import { DocksiteOrderModule } from '@bcm-work-flows/docksite-order/docksite-order.module';
import { AssignUserModule } from './docksite-order/assign-user/assign-user.module';
import { ProductDynamicPriceModule } from '@bcm-work-flows/product-dynamic-price/product-dynamic-price.module';
import { SelectBoatByPersonOrCompanyModule } from '@bcm-work-flows/select-boat-by-person-or-company/select-boat-by-person-or-company.module';
import { ProductVoucherModule } from '@bcm-work-flows/product-voucher/product-voucher.module';

@NgModule({
    imports: [
        BerthReservationModule,
        DocksiteOrderModule,
        AssignUserModule,
        ContractCreatorModule,
        SignAvvModule,
        ReadPrivacyAndConditionsModule,
        CompanyContactModule,
        PersonCompanyRelationModule,
        WinterStorageAssignmentInvoicePositionsModule,
        ProductDynamicPriceModule,
        ProductVoucherModule,
        SelectBoatByPersonOrCompanyModule
    ]
})
export class WorkFlowModule {

}
