<div [formGroup]="contractFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="headline">
        <div fxFlex="100">
            <h2 [innerHTML]="headline"></h2>
        </div>
    </ng-container>

    <ng-template #noContracts>
        <u2b-message type="warning" customClass="mt-16 mb-0">
            <header>Achtung</header>
            Es wurden keine Vertragsvorlagen im System gefunden. Diese Funktion steht nur zur Verfügung, wenn in der
            Navigation unter <strong>Vertragsmanager</strong> Vertragsvorlagen angelegt wurden und diese
            Vertragsvorlagen der ausgewählten Beziehung {{ selectedTenantRelation?.name }} zugewiesen wurden.
        </u2b-message>
    </ng-template>

    <ng-container *ngIf="contracts?.length; else noContracts">

        <div fxFlex="100">
            <u2b-message [collapsable]="true">
                <header>Info zum Vertragsmanager</header>
                <p>
                    Du kannst in der Navigation unter <strong>Vertragsmanager</strong> Vertragsvorlagen anlegen und hier
                    auswählen. Der Vertrag wird dann, wenn entsprechend angelegt, mit den in diesem Formular erfassten
                    Daten befüllt und z.B. per Mail versendet.
                </p>
                <p>
                    <strong>Wichtig:</strong><br>
                    Es sind an dieser Stelle nur Vertragsvorlagen mit der Warengruppe "Liegeplatzvertrag" auswählbar,
                    die zusätzlich mit der ausgewählten Beziehung übereinstimmen.
                </p>
            </u2b-message>
        </div>

        <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
            <mat-label>Vertragsvorlage</mat-label>
            <input type="text" matInput data-test="FI-Liegeplatz" formControlName="contract"
                   autocomplete="new-password"
                   [matAutocomplete]="contractAttachmentSelector">
            <mat-error>{{ contractFormGroup.get('contract').errors | firstErrorMessage }}</mat-error>
        </mat-form-field>

        <mat-autocomplete fxFlex="0" #contractAttachmentSelector="matAutocomplete"
                          [displayWith]="getOptionText">
            <mat-option disabled class="loading" *ngIf="loadingContracts">
                <mat-spinner diameter="35"></mat-spinner>
            </mat-option>
            <ng-container *ngIf="contracts?.length">
                <mat-option *ngFor="let contract of (filteredContracts$ | async) | orderBy: 'title'"
                            [value]="contract">
                    <span>{{ contract.title }}</span>
                    <ng-container *ngIf="contract.isWordTemplate">
                        <fa-icon class="contract-word-icon" [icon]="faFileWord"></fa-icon>
                    </ng-container>
                </mat-option>
            </ng-container>
        </mat-autocomplete>

        <ng-container *ngIf="contractTemplateMissing">
            <u2b-message type="error">Aktuell ist bei dieser Vorlage kein Word Dokument hinterlegt, bitte
                lade ein aktuelles Dokument in den Vertragsvorlagen hoch.
            </u2b-message>
        </ng-container>

        <mat-checkbox fxFlex="100" data-test="FCB-Per E-Mail versenden" formControlName="sendViaMail"
                      [disabled]="contractTemplateMissing">
            Per E-Mail versenden
        </mat-checkbox>

        <ng-container *ngIf="contractFormGroup?.get('sendViaMail').value">
            <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                <mat-label>E-Mail</mat-label>
                <input matInput data-test="FI-E-Mail" type="text" formControlName="mail"
                       autocomplete="new-password" [disabled]="contractTemplateMissing">
                <mat-error>{{ contractFormGroup.get('mail').errors | firstErrorMessage }}</mat-error>
            </mat-form-field>
        </ng-container>

        <!--            <p>-->
        <!--                <mat-checkbox formControlName="downloadPdf"-->
        <!--                              [disabled]="contractTemplateMissing">-->
        <!--                    Vertrag nach dem Speichern herunterladen (PDF)-->
        <!--                </mat-checkbox>-->
        <!--            </p>-->

        <ng-container
                *ngIf="selectedContract?.isWordTemplate && selectedContract.hasDifferentTemplates && !contractFormGroup.get('sendViaMail').value">
            <mat-radio-group class="contract-file-type-rg" fxFlex="100" fxLayout="row"
                             formControlName="contractFileType">
                <mat-radio-button style="margin-right: 15px" [disabled]="!selectedContract?.templateMailFile"
                                  [value]="ContractFileType.MAIL">
                    E-Mail Vorlage
                </mat-radio-button>
                <mat-radio-button [disabled]="!selectedContract?.templatePrintFile"
                                  [value]="ContractFileType.PRINT">
                    Druck Vorlage
                </mat-radio-button>
            </mat-radio-group>
        </ng-container>

        <div fxFlex="100">
            <form-widget-contract-co-owner [disabled]="disabled"
                                           [appearance]="appearance"
                                           [parentFormGroup]="parentFormGroup"
                                           [headline]="null"></form-widget-contract-co-owner>
        </div>

    </ng-container>
</div>
