import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, LocationStrategy } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { BcmService } from '@modules/bcm/bcm.service';
import { NavigationEnd, Router } from '@angular/router';
import { Tenant } from '@shared/models/tenant';
import { environment } from '@env/environment';
import { VersionCheckService } from '@modules/bcm/@shared/services/version-check.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { WorkFlowService } from '@modules/bcm/@work-flows/work-flow.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { NavigationService } from './navigation/navigation.service';
import { NavigationSlugs } from './navigation/navigation-slugs';
import { DataSessionStorage } from '@shared/data-session-storage';
import { AuthService } from './auth/auth.service';
import { U2bUserApiService } from '@core/services';
import { UserService } from '@core/services/user.service';
import { InformationDialogService } from '@sharedComponents/dialogs/information-dialog/information-dialog.service';
import { NavigationHistoryService } from '@core/services/navigation-history.service';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    isPopState = false;
    fuseConfig: any;

    @HostBinding('class.has-environment-banner')
    public showTestEnvironmentBanner = environment.showTestEnvironmentBanner;

    @HostBinding('class.has-environment-banner')
    public showDemoEnvironmentBanner;

    public environmentBannerText = environment.environmentBannerText || undefined;

    // Private
    private _unsubscribeAll = new Subject<any>();

    constructor(
        @Inject(DOCUMENT) private document: any,
        private _fuseConfigService: FuseConfigService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _platform: Platform,
        private _bcmService: BcmService,
        private _router: Router,
        private _locationStrategy: LocationStrategy,
        private _appNotificationService: AppNotificationService,
        private _versionCheckService: VersionCheckService,
        private _workFlowService: WorkFlowService,
        private navigationService: NavigationService,
        private userApiService: U2bUserApiService,
        private informationDialogService: InformationDialogService,
        private userService: UserService,
        private authService: AuthService,
        private navigationHistoryService: NavigationHistoryService,
    ) {
        this._fuseSplashScreenService.init();

        this.navigationHistoryService.init();

        this.authService
            .isLoggedIn$
            .subscribe(isLoggedIn => {
                if (isLoggedIn) {
                    this.navigationService.changeNavigationTo(NavigationSlugs.MainLoggedIn);
                    this.userApiService
                        .getCurrent()
                        .subscribe(appUser => {
                            this.userService.user = appUser;
                            this._workFlowService.privacyAndConditions();
                        });
                } else {
                    this.userService.reset();
                }
            });

        this._bcmService
            .selectedTenant$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((tenant) => {
                this._updateInfoBanner(tenant);

                if (tenant?.bankAccounts?.length === 0) {
                    this.informationDialogService
                        .useWarnTheme()
                        .setTitle('Keine Bankdaten')
                        .setBody('Es wurden noch keine Bankdaten hinterlegt. Bitte hinterlege die Bankdaten deines Hafens.')
                        .open();
                }
            });

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }

        window.addEventListener('popstate', () => {
            if (location.href.endsWith('/bcm/tenants')) {
                location.reload();
            }
        });
    }

    ngOnInit(): void {

        this._versionCheckService.initVersionCheck();

        this._updateInfoBanner();

        document.addEventListener(
            'drop',
            (e: DragEvent) => this._catchFileDrop(e, true),
            false
        );

        this._locationStrategy.onPopState(() => {
            this.isPopState = true;
        });

        this._router.events
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((event) => {
                // Scroll to top if accessing a page, not via browser history stack
                if (event instanceof NavigationEnd && !this.isPopState) {
                    window.scrollTo(0, 0);
                    this.isPopState = false;
                }

                // Ensures that isPopState is reset
                if (event instanceof NavigationEnd) {
                    this.isPopState = false;
                }
            });

        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if (this.fuseConfig.layout.width === 'boxed') {
                    this.document.body.classList.add('boxed');
                } else {
                    this.document.body.classList.remove('boxed');
                }

                for (const className of this.document.body.classList) {
                    if (className.startsWith('theme-')) {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });

        DataSessionStorage.init();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    private _catchFileDrop(event: DragEvent, showWarningMessage = false): void {

        if (['INPUT', 'TEXTAREA'].includes((event.target as HTMLInputElement).tagName)) {
            return;
        }

        event.preventDefault();

        if (showWarningMessage) {
            this._appNotificationService.showWarning('Text oder Daten können hier nicht abgelegt bzw. hereingezogen werden.');
        }
    }

    private _updateInfoBanner(tenant?: Tenant): void {
        this.showDemoEnvironmentBanner = tenant && tenant.isDemo;
        this.document.body.classList.remove('has-environment-banner');

        if (this.showDemoEnvironmentBanner || this.showTestEnvironmentBanner || !!environment.environmentBannerText) {
            this.document.body.classList.add('has-environment-banner');
        }
    }
}
