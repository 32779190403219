import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { U2bAdminApi } from '@modules/admin/_api/u2b-admin.api';
import { BaseEntity } from '@modules/admin/state/u2b-admin-base.state';
import { ResourceConfig } from '@modules/admin/state/u2b-admin-base.facade';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';

export class U2bAdminGenericApi<EntityType extends BaseEntity, EntityRawType> extends U2bAdminApi {

    constructor(_httpClient: HttpClient,
                protected confirmDialogService: ConfirmDialogService,
                protected resourceConfig: ResourceConfig<EntityType, EntityRawType>) {
        super(_httpClient);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<EntityType[]> {
        return super.get<EntityRawType[]>(this.resourceConfig.endpointPath, params)
            .pipe(parseHttpResponseList<EntityRawType, EntityType>(this.resourceConfig.entityClass));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<EntityType> {
        return super.get<EntityRawType>(`${this.resourceConfig.endpointPath}/${id}`, params)
            .pipe(parseHttpResponseItem<EntityRawType, EntityType>(this.resourceConfig.entityClass));
    }

    add(body: Partial<EntityType> = {}): Observable<EntityType> {
        return super.post<EntityRawType>(this.resourceConfig.endpointPath, body)
            .pipe(parseHttpResponseItem<EntityRawType, EntityType>(this.resourceConfig.entityClass));
    }

    update(entity: EntityType, data: Partial<EntityType>): Observable<EntityType> {
        return super.put<EntityRawType>(`${this.resourceConfig.endpointPath}/${entity.id}`, data)
            .pipe(parseHttpResponseItem<EntityRawType, EntityType>(this.resourceConfig.entityClass));
    }

    remove(entity: EntityType): Observable<EntityType> {
        return super.delete<EntityRawType>(`${this.resourceConfig.endpointPath}/${entity.id}`)
            .pipe(parseHttpResponseItem<EntityRawType, EntityType>(this.resourceConfig.entityClass));
    }
}

