import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { IBoat, Boat } from '@shared/models/boat';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';

@Injectable({
    providedIn: 'root'
})
export class BoatApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'boats';

    getAll(params: HttpParams = new HttpParams()): Observable<Boat[]> {
        return super.get<IBoat[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IBoat, Boat>(Boat));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Boat> {
        return super.get<IBoat>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IBoat, Boat>(Boat));
    }

    add(boat: Boat): Observable<Boat> {
        return super.post<IBoat>(this.RESOURCE_ENDPOINT, boat)
            .pipe(parseHttpResponseItem<IBoat, Boat>(Boat));
    }

    update(boat: Boat, ownershipDate?: Date): Observable<Boat> {
        return super.put<IBoat>(`${this.RESOURCE_ENDPOINT}/${boat.id}`, {boat, ownershipDate})
            .pipe(parseHttpResponseItem<IBoat, Boat>(Boat));
    }

    remove(boat: Boat): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${boat.id}`);
    }

    protected getDocument(boatId: number, documentId: number): Observable<BcmDocument> {
        return super.get<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${boatId}/documents/${documentId}`)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected addDocument(boatId: number, document: BcmDocument): Observable<BcmDocument> {
        return super.post<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${boatId}/documents`, document)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected updateDocument(boatId: number, document: BcmDocument, documentData: Partial<BcmDocument>): Observable<BcmDocument> {
        return super.put<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${boatId}/documents/${document.id}`, documentData)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected deleteDocument(boatId: number, documentId: number): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${boatId}/documents/${documentId}`);
    }
    
}
