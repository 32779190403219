<div class="navbar-wrapper">
    <div class="navbar-top" [ngClass]="logoService.logoStyle$ | async">

        <a data-test="L-Startseite" [routerLink]="'/'" class="logo">
            <ng-container *ngIf="logoService.logoPath$ | async as logoPath">
                <img class="logo-icon" [src]="logoPath" alt="">
            </ng-container>
        </a>

        <div class="buttons">

            <button data-test="B-Menu" mat-icon-button class="toggle-sidebar-folded"
                    (click)="toggleSidebarFolded()" fxHide.lt-lg>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <button data-test="B-Zurück" mat-icon-button class="toggle-sidebar-opened"
                    (click)="toggleSidebarOpened()" fxHide.gt-md>
                <mat-icon class="secondary-text">arrow_back</mat-icon>
            </button>

        </div>

    </div>

    <ng-scrollbar [ngClass]="logoService.logoStyle$ | async" [autoHeightDisabled]="false" class="navbar-scroll-container">
        <div class="navbar-content">
            <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
        </div>
    </ng-scrollbar>
</div>
