import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { BcmApiService } from './bcm-api.service';
import { map } from 'rxjs/operators';
import { BcmPermission } from './bcm-permissions-api.service';
import { User } from '@core/services/api/u2b-user.api-service';

export interface Role {
    id?: number; // if no id it's a new role
    uniqueName: string;
    visibleName: string;
    nameChanged?: string;
    editable: boolean;
    note?: string;
    noteChanged?: string;

    // for saving the permissions to DB
    permissions: BcmPermission[];
    users?: User[];
}

@Injectable({
    providedIn: 'root'
})
export class RolesApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'roles';

    getAll(params: HttpParams = new HttpParams()): Observable<Role[]> {
        return super.get<Role[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(map(roles => roles.map(role => {
                role.nameChanged = role.visibleName;
                role.noteChanged = role.note;
                return role;
            })));
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Role> {
        return super.get<Role>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(map(role => {
                role.nameChanged = role.visibleName;
                role.noteChanged = role.note;
                return role;
            }));
    }

    add(role: Role): Observable<Role> {
        return super.post(this.RESOURCE_ENDPOINT, role);
    }

    update(role: Role): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${role.id}`, role);
    }

    remove(role: Role): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${role.id}`);
    }
}
