import { NgModule } from '@angular/core';
import { NotificationsPanelComponent } from './notifications-panel.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatBadgeModule } from '@angular/material/badge';
import { CommonModule, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

@NgModule({
    declarations: [NotificationsPanelComponent],
    exports: [NotificationsPanelComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatBadgeModule,
        NgIf,
        MatIconModule,
        MatTabsModule
    ]
})
export class NotificationsPanelModule {

}
