import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyFormFieldAppearance as MatFormFieldAppearance } from '@angular/material/legacy-form-field';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Country } from '@shared/models/country';
import { Observable } from 'rxjs';
import { CountryApiService } from '@bcmApiServices/country.api-service';
import { BcmService } from '@modules/bcm/bcm.service';

@Component({
    selector: 'form-widget-countries',
    templateUrl: './form-widget-countries.component.html',
})
export class FormWidgetCountries implements OnInit, OnDestroy, OnChanges {
    @Input()
    parentFormGroup: UntypedFormGroup;

    @Input()
    appearance: MatFormFieldAppearance = 'outline';

    @Input()
    tabIndex: number;

    @Input()
    country: Country;

    countries: Country[] = [];

    filteredCountries: Observable<Country[]>;

    tenantCountry: Country;

    constructor(
        private _countryApiService: CountryApiService,
        private _bcmService: BcmService
    ) {
    }

    ngOnInit(): void {
        this._countryApiService.getAll()
            .subscribe(countries => this.countries = countries);

        this._bcmService.selectedTenant$
            .subscribe((tenant) => this.tenantCountry = tenant.country);


        this.parentFormGroup.addControl('country', new FormControl<any>(this.country));
        this.parentFormGroup.get('country').setValue(this.tenantCountry || '');

        this.filteredCountries = this.parentFormGroup.get('country').valueChanges.pipe(
            startWith(''),
            debounceTime(DEFAULT_DEBOUNCE_TIME),
            map((value) => isString(value) ? this._filterCountries(value) : this.countries)
        );
    }

    ngOnDestroy() {
        this.parentFormGroup.removeControl('country');
    }

    ngOnChanges(changes: SimpleChanges) {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'country': {
                        this.parentFormGroup?.get('country')?.setValue(this.country, {emitEvent: false});
                    }
                }
            }
        }
    }

    displayCountryWith(country: Country) {
        if (country) {
            return `${country.de} (${country.code})`;
        }
        return '';
    }

    private _filterCountries(value: string): Country[] {
        const filterValue = value.toLowerCase();

        return this.countries.filter(country => {
            return country.de.toLowerCase().includes(filterValue)
                || (country.code || '').toLowerCase().includes(filterValue);
        });
    }

}
