import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormWidgetSubscriptionPeriodComponent } from './form-widget-subscription-period.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NumberInputModule } from '@sharedComponents/form/number-input/number-input.module';
import { DateTimeInputModule } from '@sharedComponents/form/date-time-input/date-time-input.module';
import { DateFnsModule } from 'ngx-date-fns';

@NgModule({
    declarations: [FormWidgetSubscriptionPeriodComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        NumberInputModule,
        DateTimeInputModule,
        DateFnsModule,
    ],
    exports: [FormWidgetSubscriptionPeriodComponent]
})
export class FormWidgetSubscriptionPeriodModule {
    
}
