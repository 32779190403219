import { Observable } from 'rxjs';
import { BaseStateService } from './base-state.service';
import { StateResetFilter } from '@core/state-management/state-reset.service';
import { Page } from '@shared/models/pagination';
import { deepClone } from '@core/functions/deep-clone';

interface BaseEntity {
    id?: number;
}

interface IBase<Entity> {
    list: Entity[];
    page: Page<Entity>;
    selected: Entity | undefined;
}

function getInitialState<Entity>(): IBase<Entity> {
    return {
        list: [] as Entity[],
        page: undefined as Page<Entity>,
        selected: undefined as Entity,
    };
}

export abstract class BaseState<Entity extends BaseEntity> extends BaseStateService<IBase<Entity>> {

    public list$: Observable<Entity[]> = this.select(state => state.list);

    public page$: Observable<Page<Entity>> = this.select(state => state.page);

    public selected$: Observable<Entity> = this.select((state) => state.selected);

    public get selected(): Entity {
        return this.state.selected;
    }

    public get list(): Entity[] {
        return this.state.list;
    }

    public get page(): Page<Entity> {
        return this.state.page;
    }

    protected constructor(stateResetFilter = StateResetFilter.ALL, private uniqueIdentAttribute: string) {
        super(getInitialState<Entity>(), stateResetFilter);
    }

    public updateStateFor(item: Entity): void {
        if (!item) {
            return;
        }
        if (this.selected && item[this.uniqueIdentAttribute] === this.selected[this.uniqueIdentAttribute]) {
            this.setSelected(item);
        }
        this.replaceInPageAndList(item, item);
    }

    public setSelected(selected: Entity): void {
        if (!selected) {
            return;
        }
        this.setState({selected});
        this.replaceInPageAndList(selected, selected);
    }

    public setList(list: Entity[]): void {
        this.setState({list});
    }

    public setPage(page: Page<Entity>): void {
        this.setState({page});
        this.setList(page.results);
    }

    public addToList(newItem: Entity): void {
        if (this.state.page) {
            this.setPage({...this.state.page, results: [...this.state.page.results, newItem]});
        } else {
            this.setList([...this.state.list, newItem]);
        }
    }

    public replaceInPageAndList(oldItem: Entity, newItem: Entity): void {
        if (!oldItem) {
            return;
        }

        if (this.state.page) {
            const page = deepClone(this.state.page);
            const indexPageList = page.results.findIndex(item => item[this.uniqueIdentAttribute] === oldItem[this.uniqueIdentAttribute]);

            page.results[indexPageList] = newItem;

            this.setPage(page);
        } else {
            const list = deepClone(this.state.list);
            const indexList = list.findIndex(item => item[this.uniqueIdentAttribute] === oldItem[this.uniqueIdentAttribute]);

            list[indexList] = newItem;

            this.setList(list);
        }
    }

    public removeFromList(...itemsToRemove: Entity[]): void {
        if (!this.state.page) {
            const list = deepClone(this.state.list);
            const filteredList = list.filter(itemA => !itemsToRemove.some(itemB => itemB.id === itemA.id));

            this.setState({list: filteredList});
        }
    }

}
