import { AbstractControl, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export abstract class BaseValidator implements Validator {

    public IS_VALID = null;

    public abstract validationError: BaseValidationError;

    public get IS_INVALID(): ValidationErrors {
        return this.validationError.INVALID;
    }

    public abstract validate(control: AbstractControl): ValidationErrors | null;

    public asValidatorFn(): ValidatorFn {
        return (control) => this.validate(control);
    }
}
