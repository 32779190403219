import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AppNotificationService } from '@core/services/app-notification.service';
import { ConfirmDialogService } from '@shared/components/dialogs/confirm-dialog/confirm-dialog.service';
import { BcmService } from '@modules/bcm/bcm.service';
import { Tenant } from '@shared/models/tenant';
import { noop } from 'rxjs';
import { TenantApiService } from '@modules/bcm/@shared/services';

@Component({
    selector: 'sign-avv',
    templateUrl: './sign-avv.component.html'
})
export class SignAvvComponent {

    formGroup: UntypedFormGroup;

    tenant: Tenant;

    isSaving: boolean;

    constructor(private _dialogRef: MatDialogRef<SignAvvComponent>,
                private _appNotificationService: AppNotificationService,
                private _confirmDialogService: ConfirmDialogService,
                private _tenantApiService: TenantApiService,
                private _bcmService: BcmService,
                private _formBuilder: UntypedFormBuilder) {

        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.formGroup = this._formBuilder.group({});

        this._bcmService.selectedTenant$.subscribe(tenant => this.tenant = tenant);
    }

    onClickClose(): void {

        if (this.formGroup.dirty) {
            return this._confirmDialogService
                .setTitle('Achtung')
                .setBody('Alle bislang eingegebenen Daten werden nicht gespeichert. Trotzdem beenden?')
                .setYesButton({
                    color: 'warn',
                    text: 'Ja'
                })
                .setNoButton({
                    color: 'accent',
                    text: 'Nein'
                })
                .openWithCallback(() => this._avvAdvice());
        }

        this._avvAdvice();
    }

    onClickSaveAndClose(): void {

        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError('Bitte überprüfe die Rot markierten Felder');
            return;
        }

        this.isSaving = true;

        const {signature, signatureText} = this.formGroup.get('avv').value;
        this._tenantApiService
            .saveAvv(this.tenant, signature, signatureText)
            .subscribe(
                () => {
                    this._appNotificationService.showSuccess('Der AVV wurde gespeichert.');
                    this._dialogRef.close(true);
                },
                () => {
                    this._appNotificationService.showError('Beim Speichern des AVV ist ein Fehler aufgetreten');
                }
            )
            .add(() => this.isSaving = false);
    }

    private _avvAdvice(): void {
        this._confirmDialogService
            .setTitle('AVV später bestätigen')
            .setBody(`Du hast jetzt nicht die Zeit, das verstehen wir...<br><br>
                Bitte beachte, dass wir laut DSGVO verpflichtet sind, einen AVV mit dem ${this.tenant.name} abzuschließen. 
                Dieser muss von einer befugten Person bestätigt und unterschrieben werden.<br><br>
                Im schlimmsten Fall, müssen wir Dein <strong>Yachthafenmanagement</strong> vorrübergehend deaktivieren.<br><br>
                Wir geben Dir Aufschub bis zum nächsten Login.<br><br>Viele Grüße<br>Dein Up2Boat Team`)
            .setYesButton({
                color: 'accent',
                text: 'Jetzt AVV bestätigen!'
            })
            .setNoButton({
                text: 'jetzt nicht...'
            })
            .openWithCallback(
                () => noop(),
                () => this._dialogRef.close(false),
                () => noop(),
            );
    }
}
