import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { Berth, IBerth } from '@shared/models/berth';
import { Key, KeyRaw } from '@shared/models/key';
import { IInvoice, Invoice } from '@shared/models/invoice';
import { ElectricMeter, IElectricMeter } from '@shared/models/electric-meter';
import {
    IWinterStorageBoatAssignment,
    WinterStorageBoatAssignment
} from '@shared/models/winter-storage-boat-assignment';
import { catchError, map } from 'rxjs/operators';

export enum SupportedEntities {
    Persons = 'persons',
    Companies = 'companies',
    Boats = 'boats',
    Keys = 'keys',
    Berths = 'berths',
    Invoices = 'invoices',
    WinterStorageAssignments = 'winterStorageAssignments',
    ElectricMeters = 'electricMeters'
}

export interface SearchResultRaw {
    numberOfResults: number;
    results: {
        entity: SupportedEntities,
        header: string,
        icon: string,
        svgIcon?: boolean,
        translate?: boolean,
        prio: number,
        list: (IPerson | ICompany | IBoat | IBerth | KeyRaw | IInvoice | IWinterStorageBoatAssignment | IElectricMeter)[]
    }[];
}

export interface SearchResult {
    numberOfResults: number;
    results: {
        entity: SupportedEntities,
        header: string,
        icon: string,
        svgIcon?: boolean,
        translate?: boolean,
        prio: number,
        list: (Person | Company | Boat | Berth | Key | Invoice | WinterStorageBoatAssignment | ElectricMeter)[]
    }[];
}

export const emptySearchResult = {
    numberOfResults: 0,
    results: [],
};

@Injectable({
    providedIn: 'root'
})
export class SearchApiService extends BcmApi2Service {

    search(search: string, entities?: SupportedEntities[]): Observable<SearchResult> {
        return super.get<SearchResultRaw>(
            'search',
            new HttpParams()
                .set('search', search)
                .set('entities', entities ? entities.toString() : '')
        )
            .pipe(
                map(response => {
                    const results = response.results.map(item => {
                        const parsedList = item.list.map(entity => {
                            switch (item.entity) {
                                case SupportedEntities.Persons:
                                    return new Person(entity as IPerson);
                                case SupportedEntities.Companies:
                                    return new Company(entity as ICompany);
                                case SupportedEntities.Boats:
                                    return new Boat(entity as IBoat);
                                case SupportedEntities.Berths:
                                    return new Berth(entity as IBerth);
                                case SupportedEntities.Keys:
                                    return new Key(entity as KeyRaw);
                                case SupportedEntities.Invoices:
                                    return new Invoice(entity as IInvoice);
                                case SupportedEntities.WinterStorageAssignments:
                                    return new WinterStorageBoatAssignment(entity as IWinterStorageBoatAssignment);
                                case SupportedEntities.ElectricMeters:
                                    return new ElectricMeter(entity as IElectricMeter);
                            }
                        });
                        return {...item, list: parsedList};
                    });

                    return {
                        numberOfResults: response.numberOfResults,
                        results
                    };
                }),
                catchError(() => of(emptySearchResult))
            );
    }

}
