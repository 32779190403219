import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { Tenant } from '@shared/models/tenant';

export class U2BPostcodeValidator extends BaseValidator {

    public validationError: BaseValidationError;

    private pattern: RegExp;

    constructor(public tenant: Tenant) {
        super();
        let message: string;
        switch (tenant.countryCodeISO3166) {
            case 'CH':
                message = 'Bitte eine gültige vierstellige PLZ angeben.';
                // Four digits, first is district, second is area, third is route, fourth is post office number.
                this.pattern = /^[1-9]\d{3}$/;
                break;
            default:
                message = 'Bitte eine gültige fünfstellige PLZ angeben';
                this.pattern = /^[0-9]{5}$/;
                break;
        }
        this.validationError = new BaseValidationError('pattern', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {

        // optional
        if (!control.value) {
            return this.IS_VALID;
        }

        return this.pattern.test(control.value)
            ? this.IS_VALID
            : this.IS_INVALID;
    }
}
