import { InvoicePosition } from '@shared/models/invoice-position';
import { Product } from '@shared/models/product';

export function getTaxRateValue(position: InvoicePosition | { product: Product; quantity: number; }): number {
    let taxRateValue;

    if ('taxRateValue' in position && position?.taxRateValue != null) {
        taxRateValue = position.taxRateValue;
    } else if ('taxRate' in position && position?.taxRate?.value != null) {
        taxRateValue = position.taxRate.value;
    } else if (position?.product?.taxRate?.value != null) {
        taxRateValue = position.product.taxRate.value;
    } else {
        taxRateValue = 0;
    }

    return parseFloat(taxRateValue);
}
