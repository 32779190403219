import { Component, Inject, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { InvoicePosition } from '@shared/models/invoice-position';

@Component({
    selector: 'get-cost-center-for-products-dialog',
    templateUrl: './get-cost-center-for-invoice-positions-dialog.component.html',
})
export class GetCostCenterForInvoicePositionsDialogComponent {

    invoicePositions: InvoicePosition[];

    productsCostCenterFormControls: FormControl[] = [];

    constructor(
        public dialogRef: MatDialogRef<GetCostCenterForInvoicePositionsDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            invoicePositions: InvoicePosition[],
        },
    ) {
        this.invoicePositions = this.data.invoicePositions;
        this.productsCostCenterFormControls = (this.invoicePositions || [])
            .map(invoicePosition => new FormControl(invoicePosition.costCenter || undefined));
    }

    public save(): void {
        this.invoicePositions.forEach((invoicePosition: InvoicePosition, index: number) => {
            invoicePosition.costCenter = this.productsCostCenterFormControls[index].value || invoicePosition.costCenter;
        });
        this.dialogRef.close(this.invoicePositions);
    }
}
