import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WinterStorage } from '@shared/models/winter-storage';

@Component({
    selector: 'bcm-winter-storage-link',
    templateUrl: './winter-storage-link.component.html'
})
export class WinterStorageLinkComponent implements OnChanges {

    @Input()
    public winterStorage!: WinterStorage;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(): void {
        const winterStorage = this.winterStorage;
        this.viewData = [
            winterStorage?.handle || null,
            this.additionalInfo,
        ].filter(item => item != null)
            .join(' - ');
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
