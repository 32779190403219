import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { NumberInputModule } from '@sharedComponents/form/number-input/number-input.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DateTimeInputModule } from '@sharedComponents/form/date-time-input/date-time-input.module';
import { U2bConfirmDialogComponent } from './confirm-dialog.component';
import { MatLegacyAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { OrderModule } from 'ngx-order-pipe';
import { TranslationModule } from '@core/translation/translation.module';

@NgModule({
    declarations: [
        U2bConfirmDialogComponent
    ],
    imports: [
        MatDialogModule,
        MatButtonModule,
        DragDropModule,
        MatToolbarModule,
        MatIconModule,
        FlexModule,
        FuseSharedModule,
        MatFormFieldModule,
        SharedModule,
        MatInputModule,
        NumberInputModule,
        MatCheckboxModule,
        DateTimeInputModule,
        MatLegacyAutocompleteModule,
        OrderModule,
        TranslationModule
    ],
})
export class U2bConfirmDialogModule {
}
