import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {parseHttpResponseItem, parseHttpResponseList} from '@shared/functions/parse-http-response';
import {BcmApi2Service} from '@modules/bcm/@shared/services';
import {IWinterStorage, WinterStorage} from '@shared/models/winter-storage';

@Injectable({
    providedIn: 'root'
})
export class WinterStorageApiService extends BcmApi2Service {

    private RESOURCE_ENDPOINT = 'winter-storages';

    getAll(params: HttpParams = new HttpParams()): Observable<WinterStorage[]> {
        return super.get<IWinterStorage[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IWinterStorage, WinterStorage>(WinterStorage));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<WinterStorage> {
        return super.get<IWinterStorage>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IWinterStorage, WinterStorage>(WinterStorage));
    }

    add(winterStorage: IWinterStorage | WinterStorage): Observable<WinterStorage> {
        return super.post<WinterStorage>(this.RESOURCE_ENDPOINT, winterStorage);
    }

    update(winterStorage: WinterStorage): Observable<WinterStorage> {
        return super.put<WinterStorage>(`${this.RESOURCE_ENDPOINT}/${winterStorage.id}`, winterStorage);
    }

    remove(winterStorage: WinterStorage): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${winterStorage.id}`);
    }
}
