export enum TseDocumentPositionType {
    ITEM = '[0] = Item',
    SUB_ITEM = '[1] = SubItem',
    TEXT = '[2] = Text',
    BOOKING = '[3] = Booking',
    SUB_TOTAL = '[9] = SubTotal',
    TOTAL = '[10] = Total',
}



