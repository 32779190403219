<u2b-modal>

    <div u2bModalHeadline>Kostenstellen</div>

    <h3>
        Weise den hier aufgelisteten Positionen optional eine Kostenstelle zu.
    </h3>

    <ng-container *ngFor="let invoicePosition of invoicePositions; let index = index">
        <div>
            <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
                <div class="pr-16">{{invoicePosition.title || invoicePosition.product?.name}}</div>

                <app-input-cost-centers
                        [control]="productsCostCenterFormControls[index] | formControl"></app-input-cost-centers>
            </div>
        </div>
    </ng-container>

    <button modalButtonRight
            mat-flat-button
            color="accent"
            (click)="save()">
        <mat-icon>save</mat-icon>
        Speichern
    </button>

</u2b-modal>
