import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { format } from '@core/date.facade';

export class U2BMinDateValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public minDate: Date,
                message?: string) {
        super();
        this.validationError = new BaseValidationError('minDate', message || `Das Datum darf nicht vor dem ${format(this.minDate, 'dd.MM.yyyy')} liegen.`);
    }

    public validate({value}: AbstractControl): ValidationErrors | null {

        if (!value) {
            return this.IS_VALID;
        }

        if (value >= this.minDate) {
            return this.IS_VALID;
        }

        return this.IS_INVALID;
    }
}
