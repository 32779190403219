import { Component, Input, OnInit } from '@angular/core';
import { BcmReleaseNote } from '@bcmServices/release-notes/bcm-release-note';
import { BcmReleaseNotesFacade } from '@bcmServices/release-notes/release-notes.facade';

@Component({
    selector: 'u2b-release-note-body',
    templateUrl: './release-note-body.component.html'
})
export class ReleaseNoteBodyComponent implements OnInit {

    loading = true;

    finalReleaseNote: BcmReleaseNote;

    @Input()
    releaseNote!: BcmReleaseNote;

    constructor(private bcmReleaseNotesFacade: BcmReleaseNotesFacade) {
    }

    ngOnInit(): void {
        this.bcmReleaseNotesFacade
            .loadReleaseNote(this.releaseNote.id)
            .add(() => {
                this.finalReleaseNote = this.bcmReleaseNotesFacade.selected;
                setTimeout(() => this.loading = false, 800);
            });
    }

}
