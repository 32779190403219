import { U2bColumnPipeName, U2bTableData } from '@core/components/layout/table/table.types';
import { BcmUserTableSettingKey } from '@shared/models/bcm-settings-user';
import { getIsActive } from '@core/functions/get-is-active';
import { BcmVoucherDto } from '@shared/models/bcm-voucher';
import { FilterFieldType } from '@core/datafilter/available-filter-fields';
import { DataFilterOperator } from '@core/datafilter/filter-operator';

export const vouchersTableData: U2bTableData = {
    tableId: BcmUserTableSettingKey.Vouchers,
    columnDefinitions: [
        {
            property: 'id',
            name: 'ID',
            isActive: getIsActive(),
            filter: {}
        },
        {
            property: 'title',
            name: 'Gutschein',
            isActive: getIsActive(),
            translationKey: 'voucherTitle',
            filter: {}
        },
        {
            property: 'quantityTotal',
            name: 'Menge<br>(gesamt)',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            translationKey: 'voucherQuantityTotal',
            filter: {
                fieldType: FilterFieldType.Numeric
            }
        },
        {
            property: 'quantityLeft',
            name: 'Menge<br>(verbleibend)',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Decimal,
            translationKey: 'voucherQuantityLeft',
            filter: {
                fieldType: FilterFieldType.Numeric
            }
        },
        {
            property: 'totalPrice',
            name: 'Preis',
            isActive: getIsActive(),
            pipe: U2bColumnPipeName.Currency,
            translationKey: 'voucherTotalPrice',
            filter: {
                fieldType: FilterFieldType.Numeric
            }
        },
        {
            property: 'validFrom',
            name: 'Gültig<br>von',
            isActive: getIsActive(),
            translationKey: 'voucherValidFrom',
            pipe: U2bColumnPipeName.Date,
            filter: {
                fieldType: FilterFieldType.Date
            }
        },
        {
            property: 'validUntil',
            name: 'Gültig<br>bis',
            isActive: getIsActive(),
            translationKey: 'voucherValidUntil',
            pipe: U2bColumnPipeName.Date,
            filter: {
                fieldType: FilterFieldType.Date
            }
        },
        {
            property: 'productPrice',
            name: '',
            isActive: getIsActive(false, false, false),
            translationKey: 'voucherPrice',
            parseItem: (item: BcmVoucherDto) => item.product.price || 0,
            pipe: U2bColumnPipeName.Currency,
            filter: {
                fieldType: FilterFieldType.Numeric,
                property: 'product.price',
            }
        },
    ],
};
       
