import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'fuse-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class FuseSearchBarComponent implements OnInit, OnDestroy {
    collapsed: boolean;
    fuseConfig: any;

    searchTerm: string;

    @ViewChild('searchInput')
    searchInput: ElementRef;

    @Input()
    searching: boolean;

    @Output()
    inputChange: EventEmitter<any>;

    @Output()
    hasFocusChange: EventEmitter<any>;

    @Output()
    collapseChange: EventEmitter<any>;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService
    ) {
        this.inputChange = new EventEmitter();
        this.hasFocusChange = new EventEmitter();
        this.collapseChange = new EventEmitter();
        this.collapsed = true;

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                (config) => {
                    this.fuseConfig = config;
                }
            );
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    collapse(): void {
        this.inputChange.emit(null);
        this.hasFocusChange.emit(false);
        this.collapsed = true;
        this.searchTerm = null;
        this.collapseChange.emit();
    }

    expand(): void {
        this.collapsed = false;
        setTimeout(() => {
            this.searchInput.nativeElement.focus();
        }, 0);
    }

    search(value): void {
        this.inputChange.emit(value);
    }

    hasFocus(hasFocus: boolean): void {
        this.hasFocusChange.emit(hasFocus);
    }

}
