import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'get-country-dialog',
    templateUrl: './get-country-dialog.component.html',
})
export class GetCountryDialogComponent {

    formGroup = new FormGroup({});

    constructor(public dialogRef: MatDialogRef<GetCountryDialogComponent>) {
    }

    public save(): void {
        this.dialogRef.close(this.formGroup.get('country').value);
    }
}
