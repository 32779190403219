import { NgModule } from '@angular/core';
import { PersonCompanyRelationComponent } from '@bcm-work-flows/person-company-relation/person-company-relation.component';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { FormWidgetCompanyModule } from '@bcm-work-flows/@form-widgets/company/form-widget-company.module';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';

@NgModule({
    declarations: [PersonCompanyRelationComponent],
    imports: [
        CdkDrag,
        ReactiveFormsModule,
        SharedModule,
        FormWidgetCompanyModule,
        DirectivesModule,
        MatDialogModule,
    ],
    exports: [PersonCompanyRelationComponent]
})
export class PersonCompanyRelationModule {

}
