import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUnit } from '@shared/models/unit';
import { ProductUnitApiService } from '@modules/bcm/@shared/services';

@Injectable({providedIn: 'root'})
export class ProductUnitsResolver implements Resolve<IUnit[]> {

    constructor(private _productUnitApiService: ProductUnitApiService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<IUnit[]> {
        return this._productUnitApiService.getAll();
    }
}
