import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';
import { isArray } from '@shared/functions/is-array';
import { Page } from '@shared/models/pagination';

export function parseHttpResponseList<T, R>(model?: any): OperatorFunction<T[], R[]> {
    if (typeof model !== 'function') {
        throw TypeError(`${model} is not of type ctor.`);
    }

    return map((items: T[]) => isArray(items) ? items.map((item) => new model(item)) as R[] : []);
}

export function parseHttpResponseItem<T, R>(model: any): OperatorFunction<T, R> {
    if (typeof model !== 'function') {
        throw TypeError(`${model} is not of type ctor.`);
    }

    return map((item: T) => item ? new model(item) as R : undefined);
}

export function parseHttpResponsePage<T, R>(model?: any): OperatorFunction<Page<T>, Page<R>> {
    if (typeof model !== 'function') {
        throw TypeError(`${model} is not of type ctor.`);
    }

    return map((page: Page<any>) => {
        if (isArray(page.results)) {
            page.results = page.results.map((item) => new model(item) as R);
        }
        return page as Page<R>;
    });
}
