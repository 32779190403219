import { FuseNavigationItem } from '@fuse/types';
import { BcmNavigationItem } from './editable-navigation-bcm';

export interface U2bNavigationItem extends FuseNavigationItem {
    children?: U2bNavigationItem[];
    type: ListItemType;
    isText?: boolean;
}

export enum ListItemType {
    Group = 'group',
    Collapsable = 'collapsable',
    Item = 'item'
}

export interface ListItem extends BcmNavigationItem {
    isExpanded?: boolean;
    type: ListItemType;
}

export interface DropInfo {
    targetId: string;
    action?: string;
}

export interface ListItemDto {
    type: ListItemType;
    children: ListItemDto[];
    id: string;
    title: string;
}
