import { BcmUser } from '@modules/bcm/bcm-user';
import { tryParseDate } from '@shared/functions/try-parse-date';

export interface BcmCashRegisterUserDto {
    id?: number;

    tenantUser: BcmUser;
    loggedIn?: boolean;
    canCancel?: boolean;

    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmCashRegisterUser {
    id: number;

    tenantUser: BcmUser;
    loggedIn: boolean;
    canCancel?: boolean;

    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(dto: BcmCashRegisterUserDto) {
        this.id = dto.id;

        this.tenantUser = dto.tenantUser ? new BcmUser(dto.tenantUser) : null;
        this.loggedIn = dto.loggedIn;
        this.canCancel = dto.canCancel;

        this.note = dto.note;

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;
    }

    get fullName(): string {
        return this.tenantUser.fullName;
    }
}


