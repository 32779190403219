// export interface IPersonRolePermission {
//     id: number;
//     hasPermission(permission: IPersonRolePermission): boolean;
// }

import { tryParseDate } from '@shared/functions/try-parse-date';

export interface IPersonRoleRaw {
    id: number; // = unique
    name: string; // = unique
    from: string;
    to: string;
    // permissions: IPersonRolePermission[];
}

export interface IPersonRole {
    id: number; // = unique
    name: string; // = unique
    from: Date;
    to: Date;
    // permissions: IPersonRolePermission[];
}

export class PersonRole implements IPersonRole {
    public id: number;
    public name: string;
    public from: Date;
    public to: Date;

    constructor(role = {} as IPersonRoleRaw) {
        this.id = role.id;
        this.name = role.name;
        this.from = tryParseDate(role.from);
        this.to = tryParseDate(role.to);
    }

    toString(): string {
        return this.name;
    }
}

export class PersonRolesArray extends Array<PersonRole> {
    toString(): string {
        return this.map((row) => {
            return row.name;
        }).join(', ');
    }
}
