import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BcmReleaseNote, BcmReleaseNoteDto } from '@bcmServices/release-notes/bcm-release-note';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';

export abstract class BcmReleaseNotesApi extends BcmApi2Service {

    protected getAllReleaseNotes(params: HttpParams = new HttpParams()): Observable<BcmReleaseNote[]> {
        super.disableSyncForNextRequest();
        return super.get<BcmReleaseNoteDto[]>('release-notes', params)
            .pipe(parseHttpResponseList<BcmReleaseNoteDto, BcmReleaseNote>(BcmReleaseNote));
    }

    protected getReleaseNote(releaseNoteId: number, params: HttpParams = new HttpParams()): Observable<BcmReleaseNote> {
        super.disableSyncForNextRequest();
        return super.get<BcmReleaseNoteDto>(`release-notes/${releaseNoteId}`, params)
            .pipe(parseHttpResponseItem<BcmReleaseNoteDto, BcmReleaseNote>(BcmReleaseNote));
    }

    protected postReleaseNote(releaseNoteDto: BcmReleaseNoteDto): Observable<BcmReleaseNote> {
        super.disableSyncForNextRequest();
        return super.post<BcmReleaseNoteDto>(`release-notes`, releaseNoteDto)
            .pipe(parseHttpResponseItem<BcmReleaseNoteDto, BcmReleaseNote>(BcmReleaseNote));
    }

    protected putReleaseNote(releaseNote: BcmReleaseNote, updateData: BcmReleaseNoteDto): Observable<BcmReleaseNote> {
        super.disableSyncForNextRequest();
        return super.put<BcmReleaseNoteDto>(`release-notes/${releaseNote.id}`, updateData)
            .pipe(parseHttpResponseItem<BcmReleaseNoteDto, BcmReleaseNote>(BcmReleaseNote));
    }

    protected deleteReleaseNote(releaseNote: BcmReleaseNote): Observable<number> {
        super.disableSyncForNextRequest();
        return super.delete(`release-notes/${releaseNote.id}`);
    }
}
