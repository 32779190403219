<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                Abo {{ data?.subscription ? 'bearbeiten' : 'hinzufügen' }}
            </span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <form [formGroup]="formGroup">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                    <mat-label>Zahlungsintervall</mat-label>
                    <mat-select data-test="FS-Zahlungsintervall" formControlName="payableOption"
                                [compareWith]="comparePayableOptions">
                        <mat-option *ngFor="let payableOption of payableOptions" [value]="payableOption">
                            {{ payableOption.name }}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ formGroup.get('payableOption').errors | firstErrorMessage }}</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
                <br>
                <strong>
                    Regelmäßige Zahlung
                    <ng-container *ngIf="formGroup.get('payableOption').value">
                        <span style="text-transform: lowercase;">
                            - {{ formGroup.get('payableOption').value?.name || 'Laufzeit' }}
                        </span>
                    </ng-container>
                </strong>
            </div>

            <h3>Wann soll die <strong>erste</strong> Position erstellt werden?</h3>
            <app-input-date-time formControlName="fromDate">
                <label>Erste Position am</label>
            </app-input-date-time>

            <h3>
                Wann soll die <strong>letzte</strong> Position erzeugt werden?<br>
                <small>Leer lassen, wenn Laufzeitende offen.</small>
            </h3>

            <app-input-date-time formControlName="toDate">
                <label>Letzte Position am</label>
            </app-input-date-time>

            <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
                <h2>Erster Leistungszeitraum</h2>
                <p>
                    Definiere den 1. Leistungszeitraum, für den eine Position erstellt werden soll.
                    Nachfolgende Positionen werden
                    <ng-container *ngIf="formGroup.get('payableOption').value; else other">
                        <strong style="text-transform: lowercase;">{{ formGroup.get('payableOption').value?.name }}</strong>
                    </ng-container>
                    <ng-template #other>im ausgewählten Zahlungsintervall</ng-template>
                    erneut erzeugt.
                </p>
            </div>

            <ng-container
                    *ngIf="formGroup.get('payableOption')?.value?.id === PayableOptionId.Yearly && seasons?.length">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                        <mat-label>Wähle aus periodischen Saisons</mat-label>
                        <mat-select data-test="FS-Saisons"
                                    [formControl]="seasonFormControl"
                                    [compareWith]="compareSeasons">
                            <mat-option [value]="null">- Keine Saison -</mat-option>
                            <mat-option *ngFor="let season of seasons" [value]="season">
                                {{ season.name }} / {{ season.startDate | dfnsFormat : 'dd.MM.yyyy' }} -
                                {{ season.endDate | dfnsFormat : 'dd.MM.yyyy' }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>

            <app-input-date-time formControlName="vestingPeriodFrom">
                <label>Leistungszeitraum Start</label>
            </app-input-date-time>

            <app-input-date-time formControlName="vestingPeriodUntil">
                <label>Leistungszeitraum Ende</label>
            </app-input-date-time>

            <ng-container
                    *ngIf="[PayableOptionId.Monthly, PayableOptionId.HalfYearly, PayableOptionId.QuarterYearly].includes(formGroup.get('payableOption')?.value?.id)">
                <div fxLayout="row" fxLayoutAlign="start start">
                    <mat-checkbox formControlName="lastDayOfMonth">
                        Leistungszeitraum Ende automatisch auf den letzten Tag des Monats setzen
                    </mat-checkbox>
                </div>
            </ng-container>

        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <ng-container *ngIf="data.subscription; else newSubscription">
            <button data-test="B-Abo entfernen" mat-flat-button
                    color="warn"
                    (click)="dialogRef.close(false)"
                    class="save-button"
                    aria-label="Entfernen">
                Abo entfernen
            </button>

            <button data-test="B-Abo speichern" mat-flat-button
                    color="accent"
                    (click)="saveAndContinue()"
                    class="save-button"
                    [disabled]="isSaving || !formGroup.dirty"
                    aria-label="Speichern">
                Abo speichern
            </button>
        </ng-container>

        <ng-template #newSubscription>
            <button data-test="B-Abo hinzufügen" mat-flat-button
                    color="accent"
                    (click)="saveAndContinue()"
                    class="save-button"
                    [disabled]="isSaving || !formGroup.dirty"
                    aria-label="Speichern">
                Abo hinzufügen
            </button>
        </ng-template>

    </div>
</div>
