import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MatBadgeModule } from '@angular/material/badge';
import { SecuredImageModule } from '@shared/components/secured-image/secured-image.module';
import { FuseSearchBarModule } from '@fuse/components';
import { MatDividerModule } from '@angular/material/divider';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DateFnsModule } from 'ngx-date-fns';
import {ButtonBrowserBackModule} from '@core/components/button-browser-back/button-browser-back.module';
import { TranslationModule } from '@core/translation/translation.module';
import { NotificationsPanelModule } from '../notifications-panel/notifications-panel.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,

        FuseSharedModule,
        MatBadgeModule,
        SecuredImageModule,
        FuseSearchBarModule,
        MatDividerModule,
        HtmlLinksModule,
        SharedModule,
        MatProgressSpinnerModule,
        DateFnsModule,
        ButtonBrowserBackModule,
        TranslationModule,
        NotificationsPanelModule
    ],
    exports     : [
        ToolbarComponent
    ]
})
export class ToolbarModule
{
}
