<div fxHide.lt-md fxLayout="row nowrap" fxLayoutAlign="space-between"
     fxLayoutAlign.gt-sm="space-between center">

    <div fxLayout="row"
         fxLayoutAlign="space-between"
         fxLayoutAlign.gt-xs="flex-start"
         [formGroup]="berthsFilterService.dateRangeForm">

        <div class="graphical-berth-time-slider"
             [class.disabled]="sliderDisabled"
             [class.visible]="sliderVisible">
            <div class="slider-toggle"
                 (click)="sliderVisible = !sliderVisible">
                <ng-container *ngIf="sliderVisible">
                    Ausblenden
                    <mat-icon>expand_more</mat-icon>
                </ng-container>
                <ng-container *ngIf="!sliderVisible">
                    Uhrzeit einschränken
                    <mat-icon>expand_less</mat-icon>
                </ng-container>
            </div>
            <ngx-slider [formControl]="berthsFilterService.timeRange" [options]="sliderOptions"></ngx-slider>
        </div>

        <div class="button-group">
            <button mat-stroked-button
                    data-test="B-Erw. Filter"
                    aria-label="Erw. Filter"
                    (click)="berthsFilterService.toggleAdvancedFilters()">
                <mat-icon class="primary-text">tune</mat-icon>
                <ng-container *ngIf="berthsFilterService.advancedBerthFiltersOpen">
                    <mat-icon class="primary-text">keyboard_arrow_up</mat-icon>
                </ng-container>
                <ng-container *ngIf="!berthsFilterService.advancedBerthFiltersOpen">
                    <mat-icon class="primary-text">keyboard_arrow_down</mat-icon>
                </ng-container>
            </button>
        </div>

        <!--    <div class="px-24 font-size-16">-->
        <mat-menu #dateRangeMenu="matMenu">
            <button mat-menu-item (click)="berthsFilterService.selectDate(DateRangeType.Today)">
                <mat-icon>date_range</mat-icon>
                Heute
            </button>
            <button mat-menu-item (click)="berthsFilterService.selectDate(DateRangeType.Tomorrow)">
                <mat-icon>date_range</mat-icon>
                Morgen
            </button>
            <button mat-menu-item (click)="berthsFilterService.selectDate(DateRangeType.RestOfWeek)">
                <mat-icon>date_range</mat-icon>
                Rest der Woche
            </button>
            <button mat-menu-item (click)="berthsFilterService.selectDate(DateRangeType.RestOfMonth)">
                <mat-icon>date_range</mat-icon>
                Rest des Monats
            </button>
        </mat-menu>

        <div class="font-size-16">
            <div fxLayout="row" fxLayoutAlign="center stretch"
                 fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="flex-start center">

                <mat-date-range-picker #picker panelClass="date-range-picker" color="accent"></mat-date-range-picker>
                <button mat-icon-button matPrefix (click)="jumpDays(-1)" fxHide.lt-sm>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-date-range-input [rangePicker]="picker" fxHide.lt-sm fxFlex fxLayoutAlign="center">
                    <input matStartDate
                           placeholder="Anreise"
                           formControlName="start"
                           [value]="dateRangeStartValue"
                           #dateRangeStart>
                    <input matEndDate
                           placeholder="Abreise"
                           formControlName="end"
                           [value]="dateRangeEndValue"
                           #dateRangeEnd>
                </mat-date-range-input>
                <button mat-icon-button matPrefix (click)="jumpDays(1)" fxHide.lt-sm>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
                <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
                <button matPrefix mat-icon-button [matMenuTriggerFor]="dateRangeMenu"
                        #dateRangeMenuTrigger="matMenuTrigger">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </div>
        </div>
    </div>

    <div class="button-group" fxLayout="row wrap" fxLayoutAlign="center stretch">
        <ng-container *ngIf="reservedAssignments">
            <button mat-button
                    (click)="openReservations();">
                <span fxHide.lt-md>Auslaufende Reservierungen</span>
            </button>
        </ng-container>

        <button mat-button
                color="primary"
                [matMenuTriggerFor]="moreMenuFavourites"
                #moreMenuFavouritesTrigger="matMenuTrigger"
                (click)="$event.stopPropagation();">
            <mat-icon>star</mat-icon>
            Fav. <span fxHide.lt-md>Ansichten</span>
        </button>

        <mat-menu #moreMenuFavourites="matMenu">
            <ng-container *ngIf="($favourites | async) as favourites">
                <ng-container *ngFor="let favourite of favourites">
                    <button mat-menu-item (click)="changeMapView(favourite)" class="favourite-item">
                        {{favourite.name}}
                        <mat-icon (click)="deleteFavourite(favourite, $event)"
                                  class="favourite-del-btn"
                                  color="warn">
                            delete
                        </mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="!favourites?.length">
                    <div mat-menu-item [disabled]="true">
                        Noch keine Favoriten angelegt.
                    </div>
                </ng-container>
            </ng-container>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="newFavourite()">
                <mat-icon>add</mat-icon>
                Neuer Favorit
            </button>
            <button mat-menu-item (click)="showFavouritesHelp()">
                <mat-icon>help</mat-icon>
                Hilfe Anzeigen
            </button>
        </mat-menu>

        <button mat-stroked-button color="accent" (click)="openEditor()" fxHide fxShow.gt-sm>
            <mat-icon>edit</mat-icon>
            Editor öffnen
        </button>
        <button mat-stroked-button
                data-test="B-Aktionen"
                aria-label="Aktionen"
                [matMenuTriggerFor]="moreMenu"
                (click)="$event.stopPropagation();">
            <mat-icon class="primary-text">expand_more</mat-icon>
        </button>

        <mat-menu #moreMenu="matMenu">
            <button mat-menu-item (click)="openTable()">
                <mat-icon>compare_arrows</mat-icon>
                Tabellarischer Liegeplan
            </button>
        </mat-menu>
    </div>
</div>
<advanced-filters></advanced-filters>

<!-- TODO: discuss this with Thilo -->
<berth-map-fab fxHide.gt-sm
               [options]="FabOptions"
></berth-map-fab>
