<ng-container *ngIf="boat?.owner?.id || boat?.ownerCompany?.id">
    <ng-container *ngIf="prefix">{{prefix}}</ng-container>
    <ng-container *ngIf="ownerCompany?.id">
        <ng-container *ngIf="disableLink; else withLink">
            {{ownerCompany.toString()}}
            <ng-container *ngIf="additionalInfo">
                ({{additionalInfo}})
            </ng-container>
        </ng-container>
        <ng-template #withLink>
            <a (click)="click($event)"
               [attr.data-test]="dataTest"
               [bcmRouterLink]="['companies', ownerCompany.id]">
                {{ownerCompany.toString()}}
                <ng-container *ngIf="showTenantRelation">
                    <bcm-tenant-relation-colored-label
                            [tenantRelations]="ownerCompany.activeAndNextTenantRelationAssignments"></bcm-tenant-relation-colored-label>
                </ng-container>
                <ng-container *ngIf="additionalInfo">
                    ({{additionalInfo}})
                </ng-container>
            </a>
        </ng-template>
    </ng-container>
    <ng-container *ngIf="owner?.id">
        <ng-container *ngIf="disableLink; else withLink">
            {{owner.toString()}}
            <ng-container *ngIf="additionalInfo">
                ({{additionalInfo}})
            </ng-container>
        </ng-container>
        <ng-template #withLink>
            <a (click)="click($event)"
               [attr.data-test]="dataTest"
               [bcmRouterLink]="['persons', owner.id]">
                {{owner.toString()}}
                <ng-container *ngIf="showTenantRelation">
                    <bcm-tenant-relation-colored-label
                            [tenantRelations]="owner.activeAndNextTenantRelationAssignments"></bcm-tenant-relation-colored-label>
                </ng-container>
                <ng-container *ngIf="additionalInfo">
                    ({{additionalInfo}})
                </ng-container>
            </a>
        </ng-template>
    </ng-container>
</ng-container>
