import { Component, Input } from '@angular/core';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { TenantRelation } from '@shared/models/tenant-relation';

@Component({
    selector: 'bcm-tenant-relation-colored-label',
    templateUrl: './tenant-relation-colored-label.component.html',
    styleUrls: ['./tenant-relation-colored-label.component.scss']
})
export class TenantRelationColoredLabelComponent {
    protected showFullList = false;

    protected relations: TenantRelationAssignment[];

    @Input() set tenantRelations(input: TenantRelation | TenantRelation[] | TenantRelationAssignment | TenantRelationAssignment[]) {
        if (!input) {
            return;
        }
        if (input instanceof TenantRelation || (Array.isArray(input) && input[0] instanceof TenantRelation)) {
            input = (Array.isArray(input) ? input : [input])
                .map(tenantRelation => {
                    const tenantRelationAssignment = new TenantRelationAssignment({} as TenantRelationAssignmentDto);
                    tenantRelationAssignment.tenantRelation = tenantRelation;

                    return tenantRelationAssignment;
                });
        }
        this.relations = (Array.isArray(input) ? input : [input]) as TenantRelationAssignment[];
    }
}
