import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SystemNotificationsSocket extends Socket {

    private _reload$ = new Subject<void>();

    get reload$(): Observable<void> {
        return this._reload$.asObservable();
    }

    constructor() {
        super({url: `${environment.endpoints.webSocket}/system-notifications`, options: {}});

        this._setUpEvents();
    }

    private _setUpEvents() {
        this.fromEvent('reload').subscribe(() => this._reload$.next());
    }
}
