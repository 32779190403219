// source: https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { ConfirmDialogService } from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog.service';
import { UserService } from '@core/services/user.service';
import { environment } from '@env/environment';

@Injectable({providedIn: 'root'})
export class VersionCheckService {

    private currentBuildNumber = environment.appBuildNumber;

    private _hasNewVersion$ = new Subject<void>();

    private hasOpenDialog = false;

    public get hasNewVersion$(): Observable<void> {
        return this._hasNewVersion$.asObservable();
    }

    constructor(private http: HttpClient,
                private confirmDialogService: ConfirmDialogService,
                private userService: UserService) {
    }

    /**
     * Checks the version of frontend application in every set frequency
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 5 minutes
     */
    public initVersionCheck(url: string = environment.appVersionCheckURL,
                            frequency: number = environment.appVersionCheckInterval): void {

        // comment this out to test it locally
        if (!environment.production) {
            return;
        }

        setInterval(() => this.checkVersion(url), frequency);
    }

    openUpdateDialog(disableClose = false): void {
        this.hasOpenDialog = true;
        this.confirmDialogService
            .setTheme('primary')
            .setTitle('Neue Version verfügbar')
            .setBody(
                `Hallo ${this.userService.user.firstName},<br><br>
                wir haben eine neue Version von Up2Boat veröffentlicht.<br><br>
                Damit alles wie gehabt funktioniert ist es absolut notwendig, dass die Seite neu geladen wird.`
            )
            .setNoButton(disableClose ? null : {text: 'Jetzt nicht...'})
            .setYesButton({text: 'Neue Version laden', color: 'primary'})
            .setDialogConfig({disableClose})
            .openWithCallback(() => {
                this.hasOpenDialog = false;
                location.reload();
            });
    }

    /**
     * Will do the call and check if the hash has changed or not
     * @param url
     */
    private checkVersion(url): void {
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + Date.now())
            .subscribe(
                (response: { buildNumber: string, datetime: number, version: string }) => {
                    if (this.currentBuildNumber !== response.buildNumber) {
                        this._hasNewVersion$.next(undefined);
                    }
                },
                (err) => throwError(err)
            );
    }
}
