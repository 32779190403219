<div [formGroup]="conditionsAndPrivacyFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <mat-checkbox formControlName="conditions">
        Ich habe die <a href="https://www.up2boat.com/agb/" target="_blank">allgemeinen Geschäftsbedingungen</a> gelesen und akzeptiere diese.
    </mat-checkbox>
    <ng-container *ngIf="conditionsAndPrivacyFormGroup.get('conditions').invalid && conditionsAndPrivacyFormGroup.get('conditions').touched">
        <div>
            <mat-error>{{conditionsAndPrivacyFormGroup.get('conditions').errors | firstErrorMessage}}</mat-error>
        </div>
    </ng-container>

    <mat-checkbox formControlName="privacy">
        Ich habe die <a href="https://www.up2boat.com/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere diese.
    </mat-checkbox>
    <ng-container *ngIf="conditionsAndPrivacyFormGroup.get('privacy').invalid && conditionsAndPrivacyFormGroup.get('privacy').touched">
        <div>
            <mat-error>{{conditionsAndPrivacyFormGroup.get('privacy').errors | firstErrorMessage}}</mat-error>
        </div>
    </ng-container>

</div>