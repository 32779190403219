export enum BcmUserPermission {
    FULL_GRANT = 'bcm_full_grant',

    BERTHS_READ = 'bcm_berths_r',
    BERTHS_WRITE = 'bcm_berths_w',
    BERTHS_DELETE = 'bcm_berths_x',

    ABSENCES_READ = 'bcm_absences_r',
    ABSENCES_WRITE = 'bcm_absences_w',
    ABSENCES_DELETE= 'bcm_absences_x',

    // BERTHS_GRAPHICALLY_READ = 'bcm_berths_2d_r',
    // BERTHS_GRAPHICALLY_WRITE = 'bcm_berths_2d_w',

    WINTER_STORAGES_READ = 'bcm_winter_storages_r',
    WINTER_STORAGES_WRITE = 'bcm_winter_storages_w',
    WINTER_STORAGES_DELETE = 'bcm_winter_storages_x',

    BOATS_READ = 'bcm_boats_r',
    BOATS_WRITE = 'bcm_boats_w',
    BOATS_DELETE = 'bcm_boats_x',

    COMPANIES_READ = 'bcm_companies_r',
    COMPANIES_WRITE = 'bcm_companies_w',
    COMPANIES_DELETE = 'bcm_companies_x',

    DOCUMENTS_READ = 'bcm_documents_r',
    DOCUMENTS_WRITE = 'bcm_documents_w',
    DOCUMENTS_DELETE = 'bcm_documents_x',

    ELECTRIC_METERS_READ = 'bcm_electric_meters_r',
    ELECTRIC_METERS_WRITE = 'bcm_electric_meters_w',
    ELECTRIC_METERS_DELETE = 'bcm_electric_meters_x',

    INVOICES_READ = 'bcm_invoices_r',
    INVOICES_WRITE = 'bcm_invoices_w',
    INVOICES_DELETE = 'bcm_invoices_x',

    INVOICE_COLLECTIONS_READ = 'bcm_invoice_collections_r',
    INVOICE_COLLECTIONS_WRITE = 'bcm_invoice_collections_w',
    INVOICE_COLLECTIONS_DELETE = 'bcm_invoice_collections_x',

    INVOICE_EXPORT_DATEV = 'bcm_export_datev',

    INVOICE_SEPA_RUNS_READ = 'bcm_sepa_runs_r',
    INVOICE_SEPA_RUNS_WRITE = 'bcm_sepa_runs_w',
    INVOICE_SEPA_RUNS_DELETE = 'bcm_sepa_runs_x',

    // not in use actually?
    MAIL_READ = 'bcm_mail_r',
    MAIL_WRITE = 'bcm_mail_w',
    MAIL_DELETE = 'bcm_mail_x',

    MAIL_MAILINGS_READ = 'bcm_mail_mailings_r',
    MAIL_MAILINGS_WRITE = 'bcm_mail_mailings_w',
    MAIL_MAILINGS_DELETE = 'bcm_mail_mailings_x',

    MAIL_TEMPLATES_READ = 'bcm_mail_templates_r',
    MAIL_TEMPLATES_WRITE = 'bcm_mail_templates_w',
    MAIL_TEMPLATES_DELETE = 'bcm_mail_templates_x',

    MAIL_MESSAGES_READ = 'bcm_mail_messages_r',
    MAIL_MESSAGES_WRITE = 'bcm_mail_messages_w',
    MAIL_MESSAGES_DELETE = 'bcm_mail_messages_x',

    CONTRACTS_READ = 'bcm_contracts_r',
    CONTRACTS_WRITE = 'bcm_contracts_w',
    CONTRACTS_DELETE = 'bcm_contracts_x',

    PERSONS_READ = 'bcm_persons_r',
    PERSONS_WRITE = 'bcm_persons_w',
    PERSONS_DELETE = 'bcm_persons_x',

    PRODUCTS_READ = 'bcm_products_r',
    PRODUCTS_WRITE = 'bcm_products_w',
    PRODUCTS_DELETE = 'bcm_products_x',

    PRODUCT_CATEGORIES_READ = 'bcm_product_categories_r',
    PRODUCT_CATEGORIES_WRITE = 'bcm_product_categories_w',
    PRODUCT_CATEGORIES_DELETE = 'bcm_product_categories_x',

    // admin only
    // ROLES_AND_PERMISSIONS_READ = 'bcm_roles_and_permissions_r',
    // ROLES_AND_PERMISSIONS_WRITE = 'bcm_roles_and_permissions_w',
    // ROLES_AND_PERMISSIONS_DELETE = 'bcm_roles_and_permissions_x',

    SEPA_RUN_READ = 'bcm_sepa_runs_r',
    // SEPA_RUN_WRITE = 'bcm_sepa_runs_w',
    // SEPA_RUN_DELETE = 'bcm_sepa_runs_d',

    TENANT_RELATIONS_READ = 'bcm_tenant_relations_r',
    TENANT_RELATIONS_WRITE = 'bcm_tenant_relations_w',
    TENANT_RELATIONS_DELETE = 'bcm_tenant_relations_x',

    KEYS_READ = 'bcm_keys_r',
    KEYS_WRITE = 'bcm_keys_w',
    KEYS_DELETE = 'bcm_keys_x',

    SEASONS_READ = 'bcm_seasons_r',
    SEASONS_WRITE = 'bcm_seasons_w',
    SEASONS_DELETE = 'bcm_seasons_x',

    ORDERS_READ = 'bcm_jobs_r',
    ORDERS_WRITE = 'bcm_jobs_w',
    ORDERS_DELETE = 'bcm_jobs_x',

    AVV_SIGN = 'bcm_avv_sign',
    AVV_READ = 'bcm_avv_read',
    DOWNLOAD_REPORTS = 'bcm_download_reports',
    ASSOCIATION_REPORTS = 'bcm_association_reports',

    BANK_READ = 'bcm_banks_r',
    BANK_WRITE = 'bcm_banks_w',
    BANK_DELETE = 'bcm_banks_x',

    IMPORTDATA_READ = 'bcm_importdata_r',
    // IMPORTDATA_WRITE = 'bcm_importdata_w',
    // IMPORTDATA_DELETE = 'bcm_importdata_x',

    CASH_REGISTER_READ = 'bcm_cash_registers_r',
    CASH_REGISTER_WRITE = 'bcm_cash_registers_w',
    CASH_REGISTER_DELETE = 'bcm_cash_registers_x',

    NEWS_READ = 'u2b_news_r',
    NEWS_WRITE = 'u2b_news_w',
    NEWS_DELETE = 'u2b_news_x',

    // CASH_REGISTER_USERS_WRITE = 'bcm_cash_register_users_w',
    // CASH_REGISTER_USERS_DELETE = 'bcm_cash_register_users_x',

    COST_CENTERS_READ = 'bcm_cost_centers_r',
    COST_CENTERS_WRITE = 'bcm_cost_centers_w',
    COST_CENTERS_DELETE = 'bcm_cost_centers_x',

}
