import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { isPlainObject } from '@shared/functions/is-plain-object';

export type NavigationHistoryConfig = {
    label?: string,
    resolveProperty?: string,
    resolvePropertyEntityIdent?: string[],
};
export type NavigationHistoryItem = { event: NavigationEnd, label: string };

@Injectable({providedIn: 'root'})
export class NavigationHistoryService {

    public readonly HISTORY_LIMIT = 10;

    private history: NavigationHistoryItem[] = []; // todo: add typing for data

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    public init(): void {
        // only routes should be in History, which have configured data object!
        this.router
            .events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map((event: NavigationEnd) => {
                    let route = this.activatedRoute.snapshot.root;
                    let historyConfig = route.data?.historyConfig;

                    while (route && (!isPlainObject(historyConfig) || Object.keys(historyConfig).length === 0)) {
                        route = route.firstChild;

                        if (route) {
                            historyConfig = route.data?.historyConfig;
                        }
                    }

                    return {event, historyConfig, route};
                }),
                map(({event, historyConfig, route}) => {
                    const label = this.getLabel(historyConfig, route);
                    return {event, label};
                })
            )
            .subscribe(({event, label}) => {
                if (!label) {
                    return;
                }

                // do not save same url twice
                if (this.history[0]?.event?.urlAfterRedirects === event.urlAfterRedirects) {
                    return;
                }

                if (this.history.length === this.HISTORY_LIMIT) {
                    this.history.pop();
                }

                this.history.unshift({event, label});
            });
    }

    public getHistory(): NavigationHistoryItem[] {
        return this.history;
    }

    private getLabel(historyConfig: NavigationHistoryConfig, route: Route): string | undefined {
        const labelList = [];

        labelList.push(historyConfig?.label);

        if (historyConfig?.resolveProperty && historyConfig?.resolvePropertyEntityIdent) {
            const entity = route.data[historyConfig.resolveProperty] || {};

            labelList.push(this.getObjectProperties(entity, historyConfig.resolvePropertyEntityIdent));
        }

        return labelList.join(' » ') || undefined;
    }

    private getObjectProperties(obj: { [key: string]: string }, properties: string []) {
        return properties.map(property => obj[property]).filter(_ => !!_).join(', '); // filter falsy values
    }
}
