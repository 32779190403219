import { Injectable } from '@angular/core';
import { BcmSettingsApiService } from '@modules/bcm/@shared/services';
import { BcmSettingsState } from './bcm-settings-state.service';
import { Observable, Subscription } from 'rxjs';
import { BcmSettings, BcmSettingsSection, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { map, shareReplay } from 'rxjs/operators';
import { AppNotificationService } from '@core/services/app-notification.service';
import { BcmSockets } from '@modules/bcm/bcm-sockets.service';
import { UserService } from '@core/services/user.service';
import { MapFavourite } from '@shared/models/map-interfaces';

@Injectable({providedIn: 'root'})
export class BcmSettingsFacade {

    static lastSettingsState: BcmSettings;

    get isUpdating$(): Observable<boolean> {
        return this.state.isUpdating$;
    }

    get settings$(): Observable<BcmSettings> {
        return this.state.settings$.pipe(shareReplay(1));
    }

    settings(): BcmSettings {
        return this.state.settings as BcmSettings;
    }

    constructor(private api: BcmSettingsApiService,
                private state: BcmSettingsState,
                private userService: UserService,
                private appNotificationService: AppNotificationService,
                private bcmSockets: BcmSockets) {
        bcmSockets.settings$
            .subscribe((result: { user: { email: string, name: string } }) => {
                if (result === null) {
                    return;
                }
                if (result?.user?.email !== userService.user.email) {
                    this.appNotificationService.showSuccess(`${result.user.name} hat die Einstellungen geändert.`);
                }
                this.loadSettings().subscribe();
            });
    }

    loadSettings(): Observable<BcmSettings> {
        return this.api.getAll()
            .pipe(map(settings => {
                this.state.setSettings(settings);
                BcmSettingsFacade.lastSettingsState = this.state.settings;
                return this.state.settings;
            }));
    }

    updateSection<T extends BcmSettingsSection>(section: BcmSettingsSectionName, settingsSection: T, preventMessage = false): Subscription {
        this.state.startUpdating();
        return this.api.updateSection(section, settingsSection)
            .subscribe(
                () => {
                    if (!preventMessage) {
                        this.appNotificationService.showSuccess('Einstellung(en) erfolgreich gespeichert');
                    }
                    this.state.updateBcmSection(section, settingsSection);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    updateSectionItem<SectionType extends BcmSettingsSection, DataType extends any>(
        sectionKey: BcmSettingsSectionName,
        settingsKey: keyof SectionType,
        data: DataType,
        preventMessage = false
    ): Subscription {
        this.state.startUpdating();
        return this.api.updateSectionItem<SectionType, DataType>(sectionKey, settingsKey, data)
            .subscribe(
                () => {
                    if (!preventMessage) {
                        this.appNotificationService.showSuccess('Einstellung(en) erfolgreich gespeichert');
                    }
                    this.state.updateBcmSectionItem<SectionType, DataType>(sectionKey, settingsKey, data);
                },
                (error) => this.handleError(error),
                () => this.state.stopUpdating()
            );
    }

    private handleError(error: Error): void {
        throw error;
    }
}
