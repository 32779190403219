import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { IBerth, Berth } from '@shared/models/berth';
import { BcmApi2Service } from '@modules/bcm/@shared/services';

@Injectable({
    providedIn: 'root'
})
export class BerthApiService extends BcmApi2Service {

    private RESOURCE_ENDPOINT = 'berths';

    getAll(params: HttpParams = new HttpParams()): Observable<Berth[]> {
        return super.get<IBerth[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IBerth, Berth>(Berth));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    matrix(params: HttpParams = new HttpParams()): Observable<Berth[]> {
        return super.get<Berth[]>(this.RESOURCE_ENDPOINT + '/assignments-matrix', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Berth> {
        return super.get<IBerth>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IBerth, Berth>(Berth));
    }

    add(berth: IBerth | Berth): Observable<Berth> {
        return super.post<IBerth>(this.RESOURCE_ENDPOINT, berth)
            .pipe(parseHttpResponseItem<IBerth, Berth>(Berth));
    }

    update(berth: Berth, berthData: Partial<Berth>): Observable<Berth> {
        return super.put<IBerth>(`${this.RESOURCE_ENDPOINT}/${berth.id}`, berthData)
            .pipe(parseHttpResponseItem<IBerth, Berth>(Berth));
    }

    remove(berth: Berth): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${berth.id}`);
    }
}
