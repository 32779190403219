import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class NumberMinValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(private _min: number,
                private _message?: string) {
        super();
        this.validationError = new BaseValidationError('numberMin', this._message || `Nur Zahlen ab ${_min} erlaubt`);
    }

    public validate({value}: AbstractControl): ValidationErrors {

        if (value == null || this._min == null) {
            return this.IS_VALID;
        }

        return value >= this._min
            ? this.IS_VALID
            : this.IS_INVALID;
    }

}
