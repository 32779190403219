<ng-container *ngIf="person?.id">
    <ng-template #tenantRelation>
        <ng-container *ngIf="showTenantRelations">
            <ng-container *ngIf="showTenantRelationWithLineBreak">
                <br>
            </ng-container>
            <bcm-tenant-relation-colored-label
                    [tenantRelations]="person.activeAndNextTenantRelationAssignments"></bcm-tenant-relation-colored-label>
        </ng-container>
    </ng-template>
    <ng-container *ngIf="disableLink; else withLink">
        <span [attr.data-test]="dataTest">
            <ng-container *ngIf="person?.fullNameBackward; else noName">
                <ng-container *ngIf="prefix">{{ prefix }}</ng-container>
                {{ person.fullNameBackward }}
                <ng-container *ngIf="suffix">{{ suffix }}</ng-container>
                    <ng-container *ngTemplateOutlet="tenantRelation"></ng-container>
            </ng-container>
            <ng-template #noName>
                <abbr matTooltip="Person kann nicht identifiziert werden!">
                    <em>Kein Name</em>
                </abbr>
            </ng-template>
        </span>
    </ng-container>
    <ng-template #withLink>
        <a (click)="click($event)"
           [attr.data-test]="dataTest"
           [bcmRouterLink]="['persons', person.id]">
            <ng-container *ngIf="prefix">{{ prefix }}</ng-container>
            {{ person.fullNameBackward }}
            <ng-container *ngIf="suffix">{{ suffix }}</ng-container>
        </a>
        <ng-container *ngTemplateOutlet="tenantRelation"></ng-container>
    </ng-template>
</ng-container>
