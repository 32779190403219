import { getSalutationString } from './get-salutation-string';
import { isPlainObject } from '@shared/functions/is-plain-object';

export function getAddressString(
    personOrCompany: {
        fullName?: string,
        salutation?: number | string,
        title?: string,
        firstName?: string,
        lastName?: string,
        name?: string,
        form?: string,
        street?: string,
        postCode?: string,
        postcode?: string,
        city?: string,
        country?: { de: string } // todo: add multilingual
    },
    separator = '\n'
): string {
    if (!personOrCompany || !isPlainObject(personOrCompany)) {
        return '';
    }

    const addressParts = [];
    const isPerson = personOrCompany.hasOwnProperty('firstName') && personOrCompany.hasOwnProperty('lastName');

    if (personOrCompany.fullName) {
        addressParts.push(personOrCompany.fullName);
    } else if (isPerson) {
        const person = personOrCompany;

        addressParts.push(
            [
                getSalutationString(person.salutation),
                person.title,
                person.firstName,
                person.lastName
            ].filter(i => !!i).join(' ').replace(/ +/g, ' ').trim()
        );
    } else {
        const company = personOrCompany;

        let companyName = company.name;

        if (company.form && !companyName.includes(company.form)) {
            companyName += ` ${company.form}`;
        }

        addressParts.push(companyName.replace(/ +/g, ' ').trim());
    }

    const {street, postCode, postcode, city, country} = personOrCompany;

    addressParts.push(`${street || ''}`.trim());
    addressParts.push(`${postCode || postcode || ''} ${city || ''}`.trim());
    // todo: add multilingual
    addressParts.push(`${country?.de || ''}`.trim());

    return addressParts.filter(i => !!i).join(separator) || '';
}
