export enum ContractShortCodes {
    DATUM = '[DATUM]',
    UHRZEIT = '[UHRZEIT]',
    JAHR = '[JAHR]',
    MONAT = '[MONAT]',
    MONAT_TEXT = '[MONAT_TEXT]',

    FOLGEJAHR_DATUM = '[FOLGEJAHR_DATUM]',
    FOLGEJAHR_JAHR = '[FOLGEJAHR_JAHR]',

    DATUM_VON = '[DATUM_VON]',
    DATUM_VON_UHRZEIT = '[DATUM_VON_UHRZEIT]',
    DATUM_VON_JAHR = '[DATUM_VON_JAHR]',
    DATUM_VON_MONAT = '[DATUM_VON_MONAT]',
    DATUM_VON_MONAT_TEXT = '[DATUM_VON_MONAT_TEXT]',

    DATUM_BIS = '[DATUM_BIS]',
    DATUM_BIS_UHRZEIT = '[DATUM_BIS_UHRZEIT]',
    DATUM_BIS_JAHR = '[DATUM_BIS_JAHR]',
    DATUM_BIS_MONAT = '[DATUM_BIS_MONAT]',
    DATUM_BIS_MONAT_TEXT = '[DATUM_BIS_MONAT_TEXT]',

    ANREDE = '[ANREDE]',

    // Vertragsempfänger (VE)
    VE_ADRESSE = '[VE_ADRESSE]',
    VE_ADRESSE_UMBRUCH = '[VE_ADRESSE_UMBRUCH]',
    VE_ANREDE = '[VE_ANREDE]',
    VE_NAME = '[VE_NAME]',
    VE_NAME_PLUS_TITEL = '[VE_NAME_PLUS_TITEL]',
    VE_STR = '[VE_STR]',
    VE_PLZ = '[VE_PLZ]',
    VE_STADT = '[VE_STADT]',
    VE_LAND = '[VE_LAND]',
    VE_MAIL = '[VE_MAIL]',
    VE_TEL = '[VE_TEL]',
    VE_MOBIL = '[VE_MOBIL]',
    VE_FAX = '[VE_FAX]',
    VE_WEB = '[VE_WEB]',
    VE_GEBURTSDATUM = '[VE_GEBURTSDATUM]',
    VE_BANK_KONTOINHABER = '[VE_BANK_KONTOINHABER]',
    VE_BANK_NAME = '[VE_BANK_NAME]',
    VE_BANK_IBAN = '[VE_BANK_IBAN]',
    VE_BANK_BIC = '[VE_BANK_BIC]',
    VE_IDENTIFIKATIONSNUMMER = '[VE_IDENTIFIKATIONSNUMMER]',

    // Vertragsaussteller (VA)
    VA_ADRESSE = '[VA_ADRESSE]',
    VA_ADRESSE_UMBRUCH = '[VA_ADRESSE_UMBRUCH]',
    VA_NAME = '[VA_NAME]',
    VA_VORNAME = '[VA_VORNAME]',
    VA_NACHNAME = '[VA_NACHNAME]',
    VA_BANK_KONTOINHABER = '[VA_BANK_KONTOINHABER]',
    VA_BANK_NAME = '[VA_BANK_NAME]',
    VA_BANK_IBAN = '[VA_BANK_IBAN]',
    VA_BANK_BIC = '[VA_BANK_BIC]',
    VA_FAX = '[VA_FAX]',
    VA_MAIL = '[VA_MAIL]',
    VA_MAIL_2 = '[VA_MAIL_2]',
    VA_MOBIL = '[VA_MOBIL]',
    VA_TEL = '[VA_TEL]',
    VA_WEB = '[VA_WEB]',

    BOOT_NAME = '[BOOT_NAME]',
    BOOT_TYP = '[BOOT_TYP]',
    BOOT_MODELL = '[BOOT_MODELL]',
    BOOT_HERSTELLER = '[BOOT_HERSTELLER]',
    BOOT_KENNZEICHEN = '[BOOT_KENNZEICHEN]',
    BOOT_HIN = '[BOOT_HIN]',
    BOOT_LAENGE = '[BOOT_LAENGE]',
    BOOT_BREITE = '[BOOT_BREITE]',

    // Liegeplatz (LP)
    LP_NAME = '[LP_NAME]',
    LP_BREITE = '[LP_BREITE]',
    LP_LAENGE = '[LP_LAENGE]',
    LP_QM = '[LP_QM]',
    LP_STEG_NAME = '[LP_STEG_NAME]',

    // Lagerplätze (LAPL)
    LAPL_NAME = '[LAPL_NAME]',
    LAPL_QM = '[LAPL_QM]',

    ANHANG_TABELLE = '[ANHANG_TABELLE]',

    SIGNATUR = '[SIGNATUR]',
    SIGNATUR_TEXT = '[SIGNATUR_TEXT]',

    // Vertragsmitinhaber (VMI)
    VMI_1_NAME_KOMPLETT = '[VMI_1_NAME_KOMPLETT]',
    VMI_1_STR = '[VMI_1_STR]',
    VMI_1_PLZ = '[VMI_1_PLZ]',
    VMI_1_ORT = '[VMI_1_ORT]',

    VMI_2_NAME_KOMPLETT = '[VMI_2_NAME_KOMPLETT]',
    VMI_2_STR = '[VMI_2_STR]',
    VMI_2_PLZ = '[VMI_2_PLZ]',
    VMI_2_ORT = '[VMI_2_ORT]',

    VMI_3_NAME_KOMPLETT = '[VMI_3_NAME_KOMPLETT]',
    VMI_3_STR = '[VMI_3_STR]',
    VMI_3_PLZ = '[VMI_3_PLZ]',
    VMI_3_ORT = '[VMI_3_ORT]',

    VMI_4_NAME_KOMPLETT = '[VMI_4_NAME_KOMPLETT]',
    VMI_4_STR = '[VMI_4_STR]',
    VMI_4_PLZ = '[VMI_4_PLZ]',
    VMI_4_ORT = '[VMI_4_ORT]',

    // Baukostenzuschuss (BKZ)
    BKZ_BETRAG_NETTO = '[BKZ_BETRAG_NETTO]',
    BKZ_MWST = '[BKZ_MWST]',
    BKZ_MWST_BETRAG = '[BKZ_MWST_BETRAG]',
    BKZ_BETRAG_GESAMT_NETTO = '[BKZ_BETRAG_GESAMT_NETTO]',
    BKZ_BETRAG_GESAMT_BRUTTO = '[BKZ_BETRAG_GESAMT_BRUTTO]',
    BKZ_BETRAG_FAELLIG_ZUM = '[BKZ_BETRAG_FAELLIG_ZUM]',
    BKZ_TZ_1_BETRAG = '[BKZ_TZ_1_BETRAG]',
    BKZ_TZ_2_BETRAG = '[BKZ_TZ_2_BETRAG]',
    BKZ_TZ_1_FAELLIG_ZUM = '[BKZ_TZ_1_FAELLIG_ZUM]',
    BKZ_TZ_2_FAELLIG_ZUM = '[BKZ_TZ_2_FAELLIG_ZUM]',

    // Produkte
    PRODUKTE_BETRAG_NETTO = '[PRODUKTE_BETRAG_NETTO]',
    PRODUKTE_BETRAG_BRUTTO = '[PRODUKTE_BETRAG_BRUTTO]',
    PRODUKTE_BETRAG_MWST = '[PRODUKTE_BETRAG_MWST]',

    // PRODUKT_1_NAME = '[PRODUKT_1_NAME]',
    // PRODUKT_1_BETRAG_NETTO = '[PRODUKT_1_BETRAG_NETTO]',
    // PRODUKT_1_BETRAG_BRUTTO = '[PRODUKT_1_BETRAG_BRUTTO]',
    // PRODUKT_1_BETRAG_MWST = '[PRODUKT_1_BETRAG_MWST]',
    // PRODUKT_1_EINHEIT = '[PRODUKT_1_EINHEIT]',
    // PRODUKT_1_MWST = '[PRODUKT_1_MWST]',
    // 'PRODUKT_1_MWST_INKL_%' = '[PRODUKT_1_MWST_INKL_]',
    //
    // PRODUKT_2_NAME = '[PRODUKT_2_NAME]',
    // PRODUKT_2_BETRAG_NETTO = '[PRODUKT_2_BETRAG_NETTO]',
    // PRODUKT_2_BETRAG_BRUTTO = '[PRODUKT_2_BETRAG_BRUTTO]',
    // PRODUKT_2_BETRAG_MWST = '[PRODUKT_2_BETRAG_MWST]',
    // PRODUKT_2_EINHEIT = '[PRODUKT_2_EINHEIT]',
    // PRODUKT_2_MWST = '[PRODUKT_2_MWST]',
    // 'PRODUKT_2_MWST_INKL_%' = '[PRODUKT_2_MWST_INKL_]',
    //
    // PRODUKT_3_NAME = '[PRODUKT_3_NAME]',
    // PRODUKT_3_BETRAG_NETTO = '[PRODUKT_3_BETRAG_NETTO]',
    // PRODUKT_3_BETRAG_BRUTTO = '[PRODUKT_3_BETRAG_BRUTTO]',
    // PRODUKT_3_BETRAG_MWST = '[PRODUKT_3_BETRAG_MWST]',
    // PRODUKT_3_EINHEIT = '[PRODUKT_3_EINHEIT]',
    // PRODUKT_3_MWST = '[PRODUKT_3_MWST]',
    // 'PRODUKT_3_MWST_INKL_%' = '[PRODUKT_3_MWST_INKL_]',
    //
    // PRODUKT_4_NAME = '[PRODUKT_4_NAME]',
    // PRODUKT_4_BETRAG_NETTO = '[PRODUKT_4_BETRAG_NETTO]',
    // PRODUKT_4_BETRAG_BRUTTO = '[PRODUKT_4_BETRAG_BRUTTO]',
    // PRODUKT_4_BETRAG_MWST = '[PRODUKT_4_BETRAG_MWST]',
    // PRODUKT_4_EINHEIT = '[PRODUKT_4_EINHEIT]',
    // PRODUKT_4_MWST = '[PRODUKT_4_MWST]',
    // 'PRODUKT_4_MWST_INKL_%' = '[PRODUKT_4_MWST_INKL_]',
    //
    // PRODUKT_5_NAME = '[PRODUKT_5_NAME]',
    // PRODUKT_5_BETRAG_NETTO = '[PRODUKT_5_BETRAG_NETTO]',
    // PRODUKT_5_BETRAG_BRUTTO = '[PRODUKT_5_BETRAG_BRUTTO]',
    // PRODUKT_5_BETRAG_MWST = '[PRODUKT_5_BETRAG_MWST]',
    // PRODUKT_5_EINHEIT = '[PRODUKT_5_EINHEIT]',
    // PRODUKT_5_MWST = '[PRODUKT_5_MWST]',
    // 'PRODUKT_5_MWST_INKL_%' = '[PRODUKT_5_MWST_INKL_]',
    //
    // PRODUKT_6_NAME = '[PRODUKT_6_NAME]',
    // PRODUKT_6_BETRAG_NETTO = '[PRODUKT_6_BETRAG_NETTO]',
    // PRODUKT_6_BETRAG_BRUTTO = '[PRODUKT_6_BETRAG_BRUTTO]',
    // PRODUKT_6_BETRAG_MWST = '[PRODUKT_6_BETRAG_MWST]',
    // PRODUKT_6_EINHEIT = '[PRODUKT_6_EINHEIT]',
    // PRODUKT_6_MWST = '[PRODUKT_6_MWST]',
    // 'PRODUKT_6_MWST_INKL_%' = '[PRODUKT_6_MWST_INKL_]',
    //
    // PRODUKT_7_NAME = '[PRODUKT_7_NAME]',
    // PRODUKT_7_BETRAG_NETTO = '[PRODUKT_7_BETRAG_NETTO]',
    // PRODUKT_7_BETRAG_BRUTTO = '[PRODUKT_7_BETRAG_BRUTTO]',
    // PRODUKT_7_BETRAG_MWST = '[PRODUKT_7_BETRAG_MWST]',
    // PRODUKT_7_EINHEIT = '[PRODUKT_7_EINHEIT]',
    // PRODUKT_7_MWST = '[PRODUKT_7_MWST]',
    // 'PRODUKT_7_MWST_INKL_%' = '[PRODUKT_7_MWST_INKL_]',
    //
    // PRODUKT_8_NAME = '[PRODUKT_8_NAME]',
    // PRODUKT_8_BETRAG_NETTO = '[PRODUKT_8_BETRAG_NETTO]',
    // PRODUKT_8_BETRAG_BRUTTO = '[PRODUKT_8_BETRAG_BRUTTO]',
    // PRODUKT_8_BETRAG_MWST = '[PRODUKT_8_BETRAG_MWST]',
    // PRODUKT_8_EINHEIT = '[PRODUKT_8_EINHEIT]',
    // PRODUKT_8_MWST = '[PRODUKT_8_MWST]',
    // 'PRODUKT_8_MWST_INKL_%' = '[PRODUKT_8_MWST_INKL_]',
    //
    // PRODUKT_9_NAME = '[PRODUKT_9_NAME]',
    // PRODUKT_9_BETRAG_NETTO = '[PRODUKT_9_BETRAG_NETTO]',
    // PRODUKT_9_BETRAG_BRUTTO = '[PRODUKT_9_BETRAG_BRUTTO]',
    // PRODUKT_9_BETRAG_MWST = '[PRODUKT_9_BETRAG_MWST]',
    // PRODUKT_9_EINHEIT = '[PRODUKT_9_EINHEIT]',
    // PRODUKT_9_MWST = '[PRODUKT_9_MWST]',
    // 'PRODUKT_9_MWST_INKL_%' = '[PRODUKT_9_MWST_INKL_]',
    //
    // PRODUKT_10_NAME = '[PRODUKT_10_NAME]',
    // PRODUKT_10_BETRAG_NETTO = '[PRODUKT_10_BETRAG_NETTO]',
    // PRODUKT_10_BETRAG_BRUTTO = '[PRODUKT_10_BETRAG_BRUTTO]',
    // PRODUKT_10_BETRAG_MWST = '[PRODUKT_10_BETRAG_MWST]',
    // PRODUKT_10_EINHEIT = '[PRODUKT_10_EINHEIT]',
    // PRODUKT_10_MWST = '[PRODUKT_10_MWST]',
    // 'PRODUKT_10_MWST_INKL_%' = '[PRODUKT_10_MWST_INKL_]',

    // Schlüssel
    SCHLUESSEL_NUMMER = '[SCHLUESSEL_NUMMER]',
}

export const contractShortCodeEntries = Object.entries(ContractShortCodes);

export const contractShortCodeValues = Object.values(ContractShortCodes);
