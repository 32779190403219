import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { StateResetService } from '@bcmServices/state-reset.service';

export class BaseStateService<T> {

    private state$: BehaviorSubject<T>;

    protected get state(): T {
        return this.state$.getValue();
    }

    private _updatingAll$ = new BehaviorSubject<boolean>(false);

    public get isUpdatingAll$(): Observable<boolean> {
        return this._updatingAll$.asObservable();
    }

    private _updating$ = new BehaviorSubject<boolean>(false);

    public get isUpdating$(): Observable<boolean> {
        return this._updating$.asObservable();
    }

    constructor(private readonly initialState: T) {
        this.state$ = new BehaviorSubject<T>(initialState);
        StateResetService.onReset.subscribe(() => this.resetState());
    }

    public startUpdatingAll(): void {
        this._updatingAll$.next(true);
    }

    public stopUpdatingAll(): void {
        this._updatingAll$.next(false);
    }

    public startUpdating(): void {
        this._updating$.next(true);
    }

    public stopUpdating(): void {
        this._updating$.next(false);
    }

    protected select<K>(mapFn: (state: T) => K): Observable<K> {
        return this.state$.asObservable().pipe(
            map((state: T) => mapFn(state)),
            distinctUntilChanged()
        );
    }

    protected setState(newState: Partial<T>): void {
        this.state$.next({
            ...this.state,
            ...newState,
        });
    }

    private resetState(): void {
        this.setState(this.initialState);
    }

}
