import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DirectivesModule } from '@shared/directives/directives.module';
import { FormWidgetDateRangeComponent } from '@bcm-work-flows/@form-widgets/date-range/form-widget-date-range.component';
import { DateTimeInputModule } from '@sharedComponents/form/date-time-input/date-time-input.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
    declarations: [FormWidgetDateRangeComponent],
    imports: [
        FuseSharedModule,
        SharedModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule,
        DirectivesModule,
        DateTimeInputModule,
        MatTooltipModule,
    ],
    exports: [FormWidgetDateRangeComponent]
})
export class FormWidgetDateRangeModule {
    
}
