import { IProduct, Product } from './product';
import { PayableOption } from '@shared/models/payable-option';
import { tryParseDate } from '@shared/functions/try-parse-date';
import { BcmDynamicPrice, BcmDynamicPriceDto } from '@shared/models/bcm-dynamic-price';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { BerthBoatAssignment, IBerthBoatAssignment } from '@shared/models/berth-boat-assignment';
import { BcmCostCenter, BcmCostCenterDto } from '@shared/models/bcm-cost-center';

export enum ProductSubscriptionType {
    Person = 'person',
    Company = 'company',
    Boat = 'boat',
    TenantRelation = 'tenantRelation',
    BerthReservation = 'berthReservation',
}

export enum CustomPriceAdaptation {
    NONE, SUM, PERCENTAGE
}

export interface ProductSubscriptionDto {
    id?: number;
    payableOption: PayableOption;
    product: IProduct;
    person?: IPerson;
    company?: ICompany;
    boat?: IBoat;
    quantity: number;
    type: ProductSubscriptionType;
    vestingPeriodFrom: string | Date;
    vestingPeriodUntil: string | Date;
    fromDate: string | Date;
    toDate: string | Date;
    lastDayOfMonth: boolean;

    customTitle?: string;
    customPrice?: number;
    customPriceAdaptation?: CustomPriceAdaptation;

    dynamicPrice?: BcmDynamicPriceDto;

    tenantRelationName: string;

    berthAssignment: IBerthBoatAssignment;

    costCenter: BcmCostCenterDto;
}

export class ProductSubscription {
    id: number;
    payableOption: PayableOption;
    product: Product;
    person?: Person;
    company?: Company;
    boat?: Boat;
    quantity: number;
    type: ProductSubscriptionType;
    vestingPeriodFrom: Date;
    vestingPeriodUntil: Date;
    fromDate: Date;
    toDate: Date;
    lastDayOfMonth: boolean;

    customTitle: string;
    customPrice: number;
    customPriceAdaptation: CustomPriceAdaptation;

    dynamicPrice?: BcmDynamicPrice;

    tenantRelationName: string;

    berthAssignment: BerthBoatAssignment;

    costCenter: BcmCostCenter;

    constructor(subscriptionDto: ProductSubscriptionDto) {
        this.id = subscriptionDto.id;
        this.payableOption = subscriptionDto.payableOption;
        this.product = new Product(subscriptionDto.product);
        this.person = subscriptionDto.person ? new Person(subscriptionDto.person) : null;
        this.company = subscriptionDto.company ? new Company(subscriptionDto.company) : null;
        this.boat = subscriptionDto.boat ? new Boat(subscriptionDto.boat) : null;
        this.quantity = subscriptionDto.quantity;
        this.type = subscriptionDto.type;
        this.vestingPeriodFrom = tryParseDate(subscriptionDto.vestingPeriodFrom);
        this.vestingPeriodUntil = tryParseDate(subscriptionDto.vestingPeriodUntil);
        this.fromDate = tryParseDate(subscriptionDto.fromDate);
        this.toDate = tryParseDate(subscriptionDto.toDate);
        this.lastDayOfMonth = subscriptionDto.lastDayOfMonth;
        this.customTitle = subscriptionDto.customTitle;
        this.customPrice = subscriptionDto.customPrice;
        this.customPriceAdaptation = subscriptionDto.customPriceAdaptation || CustomPriceAdaptation.NONE;
        this.dynamicPrice = subscriptionDto.dynamicPrice ? new BcmDynamicPrice({
            ...subscriptionDto.dynamicPrice,
            taxRate: subscriptionDto.product?.taxRate
        }) : null;
        this.tenantRelationName = subscriptionDto.tenantRelationName || '';
        this.berthAssignment = subscriptionDto.berthAssignment ? new BerthBoatAssignment(subscriptionDto.berthAssignment) : null;
        this.costCenter = subscriptionDto.costCenter ? new BcmCostCenter(subscriptionDto.costCenter) : null;
    }
}
