import { NgModule } from '@angular/core';
import { TranslationPipe } from '@core/translation/translation.pipe';

@NgModule({
    declarations: [TranslationPipe],
    exports: [TranslationPipe],
})
export class TranslationModule {

}
