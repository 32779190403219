import { tryParseDate } from '@shared/functions/try-parse-date';

export interface BcmFileDto {
    id: number;
    categoryName: null;
    fileBlob: File;
    fileExtension: string;
    fileName: string;
    insertedBy: string;
    insertedOn: string;
    lastUpdateBy: string;
    lastUpdateOn: string;
    mimeType: string;
    name: string;
    note: string;
}

// `bcm_master`.`bcm_file_categories`
export interface BcmFileCategory {
    id: number;
    uniqueName: string;
    categoryGroup: string;
}


export class BcmFile {
    id: number;
    category: null;
    fileBlob: File;
    fileExtension: string;
    fileName: string;
    insertedBy: string;
    insertedOn: Date | null;
    lastUpdateBy: string;
    lastUpdateOn: Date | null;
    mimeType: string;
    name: string;
    note: string;

    constructor(bcmFileDto: BcmFileDto) {
        this.id = bcmFileDto.id;
        this.mimeType = bcmFileDto.mimeType;
        this.category = bcmFileDto.categoryName;
        this.fileBlob = bcmFileDto.fileBlob;
        this.fileExtension = bcmFileDto.fileExtension;
        this.fileName = bcmFileDto.fileName;
        this.name = bcmFileDto.name;
        this.note = bcmFileDto.note;
        this.insertedBy = bcmFileDto.insertedBy;
        this.insertedOn = tryParseDate(bcmFileDto.insertedOn);
        this.lastUpdateBy = bcmFileDto.lastUpdateBy;
        this.lastUpdateOn = tryParseDate(bcmFileDto.lastUpdateOn);
    }
}
