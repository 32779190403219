import { tryParseDate } from '@shared/functions/try-parse-date';

export interface BcmCostCenterDto {
    id: number;
    name: string;
    abbreviation: string;
    number: string;
    description: string;

    deletedOn: string;
    deletedBy: string;
    insertedOn: string;
    insertedBy: string;
    lastUpdatedOn: string;
    lastUpdatedBy: string;
}

export class BcmCostCenter {
    id: number;
    name: string;
    abbreviation: string;
    number: string;
    description: string;

    deletedOn: Date | null;
    deletedBy: string;
    insertedOn: Date | null;
    insertedBy: string;
    lastUpdatedOn: Date | null;
    lastUpdatedBy: string;

    constructor(costCenterDto: BcmCostCenterDto = {} as BcmCostCenterDto) {
        this.id = costCenterDto.id;
        this.name = costCenterDto.name;
        this.abbreviation = costCenterDto.abbreviation;
        this.number = costCenterDto.number;
        this.description = costCenterDto.description;

        this.deletedOn = tryParseDate(costCenterDto.deletedOn);
        this.deletedBy = costCenterDto.deletedBy;
        this.insertedOn = tryParseDate(costCenterDto.insertedOn);
        this.insertedBy = costCenterDto.insertedBy;
        this.lastUpdatedOn = tryParseDate(costCenterDto.lastUpdatedOn);
        this.lastUpdatedBy = costCenterDto.lastUpdatedBy;
    }

    toString(): string {
        return [
            this.name,
            this.abbreviation ? `(${this.abbreviation})` : undefined
        ].filter(_ => !!_).join(' ');
    }
}
