export enum BcmTenantPermission {
    ACCOUNTING_B2B = 'accounting_b2b',
    PERSONS = 'persons',
    ORGANISATIONS = 'organisations',
    TENANT_RELATIONS = 'tenant_relations',
    PRODUCTS = 'products',
    BOATS = 'boats',
    BERTH = 'berths',
    BERTH_GRAPHICALLY = 'berths_graphically',
    WINTER_STORAGES = 'winter_storages',
    BERTH_SCHEDULER = 'berths_scheduler',
    CASH = 'cash',
    ELECTRIC_METERS = 'electric_meters',
    INVOICES = 'invoices',
    INVOICES_MARK_AS_PAID = 'invoices_mark_as_paid',
    INVOICE_COLLECTION = 'invoice_collection',
    INVOICE_MAILING = 'invoice_mailing',
    IMPORTDATA = 'import',
    DATEV = 'datev',
    SEPA = 'sepa',
    MESSAGES_EMAIL = 'messages_mail',
    CONTRACT_MANAGER = 'contract_manager',
    REPORTS = 'reports',
    ASSOCIATION_REPORTS = 'association_reports',
    ROLES_PERMISSIONS = 'roles_permissions',
    DOCUMENTS_MANAGEMENT = 'documents_management',
    KEYS = 'keys',
    SEASONS = 'seasons',
    BANK = 'bank',
    DOCKSITE = 'docksite',
    DATA_RESTORE = 'data-restore',
    NEWS = 'news',
    COST_CENTERS = 'cost_centers',
}
