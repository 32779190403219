import { tryParseDate } from '@shared/functions/try-parse-date';
import { BcmCashRegisterUser, BcmCashRegisterUserDto } from '@shared/models/bcm-cash-register-user';
import { BcmUser } from '@modules/bcm/bcm-user';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { BcmReceipt, BcmReceiptDto } from '@shared/models/bcm-receipt';

export interface BcmCashRegisterDto {
    id?: number;

    clientId?: string;
    title?: string;

    cashRegisterUsers?: BcmCashRegisterUserDto[];
    receipts?: BcmReceiptDto[];
    endOfDayDocuments?: TseDocument[];

    openedBy?: BcmUser;
    closedBy?: BcmUser;

    lockedBy?: BcmUser;

    currentUser?: BcmCashRegisterUserDto;

    openedOn?: Date;
    closedOn?: Date;

    receiptPrinterIP?: string;
    receiptPrinterPort?: string;

    note?: string;
    lockedUntil?: Date;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmCashRegister {
    id: number;

    clientId: string;
    title?: string;

    cashRegisterUsers?: BcmCashRegisterUser[];
    receipts: BcmReceipt[];
    endOfDayDocuments: TseDocument[];

    openedBy?: BcmUser;
    closedBy?: BcmUser;

    lockedBy?: BcmUser;
    lockedUntil?: Date;

    currentUser?: BcmCashRegisterUser;

    openedOn?: Date;
    closedOn?: Date;

    receiptPrinterIP?: string;
    receiptPrinterPort?: string;

    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    isOpen?: boolean;
    loggedInUsers: BcmCashRegisterUser[];

    get locked() {
        return this.lockedUntil && this.lockedUntil > new Date();
    }

    get lockedByName() {
        return this.lockedBy?.fullName || 'Unbekannt';
    }

    constructor(dto: BcmCashRegisterDto) {
        this.id = dto.id;

        this.clientId = dto.clientId;
        this.title = dto.title;

        this.cashRegisterUsers = (dto.cashRegisterUsers || []).map(user => new BcmCashRegisterUser(user));
        this.receipts = (dto.receipts || []).map(receipt => new BcmReceipt(receipt));
        this.endOfDayDocuments = dto.endOfDayDocuments || [];

        this.openedBy = dto.openedBy ? new BcmUser(dto.openedBy) : null;
        this.closedBy = dto.closedBy ? new BcmUser(dto.closedBy) : null;

        this.lockedBy = dto.lockedBy ? new BcmUser(dto.lockedBy) : null;
        this.lockedUntil = dto.lockedUntil ? tryParseDate(dto.lockedUntil) : null;

        this.currentUser = dto.currentUser ? new BcmCashRegisterUser(dto.currentUser) : null;

        this.openedOn = tryParseDate(dto.openedOn);
        this.closedOn = tryParseDate(dto.closedOn);

        this.receiptPrinterIP = dto.receiptPrinterIP;
        this.receiptPrinterPort = dto.receiptPrinterPort;

        this.note = dto.note;

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;

        this.isOpen = !this.closedOn && !!this.openedOn;
        this.loggedInUsers = this.cashRegisterUsers.filter(crUser => crUser.loggedIn);

    }

}


