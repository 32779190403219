import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class U2BEmailValidator extends BaseValidator {

    public validationError: BaseValidationError;

    constructor(public message = 'Bitte überprüfe die eingegebene E-Mail-Adresse. Beachte das E-Mail Format',
                private optional = false) {
        super();
        this.validationError = new BaseValidationError('email', message);
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return (this.optional && control?.value == null) || this.isValidMail(control?.value)
            ? this.IS_VALID
            : this.IS_INVALID;
    }

    public isValidMail(mail: string): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return Validators.email({value: mail} as AbstractControl) === null && re.test(String(mail).toLowerCase());
    }
}
