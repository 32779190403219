import { isFunction } from './is-function';
import { mimeTypes } from 'mime-wrapper';

export function downloadFile(file: any, documentTitle: string, fileExt?: string, mimeType?: string): void {

    if (isFunction(navigator['msSaveOrOpen']) || isFunction(navigator['msSave'])) {
        throw Error(`Download nicht möglich! Der Internet Explorer ist sehr veraltet und wird von uns nicht unterstützt.`);
    }

    if (!documentTitle) {
        throw Error(`Download nicht möglich, Dateinamen nicht angegeben.`);
    }

    if (!fileExt) {
        fileExt = mimeTypes.getExtension(mimeType);
    }

    if (!fileExt && documentTitle.includes('.')) {
        const documentTitleParts = documentTitle.split('.');
        fileExt = documentTitleParts[documentTitleParts.length - 1];
    }

    if (!fileExt) {
        throw Error(`Download nicht möglich, Dateiendung nicht angegeben.`);
    }

    const fileName = documentTitle.includes(`.${fileExt}`)
        ? documentTitle
        : `${documentTitle}.${fileExt}`;

    const link: HTMLAnchorElement = document.createElement('a');
    // create a URI pointing to our 
    link.href = URL.createObjectURL(file);
    link.download = fileName;

    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the  uses a lot of memory
    window.addEventListener('focus', () => URL.revokeObjectURL(link.href), {once: true});
}
