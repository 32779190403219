import { tryParseDate } from '@shared/functions/try-parse-date';
import { TseDocument } from '@shared/models/tse/TseDocument';
import { BcmUser } from '@modules/bcm/bcm-user';

export interface BcmCashRegisterEndOfDayDocumentDto {
    id?: number;

    documentId?: string;
    documentContent?: string;

    openingCash?: number;
    exceptedClosingCash?: number;
    actualClosingCash?: number;

    cashAdd?: number;
    cashLift?: number;

    difference?: number;
    total?: number;
    totalCash?: number;
    totalCashless?: number;

    insertedByUser?: BcmUser;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmCashRegisterEndOfDayDocument {
    id: number;

    documentId: string;
    tseDocument: TseDocument;

    openingCash: number;
    exceptedClosingCash: number;
    actualClosingCash: number;

    cashAdd: number;
    cashLift: number;

    difference: number;
    total: number;
    totalCash?: number;
    totalCashless?: number;

    insertedByUser?: BcmUser;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(dto: BcmCashRegisterEndOfDayDocumentDto) {
        this.id = dto.id;

        this.documentId = dto.documentId;
        this.tseDocument = JSON.parse(dto.documentContent);

        this.openingCash = dto.openingCash;
        this.exceptedClosingCash = dto.exceptedClosingCash;
        this.actualClosingCash = dto.actualClosingCash;

        this.cashAdd = dto.cashAdd;

        this.cashLift = dto.cashLift;

        this.difference = dto.difference;
        this.total = dto.total;
        this.totalCash = dto.totalCash;
        this.totalCashless = dto.totalCashless;

        this.insertedByUser = dto.insertedByUser ? new BcmUser(dto.insertedByUser) : null;

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;
    }

}


