<ng-container *ngIf="boat">
    <ng-container *ngIf="disableLink || !viewData; else withLink">
        <span [attr.data-test]="dataTest">
            {{viewData || '&nbsp;'}}
        </span>
    </ng-container>
    <ng-template #withLink>
        <a (click)="click($event)"
           [attr.data-test]="dataTest"
           [bcmRouterLink]="['boats', boat.id]">
            <ng-container *ngIf="viewData; else default">
                {{viewData}}
            </ng-container>
            <ng-template #default>
                <abbr [matTooltip]="'boatCannotBeIdentified' | translate">
                    <em>Kein Identifikator</em>
                </abbr>
            </ng-template>
        </a>
    </ng-template>
</ng-container>
