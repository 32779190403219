<ng-container *ngIf="parentFormGroup">
    <form [formGroup]="parentFormGroup" class="form p-16">
        <ng-container *ngFor="let voucher of selectedVouchers; let i = index">
            <form-widget-voucher (voucherRemoved)="removeVoucherByIndex($event)"
                                 (voucherUpdated)="updateVoucher($event)"
                                 [givenVouchers]="givenVouchers"
                                 [filteredVouchers]="filteredVouchers"
                                 [parentFormGroup]="vouchersFormGroup"
                                 [selectVoucher]="voucher"
                                 [appearance]="'outline'"
                                 [inputLabel]="(i+1) + '. ' + ('voucher' | translate)"
                                 [index]="i"
                                 [topListLabel]="('vouchers' | translate) + ' von ' + (dynamicPriceContext?.person?.id ? dynamicPriceContext?.person?.toString() : dynamicPriceContext?.company?.toString())"
                                 [slimmedView]="true"></form-widget-voucher>
        </ng-container>
        <ng-container *ngIf="filteredVouchers?.length > 0 || selectedVouchers?.length > 0; else noVouchers">
            <form-widget-voucher (voucherSelected)="addVoucher($event)"
                                 [personOrCompanyName]="dynamicPriceContext?.person?.toString() || dynamicPriceContext?.company?.toString()"
                                 [givenVouchers]="givenVouchers"
                                 [filteredVouchers]="filteredVouchers"
                                 [clearAfterSelection]="true"
                                 [parentFormGroup]="vouchersFormGroup"
                                 [appearance]="'outline'"
                                 [inputLabel]="selectedVouchers.length > 0 ? ('selectAnotherVoucher' | translate) : ('selectVoucher' | translate)"
                                 [topListLabel]="('vouchers' | translate) + ' von ' + (dynamicPriceContext?.person?.id ? dynamicPriceContext?.person?.toString() : dynamicPriceContext?.company?.toString())"
                                 [slimmedView]="true"></form-widget-voucher>
        </ng-container>
        <ng-template #noVouchers>
            <u2b-message type="warning">
                {{ 'noVoucherForGivenContext' | translate }}
            </u2b-message>
        </ng-template>
    </form>

    <ng-container *ngIf="voucherPositions && (filteredVouchers?.length > 0 || selectedVouchers?.length > 0)">
        <div class="info-box p-16">
            <u2b-message type="info">
                Beim Klick auf den Button "Speichern" werden die nachfolgenden Positionen erstellt.
            </u2b-message>
            <table class="mt-8">
                <thead>
                <td class="text-bold">Position</td>
                <td class="text-bold">{{ 'voucher' | translate }}</td>
                <td class="text-right text-bold">Menge</td>
                <td class="text-right text-bold">Preis</td>
                </thead>
                <tbody>
                <tr class="mt-8" *ngFor="let position of voucherPositions; let i = index">
                    <td>{{ i + 1 }}. Position</td>
                    <td>{{ position.voucher?.title || '' }}</td>
                    <td class="text-right">{{ position.quantity }}</td>
                    <td class="text-right">{{ position.totalPrice | bcmDynamicCurrency }}</td>
                </tr>
                <!--                    <tr class="mt-8" *ngFor="let voucher of selectedVouchers; let i = index">-->
                <!--                        <td>{{i + 1}}. Position</td>-->
                <!--                        <td>{{voucher.title}}</td>-->
                <!--                        <td class="text-right">{{voucherPositions[i].quantity}}</td>-->
                <!--                        <td class="text-right">{{0 | bcmDynamicCurrency}}</td>-->
                <!--                    </tr>-->
                <!--                    <ng-container *ngIf="quantityLeftWithoutVoucher > 0">-->
                <!--                        <tr>-->
                <!--                            <td>{{selectedVouchers.length + 1}}. Position</td>-->
                <!--                            <td></td>-->
                <!--                            <td class="text-right">{{quantityLeftWithoutVoucher}}</td>-->
                <!--                            <td class="text-right">{{(quantityLeftWithoutVoucher * price) | bcmDynamicCurrency}}</td>-->
                <!--                        </tr>-->
                <!--                    </ng-container>-->
                </tbody>
            </table>

        </div>
    </ng-container>
</ng-container>
