<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <div class="title dialog-title">
                <ng-content select="[u2bModalHeadline]"></ng-content>
            </div>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="closeDialog()"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <ng-scrollbar [autoHeightDisabled]="false" [disabled]="disableScrollableContent">
        <div mat-dialog-content class="p-16 p-sm-24 m-0">
            <ng-content></ng-content>
        </div>
    </ng-scrollbar>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">

        <div class="button-group">
            <!-- attention: We need no whitespace between div and ng-content !!! -->
            <div #leftBtnRef><ng-content select="[modalButtonLeft]"></ng-content></div>
            <ng-container *ngIf="!leftBtnRef.children.length">
                <button data-test="B-Abbrechen" mat-button
                        (click)="closeDialog()"
                        class="save-button mat-primary"
                        aria-label="Abbrechen">
                    <mat-icon>cancel</mat-icon>
                    Abbrechen
                </button>
            </ng-container>
        </div>

        <div class="button-group">
            <ng-content select="[modalButtonRight]"></ng-content>
        </div>
    </div>
</div>
