<mat-chip-list #chipList>

    <mat-chip *ngFor="let chip of selectedItems"
              [removable]="true"
              [disableRipple]="true"
              [selectable]="false"
              (removed)="removeChip(chip, trigger)">
        {{ chip[dataIdentAttribute] }}
        <ng-container *ngIf="hasRemove">
            <mat-icon matChipRemove>cancel</mat-icon>
        </ng-container>
    </mat-chip>

    <div fxFlex=100>
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <input #input
                   #trigger="matAutocompleteTrigger"
                   matInput
                   [formControl]="itemCtrl"
                   [matChipInputFor]="chipList"
                   [matChipInputAddOnBlur]="true"
                   [placeholder]="placeholder"
                   [errorStateMatcher]="errorStateMatcher"
                   [matAutocomplete]="matAutocomplete ? matAutocomplete : null"
                   (matChipInputTokenEnd)="addChip($event, input)"
                   data-test="FI-Chips"/>

            <button mat-icon-button matSuffix matTooltip="Eingabefeld zurücksetzen" *ngIf="!!itemCtrl.value" (click)="clearInput()">
                <mat-icon>backspace</mat-icon>
            </button>
        </div>
    </div>

    <mat-autocomplete *ngIf="filteredItems"
                      #chipListAutocomplete="matAutocomplete"
                      fxFlex="100"
                      [displayWith]="displayWith"
                      (optionSelected)="selected($event)">
        <mat-option class="sticky-option" disabled>
            <mat-icon (click)="trigger.closePanel()">close</mat-icon>
        </mat-option>
        <ng-container *ngFor="let item of filteredItems | async">
            <mat-option [value]="item"
                        [disabled]="item.disabled"
                        [matTooltip]="item.disabledReason"
                        [matTooltipDisabled]="!item.disabled || !item.disabledReason"
                        (click)="trigger.openPanel()">
                {{ item[dataIdentAttribute] }}
            </mat-option>
        </ng-container>

        <mat-option *ngIf="(filteredItems | async).length === 0" disabled>
            Keine Einträge gefunden
        </mat-option>
    </mat-autocomplete>

</mat-chip-list>
