import { Component, Inject, OnDestroy } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';

import { Subject } from 'rxjs';

import { U2bValidators } from '@shared/validators/validators';
import { AppNotificationService } from '@core/services/app-notification.service';

import { BcmUser } from '@modules/bcm/bcm-user';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';

import { OrdersApi } from '@modules/bcm/docksite-order/orders-api.service';


@Component({
    selector: 'assign-user',
    templateUrl: './assign-user.component.html',
    styleUrls: ['./assign-user.component.scss']
})

export class AssignUserComponent implements OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    isSaving: boolean;
    form: FormGroup;

    constructor(
        private _dialogRef: MatDialogRef<AssignUserComponent>,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
        private _ordersApi: OrdersApi,
        @Inject(MAT_DIALOG_DATA) public data) {
        this.form = this._formBuilder.group({
            user: [null, [U2bValidators.required('Bitte einen Benutzer auswählen.')]]
        });
    }

    onSave(): void {
        const body: any = {
            ...this.data.order,
            id: this.data.order?.id,
            assignedUser: this.form.get('user').value.user?.id,
        };

        this._ordersApi.update(body, false)
            .subscribe((updatedOrder) => {
                this.isSaving = false;
                this._dialogRef.close(updatedOrder);
                this._appNotificationService.showSuccess('Die Daten wurden erfolgreich gespeichert.');
            });
    }

    onClickClose(): void {
        this._dialogRef.close();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }
}
