type StringOrNumber = (string | number);

export function arrayReduceTo<
    T extends { [prop in StringOrNumber]: any },
    K extends keyof Pick<T, {
        [Key in keyof T]: T[Key] extends StringOrNumber ? Key : never
    }[keyof T]> = keyof Pick<T, {
        [Key in keyof T]: T[Key] extends StringOrNumber ? Key : never
    }[keyof T]>,
>(array: T[], property: K): K[] | T[] {
    if (!property) {
        return array;
    }
    return (array || []).map(item => item[property]) as K[];
}
