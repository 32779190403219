<div [formGroup]="formGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Name</mat-label>
        <input matInput data-test="FI-Name Deines Yachtclubs / Bootsclub" type="text" formControlName="name">
        <mat-error>{{formGroup.get('name').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Anzahl der Liegeplätze</mat-label>
        <input matInput data-test="FI-Anzahl der Liegeplätze" type="text" formControlName="numberOfBerths">
        <mat-error>{{formGroup.get('numberOfBerths').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Version</mat-label>
        <mat-select formControlName="version" data-test="FI-Version">
            <mat-option [value]="BcmVersions.Premium">{{BcmVersions.Premium}}</mat-option>
            <mat-option [value]="BcmVersions.Prestige">{{BcmVersions.Prestige}}</mat-option>
            <mat-option [value]="BcmVersions.Excellent">{{BcmVersions.Excellent}}</mat-option>
        </mat-select>
        <mat-error>{{formGroup.get('version').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <div fxFlex="100">
        <mat-checkbox formControlName="isDemo">
            Demohafen
        </mat-checkbox>
    </div>

    <ng-container *ngIf="formGroup.get('isDemo').value">
        <app-input-date-time formControlName="isDemoUntil" appearance="fill">
            <label>Demo bis</label>
        </app-input-date-time>

        <div fxFlex="100">
            <mat-checkbox formControlName="addDemoData">
                Demodaten anlegen
            </mat-checkbox>
        </div>
    </ng-container>

    <h3 fxFlex="100">Adresse / Standort</h3>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Straße</mat-label>
        <input matInput data-test="FI-Straße" type="text" formControlName="street">
        <mat-error>{{formGroup.get('street').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="40" floatLabel="always">
        <mat-label>PLZ</mat-label>
        <input matInput data-test="FI-PLZ" type="text" formControlName="postcode">
        <mat-error>{{formGroup.get('postcode').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="60" floatLabel="always">
        <mat-label>Stadt</mat-label>
        <input matInput data-test="FI-Stadt" type="text" formControlName="city">
        <mat-error>{{formGroup.get('city').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Land</mat-label>
        <mat-select formControlName="country" data-test="FI-Land">
            <mat-option [value]="'Deutschland'">Deutschland (DE)</mat-option>
            <mat-option [value]="'Schweiz'">Schweiz (CH)</mat-option>
        </mat-select>
        <mat-error>{{formGroup.get('country').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <h3 fxFlex="100">Kontaktdaten</h3>

    <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
        <mat-label>E-Mail Adresse</mat-label>
        <input matInput data-test="FI-E-Mail Adresse" type="text" formControlName="mail">
        <mat-error>{{formGroup.get('mail').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
        <mat-label>Telefonnummer</mat-label>
        <input matInput data-test="FI-Telefonnummer" type="text" formControlName="phone">
        <mat-error>{{formGroup.get('phone').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Webseite</mat-label>
        <input matInput data-test="FI-Webseite" type="text" formControlName="website">
        <mat-error>{{formGroup.get('website').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

</div>
