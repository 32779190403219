import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITenant, Tenant } from '@shared/models/tenant';
import { AppUser } from '@core/services/user.service';
import { U2bAdminGenericApi } from '@modules/admin/state/u2b-admin-generic.api';

export class U2bAdminTenantsApi extends U2bAdminGenericApi<Tenant, ITenant> {

    // AVV
    getAvv(tenant: Tenant, params: HttpParams = new HttpParams()): Observable<any> {
        return super.get(`${super.resourceConfig.endpointPath}/avv`, params);
    }

    getAvvPdf(tenant: Tenant, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.resourceConfig.endpointPath}/avv/pdf`, params);
    }

    // Users
    addUser(tenant: Tenant, body: Partial<AppUser> = {}): Observable<AppUser> {
        return super.post<AppUser>(`${this.resourceConfig.endpointPath}/users`, body);
    }

    removeUser(tenant: Tenant, user: AppUser): Observable<unknown> {
        return super.delete<unknown>(`${this.resourceConfig.endpointPath}/users/${user.id}`);
    }
}

