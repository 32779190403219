import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { BcmService } from '@modules/bcm/bcm.service';
import { BcmSupportedCurrencyCodes } from '@modules/bcm/@shared/enums/supported-currencies';
import { roundNumber, RoundNumberFactor } from '@modules/bcm/@shared/pipes/dynamic-price-rounded.pipe';

export function toCurrencyString(value: any, digitsInfo?: string, locale?: string): any {

    if (value == null) {
        value = 0;
    }

    const currencyCode = BcmService.currentCurrencyCode || BcmSupportedCurrencyCodes.EUR;
    const isSwissCurrency = currencyCode === BcmSupportedCurrencyCodes.CHF;

    let result: string;

    result = new CurrencyPipe(BcmService.currentLocale).transform(
        // do not round swiss centimes here, it depends on tenant setting.
        // See src/app/modules/bcm/@shared/pipes/dynamic-price-rounded.pipe.ts
        roundNumber(value, RoundNumberFactor.TwoDecimals),
        currencyCode,
        'symbol',
        digitsInfo || BcmService.currentNumberFormatDigits,
        locale || BcmService.currentLocale
    );

    if (isSwissCurrency && value < 0) {
        return result.replace(/-(?=[^-]*$)/, ' -');
    }

    // any transformations you need

    return result;
}

@Pipe({name: 'bcmDynamicCurrency'})
export class BcmDynamicCurrency implements PipeTransform {
    transform(value: any, digitsInfo?: string, locale?: string): any {
        return toCurrencyString(value, digitsInfo, locale);
    }
}
