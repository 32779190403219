export function deepCopy<Type>(data: any): any {
    if (typeof data !== 'object' || data == null) {
        return data;
    }

    if (data instanceof Date) {
        return deepCopyDate(data);
    }

    if (data instanceof Array) {
        return deepCopyArray(data);
    }

    if (data instanceof Object) {
        return deepCopyObject<Type>(data);
    }
}

export function deepCopyArray<Type>(input: Array<Type>, entityClass?: new (_) => Type): Array<Type> {
    return (input || []).reduce((arr, item, i) => {
        arr[i] = entityClass
            ? new entityClass(deepCopy(item))
            : deepCopy(item) as Type;
        return arr;
    }, []);
}

export function deepCopyDate(date: Date): Date {
    return new Date(date.getTime()) as any;
}

export function deepCopyObject<T extends { [key: string]: any }>(input: T): T {
    return Object.keys(input || {}).reduce((newObj, key) => {
        newObj[key] = deepCopy(input[key]);
        return newObj;
    }, {}) as T;
}
