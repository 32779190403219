<div [formGroup]="voucherFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <ng-container *ngIf="!slimmedView">
        <div fxFlex=100>
            <h2>{{'vouchers' | translate}}</h2>
        </div>
    </ng-container>

    <ng-container *ngIf="topList?.length || bottomList?.length || selectVoucher?.id">
        <div fxFlex="100">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex [appearance]="appearance">
                    <mat-label>{{inputLabel}}</mat-label>
                    <input type="text" matInput data-test="FI-Nach Voucher suchen..."
                           formControlName="voucher"
                           #autocompleteInput
                           [matAutocomplete]="voucherSelector">
                    <button matSuffix
                            mat-icon-button
                            type="button"
                            color="warn"
                            matTooltip="Ausgewählten Voucher entfernen"
                            [disabled]="(!voucher?.id && (index === null || index === 0))"
                            (click)="onClickRemoveVoucher($event)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                    <mat-error
                            [hidden]="slimmedView">{{voucherFormGroup.get('voucher').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <mat-autocomplete #voucherSelector="matAutocomplete" [displayWith]="displayVoucherWith"
                                  (optionSelected)="optionSelected($event)">

                    <ng-container *ngIf="filteredTopList$ | async as topList">
                        <mat-optgroup [label]="topListLabel">
                            <ng-container *ngFor="let voucher of topList">
                                <mat-option [value]="voucher" class="voucher-option">
                                    <span>{{voucher.title}}</span><br>

                                    <small class="secondary-text">

                                        <div fxLayout="row" class="mt-8">
                                            <div>
                                                Verbleibende Menge:
                                            </div>
                                            <div style="margin-left: auto;">
                                                {{voucher.quantityLeft}}
                                            </div>
                                        </div>

                                        <div fxLayout="row">
                                            <div>
                                                Gültig für:
                                            </div>
                                            <div style="margin-left: auto;">
                                                <ng-container *ngIf="voucher.person?.id">
                                                    Person ({{voucher.person.fullName}})
                                                </ng-container>
                                                <ng-container *ngIf="voucher.company?.id">
                                                    Organisation ({{voucher.company.fullName}})
                                                </ng-container>
                                                <ng-container *ngIf="voucher.boat?.id">
                                                    {{'boat' | translate}} ({{voucher.boat.name}})
                                                </ng-container>
                                                <ng-container
                                                        *ngIf="!voucher.person && !voucher.company && !voucher.boat">
                                                    Ohne Einschränkung
                                                </ng-container>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="voucher.validFrom">
                                            <div fxLayout="row">
                                                <div>
                                                    Gültig im Zeitraum:
                                                </div>
                                                <div style="margin-left: auto;">
                                                    {{voucher.validFrom | dateFormat}}
                                                    <ng-container *ngIf="voucher.validUntil; else noValidUntil">
                                                        - {{voucher.validUntil | dateFormat}}
                                                    </ng-container>
                                                    <ng-template #noValidUntil>
                                                        - unbegrenzt
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div class="pb-4"></div>

                                    </small>
                                </mat-option>
                            </ng-container>
                            <ng-container *ngIf="!topList?.length">
                                {{personOrCompanyName}} hat keine Voucher
                            </ng-container>
                        </mat-optgroup>
                    </ng-container>

                    <ng-container *ngIf="filteredBottomList$ | async as bottomList">
                        <ng-container *ngIf="bottomList.length > 0">
                            <mat-optgroup [label]="(topList.length > 0) ? 'Weitere Vouchers' : 'Alle Vouchers'">
                                <ng-container *ngFor="let voucher of bottomList">
                                    <mat-option [value]="voucher">
                                        {{voucher.title}}
                                    </mat-option>
                                </ng-container>
                                <ng-container *ngIf="!bottomList?.length">
                                    Keinen Voucher gefunden
                                </ng-container>
                            </mat-optgroup>
                        </ng-container>
                    </ng-container>
                </mat-autocomplete>
            </div>
        </div>
    </ng-container>

</div>
