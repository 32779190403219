import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { GetBoatDialogModule } from '@shared/components/dialogs/get-boat-dialog/get-boat-dialog.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { NumberInputModule } from '@shared/components/form/number-input/number-input.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { HtmlLinksModule } from '@modules/bcm/@shared/components/html-links/html-links.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslationModule } from '@core/translation/translation.module';
import { FormWidgetBoatByPersonOrCompanyComponent } from '@bcm-work-flows/@form-widgets/boat-by-person-or-company/form-widget-boat-by-person-or-company.component';
import { FormWidgetPersonModule } from '@bcm-work-flows/@form-widgets/person/form-widget-person.module';
import { FormWidgetCompanyModule } from '@bcm-work-flows/@form-widgets/company/form-widget-company.module';
import { FormWidgetBoatModule } from '@bcm-work-flows/@form-widgets/boat/form-widget-boat.module';

@NgModule({
    declarations: [FormWidgetBoatByPersonOrCompanyComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,

        GetBoatDialogModule,
        MatRadioModule,
        NumberInputModule,
        MatCheckboxModule,
        DirectivesModule,
        MatProgressSpinnerModule,
        HtmlLinksModule,
        MatTooltipModule,
        TranslationModule,
        FormWidgetPersonModule,
        FormWidgetCompanyModule,
        FormWidgetBoatModule
    ],
    exports: [FormWidgetBoatByPersonOrCompanyComponent]
})
export class FormWidgetBoatByPersonOrCompanyModule {

}
