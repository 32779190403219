import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TenantApiService } from '@modules/bcm/@shared/services';
import { AppUser } from '@core/services/user.service';
import { AppNotificationService } from '@core/services/app-notification.service';

@Component({
    selector: 'new-bcm-dialog',
    templateUrl: './new-bcm.component.html',
    styleUrls: ['./new-bcm.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewBcmComponent {
    dialogTitle: string;

    formGroup: UntypedFormGroup;

    user: AppUser;

    isSaving: boolean;

    constructor(
        public dialogRef: MatDialogRef<NewBcmComponent>,
        private _tenantApiService: TenantApiService,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.dialogTitle = 'Yachthafenmanagement kaufen';
        this.formGroup = this._formBuilder.group({});
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        this._tenantApiService
            .add(this.formGroup.value)
            .subscribe(
                (response) => this.dialogRef.close(response),
                () => this.dialogRef.close()
            )
            .add(() => this.isSaving = false);

    }
}
