import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Person } from '@shared/models/person';

@Component({
    selector: 'bcm-person-link',
    templateUrl: './person-link.component.html'
})
export class PersonLinkComponent {

    @Input()
    public person: Person;

    @Input()
    public prefix: string;

    @Input()
    public suffix: string;

    @Input()
    public dataTest: string;

    @Input()
    public disableLink: boolean;

    @Input()
    public showTenantRelations: boolean;

    @Input()
    public showTenantRelationWithLineBreak: boolean;

    @Output()
    public clicked = new EventEmitter<void>();

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
