import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { IProduct, Product } from '@shared/models/product';

@Injectable({
    providedIn: 'root'
})
export class ProductsApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'products';

    getAll(params: HttpParams = new HttpParams()): Observable<Product[]> {
        return super.get<IProduct[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IProduct, Product>(Product));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Product> {
        return super.get<IProduct>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IProduct, Product>(Product));
    }

    add(body: object = {}): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    remove(id: number): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }
}
