import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { Injectable } from '@angular/core';
import { BoatValidatorsModule } from '@shared/validators/boat/boat-validators.module';
import { BaseAsyncValidator } from '@shared/validators/base-async-validator';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BcmApiService } from '@modules/bcm/@shared/services';
import { HttpParams } from '@angular/common/http';
import { TranslationService } from '@core/translation/translation.service';

@Injectable({
    providedIn: BoatValidatorsModule
})
export class HasBerthValidator extends BaseAsyncValidator {

    public validationError: BaseValidationError;

    constructor(private _bcmApiService: BcmApiService,
                private _translationService: TranslationService) {
        super();

        this.validationError = new BaseValidationError('boatHasBerth', TranslationService.translate('boatHasBerthValidationError'));
    }

    public validate(formGroup: UntypedFormGroup, berth): Observable<ValidationErrors> {
        const boat = formGroup.get('boat').value;
        const from: Date = formGroup.get('from').value;

        if (!boat || !from) {
            return of(this.IS_VALID);
        }

        const httpParams = new HttpParams()
            .set('from', from.toString())
            .set('berthId', berth.id);

        return this._bcmApiService
            .get(
                `boats/${boat.id}/has-berth`,
                httpParams
            ).pipe(map(hasBerth => hasBerth ? this.IS_INVALID : this.IS_VALID));
    }
}
