import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormWidgetInvoiceComponent } from './form-widget-invoice.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OrderModule } from 'ngx-order-pipe';
import {
    FormWidgetContractCoOwnerModule
} from '@bcm-work-flows/@form-widgets/contract-co-owner/form-widget-contract-co-owner.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NumberInputModule } from '@sharedComponents/form/number-input/number-input.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslationModule } from '@core/translation/translation.module';
import { CheckPayByCashModule } from '@sharedComponents/check-pay-by-cash/check-pay-by-cash.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
    declarations: [FormWidgetInvoiceComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        OrderModule,
        FormWidgetContractCoOwnerModule,
        BcmPipesModule,
        MatTooltipModule,
        NumberInputModule,
        DragDropModule,
        TranslationModule,
        CheckPayByCashModule,
        NgScrollbarModule
    ],
    exports: [FormWidgetInvoiceComponent]
})
export class FormWidgetInvoiceModule {
    
}
