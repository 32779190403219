import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';
import { map } from 'rxjs/operators';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { Boat } from '@shared/models/boat';

@Injectable({
    providedIn: 'root'
})
export class InvoicePositionApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<[Person[], Company[]]> {

        return super.get<any>('invoice-positions', params)
            .pipe(
                map((response: { persons: IPerson[], companies: ICompany[] }) => {
                    const persons = (response?.persons || []).map(person => new Person(person));
                    const companies = (response?.companies || []).map(company => new Company(company));
                    return [persons, companies];
                })
            );
    }

    getAllOpenPositionsFromPersons(person?: Person): Observable<InvoicePosition[]> {
        return super.get<InvoicePositionDto[]>('invoice-positions/persons', new HttpParams().set('bcm_persons_id', person?.id || ''))
            .pipe(parseHttpResponseList<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    getAllOpenPositionsFromCompanies(company?: Company): Observable<InvoicePosition[]> {
        return super.get<InvoicePositionDto[]>('invoice-positions/companies', new HttpParams().set('bcm_companies_id', company?.id || ''))
            .pipe(parseHttpResponseList<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    getAllOpenPositionsFromBoat(boat: Boat): Observable<InvoicePosition[]> {
        return super.get<InvoicePositionDto[]>(`invoice-positions/boat/${boat.id}`)
            .pipe(parseHttpResponseList<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    addPositionForPerson(
        position: InvoicePosition,
        person: Person
    ): Observable<InvoicePosition> {
        return super.post<InvoicePositionDto>(`invoice-positions`, {position, person})
            .pipe(parseHttpResponseItem<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    addPositionForCompany(
        position: InvoicePosition,
        company: Company
    ): Observable<InvoicePosition> {
        return super.post<InvoicePositionDto>(`invoice-positions`, {position, company})
            .pipe(parseHttpResponseItem<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    updatePositionForPerson(
        positionId,
        position: InvoicePosition,
        person: Person
    ): Observable<InvoicePosition> {
        return super.put<InvoicePositionDto>(`invoice-positions/${positionId}`, {position, person})
            .pipe(parseHttpResponseItem<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    updatePositionForCompany(
        positionId,
        position: InvoicePosition,
        company: Company
    ): Observable<InvoicePosition> {
        return super.put<InvoicePositionDto>(`invoice-positions/${positionId}`, {position, company})
            .pipe(parseHttpResponseItem<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    deletePosition(position: InvoicePosition): Observable<any> {
        return super.delete(`invoice-positions/${position.id}`);
    }

    deletePositions(idList: number[]): Observable<any> {
        return super.delete(`invoice-positions/${idList}`);
    }
}
