export interface IDataStorageItem {
    key: string;
    value: any;
}

export class DataStorageItem<T> implements IDataStorageItem {
    constructor(public key: string,
                public value: T) {
    }
}
