import { Directive, ElementRef, Injector, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';
import { fromEvent } from 'rxjs';
import { NgControl } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Because angular reactive form is not compatible with angular2-text-mask, this is a workaround.. using vanilla-text-mask
 *
 * From here:
 * https://github.com/angular/angular/issues/16755#issuecomment-365045055
 */
@Directive({selector: '[appMaskDate]'})
export class MaskDateDirective implements OnDestroy, OnInit {

    maskOptions = {
        guide: true,
        showMask: false,
        mask: [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
        keepCharPositions: true
    };

    maskedInputController;

    ngControl: NgControl;

    ngZone: NgZone;

    @Input()
    set dateMask(mask: (string | RegExp)[]) {
        if (mask?.length) {
            this.maskOptions.mask = mask;
        }
    }

    constructor(private element: ElementRef,
                private injector: Injector) {
    }

    ngOnInit(): void {
        this.ngControl = this.injector.get(NgControl);
        this.ngZone = this.injector.get(NgZone);

        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            ...this.maskOptions,
        });

        fromEvent(this.element.nativeElement, 'keyup')
            .pipe(distinctUntilChanged())
            .subscribe((event: InputEvent) => {
                this.ngZone.run(() => {
                    const {value} = event.target as HTMLInputElement;
                    if (!value && this.ngControl?.control) {
                        this.ngControl.reset();
                        this.ngControl.viewToModelUpdate(value);
                        this.ngControl.control.markAsDirty();
                    }
                });
            });
    }

    ngOnDestroy(): void {
        this.maskedInputController.destroy();
    }

}
