import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { downloadFile } from '@shared/functions/download-file';
import { ImportFile } from '@shared/models/importFile';

@Injectable({
  providedIn: 'root'
})
export class ImportApiService extends BcmApiService {
  private RESOURCE_ENDPOINT = 'import';

  getImportData(endpoint: string, name: string, params = new HttpParams()): void {
    super.getBlob(`import/${endpoint}`, params.set('workSheetName', name))
      .subscribe((file) => downloadFile(file, name, 'xlsx'));
  }

  getErrorExcel(endpoint: string, errorFile: ArrayBuffer, name: string, params = new HttpParams()): void {
    super.postWithBlobResponse(`import/${endpoint}`, errorFile, params.set('workSheetName', name + '_ausgefüllt'))
    .subscribe((file) => downloadFile(file, name, 'xlsx'));
  }
  
  setImportData(overWrite, importInfo, arrayList, tableInfo, relations, dataBaseName): Observable<number> {
    const overWriteData = {
      overWrite,
      importInfo,
      arrayList,
      tableInfo,
      relations,
      dataBaseName
    };
    return super.post(`import/${importInfo}`, overWriteData);
  }
}
