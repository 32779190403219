import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'u2b-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss'],
})
export class MessageComponent {

    @HostBinding('class.no-margin')
    private _noMargin: boolean;

    isCollapsed: boolean;

    @Input()
    public type: 'error' | 'warning' | 'success' | 'info' = 'info';

    @Input()
    public collapsable = false;

    @Input()
    public customClass?: string;

    @Input() header: string;

    @HostBinding('class.full-width')
    @Input() fullWidth: boolean;

    @HostBinding('class.border-radius')
    @Input() useBorderRadius: boolean;

    @Input()
    public set noMargin(value: boolean | string) {
        this._noMargin = typeof value === 'string'
            ? value === '' || !!value
            : value;
    }
}
