import { tryParseDate } from '@shared/functions/try-parse-date';
import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';
import { Boat, IBoat } from '@shared/models/boat';
import { toDateTimeStringDE } from '@core/functions/to-date-string';

export interface BcmAbsenceDto {
    id?: number;
    bcm_persons_id?: number;
    bcm_companies_id?: number;
    bcm_boats_id: number;
    from: string;
    until: string;
    note?: string;
    insertedOn?: string;
    insertedBy?: string;
    lastUpdateOn?: string;
    lastUpdateBy?: string;

    person?: IPerson;
    company?: ICompany;
    boat: IBoat;
}

export class BcmAbsence {
    id: number;
    bcmPersonsId?: number;
    bcmCompaniesId?: number;
    bcmBoatsId: number;
    from: Date;
    until: Date;
    note?: string;
    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    person?: Person;
    company?: Company;
    boat: Boat;

    constructor(dto: BcmAbsenceDto = {} as BcmAbsenceDto) {
        this.id = dto.id;
        this.bcmPersonsId = dto.bcm_persons_id;
        this.bcmCompaniesId = dto.bcm_companies_id;
        this.bcmBoatsId = dto.bcm_boats_id;
        this.from = tryParseDate(dto.from);
        this.until = tryParseDate(dto.until);
        this.note = dto.note;
        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;
        this.person = dto.person ? new Person(dto.person) : undefined;
        this.company = dto.company ? new Company(dto.company) : undefined;
        this.boat = dto.boat ? new Boat(dto.boat) : undefined;
    }

    toString() {
        return [
            this.boat?.fullName,
            toDateTimeStringDE(this.from),
            toDateTimeStringDE(this.until),
            ].filter(_ => !!_).join(' - ');
    }
}
