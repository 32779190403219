import { FuseUtils } from '@fuse/utils';

export class Handleizer {
    private static UMLAUT_REPLACEMENTS: string[][] = [['ä', 'ae'], ['ü', 'ue'], ['ö', 'oe'], ['ß', 'ss']];

    public static generateSlug(orig: string): string {
        let result = (orig || '').toString().toLowerCase();

        // for (let i = 0; i < Handleizer.UMLAUT_REPLACEMENTS.length; i++) {
        //     result = result.replace(Handleizer.UMLAUT_REPLACEMENTS[i][0], Handleizer.UMLAUT_REPLACEMENTS[i][1]);
        // }

        for (const item of Handleizer.UMLAUT_REPLACEMENTS) {
            result = result.replace(item[0], item[1]);
        }

        return FuseUtils.handleize(result);
    }
}
