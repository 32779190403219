import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Person } from '@shared/models/person';
import { Company } from '@shared/models/company';

@Component({
    selector: 'bcm-person-or-company-link',
    templateUrl: './person-or-company-link.component.html'
})
export class PersonOrCompanyLinkComponent implements OnChanges {

    @Input()
    public entity: any;

    @Input()
    public disableLink: boolean;

    person: Person;

    company: Company;

    @Output()
    public clicked = new EventEmitter<void>();

    ngOnChanges(): void {
        if (this.entity?.owner?.id) {
            this.company = null;
            this.person = this.entity.owner;
            return;
        }

        if (this.entity?.person?.id) {
            this.company = null;
            this.person = this.entity.person;
            return;
        }

        if (this.entity?.ownerCompany?.id) {
            this.company = this.entity.ownerCompany;
            this.person = null;
            return;
        }

        if (this.entity?.company?.id) {
            this.company = this.entity.company;
            this.person = null;
            return;
        }
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
