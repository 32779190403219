import { NgModule } from '@angular/core';
import { ProductPriceLabelComponent } from './product-price-label.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [ProductPriceLabelComponent],
    exports: [ProductPriceLabelComponent],
    imports: [
        CommonModule
    ]
})
export class ProductPriceLabelModule {

}
