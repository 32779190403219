import { UntypedFormGroup } from '@angular/forms';

export function disableControlsByName(fromGroup: UntypedFormGroup, controlNames: string[], opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
}): void {
    controlNames.forEach((controlName) => {
        const control = fromGroup.get(controlName);

        if (control) {
            control.disable(opts);
        } else {
            throw new Error(`${controlName} existiert nicht in der`);
        }
    });
}
