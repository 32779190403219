import { NgModule } from '@angular/core';
import { GetCostCenterForInvoicePositionsDialogComponent } from './get-cost-center-for-invoice-positions-dialog.component';
import { ModalModule } from '@core/components/modal/modal.module';
import { CoreDirectivesModule } from '@core/directives/core.directives.module';
import { CostCenterInputModule } from '@modules/bcm/cost-centers/cost-center-input/cost-center-input.module';
import { FlexModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { NgForOf } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@NgModule({
    declarations: [
        GetCostCenterForInvoicePositionsDialogComponent
    ],
    imports: [
        ModalModule,
        CoreDirectivesModule,
        CostCenterInputModule,
        FlexModule,
        MatDividerModule,
        NgForOf,
        SharedModule
    ]
})
export class GetCostCenterForInvoicePositionsDialogModule {
}
