import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@shared/shared.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { SignaturePadModule } from 'angular2-signaturepad';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormWidgetSignatureComponent } from '@bcm-work-flows/@form-widgets/core/signature/form-widget-signature.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';

@NgModule({
    declarations: [FormWidgetSignatureComponent],
    imports: [
        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatCheckboxModule,

        SignaturePadModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatButtonToggleModule,
        MatRadioModule
    ],
    exports: [FormWidgetSignatureComponent]
})
export class FormWidgetSignatureModule {
    
}
