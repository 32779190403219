import { Tenant } from '@shared/models/tenant';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@env/environment';
import {
    BcmSupportedCurrencyCodes,
    BcmSupportedCurrencySymbols
} from '@modules/bcm/@shared/enums/supported-currencies';
import { StateResetService } from '@bcmServices/state-reset.service';
import { filter } from 'rxjs/operators';

const bcmBaseUrl = '/bcm/';

@Injectable({
    providedIn: 'root'
})
export class BcmService {

    static currentCurrencyCode: BcmSupportedCurrencyCodes = BcmSupportedCurrencyCodes.EUR;

    static currentNumberFormatDigits = '1.2-2';

    static currentLocale = 'de-DE';

    private readonly _selectedTenant$ = new BehaviorSubject<Tenant>(null);

    public get selectedTenant$(): Observable<Tenant> {
        return this._selectedTenant$.asObservable()
            .pipe(filter(item => item !== undefined));
    }

    private _baseUrl = bcmBaseUrl;

    public get baseUrl(): string {
        return this._baseUrl;
    }

    private _apiBaseUrl = '';

    public get apiBaseUrl(): string {
        return this._apiBaseUrl;
    }

    private _managePricesInNet: boolean;

    public get managePricesInNet(): boolean {
        return this._managePricesInNet;
    }

    private _tenant: Tenant;

    public get tenant(): Tenant {
        return this._tenant;
    }

    public set tenant(tenant: Tenant) {

        if (tenant && tenant.id > 0) {
            this._tenant = tenant;
            this._baseUrl = bcmBaseUrl + tenant.id + '/';
            this._apiBaseUrl = environment.endpoints.bcm.api + tenant.id + '/';
            this._currencyCode = BcmService.currentCurrencyCode = tenant.currencyCode;
            this._currencySymbol = tenant.currencySymbol;
            this._managePricesInNet = tenant.managePricesInNet;
            this._selectedTenant$.next(tenant);

            BcmService.currentLocale = `${tenant?.localeISO639 || 'de'}-${tenant?.countryCodeISO3166 || 'DE'}`;
            return;
        }

        this._tenant = undefined;
        this._baseUrl = bcmBaseUrl;
        this._apiBaseUrl = null;
        this._selectedTenant$.next(tenant);
    }

    private _currencyCode: BcmSupportedCurrencyCodes;
    public get currencyCode(): BcmSupportedCurrencyCodes {
        return this._currencyCode;
    }

    private _currencySymbol: BcmSupportedCurrencySymbols;
    public get currencySymbol(): BcmSupportedCurrencySymbols {
        return this._currencySymbol;
    }

    // constructor(private _router: Router) {
    //     this.selectedTenant.subscribe((tenant: Tenant) => {
    //         this._router.navigate(['/bcm']);
    //     });
    // }
    //
    // get(moduleName: ModuleNames): Observable<Module> {
    //     return this._apiService.get<Module>(`settings/${moduleName}`);
    // }

    public reset(): void {
        this._tenant = null;
        this._baseUrl = bcmBaseUrl;
        this._apiBaseUrl = null;
        this._currencyCode = BcmService.currentCurrencyCode = BcmSupportedCurrencyCodes.EUR;
        this._currencySymbol = BcmSupportedCurrencySymbols.EUR;
        this._managePricesInNet = false;
        this._selectedTenant$.next(null);
        BcmService.currentLocale = `de-DE`;
        StateResetService.reset();
    }
}
