import {
    Directive,
    Input,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { UserService } from '../services/user.service';
import { BcmUserPermission } from '@modules/bcm/bcm-user-permission';
import { Logger } from '@core/services/logger.service';

const log = new Logger('HasPermissionDirective');

@Directive({
    selector: '[hasPermission],[notHasPermission]',
})
export class HasPermissionDirective {
    private _logicalOperator: 'and' | 'or' = 'and';
    private _negateCondition = false;
    private permissions: BcmUserPermission[];
    private isHidden = true;
    private elseTemplate: TemplateRef<any>;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private _userService: UserService,
    ) {

    }

    @Input()
    set hasPermission(permissions: BcmUserPermission | BcmUserPermission[]) {
        this.preparePermissions(permissions);
    }

    @Input()
    set notHasPermission(permissions: BcmUserPermission | BcmUserPermission[]) {
        this._negateCondition = true;
        this.preparePermissions(permissions);
    }

    @Input()
    set hasPermissionElse(elseTemplate: TemplateRef<any>) {
        this.elseTemplate = elseTemplate;
        this.updateView();
    }

    @Input()
    set hasPermissionOperator(value: 'and' | 'or') {
        this._logicalOperator = value;
    }

    private preparePermissions(permissions: BcmUserPermission | BcmUserPermission[]): void {
        this.permissions = Array.isArray(permissions) ? permissions : [permissions];
        this.updateView();
    }

    private updateView(): void {
        this.viewContainer.clear();

        let result = this.checkPermission();

        if (this._negateCondition) {
            result = !result;
        }

        if (result) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.isHidden = false;
        } else {
            this.isHidden = true;

            if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            } else {
                this.viewContainer.clear();
            }
        }
    }

    private checkPermission(): boolean {
        switch (this._logicalOperator) {
            case 'and':
                return this._userService.hasAllPermissionsOf(...this.permissions);
            case 'or':
                return this._userService.hasOnePermissionOf(...this.permissions);
        }
    }
}
