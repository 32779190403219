export enum BcmDocumentCategorySystemKey {
    SON = 'SON', // Sonstiges
    GAP = 'GAP', // Gasanlagenprüfung
    HPV = 'HPV', // Haftpflichtversicherung
    BSZ = 'BSZ', // Bodenseezulassung
    VDE = 'VDE', // VDE-Zulassung
    EMA = 'EMA', // E-Mail-Anhang
    BOC = 'BOC', // Buchungsbestätigung
    CRW = 'CRW', // Crewliste
    CON = 'CON', // Vertrag
    INV = 'INV', // Rechnung
}

export interface BcmDocumentCategoryDto {
    id?: number;
    name: string;
    systemKey?: BcmDocumentCategorySystemKey;
    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmDocumentCategory {
    id: number;
    name: string;
    systemKey: BcmDocumentCategorySystemKey;
    note?: string;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    constructor(dto: BcmDocumentCategoryDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.systemKey = dto.systemKey;
        this.note = dto.note;

        this.insertedOn = dto.insertedOn;
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = dto.lastUpdateOn;
        this.lastUpdateBy = dto.lastUpdateBy;
    }
}
