import { BcmOrderWorkPackageTodo, BcmOrderWorkPackageTodoDto } from './bcm-order-work-package-todo';
import {
    BcmOrderWorkPackageInvoicePosition,
    BcmOrderWorkPackageInvoicePositionsDto
} from './bcm-order-work-package-invoice-position';

export interface BcmOrderWorkPackageDto {
    id: number;
    shortDescription: string;
    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;
    todos: BcmOrderWorkPackageTodoDto[];
    invoicePositions: BcmOrderWorkPackageInvoicePositionsDto[];
}

export class BcmOrderWorkPackage {
    id: number;
    shortDescription: string;
    readonly insertedOn: Date;
    readonly insertedBy: string;
    readonly lastUpdateOn: Date;
    readonly lastUpdateBy: string;
    todos: BcmOrderWorkPackageTodo[] | null;
    invoicePositions: BcmOrderWorkPackageInvoicePosition[] | null;

    constructor(bcmOrderWorkPackageDto: BcmOrderWorkPackageDto = {} as BcmOrderWorkPackageDto) {
        this.id = bcmOrderWorkPackageDto.id;
        this.shortDescription = bcmOrderWorkPackageDto.shortDescription;
        this.insertedOn = bcmOrderWorkPackageDto.insertedOn;
        this.insertedBy = bcmOrderWorkPackageDto.insertedBy;
        this.lastUpdateOn = bcmOrderWorkPackageDto.lastUpdateOn;
        this.lastUpdateBy = bcmOrderWorkPackageDto.lastUpdateBy;

        this.todos = bcmOrderWorkPackageDto.todos?.length
            ? bcmOrderWorkPackageDto.todos.map(todo => new BcmOrderWorkPackageTodo(todo))
            : null;

        this.invoicePositions = bcmOrderWorkPackageDto.invoicePositions?.length
            ? bcmOrderWorkPackageDto
                .invoicePositions
                .map(position => new BcmOrderWorkPackageInvoicePosition(position))
            : null;
    }
}

