<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Kasseneröffnung</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <u2b-message type="info">
            Bitte gib den aktuellen Bestand an Bargeld an, der sich in <b>{{cashRegister?.title}}</b> befindet.
        </u2b-message>

        <ng-container *ngIf="previousDayCashError">
            <u2b-message type="error">
                Der letzte Kassenbestand konnte nicht geladen werde, bitte trage den Kassenbestand von gestern ein unter
                "Kassenbestand bei Eröffnung".
            </u2b-message>
        </ng-container>

        <ng-container *ngIf="formGroup">
            <form [formGroup]="formGroup" class="mt-24">

                <div class="mb-24" fxLayout="column" fxLayoutAlign="start start">
                    <mat-form-field appearance="outline" style="width: 100%">
                        <mat-label>Kassenbestand bei Eröffnung</mat-label>
                        <input type="number" matInput data-test="FI-Kassenbestand bei Eröffnung" formControlName="cash">
                        <mat-hint>Kassenbestand vom
                            Vortag: {{previousDayCash ? (previousDayCash | bcmDynamicCurrency) : '- keine Daten vorhanden -'}}</mat-hint>
                        <mat-error>{{formGroup.get('cash').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

            </form>
        </ng-container>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="space-between center">
        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                aria-label="Dialog schließen">
            <mat-icon>cancel</mat-icon>
            Abbrechen
        </button>
        <button data-test="B-Kasse eröffnen" mat-flat-button
                color="accent"
                (click)="openCashRegister()"
                class="save-button mat-accent"
                [disabled]="!formGroup.valid || isLoading"
                aria-label="Kasse eröffnen">
            <mat-icon>point_of_sale</mat-icon>
            <span>Kasse eröffnen</span>
            <ng-container *ngIf="isLoading">
                <mat-spinner class="saving-spinner" [diameter]="20"></mat-spinner>
            </ng-container>
        </button>
    </div>

</div>
