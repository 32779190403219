import { tryParseDate } from '@shared/functions/try-parse-date';

export interface BcmDefaultAssetDto {
    id?: number;

    name: string;
    width?: number;
    length?: number;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;
}

export class BcmDefaultAsset {
    id: number;

    name: string;
    width?: number;
    length?: number;

    insertedOn: Date;
    insertedBy: string;
    lastUpdateOn: Date;
    lastUpdateBy: string;

    inEditMode?: boolean;

    constructor(dto: BcmDefaultAssetDto) {
        this.id = dto.id;
        this.name = dto.name;
        this.width = dto.width;
        this.length = dto.length;
        this.insertedOn = dto.insertedOn ? tryParseDate(dto.insertedOn) : null;
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = dto.lastUpdateOn ? tryParseDate(dto.lastUpdateOn) : null;
        this.lastUpdateBy = dto.lastUpdateBy;
    }
}
