import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { MailTemplate } from '@shared/models/mail-template';

@Injectable({
    providedIn: 'root'
})
export class MailTemplateApiService extends BcmApiService {

    getAll(params: HttpParams = new HttpParams()): Observable<MailTemplate[]> {
        return super.get<MailTemplate[]>('mail-templates', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<MailTemplate> {
        return super.get<MailTemplate>(`mail-templates/${id}`, params);
    }

    getOneByTitle(title: string, params: HttpParams = new HttpParams()): Observable<MailTemplate> {
        return super.get<MailTemplate>(`mail-templates/by-title/${title}`, params);
    }

    add(mailTemplate: MailTemplate): Observable<MailTemplate> {
        return super.post('mail-templates', mailTemplate);
    }

    update(mailTemplate: MailTemplate): Observable<MailTemplate> {
        return super.put(`mail-templates/${mailTemplate.id}`, mailTemplate);
    }

    remove(mailTemplate: MailTemplate): Observable<any> {
        return super.delete(`mail-templates/${mailTemplate.id}`);
    }

    sendTestMail(mailTemplate: MailTemplate): Observable<any> {
        return super.post(`mail-templates/send-test`, mailTemplate);
    }

}
