<div [formGroup]="userFormGroup"
     fxLayout="row wrap"
     fxLayoutGap="12px grid">

    <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
        <mat-label>Anrede</mat-label>
        <mat-select data-test="FS-Anrede" formControlName="salutation">
            <mat-option [value]="salutation.None"></mat-option>
            <mat-option [value]="salutation.Male">Herr</mat-option>
            <mat-option [value]="salutation.Female">Frau</mat-option>
            <mat-option [value]="salutation.Divers">Divers</mat-option>
        </mat-select>
        <mat-error>{{userFormGroup.get('salutation').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="50" floatLabel="always">
        <mat-label>Titel (optional)</mat-label>
        <input matInput data-test="FI-Titel" type="text" formControlName="title">
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
        <mat-label>Vorname</mat-label>
        <input matInput data-test="FI-Vorname" type="text" formControlName="firstName">
        <mat-error>{{userFormGroup.get('firstName').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
        <mat-label>Nachname</mat-label>
        <input matInput data-test="FI-Nachname" type="text" formControlName="lastName">
        <mat-error>{{userFormGroup.get('lastName').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>Straße + Hausnummer</mat-label>
        <input matInput data-test="FI-Straße + Hausnummer" type="text" formControlName="street">
        <mat-error>{{userFormGroup.get('street').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="40" floatLabel="always">
        <mat-label>PLZ</mat-label>
        <input matInput data-test="FI-PLZ" type="text" formControlName="postcode">
        <mat-error>{{userFormGroup.get('postcode').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="60" floatLabel="always">
        <mat-label>Ort</mat-label>
        <input matInput data-test="FI-Ort" type="text" formControlName="city">
        <mat-error>{{userFormGroup.get('city').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="100" floatLabel="always">
        <mat-label>Land</mat-label>
        <input matInput data-test="FI-Ort" type="text" formControlName="country">
        <mat-error>{{userFormGroup.get('country').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" floatLabel="always">
        <mat-label>E-Mail</mat-label>
        <input matInput data-test="FI-E-Mail" type="text" formControlName="email">
        <mat-error>{{userFormGroup.get('email').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50"  floatLabel="always">
        <mat-label>Telefonnummer</mat-label>
        <input matInput data-test="FI-Telefonnummer" type="text" formControlName="phone">
        <mat-error>{{userFormGroup.get('phone').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill" fxFlex="100" fxFlex.gt-xs="50" floatLabel="always">
        <mat-label>Handynummer</mat-label>
        <input matInput data-test="FI-Handynummer" type="text" formControlName="mobilePhone">
        <mat-error>{{userFormGroup.get('mobilePhone').errors | firstErrorMessage}}</mat-error>
    </mat-form-field>

</div>