import { MatSort } from '@angular/material/sort';
import { isValidDate } from 'rxjs/internal/util/isDate';

const collator = new Intl.Collator(
    navigator.languages[0] || navigator.language,
    {
        numeric: true,
        ignorePunctuation: true,
        sensitivity: 'base'
    }
);

export function naturalCompare(a: string, b: string): number {
    // source: http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
    return String(a).localeCompare(String(b), navigator.languages[0] || navigator.language, {
        numeric: true,
        ignorePunctuation: true,
        sensitivity: 'base'
    });
}

export function naturalSort(items: string[]): string[] {
    // source: http://fuzzytolerance.info/blog/2019/07/19/The-better-way-to-do-natural-sort-in-JavaScript/
    return items.sort((a, b) => naturalCompare(a, b));
}

export function naturalSortArrayOfObjects(items: any[],
                                          attribute: string,
                                          sortingDataAccessor?: (data: any[], sortHeaderId: string) => string | number): any[] {
    // TODO: möglichkeit für object Notation schaffen e.g. mat-sort-header="person.fullNameBackward"
    return items.sort((a, b) => {
        const valueA = sortingDataAccessor ?
            sortingDataAccessor(a, attribute)
            : a[attribute];
        const valueB = sortingDataAccessor ?
            sortingDataAccessor(b, attribute)
            : b[attribute];

        if (isValidDate(valueA)) {
            return collator.compare((+valueA).toString(), (+valueB).toString());
        }
        return collator.compare((valueA || '').toString(), (valueB || '').toString());
    });
}

export function naturalSortMatTable(data: any, sort: MatSort): any[] {
    switch (sort.direction) {
        case 'desc':
            return naturalSortArrayOfObjects(data, sort.active).reverse();
        case 'asc':
            return naturalSortArrayOfObjects(data, sort.active);
        case '':
            return data;
    }
}

export function naturalSortMatTableFactory(sortingDataAccessor: (data: any, sortHeaderId: string) => string | number): (data: any[], sort: MatSort) => any[] {
    return (data: any[], sort: MatSort) => {
        switch (sort.direction) {
            case 'desc':
                return naturalSortArrayOfObjects(data, sort.active, sortingDataAccessor).reverse();
            case 'asc':
                return naturalSortArrayOfObjects(data, sort.active, sortingDataAccessor);
            case '':
                return data;
        }
    };
}
