import { EventEmitter } from '@angular/core';

export enum StateResetFilter {
    ALL = 0,
    BCM = 'bcm'
}

export class StateResetService {
    static readonly onReset = new EventEmitter<StateResetFilter>();
    static reset(resetFilter: StateResetFilter = StateResetFilter.ALL): void {
        this.onReset.emit(resetFilter);
    }
}
