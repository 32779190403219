export interface BcmTagDto {
    id: number;
    name: string;
}

export class BcmTag {

    id: number;
    name: string;

    constructor(dto: { id?: number, name: string }) {
        this.id = dto.id;
        this.name = dto.name;
    }

    toString(): string {
        return this.name;
    }
}
