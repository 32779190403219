import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '@shared/shared.module';
import { ProductDynamicPriceComponent } from '@bcm-work-flows/product-dynamic-price/product-dynamic-price.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FormWidgetBoatModule } from '@bcm-work-flows/@form-widgets/boat/form-widget-boat.module';
import {
    FormWidgetTenantRelationModule
} from '@bcm-work-flows/@form-widgets/tenant-relation/form-widget-tenant-relation.module';
import { FormWidgetBerthOnlyModule } from '@bcm-work-flows/@form-widgets/berth-only/form-widget-berth-only.module';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FormWidgetSeasonModule } from '@bcm-work-flows/@form-widgets/season/form-widget-season.module';
import { BcmPipesModule } from '@modules/bcm/@shared/pipes/pipes.module';
import { ProductService } from '@modules/bcm/products/product/product.service';
import {
    FormWidgetWinterStorageModule
} from '@bcm-work-flows/@form-widgets/winter-storage/form-widget-winter-storage.module';
import { SeasonsService } from '@modules/bcm/seasons/seasons.service';
import { FormWidgetPierModule } from '@bcm-work-flows/@form-widgets/pier/form-widget-pier.module';

@NgModule({
    declarations: [ProductDynamicPriceComponent],
    imports: [
        FuseSharedModule,
        SharedModule,
        MatSlideToggleModule,
        FormWidgetBoatModule,
        FormWidgetTenantRelationModule,
        FormWidgetBerthOnlyModule,
        MatIconModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTooltipModule,
        FormWidgetSeasonModule,
        BcmPipesModule,
        FormWidgetWinterStorageModule,
        FormWidgetPierModule,
    ],
    providers: [ProductService, SeasonsService],
    exports: [ProductDynamicPriceComponent]
})
export class ProductDynamicPriceModule {

}
