import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { BcmApiService } from './bcm-api.service';
import { parseHttpResponseItem, parseHttpResponseList } from '@shared/functions/parse-http-response';
import { IPerson, Person } from '@shared/models/person';
import { TenantRelationAssignment, TenantRelationAssignmentDto } from '@shared/models/tenant-relation-assignment';
import { switchMap } from 'rxjs/operators';
import {
    GetProductSubscriptionEndDialogComponent
} from '@sharedComponents/dialogs/get-product-subscription-end-dialog/get-product-subscription-end-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BcmService } from '../../../bcm.service';
import { BcmDocument, BcmDocumentDto } from '@shared/models/bcm-document';
import { arrayReduceTo } from '@core/functions/array-reduce-to';
import { InvoicePosition, InvoicePositionDto } from '@shared/models/invoice-position';

@Injectable({
    providedIn: 'root'
})
export class PersonApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'persons';

    constructor(private _matDialog: MatDialog,
                http: HttpClient,
                bcmService: BcmService) {
        super(http, bcmService);
    }

    getAll(params: HttpParams = new HttpParams()): Observable<Person[]> {
        return super.get<IPerson[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IPerson, Person>(Person));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Person> {
        return super.get<IPerson>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IPerson, Person>(Person));
    }

    add(body: object = {}): Observable<number> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    remove(id: number): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }

    newTenantRelationAssignments(person: Person,
                                 body: any): Observable<TenantRelationAssignment[]> {
        return super.post<TenantRelationAssignmentDto[]>(`${this.RESOURCE_ENDPOINT}/${person.id}/tenant-relation`, body)
            .pipe(parseHttpResponseList<TenantRelationAssignmentDto, TenantRelationAssignment>(TenantRelationAssignment));
    }

    multipleNewTenantRelationAssignment(tenantRelationAssignment: TenantRelationAssignment, ...persons: Person[]): Observable<TenantRelationAssignment[]> {
        return super
            .post<TenantRelationAssignmentDto[]>(
                `${this.RESOURCE_ENDPOINT}/${arrayReduceTo(persons, 'id').toString()}/tenant-relation`,
                tenantRelationAssignment
            )
            .pipe(parseHttpResponseList<TenantRelationAssignmentDto, TenantRelationAssignment>(TenantRelationAssignment));
    }

    editTenantRelationAssignment(person: Person, tenantRelationAssignment: TenantRelationAssignment): Observable<TenantRelationAssignment> {
        return super.put<TenantRelationAssignmentDto>(`${this.RESOURCE_ENDPOINT}/${person.id}/tenant-relation/${tenantRelationAssignment.id}`, tenantRelationAssignment)
            .pipe(parseHttpResponseItem<TenantRelationAssignmentDto, TenantRelationAssignment>(TenantRelationAssignment));
    }

    endTenantRelationAssignment(person: Person, tenantRelationAssignment: TenantRelationAssignment): Observable<any> {
        return this
            ._getEndTimestamp(tenantRelationAssignment)
            .pipe(switchMap((date) => {
                if (date) {
                    return super.put(`${this.RESOURCE_ENDPOINT}/${person.id}/tenant-relation/${tenantRelationAssignment.id}/end`, {date});
                }
                return EMPTY;
            }));
    }

    deleteTenantRelationAssignment(person: Person, tenantRelation: TenantRelationAssignment): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${person.id}/tenant-relation/${tenantRelation.id}`);
    }

    multipleNewInvoicePosition(invoicePosition: InvoicePosition, ...persons: Person[]): Observable<InvoicePosition[]> {
        return super
            .put<InvoicePositionDto[]>(
                `${this.RESOURCE_ENDPOINT}/${arrayReduceTo(persons, 'id').toString()}/positions`,
                invoicePosition
            )
            .pipe(parseHttpResponseList<InvoicePositionDto, InvoicePosition>(InvoicePosition));
    }

    protected getDocument(personId: number, documentId: number): Observable<BcmDocument> {
        return super.get<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${personId}/documents/${documentId}`)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected addDocument(personId: number, document: BcmDocument): Observable<BcmDocument> {
        return super.post<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${personId}/documents`, document)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected updateDocument(personId: number, document: BcmDocument, documentData: Partial<BcmDocument>): Observable<BcmDocument> {
        return super.put<BcmDocumentDto>(`${this.RESOURCE_ENDPOINT}/${personId}/documents/${document.id}`, documentData)
            .pipe(parseHttpResponseItem<BcmDocumentDto, BcmDocument>(BcmDocument));
    }

    protected deleteDocument(personId: number, documentId: number): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${personId}/documents/${documentId}`);
    }

    private _getEndTimestamp(tenantRelationAssignment: TenantRelationAssignment): Observable<Date> {
        return this._matDialog.open(GetProductSubscriptionEndDialogComponent, {
            data: {
                subscription: tenantRelationAssignment,
                modalTitle: 'Beziehung beenden'
            }
        }).afterClosed();
    }
}
