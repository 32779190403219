import { Injectable } from '@angular/core';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { CustomDialogOnClickButton, DialogButton } from '@shared/components/dialogs/confirm-dialog/dialog-button';
import { U2bInformationDialogComponent } from '@shared/components/dialogs/information-dialog/information-dialog.component';
import { noop } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class InformationDialogService {

    private _title: string;

    private _theme: ThemePalette = 'accent';

    private _body: SafeHtml;

    private _button: DialogButton;

    private _leftButton?: CustomDialogOnClickButton;

    private _dialogConfig = new MatDialogConfig();

    constructor(private _matDialog: MatDialog,
                private sanitizer: DomSanitizer) {
    }

    public setTitle(title: string): InformationDialogService {
        this._title = title;
        return this;
    }

    public setTheme(theme: ThemePalette, ignoreButtonsOnTheming = false): InformationDialogService {
        this._theme = theme;
        if (ignoreButtonsOnTheming) {
            return this;
        }
        return this.setButton({color: theme});
    }

    public useWarnTheme(ignoreButtonsOnTheming = false): InformationDialogService {
        this._theme = 'warn';
        if (!this._title) {
            this._title = 'Achtung';
        }
        if (ignoreButtonsOnTheming) {
            return this;
        }
        return this.setButton({color: this._theme}).setLeftButton({color: 'primary'} as CustomDialogOnClickButton);
    }

    public setBody(body: string): InformationDialogService {
        this._body = this.sanitizer.bypassSecurityTrustHtml(body);
        return this;
    }

    public setButton(button: DialogButton): InformationDialogService {
        this._button = {...{color: 'primary', text: 'Ok'}, ...button};
        return this;
    }

    public setLeftButton(button: CustomDialogOnClickButton): InformationDialogService {
        this._leftButton = {...{color: 'accent'}, ...button};
        this.setDialogConfig(this._dialogConfig);
        return this;
    }

    public setDialogConfig(dialogConfig: MatDialogConfig): InformationDialogService {
        this._dialogConfig = dialogConfig || new MatDialogConfig();

        if (this._leftButton) {

            if (!this._dialogConfig.data) {
                this._dialogConfig.data = {};
            }

            this._dialogConfig.data.leftButton = this._leftButton;
        }

        return this;
    }

    public openWithCallback(onConfirmCallback: () => void, onDeclineCallback?: () => void): void {
        this._open(onConfirmCallback, onDeclineCallback);
    }

    public open(): void {
        this._open(noop, noop);
    }

    private _open(onConfirmCallback: () => void, onDeclineCallback = () => null): void {
        const informationDialogRef = this._matDialog.open(U2bInformationDialogComponent, this._dialogConfig);
        const component = informationDialogRef.componentInstance;

        if (this._title) {
            component.dialogTitle = this._title;
        }

        if (this._theme) {
            component.dialogTitleTheme = this._theme;
        }

        if (this._body) {
            component.dialogBody = this._body;
        }

        if (this._button) {
            component.dialogButton = this._button;
        }

        informationDialogRef
            .afterClosed()
            .subscribe(value => {
                value ? onConfirmCallback() : onDeclineCallback();
                this._reset();
            });
    }

    private _reset(): void {
        this._title = null;
        this._theme = 'accent';
        this._body = null;
        this._button = null;
        this._leftButton = null;
        this._dialogConfig = new MatDialogConfig();
    }
}
