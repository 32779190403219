import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CoreDirectivesModule } from '@core/directives/core.directives.module';

/*
* TODO: ... clean up the modules ... use core module ...
* DO import modules that should be instantiated once in your app.
* DO place services in the module, but do not provide them.
* DO NOT declare components, pipes, directives.
* DO NOT import the CoreModule into any modules other than the AppModule.
* */
@NgModule({
    declarations: [],
    providers: [],
    imports: [
        CommonModule,
        HttpClientModule,
        CoreDirectivesModule,
    ],
    exports: []
})
export class CoreModule {

}
