import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import { DialogButton } from '@shared/components/dialogs/confirm-dialog/dialog-button';
import { LegacyThemePalette as ThemePalette } from '@angular/material/legacy-core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
    ConfirmDialogFormControlBase,
    ConfirmDialogFormSelectControl
} from '@sharedComponents/dialogs/confirm-dialog/confirm-dialog-form-control';
import { AppNotificationService } from '@core/services/app-notification.service';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { DEFAULT_DEBOUNCE_TIME } from '@modules/bcm/@shared/constants';
import { isString } from '@shared/functions/is-string';
import { Berth } from '@shared/models/berth';
import { SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'u2b-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class U2bConfirmDialogComponent {

    public formGroup: UntypedFormGroup;

    public confirmDialogFormControls: ConfirmDialogFormControlBase[];

    public confirmTitle: string;

    public confirmTitleTheme: ThemePalette;

    public confirmBody: string | SafeHtml;

    public yesButton: DialogButton = {
        color: 'accent',
        text: 'Ja'
    };

    public noButton: DialogButton = {
        color: null,
        text: 'Nein'
    };

    selectLists?: { [key: string]: Observable<any[]> };

    filteredSelectLists: { [key: string]: Observable<any[]> };

    constructor(public dialogRef: MatDialogRef<U2bConfirmDialogComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) data: any,
                private _appNotificationService: AppNotificationService) {
        dialogRef.addPanelClass('confirm-dialog');

        if (data?.confirmDialogFormControls?.length) {
            this.formGroup = new UntypedFormGroup({});
            this.confirmDialogFormControls = data.confirmDialogFormControls;
            this.confirmDialogFormControls.forEach(formControl => {
                if (formControl.type === 'select') {
                    const {name, defaultValue, validators, list, listAttribute} = (formControl as ConfirmDialogFormSelectControl<any>);
                    const newControl = new UntypedFormControl(defaultValue, validators);
                    this.formGroup.addControl(name, newControl);

                    if (!this.selectLists) {
                        this.selectLists = {};
                        this.filteredSelectLists = {};
                    }

                    this.selectLists[name] = of(list);
                    this.filteredSelectLists[name] = newControl.valueChanges
                        .pipe(
                            startWith(this.selectLists[name]),
                            debounceTime(DEFAULT_DEBOUNCE_TIME),
                            switchMap((value) => isString(value)
                                ? this._filterList(this.selectLists[name], value, listAttribute)
                                : this.selectLists[name])
                        );

                } else {
                    const {name, defaultValue, validators} = formControl;
                    this.formGroup.addControl(name, new UntypedFormControl(defaultValue, validators));
                }
            });
        }
    }

    public displayListItemWith(controlCfg: ConfirmDialogFormControlBase): (_: any) => string {
        return (item: any): string => {
            return item ? item[(controlCfg as ConfirmDialogFormSelectControl<any>).listAttribute] : '';
        };
    }

    yesButtonClick(): void {
        if (this.formGroup && !this.formGroup.valid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }
        this.dialogRef.close(this.formGroup?.value || true);
    }

    private _filterList(list$: Observable<any[]>,
                        filter: string = '',
                        attribute: string): Observable<Berth[]> {
        return list$.pipe(
            map((list: any[]) => list.filter(item => item[attribute].toLowerCase().includes(filter.toLowerCase())))
        );
    }
}
