import { UntypedFormGroup } from '@angular/forms';

export function enableControlsByName(fromGroup: UntypedFormGroup, controlNames: string[], resetControl = true, opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
}): void {
    controlNames.forEach((controlName) => {
        const control = fromGroup.get(controlName);

        if (control) {
            control.enable(opts);

            if (resetControl) {
                control.reset(control.value, opts);
            }
        }
    });
}
