import { NgModule } from '@angular/core';
import { BcmRouterLinkDirective } from './router-link.directive';

@NgModule({
    declarations: [BcmRouterLinkDirective],
    exports: [BcmRouterLinkDirective],
})
export class BcmDirectivesModule {

}
