import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { PierRaw, Pier } from '@shared/models/pier';
import { BcmApi2Service } from '@modules/bcm/@shared/services';

@Injectable({
    providedIn: 'root'
})
export class PiersApiService extends BcmApi2Service {

    private RESOURCE_ENDPOINT = 'piers';

    getAll(params: HttpParams = new HttpParams()): Observable<Pier[]> {
        return super.get<PierRaw[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<PierRaw, Pier>(Pier));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<Pier> {
        return super.get<PierRaw>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<PierRaw, Pier>(Pier));
    }

    add(pier: Partial<Pier>): Observable<Pier> {
        return super.post<PierRaw>(this.RESOURCE_ENDPOINT, pier)
            .pipe(parseHttpResponseItem<PierRaw, Pier>(Pier));
    }

    update(pier: Pier, PierData: Partial<Pier>): Observable<Pier> {
        return super.put<PierRaw>(`${this.RESOURCE_ENDPOINT}/${pier.id}`, PierData)
            .pipe(parseHttpResponseItem<PierRaw, Pier>(Pier));
    }

    remove(pier: Pier): Observable<number> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${pier.id}`);
    }
}
