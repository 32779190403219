import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BcmApiService } from './bcm-api.service';
import { IFile } from '@shared/interfaces/file';
import { BcmDocument } from '@shared/models/bcm-document';
import { BcmFile } from '@shared/models/bcm-file';

@Injectable({
    providedIn: 'root'
})
export class FilesApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'files';

    getAll(params: HttpParams = new HttpParams()): Observable<IFile[]> {
        return super.get<IFile[]>(this.RESOURCE_ENDPOINT, params);
    }

    getOne(file: BcmFile, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${file?.id}`, params);
    }

    getById(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.getBlob(`${this.RESOURCE_ENDPOINT}/${id}`, params);
    }

    add(file: IFile, params: HttpParams = new HttpParams()): Observable<number> {
        return super.post(`${this.RESOURCE_ENDPOINT}`, this._getFileFormData(file), params);
    }

    addAndFetch(file: IFile, params: HttpParams = new HttpParams()): Observable<IFile> {
        params = params.set('fetchAfterInsert', '1');
        return super.post(`${this.RESOURCE_ENDPOINT}`, this._getFileFormData(file), params);
    }

    update(file: IFile, params: HttpParams = new HttpParams()): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${file.id}`, this._getFileFormData(file), params);
    }

    updateAndFetch(file: IFile, params: HttpParams = new HttpParams()): Observable<IFile> {
        params = params.set('fetchAfterUpdate', '1');
        return super.put(`${this.RESOURCE_ENDPOINT}/${file.id}`, this._getFileFormData(file), params);
    }

    delete(file: IFile, params: HttpParams = new HttpParams()): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${file.id}`, params);
    }

    deleteById(id: number, params: HttpParams = new HttpParams()): Observable<any> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`, params);
    }

    private _getFileFormData(file: IFile): FormData {
        const theFile = new FormData();
        theFile.append('file', file.file, file.file.name);
        return theFile;
    }
}
