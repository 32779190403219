import { NgModule } from '@angular/core';
import { SvgIconService } from './svg-icon.service';

@NgModule({
    providers: [SvgIconService]
})
export class SvgIconModule {
    constructor(service: SvgIconService) {
        service.registerIcons();
    }
}
