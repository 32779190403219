import { Injectable } from '@angular/core';
import { CanLoad, CanActivate } from '@angular/router';
import { NavigationSlugs } from '../../navigation/navigation-slugs';
import { NavigationService } from '../../navigation/navigation.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class BcmAuthGuard implements CanLoad, CanActivate {
    constructor(
        private authService: AuthService,
        private navigationService: NavigationService,
    ) {
    }

    canLoad(): Observable<boolean> {
        return this.isAccessAllowed();
    }

    canActivate(): Observable<boolean> {
        return this.isAccessAllowed();
    }

    isAccessAllowed(): Observable<boolean> {
        return this.authService
            .isLoggedIn$
            .pipe(
                take(1),
                map(isLoggedIn => {
                    if (isLoggedIn) {
                        this.navigationService.changeNavigationTo(NavigationSlugs.MainBcm);
                    } else {
                        this.authService.doLogin();
                    }
                    return isLoggedIn;
                })
            );
    }
}
