import { ElectricMeter, IElectricMeter, IElectricMeterRaw } from '@shared/models/electric-meter';
import { ElectricMeterAssignment, IElectricMeterAssignment } from '@shared/models/electric-meter-assignment';

export interface IElectricMeterCabinet {
    id: number;
    handle: string;
    pierHandle: string;
    keyHandle: string;
    note: string;
    slotsTotal: number;
    slotsOccupied: number;

    insertedOn: string;
    insertedBy: string;
    lastUpdateOn: string;
    lastUpdateBy: string;

    electricMeters: (IElectricMeterRaw | IElectricMeter)[];
}

export class ElectricMeterCabinet {
    id: number;
    handle: string;
    pierHandle: string;
    keyHandle: string;
    note: string;
    slotsTotal: number;
    slotsOccupied: number;
    hasFreeSlots: boolean;

    insertedOn: string;
    insertedBy: string;
    lastUpdateOn: string;
    lastUpdateBy: string;

    electricMeters: ElectricMeter[];
    hasFreeElectricMeters: boolean;

    constructor(data = {} as IElectricMeterCabinet) {
        this.id = data.id;
        this.handle = data.handle;
        this.pierHandle = data.pierHandle;
        this.keyHandle = data.keyHandle;
        this.note = data.note;
        this.slotsTotal = data.slotsTotal;
        this.slotsOccupied = data.slotsOccupied;
        this.hasFreeSlots = this.slotsTotal - this.slotsOccupied > 0;

        this.insertedOn = data.insertedOn;
        this.insertedBy = data.insertedBy;
        this.lastUpdateOn = data.lastUpdateOn;
        this.lastUpdateBy = data.lastUpdateBy;

        this.electricMeters = (data.electricMeters || []).map(eMeter => new ElectricMeter(eMeter));
        this.hasFreeElectricMeters =
            this.electricMeters.length > this.electricMeters.filter(eMeter => eMeter.boats.length > 0).length;
    }
}

