export interface IBankAccount {
    id?: number;
    tenantId?: number;
    isPrimary: boolean;
    bankName: string;
    bankCode: string;
    accountNumber: string;
    iban: string;
    bic: string;
    SEPA: boolean;
    SEPAId: string;
    SEPAAccountHolder: string;
    SEPADaysOfMinLeadTimeFirstDebit: number;
    SEPADaysOfMinLeadTimeOtherDebits: number;
    editable: boolean;
    batchBooking: boolean;
    inEditMode?: boolean;
}

export class BankAccount {
    id?: number;
    tenantId?: number;
    isPrimary: boolean;
    bankName: string;
    bankCode: string;
    accountNumber: string;
    iban: string;
    bic: string;
    SEPA: boolean;
    SEPAId: string;
    SEPAAccountHolder: string;
    SEPADaysOfMinLeadTimeFirstDebit: number;
    SEPADaysOfMinLeadTimeOtherDebits: number;
    batchBooking: boolean;
    editable: boolean;

    constructor(tenantAccounts = {} as IBankAccount) {
        this.id = tenantAccounts.id;
        this.tenantId = tenantAccounts.tenantId;
        this.isPrimary = tenantAccounts.isPrimary;
        this.bankName = tenantAccounts.bankName;
        this.bankCode = tenantAccounts.bankCode;
        this.accountNumber = tenantAccounts.accountNumber;
        this.iban = tenantAccounts.iban;
        this.bic = tenantAccounts.bic;
        this.SEPA = tenantAccounts.SEPA;
        this.SEPAId = tenantAccounts.SEPAId;
        this.SEPAAccountHolder = tenantAccounts.SEPAAccountHolder;
        this.SEPADaysOfMinLeadTimeFirstDebit = tenantAccounts.SEPADaysOfMinLeadTimeFirstDebit;
        this.SEPADaysOfMinLeadTimeOtherDebits = tenantAccounts.SEPADaysOfMinLeadTimeOtherDebits;
        this.batchBooking = tenantAccounts.batchBooking;
    }
}


