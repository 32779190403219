import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { combineLatest, Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { TIMEOUT_FORM_SAVING_MIN } from '@shared/constants/timeouts';
import { Page } from '@shared/models/pagination';

export abstract class BaseApiService<Entity, RawEntity> {

    protected abstract endpoint: string;

    protected constructor(protected http: HttpClient) {
    }

    protected getList(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity[]> {
        return this.http.get<RawEntity[]>(`${this.endpoint}${path}`, {params});
    }

    protected getPage(path: string, params: HttpParams = new HttpParams()): Observable<Page<RawEntity>> {
        return this.http.get<Page<RawEntity>>(`${this.endpoint}${path}`, {params});
    }

    protected get(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        return this.http.get<RawEntity>(`${this.endpoint}${path}`, {params});
    }

    protected getBlob(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
        return this.http.get(`${this.endpoint}${path}`, {params, responseType: 'blob'});
    }

    protected getBlobWithProgress(path: string, params: HttpParams = new HttpParams()): Observable<HttpEvent<Blob>> {
        return this.http.get(`${this.endpoint}${path}`, {
            params,
            reportProgress: true,
            observe: 'events',
            responseType: 'blob'
        });
    }

    protected post(path: string,
                   body: any,
                   params: HttpParams = new HttpParams()): Observable<RawEntity> {
        return combineLatest([
            this.http.post<RawEntity>(`${this.endpoint}${path}`, body, {params}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [RawEntity, number]) => response));
    }

    protected postWithBlobResponse(path: string,
                                   body: any,
                                   params: HttpParams = new HttpParams()): Observable<RawEntity> {
        return combineLatest([
            this.http.post<RawEntity>(`${this.endpoint}${path}`, body, {params, responseType: 'blob' as 'json'}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [RawEntity, number]) => response));
    }

    protected put(path: string,
                  body: any,
                  params: HttpParams = new HttpParams()): Observable<RawEntity> {
        return combineLatest([
            this.http.put<RawEntity>(`${this.endpoint}${path}`, body, {params}),
            timer(TIMEOUT_FORM_SAVING_MIN)
        ]).pipe(map(([response]: [RawEntity, number]) => response));
    }

    protected delete(path: string, params: HttpParams = new HttpParams()): Observable<RawEntity> {
        return this.http.delete<RawEntity>(`${this.endpoint}${path}`, {params});
    }

    protected deleteMultiple(path: string, params: HttpParams = new HttpParams()): Observable<number[]> {
        return this.http.delete<number[]>(`${this.endpoint}${path}`, {params});
    }

}
