<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                <ng-container>
                    {{'selectBoat' | translate}}
                </ng-container>
            </span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0" fusePerfectScrollbar>

        <mat-table class="boats-list" [dataSource]="dataSource" matSort matSortActive="name" matSortDisableClear
                   matSortDirection="asc" fusePerfectScrollbar>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{'boatName' | translate}}</mat-header-cell>
                <mat-cell *matCellDef="let boat">
                    <p class="text-truncate">{{boat.name}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="licensePlate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Kennzeichen</mat-header-cell>
                <mat-cell *matCellDef="let boat">
                    <p class="text-truncate">{{boat.licensePlate}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Typ</mat-header-cell>
                <mat-cell *matCellDef="let boat">
                    <p class="text-truncate">{{boat.type}}</p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="length">
                <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" fxShow.gt-md
                                 fxLayoutAlign="flex-end center">Länge
                </mat-header-cell>
                <mat-cell *matCellDef="let boat" fxLayoutAlign="flex-end center">
                    <p class="phone text-truncate">
                        {{boat.length | number : '1.1-4'}}
                    </p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="width">
                <mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" fxShow.gt-lg
                                 fxLayoutAlign="flex-end center">Breite
                </mat-header-cell>
                <mat-cell *matCellDef="let boat" fxLayoutAlign="flex-end center">
                    <p class="job-title text-truncate">
                        {{boat.width | number : '1.1-4'}}
                    </p>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let boat; columns: displayedColumns;" [attr.data-test]="'TZ-Boot-' + boat.name"
                     class="boat" (click)="selectBoat(boat)">
            </mat-row>
        </mat-table>
    </div>
</div>
