import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { SharedModule } from '@shared/shared.module';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { OrderModule } from 'ngx-order-pipe';
import { PremiumFormWidgetElectricMeterComponent } from './premium-form-widget-electric-meter.component';
import { HasBerthValidator } from '@shared/validators/boat/async/has-berth.validator';
import { DateFnsModule } from 'ngx-date-fns';
import { NumberInputModule } from '@shared/components/form/number-input/number-input.module';

@NgModule({
    declarations: [PremiumFormWidgetElectricMeterComponent],
    imports: [

        FuseSharedModule,
        SharedModule,

        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        DirectivesModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        OrderModule,
        DateFnsModule,
        NumberInputModule,
    ],
    providers: [HasBerthValidator],
    exports: [PremiumFormWidgetElectricMeterComponent]
})
export class PremiumFormWidgetElectricMeterModule {

}
