import { NgModule } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { SharedModule } from '@shared/shared.module';
import { ReleaseNotesDialogComponent } from './release-notes-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DirectivesModule } from '@shared/directives/directives.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { ReleaseNoteBodyComponent } from './release-note-body/release-note-body.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { DateFnsModule } from 'ngx-date-fns';

@NgModule({
    declarations: [
        ReleaseNotesDialogComponent,
        ReleaseNoteBodyComponent,
    ],
    imports: [
        FuseSharedModule,
        FuseWidgetModule,

        SharedModule,
        DragDropModule,
        DirectivesModule,
        MatToolbarModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        DateFnsModule,
    ],
    exports: [
        ReleaseNotesDialogComponent
    ]
})
export class ReleaseNotesDialogModule {
}
