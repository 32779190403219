import { Directive, ElementRef, OnDestroy } from '@angular/core';
import * as textMask from 'vanilla-text-mask/dist/vanillaTextMask.js';

/**
 * Because angular reactive form is not compatible with angular2-text-mask, this is a workaround.. using vanilla-text-mask
 *
 * From here:
 * https://github.com/angular/angular/issues/16755#issuecomment-365045055
 */
@Directive({selector: '[appMaskTimeHours]'})
export class MaskTimeHoursDirective implements OnDestroy {

    mask = {
        guide: true,
        showMask: true,
        mask: [/[012]/, /[0-9]/],
        keepCharPositions: true
    };

    maskedInputController: any;

    constructor(private element: ElementRef) {
        this.maskedInputController = textMask.maskInput({
            inputElement: this.element.nativeElement,
            mask: this.mask.mask
        });
    }

    ngOnDestroy(): void {
        this.maskedInputController.destroy();
    }

}
