import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@core/services/app-notification.service';
import { AppUser, UserService } from '@core/services/user.service';
import { BcmService } from '@modules/bcm/bcm.service';
import { U2bUserApiService } from '@core/services/api/u2b-user.api-service';
import { parseISO } from '@core/date.facade';
import { AuthService } from '../../../../auth/auth.service';
import { tryParseDate } from '@shared/functions/try-parse-date';

@Component({
    selector: 'read-privacy-and-conditions',
    templateUrl: './read-privacy-and-conditions.component.html'
})
export class ReadPrivacyAndConditionsComponent {

    user: AppUser;

    privacyPage: any;

    conditionsPage: any;

    isSaving: boolean;

    constructor(private _dialogRef: MatDialogRef<ReadPrivacyAndConditionsComponent>,
                private _appNotificationService: AppNotificationService,
                private _userService: UserService,
                private _u2bUserApiService: U2bUserApiService,
                private _bcmService: BcmService,
                private _authService: AuthService,
    ) {

        this._dialogRef.addPanelClass('default-dialog');
        this._dialogRef.disableClose = true;

        this.user = this._userService.user;
    }

    onClickLogout(): void {
        this._dialogRef.close(false);

        this._userService.reset();
        this._bcmService.reset();
        this._authService.signOut();
    }

    onClickOK(): void {
        this.isSaving = true;
        this._u2bUserApiService
            .updatePrivacyAndConditions(this.user)
            .subscribe((result) => {
                this.user.privacyAndConditions = {
                    id: result?.id || null,
                    readPrivacyOn: tryParseDate(result.readPrivacyOn),
                    readConditionsOn: tryParseDate(result.readConditionsOn)
                };
                this._appNotificationService.showSuccess('Vielen Dank, Deine Bestätigung wurde gespeichert.');
            })
            .add(() => {
                this.isSaving = false;
                this._dialogRef.close(true);
            });
    }
}
