import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';
import { Observable } from 'rxjs';

export abstract class BaseAsyncValidator implements AsyncValidator {

    public IS_VALID = null;

    public abstract validationError: BaseValidationError;

    public get IS_INVALID(): ValidationErrors {
        return this.validationError.INVALID;
    }

    constructor(public message?: string) {
    }

    public abstract validate(control: AbstractControl, ...params): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>;

    public asValidatorFn(...params): AsyncValidatorFn {
        return (control) => this.validate(control, ...params);
    }

}
