import { NgModule } from '@angular/core';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';
import { AuthStorageService } from './auth-storage.service';

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                authority: environment.keycloakConfig.url,
                redirectUrl: environment.keycloakConfig.redirectUrl || window.location.origin,
                postLogoutRedirectUri: environment.keycloakConfig.redirectUrl || window.location.origin,
                clientId: 'u2b-web',
                scope: 'openid profile email', // offline_access needed?
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
                customParamsAuthRequest: {
                    prompt: 'login' // Login erzwingen
                },

                // renewTimeBeforeTokenExpiresInSeconds: 30,
                secureRoutes: [
                    'http://localhost:4201/api/',
                    'http://localhost:1337/api/',
                    'http://localhost:3333/api/',
                ],
            }
        })
    ],
    providers: [{ provide: AbstractSecurityStorage, useClass: AuthStorageService }],
    exports: [AuthModule],
})
export class AuthenticationModule {
}
