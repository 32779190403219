<div [formGroup]="productsForm">

    <h2>{{headline}}</h2>

    <ng-container *ngIf="productsForm.get('products')['controls'].length">

        <div formArrayName="products"
             *ngFor="let item of productsForm.get('products')['controls']; let i = index">

            <ng-container *ngIf="!singleSelection">
                <p>{{i + 1}}. Produkt</p>
            </ng-container>

            <div [formGroupName]="i"
                 fxLayout="row wrap"
                 fxLayoutGap="12px grid">

                <div fxFlex="100" fxLayout="row" fxLayoutAlign="start start">
                    <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                        <mat-label>Produkt wählen</mat-label>
                        <input type="text" matInput data-test="FI-Produkte durchsuchen..."
                               autocomplete="new-password"
                               formControlName="product"
                               [matAutocomplete]="productSelector">
                        <mat-hint *ngIf="item.get('product').value">
                            <bcm-product-price-label></bcm-product-price-label>
                            : {{ item.get('product').value?.price | bcmDynamicCurrency: '1.2-2' }}
                            / {{item.get('product').value?.unit?.name}}
                        </mat-hint>
                        <mat-error>{{item.get('product').errors | firstErrorMessage}}</mat-error>
                    </mat-form-field>

                    <mat-autocomplete #productSelector="matAutocomplete" [displayWith]="displayProductWith">
                        <mat-option *ngFor="let product of (filteredProductsList$[i] | async)" [value]="product">
                            <span>{{ product.name }}</span>
                            <small> | {{product.price | bcmDynamicCurrency: '1.2-2'}}</small>
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <mat-form-field [appearance]="appearance" [fxFlex]="singleSelection ? 100 : 85" floatLabel="always">
                    <mat-label>Anzahl / Einheiten</mat-label>
                    <app-input-number
                            matInput
                            data-test="FI-Anzahl / Einheiten"
                            formControlName="quantity"
                            [step]="1"
                            [min]="0"></app-input-number>
                    <mat-error>{{item.get('quantity').errors | firstErrorMessage}}</mat-error>
                </mat-form-field>

                <ng-container *ngIf="!singleSelection">
                    <div fxFlex="15">
                        <button data-test="B-Produkt entfernen"
                                mat-button
                                matTooltip="Produkt entfernen"
                                [matTooltipPosition]="'above'"
                                class="mat-error"
                                (click)="removeProduct($event, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>

            <ng-container *ngIf="!singleSelection">
                <mat-divider class="my-16"></mat-divider>
            </ng-container>
        </div>

    </ng-container>

    <ng-container *ngIf="!singleSelection">
        <button data-test="B-Produkt hinzufügen"
                mat-button
                color="accent"
                (click)="addProduct($event)">
            <mat-icon>add_circle</mat-icon>
            Produkt hinzufügen
        </button>
    </ng-container>
</div>
