import { NgModule } from '@angular/core';
import { E2eTestIdDirective } from '@core/directives/e2e/e2e-test-id.directive';
import { BrowserBackOnClickDirective } from '@core/directives/browser-back-on-click.directive';
import { HasPermissionDirective } from '@core/directives/has-permission.directive';
import { InfoBannerHostDirective } from '@core/directives/info-banner-host.directive';
import { HasTenantPermissionDirective } from '@core/directives/has-tenant-permission.directive';
import { TypeSafeMatCellDef } from '@core/directives/type-save-mat-cell-def.directive';

@NgModule({
    declarations: [
        E2eTestIdDirective,
        BrowserBackOnClickDirective,
        HasPermissionDirective,
        HasTenantPermissionDirective,
        InfoBannerHostDirective,
        TypeSafeMatCellDef,
    ],
    exports: [
        E2eTestIdDirective,
        BrowserBackOnClickDirective,
        HasPermissionDirective,
        HasTenantPermissionDirective,
        InfoBannerHostDirective,
        TypeSafeMatCellDef,
    ]
})
export class CoreDirectivesModule {

}
