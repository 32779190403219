import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BaseValidator } from '@shared/validators/base-validator';
import { BaseValidationError } from '@shared/validators/validation-error/base-error';

export class MailGroupValidator extends BaseValidator {

    constructor(public message?: string) {
        super();
    }

    public validationError = new BaseValidationError('mailGroup', this.message || 'Bitte gib E-Mail und den Typ an');

    validate(control: AbstractControl): ValidationErrors | null {

        const typeField = control.get('type');
        const emailField = control.get('email');

        if (!typeField || !emailField) {
            return this.IS_VALID;
        }

        const hasType = !!typeField.value;
        const hasEmail = !!emailField.value;

        if (hasType && !hasEmail || !hasType && hasEmail) {
            control.markAllAsTouched();
            return this.IS_INVALID;
        }

        return this.IS_VALID;
    }
}
