import { BcmFile } from './bcm-file';
import { tryParseDate } from '@shared/functions/try-parse-date';

export class BcmInvoiceWdTemplate {
    id: number;
    title: string;
    note: string;

    file?: BcmFile;

    insertedOn?: Date;
    insertedBy?: string;
    lastUpdateOn?: Date;
    lastUpdateBy?: string;

    creator: { // ToDo: create interface
        firstName: string;
        lastName: string;
        [key: string]: any
    };

    creatorName: string;

    constructor(dto) {
        if (!dto) {
            return;
        }
        this.id = dto.id;
        this.title = dto.title;
        this.note = dto.note;
        this.file = (dto.file) ? new BcmFile(dto.file) : null;

        this.creator = dto.creator;
        this.creatorName = `${this.creator?.firstName} ${this.creator?.lastName}` || '';

        this.insertedOn = tryParseDate(dto.insertedOn);
        this.insertedBy = dto.insertedBy;
        this.lastUpdateOn = tryParseDate(dto.lastUpdateOn);
        this.lastUpdateBy = dto.lastUpdateBy;

    }
}
