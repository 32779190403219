import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { AuthEventsService } from './auth/auth-events.service';
import { TranslationService } from '@core/translation/translation.service';
import { authInitializer } from './auth';
import { translationInitializer } from '@core/translation/translation-initializer';
import { TranslationLanguage } from '@core/translation/translation-models';

export function appInitializer(authService: AuthService,
                               authEventsService: AuthEventsService,
                               translationService: TranslationService): () => Observable<boolean> {
    return () => {
        // first auth, then load translations!
        return authInitializer(authService, authEventsService)
            .pipe(switchMap(authSuccess => {
                if (authSuccess) {
                    return translationInitializer(
                        TranslationLanguage.DE,
                        translationService
                    );
                } else {
                    return of(authSuccess);
                }
            }));
    };
}
