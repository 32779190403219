import { Injectable } from '@angular/core';
import { LogApiService } from '@bcmServices/index';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class AppLoggingService {

    constructor(private _logApiService: LogApiService) {
    }

    logError(message: string, error: Error, stack: any): void {

        this._logApiService.add({error: error, category: 'Front-End', stack}).subscribe();

        if (!environment.production) {
            console.error(error);
        }
    }
}
