import { IPerson, Person } from '@shared/models/person';
import { Company, ICompany } from '@shared/models/company';

export interface ICompanyContact {
    id?: number;
    bcm_persons_id?: number;
    bcm_companies_id?: number;
    company: ICompany;
    person?: IPerson;
    personName?: string;
    personPhone?: string;
    personMail?: string;
    note?: string;
}

export class CompanyContact {
    id?: number;
    personId?: number;
    companyId?: number;
    company?: Company;
    person?: Person;
    personName?: string;
    personPhone?: string;
    personMail?: string;
    note?: string;

    constructor(companyContact = {} as ICompanyContact) {
        this.id = companyContact.id;
        this.companyId = companyContact.bcm_companies_id;
        this.company = new Company(companyContact.company);
        this.personId = companyContact.bcm_persons_id;
        this.person = (companyContact.person) ? new Person(companyContact.person) : null;
        this.personName = this.person?.fullName || companyContact.personName;
        this.personMail = this.person?.mail || companyContact.personMail;
        this.personPhone = this.person?.phone || companyContact.personPhone;
        this.note = companyContact.note;
    }
}
