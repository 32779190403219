import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { ModalModule } from '@core/components/modal/modal.module';
import { SharedModule } from '@shared/shared.module';
import { DateFnsModule } from 'ngx-date-fns';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
    selector: 'berth-reservation-dialog',
    templateUrl: './berth-reservation-dialog.html',
    styleUrls: ['./berth-reservation-dialog.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
    standalone: true,
    imports: [
        ModalModule,
        SharedModule,
        DateFnsModule,
        MatLegacyTooltipModule,
    ]
})

export class BerthReservationsDialog implements OnInit {
    reservedAssignments: any[];

    constructor(
        public dialogRef: MatDialogRef<BerthReservationsDialog>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: {
            reservedAssignments: any[];
        },
    ) {}

    ngOnInit(): void {
        this.reservedAssignments = this.data.reservedAssignments;
    }
}
