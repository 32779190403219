import { ValidatorFn } from '@angular/forms';
import { U2BDateRequiredValidator } from '@shared/validators/date/date-required';
import { U2BMaxDateValidator } from '@shared/validators/date/max-date';
import { U2BMinDateValidator } from '@shared/validators/date/min-date';
import { U2BDateBeforeOtherDateValidator } from '@shared/validators/date/date-before-other-date';

export class U2bDateValidators {
    public static dateRequired(message?: string): ValidatorFn {
        return new U2BDateRequiredValidator(message).asValidatorFn();
    }

    public static maxDate(maxDate: Date, message?: string): ValidatorFn {
        return new U2BMaxDateValidator(maxDate, message).asValidatorFn();
    }

    public static minDate(minDate: Date, message?: string): ValidatorFn {
        return new U2BMinDateValidator(minDate, message).asValidatorFn();
    }

    public static dateBeforeOtherDate(date: string, otherDate: string, dateLabel: string, otherDateLabel: string): ValidatorFn {
        return new U2BDateBeforeOtherDateValidator(date, otherDate, dateLabel, otherDateLabel).asValidatorFn();
    }
}
