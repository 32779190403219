import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ElectronService } from '@core/services/electron.service';

@Injectable({providedIn: 'root'})
export class AuthEventsService {
    constructor(
        private electronService: ElectronService,
        private authService: AuthService,
    ) {
    }

    registerEvents(): void {
        this.electronService.onEventAuth((url: string) => {
            this.authService.checkAuth(url).subscribe();
        });
    }
}
