import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { SalutationNew } from '@shared/models/salutation';
import { AppUser, UserService } from '@core/services/user.service';

@Component({
    selector: 'form-widget-tenant-admin-account',
    templateUrl: './form-widget-tenant-admin-account.component.html'
})
export class FormWidgetTenantAdminAccountComponent implements OnInit, OnDestroy {

    @Input()
    parentFormGroup: UntypedFormGroup;

    userFormGroup: UntypedFormGroup;

    salutation = SalutationNew;

    user: AppUser;

    constructor(private _formBuilder: UntypedFormBuilder,
                private _userService: UserService) {

        this.user = this._userService.user || {} as AppUser;

        this.userFormGroup = this._formBuilder.group({
            salutation: [this.user.salutation, [U2bValidators.required('Bitte Anrede angeben')]],
            firstName: [this.user.firstName, [U2bValidators.required('Bitte Vornamen angeben')]],
            lastName: [this.user.lastName, [U2bValidators.required('Bitte Nachnamen angeben')]],
            email: [this.user.email, [U2bValidators.required('Bitte gültige E-Mail Adresse angeben'), U2bValidators.email()]],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('user', this.userFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('user');
    }
}
