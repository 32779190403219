import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bAdminTenantsFacade } from '@modules/admin/state/tenants/u2b-admin-tenants.facade';
import { MatStepper } from '@angular/material/stepper';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { AppUser } from '@core/services/user.service';
import { AppNotificationService } from '@core/services/app-notification.service';

@Component({
    selector: 'new-customer-dialog',
    templateUrl: './new-customer.component.html',
    styleUrls: ['./new-customer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewCustomerComponent {
    dialogTitle = 'Neukunde Yachthafenmanagement';

    formGroup: UntypedFormGroup;

    user: AppUser;

    isSaving: boolean;

    @ViewChild(MatStepper) stepper: MatStepper;

    @ViewChild(FusePerfectScrollbarDirective) scrollable: FusePerfectScrollbarDirective;

    constructor(
        public dialogRef: MatDialogRef<NewCustomerComponent>,
        private u2bAdminTenantsFacade: U2bAdminTenantsFacade,
        private _formBuilder: UntypedFormBuilder,
        private _appNotificationService: AppNotificationService,
    ) {
        this.formGroup = this._formBuilder.group({});
    }

    goBack(): void {
        this.stepper.previous();
    }

    goForward(): void {
        this.stepper.next();
    }

    scrollTop(): void {
        this.scrollable.scrollToTop();
        this.scrollable.update();
    }

    save(): void {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            this._appNotificationService.showError(`Bitte überprüfe die Rot markierten Felder`);
            return;
        }

        this.isSaving = true;
        this.u2bAdminTenantsFacade
            .add(this.formGroup.value)
            .subscribe((tenant) => {
                if (tenant) {
                    this.dialogRef.close();
                }
            })
            .add(() => this.isSaving = false);
    }
}
