<div [formGroup]="subscriptionPeriodFormGroup">

    <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" floatLabel="always" fxFlex>
            <mat-label>Zahlungsintervall</mat-label>
            <mat-select data-test="FS-Zahlungsintervall" formControlName="payableOption"
                        [compareWith]="comparePayableOptions">
                <mat-option *ngFor="let payableOption of payableOptions" [value]="payableOption">
                    {{payableOption.name}}
                </mat-option>
            </mat-select>
            <mat-error>{{subscriptionPeriodFormGroup.get('payableOption').errors | firstErrorMessage}}</mat-error>
        </mat-form-field>
    </div>

    <br>

    <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
        <br>
        <strong>
            Regelmäßige Zahlung
            <ng-container *ngIf="subscriptionPeriodFormGroup.get('payableOption').value">
                            <span style="text-transform: lowercase;">
                                - {{subscriptionPeriodFormGroup.get('payableOption').value?.name || 'Laufzeit'}}
                            </span>
            </ng-container>
        </strong>
    </div>

    <h3>Wann soll die <strong>erste</strong> Position erstellt werden?</h3>
    <app-input-date-time formControlName="fromDate">
        <label>Erste Position am</label>
    </app-input-date-time>

    <h3>
        Wann soll die <strong>letzte</strong> Position erzeugt werden?<br>
        <small>Leer lassen, wenn Laufzeitende offen.</small>
    </h3>

    <app-input-date-time formControlName="toDate">
        <label>Letzte Position am</label>
    </app-input-date-time>

    <div fxLayout="column" fxLayoutAlign="start start" class="mb-8">
        <h2>Erster Leistungszeitraum</h2>
        <p>
            Definiere den 1. Leistungszeitraum, für den eine Position erstellt werden soll.
            Nachfolgende Positionen werden
            <ng-container *ngIf="subscriptionPeriodFormGroup.get('payableOption').value; else other">
                <strong style="text-transform: lowercase;">{{subscriptionPeriodFormGroup.get('payableOption').value?.name}}</strong>
            </ng-container>
            <ng-template #other>im ausgewählten Zahlungsintervall</ng-template>
            erneut erzeugt.
        </p>
    </div>

    <ng-container
            *ngIf="subscriptionPeriodFormGroup.get('payableOption')?.value?.id === PayableOptionId.Yearly && seasons?.length">
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex>
                <mat-label>Wähle aus periodischen Saisons</mat-label>
                <mat-select data-test="FS-Saisons"
                            [formControl]="seasonFormControl"
                            [compareWith]="compareSeasons">
                    <mat-option [value]="null">- Keine Saison -</mat-option>
                    <mat-option *ngFor="let season of seasons" [value]="season">
                        {{season.name}} / {{season.startDate | dfnsFormat : 'dd.MM.yyyy'}} -
                        {{season.endDate | dfnsFormat : 'dd.MM.yyyy'}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </ng-container>

    <app-input-date-time formControlName="vestingPeriodFrom">
        <label>Leistungszeitraum Start</label>
    </app-input-date-time>

    <app-input-date-time formControlName="vestingPeriodUntil">
        <label>Leistungszeitraum Ende</label>
    </app-input-date-time>

    <ng-container
            *ngIf="[PayableOptionId.Monthly, PayableOptionId.HalfYearly, PayableOptionId.QuarterYearly].includes(subscriptionPeriodFormGroup.get('payableOption')?.value?.id)">
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-checkbox formControlName="lastDayOfMonth">
                Leistungszeitraum Ende automatisch auf den letzten Tag des Monats setzen
            </mat-checkbox>
        </div>
    </ng-container>

</div>
