<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="{{dialogTitleTheme ? 'mat-' + dialogTitleTheme : 'mat-accent'}} m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle || 'Information'}}</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)" aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0" fusePerfectScrollbar>

        <div [innerHTML]="dialogBody"></div>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" [fxLayoutAlign]="leftButton ? 'space-between' : 'end'">

        <ng-container *ngIf="leftButton">
            <button [attr.data-test]="'B-' + leftButton.text" mat-flat-button [color]="leftButton.color" (click)="leftButton.onClick(); dialogRef.close(true)">{{leftButton.text}}</button>
        </ng-container>

        <button [attr.data-test]="'B-' + dialogButton.text" mat-flat-button [color]="dialogButton.color" (click)="dialogRef.close(true)">{{dialogButton.text}}</button>

    </div>
</div>
