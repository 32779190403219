<ng-container *ngIf="relations?.length">
    <ng-container *ngFor="let relation of relations; let last = last; let i = index">
        <ng-container *ngIf="showFullList || i < 1; else showAllButton">
            <div class="tenant-color-indicator"
                 [matTooltip]="relation.upcomingTenantRelation ? ('Künftige Beziehung: ' + relation.rangeTooltip) : relation.rangeTooltip"
                 [style.background-color]="relation?.tenantRelation?.hexBgColor || undefined">
                <span [style.color]="relation?.tenantRelation?.hexColor || undefined">
                    <ng-container *ngIf="relation.upcomingTenantRelation">&#9733;</ng-container>
                    {{relation?.tenantRelation?.name}}
                </span>
            </div>
        </ng-container>
        <ng-template #showAllButton>
            <ng-container *ngIf="!showFullList && relations.length > 1 && i === 1">
                <div (click)="$event.stopPropagation(); showFullList = true"
                     class="tenant-color-indicator more-button">
                    <span>+{{relations.length - 1}}</span>
                </div>
            </ng-container>
        </ng-template>
    </ng-container>
</ng-container>
