export enum WeekDayNumber {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
}

export const TWO_WEEKS_IN_DAYS = 14;
export const ONE_MINUTE_IN_MS = 1000;
export const FIVE_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 5;
export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export function minutesToSeconds(minutes: number): number {
    return (minutes || 0) * 60;
}

export function minutesToMilliSeconds(minutes: number): number {
    return minutesToSeconds(minutes) * 1000;
}

export function secondsToMilliSeconds(seconds: number): number {
    return (seconds || 0) * 1000;
}
