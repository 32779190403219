import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { NavigationSlugs } from '../../navigation/navigation-slugs';
import { NavigationService } from '../../navigation/navigation.service';
import { AppNotificationService } from '@core/services/app-notification.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class U2bAdminAuthGuard implements CanLoad, CanActivate {
    constructor(private authService: AuthService,
                private navigationService: NavigationService,
                private appNotificationService: AppNotificationService) {
    }

    canLoad(): Observable<boolean> {
        return this.isAccessAllowed();
    }

    canActivate(): Observable<boolean> {
        return this.isAccessAllowed();
    }

    isAccessAllowed(): Observable<boolean> {
        return this.authService
            .userData$
            .pipe(map(userData => {
                const roles = userData?.groups || [];
                const result = roles.includes('u2b_super_admin')
                    || roles.includes('u2b_admin')
                    || roles.includes('u2b_support');

                if (result) {
                    this.navigationService.changeNavigationTo(NavigationSlugs.MainAdmin);
                } else {
                    this.appNotificationService.showError('Zugriff verweigert.');
                }

                return result;
            }));
    }
}
