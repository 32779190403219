import { IFile } from '@shared/interfaces/file';
import { AppUser } from '@core/services/user.service';
import { parseISO } from '@core/date.facade';
import { User } from '@core/services/api/u2b-user.api-service';

export interface IAvv {
    id: number;
    user: AppUser;
    signedBy: User;
    signedOn: string | Date;
    pdf: IFile;
    user_id: string;
}

export class Avv implements IAvv {
    id: number;
    user: AppUser;
    signedBy: User;
    signedOn: Date;
    pdf: IFile;
    // tslint:disable-next-line:variable-name
    user_id: string;

    constructor(avv = {} as IAvv) {
        this.id = avv?.id;
        this.user = avv?.user;
        this.signedBy = avv?.signedBy;
        this.signedOn = avv?.signedOn ? parseISO(avv.signedOn as string) : null;
        this.pdf = avv?.pdf;
        this.user_id = avv?.user_id;
    }
}
