import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { U2bValidators } from '@shared/validators/validators';
import { SignaturePad } from 'angular2-signaturepad';
import { TenantApiService } from '@modules/bcm/@shared/services';
import { BcmService } from '@modules/bcm/bcm.service';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { downloadFile } from '@shared/functions/download-file';

@Component({
    selector: 'form-widget-avv',
    templateUrl: './form-widget-avv.component.html',
    styleUrls: ['./form-widget-avv.component.scss']
})
export class FormWidgetAvvComponent implements AfterViewInit, OnInit, OnDestroy {

    private _unsubscribeAll = new Subject<any>();

    @ViewChild(SignaturePad) signaturePad: SignaturePad;

    @Input()
    parentFormGroup: UntypedFormGroup;

    avvFormGroup: UntypedFormGroup;

    isSigned: boolean;

    isLoadingAvvPdf: boolean;

    signaturePadOptions: object = { // passed through to szimek/signature_pad constructor
        // minWidth: 5,
        canvasWidth: 500 * Math.max(window.devicePixelRatio || 1, 1),
        canvasHeight: 150 * Math.max(window.devicePixelRatio || 1, 1)
    };

    constructor(private _formBuilder: UntypedFormBuilder,
                private _bcmService: BcmService,
                private _tenantApiService: TenantApiService) {
        this.avvFormGroup = this._formBuilder.group({
            accepted: [false, [U2bValidators.requiredTrue('Bitte akzeptiere die Auftragsverarbeitungsvereinbarung (AVV)')]],
            signature: [null, [/*U2bValidators.required('Keine digitale Signatur eingezeichnet')*/]],
            signatureText: [null, [U2bValidators.required('Bitte Unterschrift angeben')]],
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.parentFormGroup.addControl('avv', this.avvFormGroup);
        });
    }

    ngOnDestroy(): void {
        this.parentFormGroup.removeControl('avv');
    }

    onClickGetAvv(): void {
        this.isLoadingAvvPdf = true;
        this._tenantApiService
            .generateAvv(
                this._bcmService.tenant,
                '',
                this.avvFormGroup.get('signatureText').value,
            )
            .pipe(takeUntil(this._unsubscribeAll))
            .pipe(mergeMap(() => this._tenantApiService.getAvvPdf(this._bcmService.tenant)))
            .subscribe(blob => downloadFile(blob, 'Up2Boat_BCM_Auftragsverarbeitungsvereinbarung.pdf', 'pdf', 'application/pdf'))
            .add(() => this.isLoadingAvvPdf = false);
    }

    ngAfterViewInit(): void {
        // this.signaturePad.clear();
    }

    drawComplete(): void {
        this.isSigned = true;
        this.saveSignature();
    }

    drawStart(): void {
        // ...
    }

    saveSignature(): void {
        // .toDataURL(); // save image as PNG
        // .toDataURL("image/jpeg"); // save image as JPEG
        // .toDataURL("image/svg+xml"); // save image as SVG
        this.avvFormGroup.get('signature').setValue(this.signaturePad.toDataURL());
        this.avvFormGroup.get('signature').updateValueAndValidity();
    }

    clearSignature(): void {
        this.isSigned = false;
        this.signaturePad.clear();
        this.avvFormGroup.get('signature').reset();
        this.avvFormGroup.get('signature').updateValueAndValidity();
    }
}
