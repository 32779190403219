import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BcmContractsApiService } from './bcm-contracts-api.service';
import { Contract, ContractFileType } from '@shared/models/contract';
import { StateResetService } from '@bcmServices/state-reset.service';
import { PageRequest } from '@shared/models/pagination';


// - We set the initial state in BehaviorSubject's constructor
// - Nobody outside the Store should have access to the BehaviorSubject
//   because it has the correct rights
// - Writing to state should be handled by specialized Store methods (ex: addContract, removeContract, etc)
// - Create one BehaviorSubject per store entity, for example if you have ContractGroups
//   create a new BehaviorSubject for it, as well as the observable$, and getters/setters

@Injectable({providedIn: 'root'})
export class BcmContractsStoreService {

    private readonly _contracts = new BehaviorSubject<Contract[]>([]);

    private readonly _contract = new BehaviorSubject<Contract>(null);

    private _latestPageRequest: PageRequest<Contract>;

    // Expose the observable$ part of the _todos subject (read only stream)
    readonly contracts$ = this._contracts.asObservable();
    readonly contract$ = this._contract.asObservable();

    get contracts(): Contract[] {
        return this._contracts.getValue();
    }

    set contracts(val: Contract[]) {
        this._contracts.next(val);
    }

    get contract(): Contract {
        return this._contract.getValue();
    }

    set contract(val: Contract) {
        this._contract.next(val);
    }

    get latestPageRequest(): PageRequest<Contract> {
        return this._latestPageRequest;
    }

    set latestPageRequest(value: PageRequest<Contract>) {
        this._latestPageRequest = value;
    }

    // we'll compose the contracts$ observable with map operator to create a stream of only completed contracts
    // readonly completedContracts$ = this.contracts$.pipe(
    //     map(contracts => contracts.filter(contract => contract.isCompleted))
    // );
    //
    // readonly uncompletedContracts$ = this.contracts$.pipe(
    //     map(contracts => contracts.filter(contract => !contract.isCompleted))
    // );

    constructor(private contractsService: BcmContractsApiService) {
        this.fetchAll();
        StateResetService.onReset.subscribe(() => this.resetState());
    }

    async addContract(tmpContract: Contract): Promise<Contract> {

        if (tmpContract && tmpContract.title?.length) {

            // This is called an optimistic update
            // updating the record locally before actually getting a response from the server
            // this way, the interface seems blazing fast to the enduser
            // and we just assume that the server will return success responses anyway most of the time.
            // if server returns an error, we just revert back the changes in the catch statement

            const tmpId = -1;
            tmpContract.id = tmpId;

            this.contracts = [
                ...this.contracts,
                tmpContract
            ];

            try {
                const contract = await this.contractsService
                    .create(tmpContract)
                    .toPromise();

                // we swap the local tmp record with the record from the server (id must be updated)
                const index = this.contracts.indexOf(this.contracts.find(t => t.id === tmpId));
                this.contracts[index] = {
                    ...contract
                };
                this.contracts = [...this.contracts];

                return contract;

            } catch (e) {
                // if server sends back an error, we revert the changes
                console.error(e);
                this.removeContract(tmpContract, false);
            }

        }

        return Promise.reject('Vertrag nicht gespeichert. Fehlende Angaben.');
    }

    async removeContract(contract: Contract, serverRemove = true): Promise<void> {
        // optimistic update
        this.contracts = this.contracts.filter(c => c.id !== contract.id);
        this.contract = null;

        if (serverRemove) {
            try {
                await this.contractsService.remove(contract).toPromise();
            } catch (e) {
                console.error(e);
                this.contracts = [...this.contracts, contract];
                this.contract = contract;
            }
        }
    }

    async updateContract(contract: Contract, contractData: Contract): Promise<void> {
        if (contract?.id) {
            // optimistic update
            const index = this.contracts.map(x => x.id).indexOf(contract.id);

            this.contracts[index] = {
                ...contract,
                ...contractData
            };

            this.contracts = [...this.contracts];

            try {
                await this.contractsService
                    .update(this.contracts[index])
                    .toPromise();
            } catch (e) {

                console.error(e);
                this.contracts[index] = {...contract};
            }
        }
    }

    async fetchAll(): Promise<void> {
        this.contracts = await this.contractsService.getAll().toPromise();
    }

    async fetchOne(id: number): Promise<void> {
        this.contract = await this.contractsService.getOne(id).toPromise();
    }

    fetchOneAsPdf(contract: Contract, contractFileType?: ContractFileType): void {
        this.contractsService.generateTestContract(contract, contractFileType);
    }

    private resetState(): void {
        this._contracts.next([]);
        this._contract.next(null);
    }
}
