import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

interface ElectronWindow {
    electronApi: undefined | {
        onEvent: (channel: 'fromMain' | 'authEvent', cb: (...attrs: any[]) => void) => void,
        send: (channel: 'printReceipt', ...attrs: any[]) => void,
    };
}

@Injectable({providedIn: 'root'})
export class ElectronService {

    private readonly window: (WindowProxy & typeof globalThis & ElectronWindow);

    constructor(@Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView as (WindowProxy & typeof globalThis & ElectronWindow);
    }

    isElectronApp(): boolean {
        return this.window.electronApi != null;
    }

    onEventAuth(cb: (data: string) => void): void {
        if (!this.isElectronApp()) {
            return;
        }
        return this.window.electronApi?.onEvent('authEvent', cb);
    }

    onEventFromMain(cb: (data: any) => void): void {
        if (!this.isElectronApp()) {
            return;
        }
        return this.window.electronApi?.onEvent('fromMain', cb);
    }

    printReceipt(data: any): void {
        if (!this.isElectronApp()) {
            return;
        }
        return this.window.electronApi?.send('printReceipt', data);
    }
}
