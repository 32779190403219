import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApiService } from '@modules/bcm/@shared/services';
import { IUnit } from '@shared/models/unit';
import { PayableOption } from '@shared/models/payable-option';

export enum PayableOptionId {
    Yearly = 1,
    HalfYearly = 6,
    QuarterYearly = 7,
    Monthly = 2,
    Weekly = 3,
    Daily = 4,
    Hourly = 5,
    NextInvoice = 100
}

@Injectable({
    providedIn: 'root'
})
export class ProductPayableOptionApiService extends BcmApiService {

    private RESOURCE_ENDPOINT = 'products/payable-options';

    getAll(params: HttpParams = new HttpParams()): Observable<PayableOption[]> {
        return super.get<PayableOption[]>(this.RESOURCE_ENDPOINT, params);
    }

}
