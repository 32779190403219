import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BcmApi2Service } from '@modules/bcm/@shared/services';
import { BcmSettings, BcmSettingsSection, BcmSettingsSectionName } from '@shared/models/bcm-settings';
import { BcmUserSettings, BcmUserSettingsSection, BcmUserSettingKey } from '@shared/models/bcm-settings-user';

@Injectable({
    providedIn: 'root'
})
export class BcmSettingsApiService extends BcmApi2Service {

    getAll(params: HttpParams = new HttpParams()): Observable<BcmSettings> {
        return super.get<BcmSettings>('settings', params);
    }

    updateSection(sectionKey: BcmSettingsSectionName, body: BcmSettingsSection): Observable<any> {
        return super.put(`settings/${String(sectionKey)}`, body || {});
    }

    updateSectionItem<T, R extends any>(sectionKey: BcmSettingsSectionName,
                                        settingKey: keyof T,
                                        value: R): Observable<any> {
        return super.put(`settings/${String(sectionKey)}/${String(settingKey)}`, {value});
    }

    getUserSettings(params: HttpParams = new HttpParams()): Observable<BcmUserSettings> {
        return super.get<BcmUserSettings>('user-settings', params);
    }

    getSingleUserSetting<T extends BcmUserSettingsSection>(settingKey: BcmUserSettingKey | string, params: HttpParams = new HttpParams()): Observable<T> {
        return super.get<T>(`user-settings/${String(settingKey)}`, params);
    }

    updateSingleUserSetting<T extends BcmUserSettingsSection>(settingKey: BcmUserSettingKey | string, body: T): Observable<T> {
        return super.put<T>(`user-settings/${String(settingKey)}`, body || {});
    }

    sendTestMail(sectionKey: string): Observable<any> {
        return super.post(`settings/${sectionKey}/send-test-mail`);
    }
}
