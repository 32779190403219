import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Invoice } from '@shared/models/invoice';
import { InvoiceTypes } from '@shared/invoice-types';
import { TranslationService } from '@core/translation/translation.service';

@Component({
    selector: 'bcm-invoice-link',
    templateUrl: './invoice-link.component.html'
})
export class InvoiceLinkComponent implements OnChanges {

    @Input()
    public invoice!: Invoice;

    @Input()
    public dataTest: string;

    @Input()
    public additionalInfo: string;

    @Input()
    public disableLink: boolean;

    public viewData: string;

    @Output()
    public clicked = new EventEmitter<void>();

    constructor(private _translationService: TranslationService) {
    }

    ngOnChanges(): void {
        const invoice = this.invoice;
        this.viewData = [
            (invoice.invoiceNumber || null),
            (TranslationService.translate(InvoiceTypes[invoice.invoiceType], null, null)),
            this.additionalInfo,
        ].filter(item => item != null)
            .join(' - ');
    }

    click(event): void {
        this.clicked.emit();
        event.stopPropagation();
    }
}
