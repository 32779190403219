import { Injectable } from '@angular/core';
import { Tenant } from '@shared/models/tenant';
import { BcmService } from '@modules/bcm/bcm.service';
import { catchError, filter, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { HashMap, Translation, TranslationLanguage } from '@core/translation/translation-models';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private static activeLanguage: TranslationLanguage;

    private static activeTranslation: Translation;

    private readonly apiUrl = environment.endpoints.bcm.api;

    private readonly endpoint = 'i18n';

    private readonly cachedTranslations = new Map<TranslationLanguage | string, Translation>();

    constructor(private httpClient: HttpClient,
                private _bcmService: BcmService) {
        this._bcmService.selectedTenant$
            .pipe(filter(tenant => !!tenant?.id))
            .subscribe(tenant => {
                // todo: only load if tenant has custom translations ? Or cache in BE ?
                this.loadTranslation(TranslationService.activeLanguage, tenant).subscribe();
            });
    }

    static translate(key: string, params?: HashMap, fallbackValue?: string): string {

        if (fallbackValue === undefined) {
            fallbackValue = key;
        }
        
        if (params) {
            return this.activeTranslation && this.activeTranslation[key]
                ? TranslationService.replacePlaceholders(this.activeTranslation[key], params) || fallbackValue
                : fallbackValue;
        }

        return this.activeTranslation && this.activeTranslation[key]
            ? this.activeTranslation[key]
            : fallbackValue;
    }

    private static replacePlaceholders(inputString: string, params: HashMap): string {
        // Regex for {{ }}
        const regex = /{{\s*([^}\s]+)\s*}}/g;

        // Replace all {{ }} with values from valueObject
        return inputString.replace(regex, (_match, placeholder) => {
            // If value does not exist -> use '?'
            return params.hasOwnProperty(placeholder) ? params[placeholder] : '?';
        });
    }

    init(activeLanguage: TranslationLanguage): Observable<boolean> {
        return this.loadTranslation(activeLanguage);
    }

    getActiveLanguage(): string {
        return TranslationService.activeLanguage;
    }

    // getActiveTranslation(): Translation {
    //     return TranslationService.activeTranslation;
    // }

    // do not delete. We need it later
    // getAvailableLanguages(): string[] {
    //     return this._availableLanguages;
    // }

    public loadTranslation(lang: TranslationLanguage, tenant?: Tenant): Observable<boolean> {
        const cacheIdent = tenant?.id ? `${lang}${tenant.id}` : lang;
        const cachedTranslation = this.cachedTranslations.get(cacheIdent);

        if (cachedTranslation) {
            this.setActiveTranslation(cachedTranslation, lang, tenant);
            return of(true);
        }

        return this.httpClient
            .get<Translation>(`${this.apiUrl}${tenant?.id ? `${tenant.id}/` : ''}${this.endpoint}/${lang}`)
            .pipe(
                catchError(error => {
                    // todo: redirect to error 500 page
                    console.error(error);
                    return of(undefined);
                }),
                map(translation => {
                    // console.log(translation);
                    if (translation) {
                        this.setActiveTranslation(translation, lang, tenant);
                    } else {
                        // fallback to default
                        this.setActiveTranslation(this.cachedTranslations.get(TranslationLanguage.DE), lang, tenant);
                    }
                    return !!translation;
                })
            );
    }

    public setActiveTranslation(translation: Translation,
                                lang: TranslationLanguage,
                                tenant?: Tenant) {
        TranslationService.activeTranslation = translation;
        TranslationService.activeLanguage = lang;

        const cacheIdent = tenant?.id ? `${lang}${tenant.id}` : lang;
        this.cachedTranslations.set(cacheIdent, translation);
    }
}
