<div [formGroup]="contractCoOwnerForm">

    <ng-container *ngIf="headline">
        <h2>{{ headline }}</h2>
    </ng-container>


    <mat-checkbox [disabled]="disabled"
                  [(ngModel)]="captureContractCoOwner"
                  [ngModelOptions]="{standalone: true}">
        Vertragsmitinhaber erfassen
    </mat-checkbox>

    <ng-container *ngIf="captureContractCoOwner">
        <section class="pt-12">
            <mat-tab-group>
                <ng-container *ngFor="let i of [1,2,3,4]">
                    <mat-tab label="Mitinhaber {{i}}">

                        <div class="py-24">
                            <div fxLayout="column wrap">
                                <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                                    <mat-label>Vollständiger Name (inkl. Titel, Anrede, ...)</mat-label>
                                    <input matInput
                                           type="text"
                                           data-test="FI-Titel"
                                           [formControlName]="'VMI_' + i + '_NAME_KOMPLETT'"
                                           autocomplete="new-password">
                                    <mat-error>{{ contractCoOwnerForm.get('VMI_' + i + '_NAME_KOMPLETT').errors | firstErrorMessage }}</mat-error>
                                </mat-form-field>

                                <mat-form-field [appearance]="appearance" fxFlex="100" floatLabel="always">
                                    <mat-label>Straße</mat-label>
                                    <input matInput
                                           type="text"
                                           data-test="FI-Straße"
                                           [formControlName]="'VMI_' + i + '_STR'"
                                           autocomplete="new-password">
                                    <mat-error>{{ contractCoOwnerForm.get('VMI_' + i + '_STR').errors | firstErrorMessage }}</mat-error>
                                </mat-form-field>

                                <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
                                    <mat-label>PLZ</mat-label>
                                    <input matInput
                                           type="text"
                                           data-test="FI-PLZ"
                                           [formControlName]="'VMI_' + i + '_PLZ'"
                                           autocomplete="new-password">
                                    <mat-error>{{ contractCoOwnerForm.get('VMI_' + i + '_PLZ').errors | firstErrorMessage }}</mat-error>
                                </mat-form-field>

                                <mat-form-field [appearance]="appearance" fxFlex="50" floatLabel="always">
                                    <mat-label>Ort</mat-label>
                                    <input matInput
                                           type="text"
                                           data-test="FI-Ort"
                                           [formControlName]="'VMI_' + i + '_ORT'"
                                           autocomplete="new-password">
                                    <mat-error>{{ contractCoOwnerForm.get('VMI_' + i + '_ORT').errors | firstErrorMessage }}</mat-error>
                                </mat-form-field>

                            </div>
                        </div>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
        </section>
    </ng-container>

</div>
