<div class="dialog-content-wrapper" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragBoundary="body">
    <mat-toolbar matDialogTitle class="mat-accent m-0" cdkDragHandle>
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Release-Notes</span>
            <button data-test="B-Dialog schließen" mat-icon-button (click)="dialogRef.close(false)"
                    aria-label="Dialog schließen">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-16 p-sm-24 m-0">

        <mat-tab-group dynamicHeight="true">

            <mat-tab label="Info">
                <div class="p16" fusePerfectScrollbar>
                    <h3>Up2Boat Yachthafenmanagement - Release Notes</h3>
                    <p>
                        Up2Boat und die dazugehörenden Dienste werden kontinuierlich gewartet und weiterentwickelt. Neue
                        Versionsstände (sogenannte Releases) werden nach der Entwicklung zunächst von unserem Testteam
                        in einer speziellen Testumgebung qualitätsgesichert, bevor sie in der Produktionsumgebung für
                        alle Nutzer verfügbar gemacht werden. Das „Verfügbar machen“ nennt man im Fachjargon „releasen“.
                        Dieses Dokument beschreibt, was sich von Release zu Release geändert hat, welche
                        Anwendungsfehler bereinigt wurden und welche neuen Funktionen mit dem jeweiligen Release
                        produktiv genommen wurden.
                    </p>
                    <p>
                        Release-Notes wurden mit der Version 2022.1.8 eingeführt.
                    </p>
                </div>
            </mat-tab>

            <ng-container *ngFor="let releaseNote of releaseNotes">
                <mat-tab [label]="releaseNote.releaseNumber">
                    <ng-template matTabContent>
                        <u2b-release-note-body [releaseNote]="releaseNote"></u2b-release-note-body>
                    </ng-template>
                </mat-tab>
            </ng-container>

        </mat-tab-group>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button data-test="B-Abbrechen" mat-button
                (click)="dialogRef.close(false)"
                class="save-button mat-primary"
                aria-label="Abbrechen">
            <mat-icon>cancel</mat-icon>
            Schließen
        </button>

    </div>
</div>
