<div class="input-number-wrapper">
    <input type="text"
           #input
           matInput
           class="mat-input-element {{align === 'r' ? 'align-right' : ''}}"
           autocomplete="new-password"
           [errorStateMatcher]="errorStateMatcher"
           [placeholder]="placeholder"
           [disabled]="disabled"
           [value]="parsedValue"
           (input)="customOnInput($event)"
           (change)="customOnChange($event)"
           (blur)="input.value = parsedValue">

    <ng-container *ngIf="!disabled && showArrowButtons">
        <div class="inc" contentEditable="false" (click)="incrementValue($event)" (dblclick)="disableEvent($event)">
            <div class="input-number-inc" contentEditable="false"></div>
        </div>
        <div class="dec" contentEditable="false" (click)="decrementValue($event)" (dblclick)="disableEvent($event)">
            <div class="input-number-dec" contentEditable="false"></div>
        </div>
    </ng-container>
</div>
