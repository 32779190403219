import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { parseHttpResponseList, parseHttpResponseItem } from '@shared/functions/parse-http-response';
import { IElectricMeter, ElectricMeter } from '@shared/models/electric-meter';
import { BcmApi2Service } from '@modules/bcm/@shared/services';

@Injectable({
    providedIn: 'root'
})
export class ElectricMeterApiService extends BcmApi2Service {

    private RESOURCE_ENDPOINT = 'electric-meters';

    getAll(params: HttpParams = new HttpParams()): Observable<ElectricMeter[]> {
        return super.get<IElectricMeter[]>(this.RESOURCE_ENDPOINT, params)
            .pipe(parseHttpResponseList<IElectricMeter, ElectricMeter>(ElectricMeter));
    }

    count(params: HttpParams = new HttpParams()): Observable<number> {
        return super.get<number>(this.RESOURCE_ENDPOINT + '/count', params);
    }

    getOne(id: number, params: HttpParams = new HttpParams()): Observable<ElectricMeter> {
        return super.get<IElectricMeter>(`${this.RESOURCE_ENDPOINT}/${id}`, params)
            .pipe(parseHttpResponseItem<IElectricMeter, ElectricMeter>(ElectricMeter));
    }

    add(body: object = {}): Observable<ElectricMeter> {
        return super.post(this.RESOURCE_ENDPOINT, body);
    }

    update(id: number, body: object = {}): Observable<ElectricMeter> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}`, body);
    }

    updateCabinet(id: number, cabinet: any): Observable<number> {
        return super.put(`${this.RESOURCE_ENDPOINT}/${id}/switch-cabinet`, cabinet);
    }

    remove(id: number): Observable<ElectricMeter> {
        return super.delete(`${this.RESOURCE_ENDPOINT}/${id}`);
    }
}
